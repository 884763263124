import BigNumber from 'bignumber.js';
import { CoupaPurchaseLineType, MspModel } from '@amzn/merp-core/models';
import { Receipt } from 'modules/purchase/models';
import { CoupaReceiptType } from 'modules/purchase/models/CoupaReceiptType';

export class BulkReceivingRow extends MspModel {
    public poId?: string;
    public poNo?: string;
    public lineId?: string;
    public lineType?: CoupaPurchaseLineType.ORDER_AMOUNT_LINE | CoupaPurchaseLineType.ORDER_QUANTITY_LINE;
    public poCreationTime?: number;
    public poRequestor?: string;
    public vendor?: string;
    public locationName?: string;
    public lineNo?: number;
    public lineDescription?: string;
    public currency?: string;
    public totalInvoiced?: number;
    public pendingInvoiced?: number;
    public receivedQuantity?: number;
    public totalReceived?: number;
    public unitPrice?: number;
    public amount?: number;
    public quantity?: number;
    public status?: 'NO_RECEIVING' | 'PARTIALLY_RECEIVED' | 'FULLY_RECEIVED';
    public invoiceStatus?: 'NO_INVOICES' | 'PARTIALLY_INVOICED' | 'PENDING_INVOICE' | 'FULLY_INVOICED';
    public lastReceivedDate?: number;
    public companyCode?: string;
    public location?: string;
    public costCenter?: string;
    public glAccount?: string;
    public projectCode?: string;
    public productLine?: string;
    public channel?: string;
    public poCreator?: string;
    public unusedAmount = 0;
    public unusedQuantity = 0;
    public receivingAmount = 0;
    public receivingQuantity = 0;

    public static from(obj: Record<string, unknown> | BulkReceivingRow): BulkReceivingRow {
        return Object.assign(new BulkReceivingRow(), obj);
    }

    public receive(_amount: unknown): Receipt {
        if (isNaN(parseFloat(_amount as string))) throw new Error(`${_amount} is not a number`);

        const amount = new BigNumber(parseFloat(_amount as string));

        if (this.isQuantityBased) {
            this.receiveQuantity(amount);
        } else {
            this.receiveAmount(amount);
        }

        const receipt: Receipt = Object.assign(new Receipt(), {
            purchaseId: this.poId,
            orderNumber: this.poNo,
            lineNumber: this.lineNo,
            receiptTime: Date.now(),
            receivingType: this.isQuantityBased ? CoupaReceiptType.QUANTITY : CoupaReceiptType.AMOUNT,
            quantity: this.isQuantityBased ? amount.toNumber() : undefined,
            receiptAmount: this.isQuantityBased ? this.toAmount(amount) : amount.toNumber()
        });

        return receipt;
    }

    public receiveInFull(): Receipt {
        if (this.isQuantityBased) {
            return this.receive(this.unusedQuantity);
        }

        return this.receive(this.unusedAmount);
    }

    public get isQuantityBased() {
        return this.lineType === CoupaPurchaseLineType.ORDER_QUANTITY_LINE;
    }

    public get receivingUnusedAmount() {
        return this.unusedAmount ? this.computeUnused(this.unusedAmount, this.receivingAmount) : this.unusedAmount;
    }

    public get receivingUnusedQuantity() {
        return this.unusedQuantity
            ? this.computeUnused(this.unusedQuantity, this.receivingQuantity)
            : this.unusedQuantity;
    }

    private receiveQuantity(quantity: BigNumber) {
        this.receivingQuantity = quantity.toNumber();
        this.receivingAmount = this.toAmount(quantity);
    }

    private receiveAmount(amount: BigNumber) {
        this.receivingAmount = amount.toNumber();
    }

    private toAmount(quantity: BigNumber): number {
        return quantity.multipliedBy(this.unitPrice ?? 0).toNumber();
    }

    private computeUnused(unused: number, receiving: number): number {
        return new BigNumber(unused).minus(new BigNumber(receiving)).toNumber();
    }
}
