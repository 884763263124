import React from 'react';
import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { RouteComponentProps } from '@reach/router';
import { ApplicationState } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BulkEditSpend } from '../components';

export const BulkEdit = (_props: RouteComponentProps) => {
    const { t } = useTranslation('purchase');
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const breadcrumbItems = [
        { text: t('breadCrumbs.poList'), href: '/purchaseOrder' },
        {
            text: t('breadCrumbs.bulkUpload'),
            href: '#'
        }
    ];

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                ) : (
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <BreadcrumbGroup ariaLabel="Breadcrumbs" items={breadcrumbItems} />
                                <BulkEditSpend />
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </div>
                )
            }
            toolsHide={true}
            navigationHide={true}
        />
    );
};
