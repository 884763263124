import { ApiClient } from 'modules/core/class/api/ApiClient';

interface UserEditResponse {
    validUserEdit?: boolean;
}

interface UserSoftCloseAccessResponse {
    validUserSoftCloseAccess?: boolean;
}

export class ValidateUserEditService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'users';

    public async validateUserEditAsync(purchaseId: string, login?: string): Promise<UserEditResponse> {
        try {
            const url = `validateUserEdit?purchaseId=${purchaseId}`;
            const updatedUrl = login ? `${url}&login=${login}` : url;

            await this.get(updatedUrl);

            return { validUserEdit: true };
        } catch (error) {
            return { validUserEdit: false };
        }
    }

    public async validateUserSoftCloseAccessAsync(
        purchaseId: string,
        login?: string
    ): Promise<UserSoftCloseAccessResponse> {
        try {
            const url = `validateUserHasSoftCloseAccess?purchaseId=${purchaseId}`;
            const updatedUrl = login ? `${url}&login=${login}` : url;

            await this.get(updatedUrl);

            return { validUserSoftCloseAccess: true };
        } catch (error) {
            return { validUserSoftCloseAccess: false };
        }
    }
}

export const validateUserEditService = new ValidateUserEditService();
