export class CSVTemplate {
    private csvData: string[][] = [];
    private headers: string[] = [];

    public setHeaders(header: string[]) {
        this.headers = header;
    }

    public setCSVData(csvData: string[]) {
        this.csvData.push(csvData);
    }

    public getHeaders() {
        return this.headers;
    }

    public getCSVData() {
        return this.csvData;
    }

    public get isEmpty() {
        return this.csvData.length < 1;
    }
}
