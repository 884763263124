import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState, getTeamNames } from 'modules/app/store';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { v4 as uuidV4 } from 'uuid';
import { Form, Header } from '@amzn/awsui-components-react';
import { Budget, BudgetStatus, BudgetStep, GuidanceCycle } from '@amzn/merp-core/models/budgetManagement';

import { DateGenerator } from 'modules/core/class';
import { TaskTable } from 'modules/budgetManagement/components/TaskTable';
import { BudgetPlanTable } from 'modules/budgetManagement/components/BudgetPlanTable';
import { StartCycleModal } from 'modules/budgetManagement/components/StartCycleModal';
import { useGuidanceCycle, useBudget, useTableRowForecast, useTask } from 'modules/budgetManagement/hooks';

export interface FinancialAnalystDashboardProps {
    onStartGuidancecycle?: (success: boolean) => void;
}

export const FinancialAnalystDashboard = ({ onStartGuidancecycle }: FinancialAnalystDashboardProps) => {
    const { t } = useTranslation('budgetManagement');

    const teamNames = useSelector(getTeamNames);
    const { loadingTasks, tasks } = useTask();
    const { loadingForecast, items } = useTableRowForecast();
    const { startGuidanceCycle } = useGuidanceCycle();
    const { ongoingBudgets } = useBudget('', false, '', teamNames);

    const userClaims = useSelector((state: ApplicationState) => state.user);
    const userAlias = userClaims.alias;

    const [isStartCycleModalVisible, setStartCycleModalVisible] = useState<boolean>(false);

    const currentYear = DateGenerator.getFullYear();

    const getUnassignedCoaCount = () => {
        let unassignedCount = 0;

        for (const item of items ?? []) {
            if (item.payload.owner === undefined) {
                unassignedCount += 1;
            }
        }

        return unassignedCount;
    };

    const onEditCoaStringsClicked = () => {
        return window.open('/bulkEdit/accountingStrings', '_blank');
    };

    function genereteBudget(yearGuidanceCycle?: string) {
        const currentTime = Date.now();

        const budget = new Budget();

        const userBrassTeam = teamNames ? teamNames[0] : '';

        const cycleYear = Number(yearGuidanceCycle?.split(' ')[0]);
        let guidanceCycle = yearGuidanceCycle?.split(' ')[1];
        guidanceCycle = guidanceCycle === 'Y3F' ? 'THREE_YF' : guidanceCycle;

        const indexOfGuidanceCycle = Object.values(GuidanceCycle).indexOf((guidanceCycle as unknown) as GuidanceCycle);
        const cycle = Object.keys(GuidanceCycle)[indexOfGuidanceCycle] as GuidanceCycle;

        budget.id = uuidV4();
        budget.isLocked = false;
        budget.guidanceCycle = cycle;
        budget.createdAt = currentTime;
        budget.updatedAt = currentTime;
        budget.team = userBrassTeam;
        budget.createdBy = userAlias;
        budget.requestedBy = userAlias;
        budget.teamYear = `${userBrassTeam}_${cycleYear}`;
        budget.version = currentTime.toString();
        budget.step = BudgetStep.ASSIGN_OWNERS;
        budget.lastCognosSync = 0;
        budget.ignoredAccountingStrings = [];
        budget.year = cycleYear;

        return budget;
    }

    const onSelectedCycleSubmitted = async (selectedYearCycle?: string) => {
        if (selectedYearCycle) {
            const result = await startGuidanceCycle(genereteBudget(selectedYearCycle));
            if (onStartGuidancecycle) {
                onStartGuidancecycle(result);
            }
        }
    };

    const onAssignOwnerClicked = () => {
        return navigate('/budget/assignOwners');
    };

    function generateCycleOptions() {
        return [
            `${currentYear} OP1`,
            `${currentYear} OP2`,
            `${currentYear} Q2G`,
            `${currentYear} Q3G`,
            `${currentYear} Q4G`,
            `${currentYear} 3YF`
        ];
    }

    function generateOngoingCycle() {
        let ongoingCycles: string[] = [];

        if (ongoingBudgets) {
            for (let i = 0; i < ongoingBudgets.length; i++) {
                const cycle = ongoingBudgets[i].guidanceCycle;
                const cycleDisplay =
                    ongoingBudgets[i].guidanceCycle === GuidanceCycle.THREE_YF ? 'Y3F' : cycle.toString();
                ongoingCycles = ongoingCycles.concat(`${ongoingBudgets[i].year} ${cycleDisplay}`);
            }
        }

        return ongoingCycles;
    }

    function startedOngoingBudgetsExist(ongoingBudgets: Budget[] | undefined) {
        if (!ongoingBudgets) {
            return false;
        }

        let startedBudgetsCount = 0;
        for (let i = 0; i < ongoingBudgets.length; i++) {
            const budgetStatus = ongoingBudgets[i].status;
            if (BudgetStatus.STARTED === budgetStatus) {
                startedBudgetsCount += 1;
            }
        }

        return startedBudgetsCount === 0;
    }

    function startedAndUnlockedBudgetsExist(ongoingBudgets: Budget[] | undefined) {
        if (!ongoingBudgets) {
            return false;
        }

        let lockedBudgets = 0;
        for (let i = 0; i < ongoingBudgets.length; i++) {
            const budgetStatus = ongoingBudgets[i].status;
            if (BudgetStatus.STARTED === budgetStatus && !ongoingBudgets[i].isLocked) {
                lockedBudgets += 1;
            }
        }

        return lockedBudgets > 0;
    }

    return (
        <Form header={<Header variant="h1">{t('dashboard')}</Header>}>
            <TaskTable items={tasks ?? []} loading={loadingTasks} forecastsWithNoOwnerCount={getUnassignedCoaCount()} />
            <br />
            <BudgetPlanTable
                items={items ?? []}
                loading={loadingForecast}
                inGuidanceCycle={!startedOngoingBudgetsExist(ongoingBudgets)}
                isGuidanceCycleLocked={!startedAndUnlockedBudgetsExist(ongoingBudgets)}
                onEditCoaStringsClicked={onEditCoaStringsClicked}
                onStartCycleClicked={() => setStartCycleModalVisible(true)}
                onAssignOwnerClicked={onAssignOwnerClicked}
            />

            {isStartCycleModalVisible && (
                <StartCycleModal
                    cycleOptions={generateCycleOptions()}
                    ongoingCycles={generateOngoingCycle()}
                    onDismissed={() => setStartCycleModalVisible(false)}
                    onChange={onSelectedCycleSubmitted}
                ></StartCycleModal>
            )}
        </Form>
    );
};
