import { useState } from 'react';
import { PurchaseSubmitStatus, MspOwner, SoftCloseStatus } from 'modules/purchase/models';
import { logger } from 'modules/core';
import { ownerService } from 'modules/purchase/services';
import { updateOwnersList } from 'modules/app/store';
import { useDispatch } from 'react-redux';
import { OwnerUpdateStatus } from '../models/OwnerUpdateStatus';

interface SubmitResult {
    status: PurchaseSubmitStatus | OwnerUpdateStatus | SoftCloseStatus;
    reason: string;
}

export function usePurchaseOwner(purchaseId: string) {
    const [ownerUpdated, updateOwnerStatus] = useState<SubmitResult>({
        status: OwnerUpdateStatus.NOT_UPDATED,
        reason: ''
    });

    const dispatch = useDispatch();

    async function updateMspOwnersAsync(updateOwners: MspOwner[]): Promise<void> {
        try {
            const result = await ownerService.updateMspOwnersAsync(purchaseId, updateOwners);

            const resultStatus = result.owners ? OwnerUpdateStatus.UPDATE_SUCCESS : OwnerUpdateStatus.UPDATE_FAILURE;

            if (result.owners && result.errorDescription === null) {
                dispatch(updateOwnersList(result.owners));
            }

            updateOwnerStatus({ status: resultStatus, reason: result.errorDescription });
        } catch (error) {
            logger.error(error);
        }
    }

    return {
        ownerUpdated,
        updateMspOwnersAsync
    };
}
