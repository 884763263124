import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules/app/store';
import { Container, Header, Button, Link, FormField, Icon } from '@amzn/awsui-components-react';
import { FileUpload } from 'modules/core/components/FileUpload';
import { navigate } from '@reach/router';

import style from './RequestChangeContainer.module.scss';

interface RequestChangeContainerProps {
    uploadSuccess: boolean;
    allowUpload: boolean;
    onFileUpload?: (uploadfile: File, fileUrl: string) => Promise<void>;
    previewChanges?: () => void;
    file?: File;
}

export const RequestChangeContainer = (props: RequestChangeContainerProps) => {
    const { t } = useTranslation('budgetManagement');

    const { uploadSuccess, allowUpload, onFileUpload, previewChanges, file } = props;

    const userClaims = useSelector((state: ApplicationState) => state.user);
    const userAlias = userClaims.alias;
    const firstName = userClaims.firstName || userAlias;
    const lastName = userClaims.lastName ?? '';

    const onClickedBackToTaskList = () => {
        navigate('/budget');
    };

    const getHeader = () => {
        return (
            <Header
                variant="h2"
                actions={
                    uploadSuccess && (
                        <Button onClick={onClickedBackToTaskList} variant="primary">
                            {t('requestChange.container.backToTaskList')}
                        </Button>
                    )
                }
            >
                {t('requestChange.container.header')}
                <Link className="ml-1">{t('info')}</Link>
            </Header>
        );
    };

    const getContent = () => {
        return uploadSuccess ? (
            <div>
                <div>
                    {t('requestChange.container.uploadSuccess', {
                        firstName: firstName,
                        lastName: lastName,
                        alias: userAlias
                    })}
                </div>
                <div>{t('requestChange.container.uploadSuccessRedirect')}</div>
                <div className={style['upload-success-icon']}>
                    <Icon name="status-positive" size="large" variant="success" />
                </div>
            </div>
        ) : (
            <FormField
                description={t('requestChange.container.uploadDescription')}
                label={t('requestChange.container.uploadUser', { firstName: firstName })}
            >
                <br />
                <FileUpload onFileUpload={onFileUpload} file={file} fileFormats={'.csv'} />
                <br />
                <Button variant="primary" onClick={previewChanges} disabled={!allowUpload}>
                    {t('requestChange.container.upload')}
                </Button>
            </FormField>
        );
    };

    return <Container header={getHeader()}>{getContent()}</Container>;
};
