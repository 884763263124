export class OrgCostCentersDetails {
    costCenterName: string;
    initialMappedOrg?: string;
    mappedOrg?: string;

    constructor(costCenterName?: string, initialMappedOrg?: string) {
        this.costCenterName = costCenterName || '';
        this.initialMappedOrg = initialMappedOrg;
        this.mappedOrg = initialMappedOrg;
    }

    isNewCostCenter = () => !this.initialMappedOrg;
    isReassigned = () => this.initialMappedOrg && this.mappedOrg !== this.initialMappedOrg;
}
