/* eslint-disable react/display-name */
import React from 'react';
import { HelpPanel } from 'modules/core/hooks/useHelpPanel.hook';
import { ToolContent } from 'modules/purchase/components';
import { ChannelsDescriptionToolContent } from 'modules/channel/components';

export type RequisitionDetailPagePanels =
    | 'PR_HELP'
    | 'P_LINE_HELP'
    | 'SPEND_HELP'
    | 'BIG_ROCK_OBJECTIVE_HELP'
    | 'CHANNEL_HELP'
    | 'CENTRAL_RECEIVER_HELP';

export const detailPagePanels: HelpPanel<RequisitionDetailPagePanels>[] = [
    {
        key: 'PR_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent
                header="helpPanel.PRHeader"
                paragraphs={['helpPanel.PRPara1', 'helpPanel.PRPara2']}
                link={link}
            />
        )
    },
    {
        key: 'P_LINE_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent header="helpPanel.POLineHeader" paragraphs={['helpPanel.POLinePara1']} link={link} />
        )
    },
    {
        key: 'SPEND_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent
                header="helpPanel.SDHeader"
                paragraphs={['helpPanel.SDPara1', 'helpPanel.SDPara2']}
                link={link}
            />
        )
    },
    {
        key: 'BIG_ROCK_OBJECTIVE_HELP',
        content: () => <ToolContent header="helpPanel.bigRockHeader" paragraphs={['helpPanel.bigRockPara1']} />
    },
    {
        key: 'CHANNEL_HELP',
        content: () => <ChannelsDescriptionToolContent />
    },
    {
        key: 'CENTRAL_RECEIVER_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent header="helpPanel.OwnerHeader" paragraphs={['helpPanel.permissionsInfo']} link={link} />
        )
    }
];
