import { auditEventDetailsService, EventDetailsResult } from 'modules/audit/services';
import { PurchaseLine, Receipt, ReceiptStatus } from 'modules/purchase/models';
import { useCallback, useEffect, useState } from 'react';

export function getEventDetails(purchaseId: string, lineVersionLineNumber: string): EventDetailsResult {
    const [versionType, updateVersionType] = useState<string>();
    const [originalPurchaseLine, updateOriginalLine] = useState<PurchaseLine>();
    const [modifiedPurchaseLine, updateModifiedLine] = useState<PurchaseLine>();

    const getEventDetailsAsync = useCallback(async () => {
        const {
            versionType,
            originalPurchaseLine,
            modifiedPurchaseLine
        } = await auditEventDetailsService.getEventDetailsAsync(purchaseId, lineVersionLineNumber);

        originalPurchaseLine.receipts = originalPurchaseLine?.receipts || [];
        modifiedPurchaseLine.receipts = modifiedPurchaseLine?.receipts || [];

        if (modifiedPurchaseLine.receipts.length < originalPurchaseLine.receipts.length) {
            const modifiedReceiptNumbers = modifiedPurchaseLine.receipts.map((r: Receipt) => r.receiptNumber);

            const missingInModifiedAndPosted = (r: Receipt) =>
                !modifiedReceiptNumbers.includes(r.receiptNumber) && r.receiptStatus === ReceiptStatus.POSTED;

            originalPurchaseLine.receipts.filter(missingInModifiedAndPosted).forEach((originalReceipt: Receipt) => {
                modifiedPurchaseLine.receipts.push({
                    ...originalReceipt,
                    receiptStatus: ReceiptStatus.VOIDED,
                    allocations: [],
                    channelAllocations: []
                });
            });
        }

        updateModifiedLine(modifiedPurchaseLine);
        updateOriginalLine(originalPurchaseLine);
        updateVersionType(versionType);
    }, [purchaseId, lineVersionLineNumber]);

    useEffect(() => {
        getEventDetailsAsync();
    }, [getEventDetailsAsync]);

    return { versionType, originalPurchaseLine, modifiedPurchaseLine };
}
