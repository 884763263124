import {
    BULK_EDIT_TAB,
    BULK_RECEIVING_SPEND_HEADER,
    BulkUploadReportName,
    FILE_READ_ERROR,
    GET_MANDATORY_FIELDS,
    INCORRECT_PLACE_TO_UPLOAD,
    MAX_BULK_RECEIVING_ROWS,
    ALTERNATIVE_BULK_UPLOAD_REPORT_NAME,
    ABPO_BULK_EDIT_TAB,
    QBPO_BULK_EDIT_TAB,
    EDITABLE_SHEET_NAMES,
    MONTHS,
    EditableSheetName
} from 'modules/bulkEdit/constants';
import { validateReceivingQuantityPrecision } from '../validators';
import { ReportName } from 'modules/reports/constants';
import { useState } from 'react';
import { utils as xlsxUtils, read as xlsxRead } from 'xlsx';
import _ from 'underscore';

type ParsedWorkbook = { [sheetName: string]: string[][] };
type BulkUploadExcel = { workbook: ParsedWorkbook; errors: string[] };
type SheetInfo = {
    sheetName: string;
    sheetRows: string[][];
    headers: string[];
};
type ForEachSheetCallback = (sheetInfo: SheetInfo) => void;

export function useBulkEditUpload() {
    const [errors, setErrors] = useState<string[]>([]);
    const [reportName, setReportName] = useState<ReportName>(ReportName.ExportBulkEditSpend);

    const forEachSheet = (workbook: ParsedWorkbook, cb: ForEachSheetCallback): void => {
        _.each(workbook, (sheetRows, sheetName) => {
            cb({ sheetName, sheetRows, headers: sheetRows[0] || [] });
        });
    };

    const validateFile = async (fileUrl: string, reportName: BulkUploadReportName) => {
        setErrors([]);
        const { workbook, errors } = await getBulkUploadExcel(reportName, fileUrl);

        if (_.isEmpty(errors)) {
            validateDuplicateHeaders(workbook, errors);
            validateMandatoryHeaders(workbook, reportName, errors);
            validateSpendHeaders(workbook, errors);
            validateEditTabRowCount(workbook, errors);
            validateAmountAndQuantityTabRowCount(workbook, errors);
            validateReceivingQuantityPrecision(workbook, errors);
        }

        setErrors(e => _.uniq([...e, ...errors]));

        return errors;
    };

    const validateDuplicateHeaders = (workbook: ParsedWorkbook, errors: string[]): void => {
        forEachSheet(workbook, ({ sheetName, headers }) => {
            if (_.uniq(headers).length !== headers.length) {
                errors.push(`Duplicate headers found on sheet ${sheetName}`);
            }
        });
    };

    const validateMandatoryHeaders = (
        workbook: ParsedWorkbook,
        reportName: BulkUploadReportName,
        errors: string[]
    ): void => {
        forEachSheet(workbook, ({ sheetName, headers }) => {
            if (!EDITABLE_SHEET_NAMES.includes(sheetName)) return;
            const editableSheetName = sheetName as EditableSheetName;
            const mandatoryHeaders = GET_MANDATORY_FIELDS(reportName, editableSheetName);
            if (!mandatoryHeaders.every(i => headers.includes(i))) {
                const error = `Please ensure sheet '${sheetName}' has all mandatory columns present.`;
                const mandatory = `Mandatory columns are: ${mandatoryHeaders.join(', ')}`;
                errors.push(`${error} ${mandatory}`);
            }
        });
    };

    const validateSpendHeaders = (workbook: ParsedWorkbook, errors: string[]): void => {
        const editSheet = workbook[BULK_EDIT_TAB];
        if (!editSheet || editSheet.length < 1) return;
        const spendHeaders = editSheet[0].filter(header => header.includes('(Spend)'));
        if (!spendHeaders.length) {
            errors.push(`Please ensure sheet '${BULK_EDIT_TAB}' has at least one spend column present.`);
        }

        const re = new RegExp(`^(${MONTHS.join('|')}) \\d{4} \\(Spend\\)$`);
        const invalidSpendHeaders = spendHeaders.filter(header => !header.match(re));
        if (invalidSpendHeaders.length) {
            errors.push(`Invalid header '${invalidSpendHeaders[0]}' found on sheet '${BULK_EDIT_TAB}'.`);
        }
    };

    const validateEditTabRowCount = (workbook: ParsedWorkbook, errors: string[]): void => {
        const editTabRows = (workbook[BULK_EDIT_TAB]?.length ?? 1) - 1;
        if (Object.keys(workbook).includes(BULK_EDIT_TAB) && editTabRows <= 0) {
            // eslint-disable-next-line quotes
            const error = `Sheet '${BULK_EDIT_TAB}' does not contain any spend rows.`;
            const action = 'Please ensure you upload at least one spend row.';
            errors.push(`${error} ${action}`);
        }
    };

    const validateAmountAndQuantityTabRowCount = (workbook: ParsedWorkbook, errors: string[]): void => {
        if (workbook[ABPO_BULK_EDIT_TAB] == null || workbook[QBPO_BULK_EDIT_TAB] == null) return;
        const abpoRows = workbook[ABPO_BULK_EDIT_TAB].length - 1;
        const qbpoRows = workbook[QBPO_BULK_EDIT_TAB].length - 1;
        const totalRows = abpoRows + qbpoRows;
        if (totalRows < 1) {
            errors.push(
                `Please ensure you upload at least one spend row in either '${ABPO_BULK_EDIT_TAB}' or '${QBPO_BULK_EDIT_TAB}'`
            );
        }
        if (totalRows > MAX_BULK_RECEIVING_ROWS) {
            const overflow = totalRows - MAX_BULK_RECEIVING_ROWS;
            const error = `Sheet '${ABPO_BULK_EDIT_TAB} and ${QBPO_BULK_EDIT_TAB}' exceeds the upload row count limit by ${overflow}.`;
            const action = `Please ensure you upload a maximum of ${MAX_BULK_RECEIVING_ROWS} spend rows.`;
            errors.push(`${error} ${action}`);
        }
    };

    const mandatorySheetErrors = (workbook: ParsedWorkbook, mandatorySheetNames: string[]): string[] => {
        return _.difference(mandatorySheetNames, Object.keys(workbook)).map(
            sheetName => `Could not find mandatory sheet "${sheetName}"`
        );
    };

    const hasMandatorySheet = (workbook: ParsedWorkbook, mandatorySheetNames: string[]): boolean => {
        return mandatorySheetErrors(workbook, mandatorySheetNames).length === 0;
    };

    const getBulkUploadExcel = async (reportName: BulkUploadReportName, fileUrl: string): Promise<BulkUploadExcel> => {
        try {
            const workbook = await readWorkBook(fileUrl);

            const mandatorySheets: Record<BulkUploadReportName, string[]> = {
                [ReportName.BulkEditSpendUpload]: [BULK_EDIT_TAB],
                [ReportName.BulkReceivingSpendUpload]: [ABPO_BULK_EDIT_TAB, QBPO_BULK_EDIT_TAB]
            };

            if (!hasMandatorySheet(workbook, mandatorySheets[reportName])) {
                const altReportName = ALTERNATIVE_BULK_UPLOAD_REPORT_NAME[reportName];
                if (hasMandatorySheet(workbook, mandatorySheets[altReportName])) {
                    return { workbook, errors: [INCORRECT_PLACE_TO_UPLOAD(altReportName)] };
                } else {
                    return { workbook, errors: mandatorySheetErrors(workbook, mandatorySheets[reportName]) };
                }
            }

            return { workbook, errors: [] };
        } catch (e) {
            return { workbook: {}, errors: [FILE_READ_ERROR] };
        }
    };

    const setReportType = async (fileUrl: string) => {
        const { workbook, errors } = await getBulkUploadExcel(ReportName.BulkEditSpendUpload, fileUrl);
        setErrors(e => _.uniq([...e, ...errors]));

        const allSheetHeaders = _.mapObject(workbook, sheet => sheet[0] || []);
        const reportName = getReportName(allSheetHeaders);
        setReportName(reportName);
    };

    const readWorkBook = async (fileUrl: string): Promise<ParsedWorkbook> => {
        const workbook = await fetch(fileUrl)
            .then(file => file.arrayBuffer())
            .then(bytes => xlsxRead(bytes));

        return _.mapObject(workbook.Sheets, sheet =>
            xlsxUtils
                .sheet_to_json(sheet, { header: 1, blankrows: false })
                .filter(row => Array.isArray(row))
                .map(row => (row as unknown[]).map(col => String(col)))
        );
    };

    const getReportName = (headers: Record<string, string[]>) => {
        const containsEditTab = BULK_EDIT_TAB in headers;
        if (!containsEditTab) {
            return ReportName.ExportBulkEditSpend;
        }

        return getBulkUploadReportName(headers[BULK_EDIT_TAB]);
    };

    const getBulkUploadReportName = (headers: string[]): BulkUploadReportName =>
        headers.includes(BULK_RECEIVING_SPEND_HEADER)
            ? ReportName.BulkReceivingSpendUpload
            : ReportName.BulkEditSpendUpload;

    return { validateFile, setReportType, errors, reportName };
}
