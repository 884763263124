import { useRef, useState } from 'react';

import { logger } from 'modules/core';
import { PaginatedQuery } from 'modules/core/model';
import { Purchase } from 'modules/purchase/models';
import { purchaseListService } from '../services';
import { InvoiceLine } from 'modules/invoices/models';

export function usePurchasesForExport() {
    const [bulkPurchaseOrders, updatePurchaseOrders] = useState<Purchase[]>([]);
    const [invoiceLines, updateInvoiceLines] = useState<InvoiceLine[]>([]);
    const lastEvaluatedKey = useRef<Map<string, unknown>>();

    const getPaginatedBulkPurchase = async (isReport: boolean) => {
        try {
            do {
                const queries = {
                    lastEvaluatedKey: lastEvaluatedKey.current,
                    pageSize: 50,
                    sortedBy: 'Created',
                    report: isReport
                } as PaginatedQuery;

                const response = await purchaseListService.getPurchaseOrdersAsyncV1(queries);

                const purchases = response.items as Purchase[];

                lastEvaluatedKey.current = response.lastEvaluatedKey;

                updatePurchaseOrders(prev => {
                    purchases?.forEach(rec => prev.push(rec));
                    return prev;
                });
            } while (lastEvaluatedKey.current !== null && lastEvaluatedKey.current !== undefined);

            lastEvaluatedKey.current = undefined;

            for (const p of bulkPurchaseOrders) {
                for (const line of p.lines) {
                    const invoiceLines = await purchaseListService.getInvoiceLinesAsync(
                        line.purchaseId,
                        line.lineNumber
                    );
                    updateInvoiceLines(prev => {
                        invoiceLines?.forEach(rec => prev.push(rec));
                        return prev;
                    });
                }
            }
        } catch (e) {
            logger.error(e);
        }
    };

    return {
        bulkPurchaseOrders,
        invoiceLines,
        updatePurchaseOrders,
        updateInvoiceLines,
        getPaginatedBulkPurchase
    };
}
