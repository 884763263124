import {
    PurchaseIndex,
    PurchaseLineIndex,
    Receipt as TSCoreReceipt,
    InvoiceLine as TSCoreInvoiceLine
} from '@amzn/merp-core/models';
import { InvoiceAllocation, InvoiceLine } from 'modules/invoices/models';
import { Receipt, PurchaseLine, Purchase } from 'modules/purchase/models';

export class DeepCopy {
    public static allocation(allocation: InvoiceAllocation): InvoiceAllocation {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const alloc: any = Object.assign({}, allocation);

        alloc.deleted = alloc.deleted === true || alloc.deleted === 'true';

        return Object.assign({}, alloc);
    }

    public static receipt(receipt: Receipt): Receipt {
        const { allocations } = receipt;

        const newReceipt = Object.assign(new Receipt(), receipt);
        newReceipt.allocations = allocations?.map(DeepCopy.allocation) || [];

        return newReceipt;
    }

    public static purchaseLine(purchaseLine: PurchaseLine): PurchaseLine {
        const { receipts } = purchaseLine;
        const newLine = Object.assign(new PurchaseLine(), purchaseLine);
        newLine.receipts = receipts?.map(DeepCopy.receipt) || [];

        return newLine;
    }

    public static purchase(purchase: Purchase): Purchase {
        const { lines } = purchase;

        const newPurchase = Object.assign(new Purchase(), purchase);
        newPurchase.lines = lines?.map(DeepCopy.purchaseLine) || [];

        return newPurchase;
    }

    public static invoiceLine(line: InvoiceLine): InvoiceLine {
        const newLine = Object.assign(new InvoiceLine(), line);

        return newLine;
    }

    public static invoiceAllocation(invoiceAllocation: InvoiceAllocation): InvoiceAllocation {
        const newInvoiceAllocation = Object.assign(new InvoiceAllocation(), invoiceAllocation);

        return newInvoiceAllocation;
    }

    public static purchaseLineIndex(purchaseLineIndex: PurchaseLineIndex): PurchaseLineIndex {
        const newPurchaseLineIndex = Object.assign(new PurchaseLineIndex(), purchaseLineIndex);

        return newPurchaseLineIndex;
    }

    public static purchaseIndex(purchaseIndex: PurchaseIndex): PurchaseIndex {
        const { purchaseLines } = purchaseIndex;

        const newPurchaseIndex = Object.assign(new PurchaseIndex(), purchaseIndex);

        const receipts = JSON.parse(JSON.stringify(purchaseIndex.receipts));

        const invoicelines = JSON.parse(JSON.stringify(purchaseIndex.invoiceLines));

        const receiptsMap = new Map<number, TSCoreReceipt[]>();

        const invoiceLineMap = new Map<number, TSCoreInvoiceLine[]>();

        newPurchaseIndex.purchaseLines = purchaseLines?.map(DeepCopy.purchaseLineIndex) || [];

        newPurchaseIndex.purchaseLines.forEach(line => {
            if (receipts && receipts[line.lineNumber]) {
                receiptsMap.set(line.lineNumber, receipts[line.lineNumber]);
            }

            if (invoicelines && invoicelines[line.lineNumber]) {
                invoiceLineMap.set(line.lineNumber, invoicelines[line.lineNumber]);
            }
        });

        newPurchaseIndex.receipts = receiptsMap;
        newPurchaseIndex.invoiceLines = invoiceLineMap;

        return newPurchaseIndex;
    }
}
