import { COACombination } from './COACombination';
import { CSVTemplate } from 'modules/dashboard/models';

export class CustomerCoaReportBuilder {
    private csvTemplate: CSVTemplate;
    private coaCombinations: COACombination[];

    private header = [
        'Company',
        'Location',
        'Cost Center',
        'Account',
        'Product',
        'Channel',
        'Project',
        'Eighth Segment',
        'Team Name'
    ];

    constructor() {
        this.csvTemplate = new CSVTemplate();
        this.coaCombinations = [];
    }

    private setReportHeaders() {
        this.csvTemplate.setHeaders(this.header);
    }

    public useRecords(coaCombinations?: COACombination[]): CustomerCoaReportBuilder {
        if (coaCombinations) this.coaCombinations = coaCombinations;
        return this;
    }

    private parseRecords() {
        this.coaCombinations?.forEach(coa => {
            this.csvTemplate.setCSVData([
                coa.company,
                coa.location,
                coa.costCenter,
                coa.glAccount,
                coa.productLine,
                coa.channel,
                coa.project,
                coa.customEighthSegment,
                coa.teamName
            ]);
        });
    }

    public build(): CSVTemplate {
        this.setReportHeaders();
        this.parseRecords();

        return this.csvTemplate;
    }
}
