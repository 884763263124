import React, { useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField, Icon, Button } from '@amzn/awsui-components-react';

import { I18nFormatter } from 'modules/core/class';

interface FileUploadProps {
    onFileUpload?: (file: File, fileUrl: string) => void;
    file?: File;
    fileFormats?: string;
    hideStats?: boolean;
    buttonLabel?: string;
    isFileHandlingDisabled?: boolean;
}

export const FileUpload = (props: FileUploadProps) => {
    const { onFileUpload, file, fileFormats, hideStats, buttonLabel, isFileHandlingDisabled } = props;
    const hiddenFileInput = React.createRef<HTMLInputElement>();

    const { t } = useTranslation('bulkEdit');

    const [uploadedFile, setUploadedFile] = useState<File | undefined>(file);

    useEffect(() => {
        setUploadedFile(file);
    }, [file]);

    const handleFileUpload = (files: FileList | null) => {
        if (!files) return;

        setUploadedFile(files[0]);

        const fileUrl = URL.createObjectURL(files[0]);
        if (onFileUpload) onFileUpload(files[0], fileUrl);
    };

    const ConditionalFormFieldWrapper = (props: { children?: ReactNode | undefined; includeWrapper: boolean }) => {
        return props.includeWrapper ? (
            <FormField label={t('fileUpload.header')}>{props.children}</FormField>
        ) : (
            <>{props.children}</>
        );
    };

    return (
        <>
            <ConditionalFormFieldWrapper includeWrapper={!hideStats}>
                <input
                    ref={hiddenFileInput}
                    data-testid="chooseFileInput"
                    id="chooseFileInput"
                    type="file"
                    hidden
                    multiple={false}
                    accept={fileFormats || '.xls,.xlsx'}
                    value=""
                    onChange={event => handleFileUpload(event.target.files)}
                />
                <Button
                    disabled={isFileHandlingDisabled}
                    iconName="upload"
                    data-testid="fileUpload.uploadBtn"
                    onClick={() => {
                        hiddenFileInput.current?.click();
                    }}
                >
                    {buttonLabel ?? t('fileUpload.chooseFile')}
                </Button>
            </ConditionalFormFieldWrapper>
            {!hideStats && uploadedFile && (
                <div>
                    <span>
                        {<Icon name="status-positive" variant="success" />}
                        {uploadedFile.name}
                    </span>
                    <br />
                    <small>
                        {t('fileUpload.formattedBytes', { bytesAmount: uploadedFile.size })} {t('fileUpload.bytes')}
                    </small>
                    <br />
                    <small>
                        {t('fileUpload.lastModified')}: {I18nFormatter.date(t, uploadedFile.lastModified)}
                    </small>
                </div>
            )}
        </>
    );
};
