import { ChannelMeasurementMapping } from 'modules/mappings/models';

interface ChannelMeasurementsResult {
    status?: number;
    requestId?: string;
    channelMeasurements?: ChannelMeasurementMapping[];
    teamName: string;
}

class MockedChannelMeasurementService {
    public async getChannelMeasurementMappingsAsync(): Promise<ChannelMeasurementsResult> {
        const companyCodes = ['2D', '4R'];
        const resultList: ChannelMeasurementMapping[] = [];
        companyCodes.map(companyCode => {
            let counter = 0;
            while (counter < 15) {
                const mapping = Object.assign(new ChannelMeasurementMapping(), {
                    companyCode,
                    channelType: 'TV',
                    smartE: 'SMARTE',
                    teamName: 'teamName'
                });
                resultList.push(mapping);
                counter += 1;
            }
        });

        const resultBody: ChannelMeasurementsResult = {
            requestId: 'requestId',
            channelMeasurements: undefined,
            teamName: '',
            status: 400
        };

        return resultBody;
    }

    public async updateChannelMeasurementMappingsAsync(_body: unknown) {
        return {
            success: true,
            message: 'Request success',
            requestId: 'requestId'
        };
    }
}

export const mockedChannelMeasurementService = new MockedChannelMeasurementService();
