import * as yup from 'yup';
import { ReceiptDateValidator, PurchaseLine, ReceiptStatus } from '../models';
import { getMaxDecimalDigitsRe } from 'modules/core/services/precision.service';
import BigNumber from 'bignumber.js';

export const PurchaseSchema = yup.object().shape({
    selectReason: yup
        .string()
        .ensure()
        .required('Reason is required!'),
    explanation: yup.string().max(140),
    amount: yup
        .array()
        .min(1, 'Pick at least 1 item')
        .of(
            yup
                .number()
                .moreThan(0, 'Amount should be more than zero')
                .typeError('Enter valid amount')
                .required('Amount is required')
                .test('is-valid-decimal', 'Invalid decimals in amount', function(value) {
                    if (receiptIsPosted(this.options.context, getPathIndex(this.path))) return true;
                    return new BigNumber(value).toFixed().match(getMaxDecimalDigitsRe()) === null;
                })
        ),
    quantity: yup
        .array()
        .min(1, 'Pick at least 1 item')
        .of(
            yup
                .number()
                .moreThan(0, 'Quantity must be more than zero')
                .typeError('Enter valid quantity')
                .required('Quantity is required')
                .test('is-valid-decimal', 'Invalid decimals in quantity', function(value) {
                    if (receiptIsPosted(this.options.context, getPathIndex(this.path))) return true;
                    return new BigNumber(value).toFixed().match(getMaxDecimalDigitsRe()) === null;
                })
        ),
    receiptDate: yup
        .array()
        .min(1, 'Pick at least 1 item')
        .of(
            yup
                .date()
                .min(
                    ReceiptDateValidator.validDate(),
                    `Date field must be later than ${ReceiptDateValidator.validDate().toDateString()}`
                )
                .required('Date is required')
                .typeError('Date is invalid')
        )
});

function getPathIndex(path: string): number {
    return parseInt(path.split(/\[|\]/)[1]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function receiptIsPosted(context: any, index: number): boolean {
    return (context?.line as PurchaseLine | undefined)?.receipts?.[index]?.receiptStatus === ReceiptStatus.POSTED;
}
