import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineVersion, VersionType } from 'modules/audit/models';
import { NameCard } from '../NameCard/NameCard';
import styles from './EditedVersion.module.scss';
import { VersionTitle } from '../VersionTitle/VersionTitle';
import { ChangedLineAmount } from '../ChangedLineAmount';

export interface EditedVersionProps {
    lineVersions: LineVersion[];
    versionType: VersionType;
}

export const EditedVersion = (props: EditedVersionProps) => {
    const { lineVersions, versionType } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    let containerClass = 'awsui-util-container awsui-util-no-gutters';
    if (lineVersion.isRejected) {
        containerClass += ` ${styles['transparent-box']}`;
    }

    const getTitleBasedOnVersionType = (versionType: VersionType) => {
        const versionTypeTitleMap = new Map();

        versionTypeTitleMap.set(VersionType.BULK_EDIT_SPEND, t('version.bulkEditedTitle'));
        versionTypeTitleMap.set(VersionType.ONBOARD_PURCHASE_SPEND, t('version.onboardPurchaseSpendTitle'));
        versionTypeTitleMap.set(VersionType.ONBOARD_PURCHASE, t('version.onboardPurchaseTitle'));
        versionTypeTitleMap.set(VersionType.COUPA_SYNC_RECEIPTS, t('version.coupaSyncReceiptsTitle'));
        versionTypeTitleMap.set(VersionType.SPEND_EDIT, t('version.editedTitle'));
        versionTypeTitleMap.set(VersionType.CREDIT_MEMO_REDUCED, t('version.creditMemoReducedTitle'));
        versionTypeTitleMap.set(VersionType.CREDIT_MEMO_CANCELLED, t('version.creditMemoCancelledTitle'));

        return versionTypeTitleMap.get(versionType) ?? '';
    };

    lineVersion.receiptVersions.sort((a, b) => b.modifiedReceiptTime - a.modifiedReceiptTime);

    const title = getTitleBasedOnVersionType(versionType);

    return (
        <div className={containerClass}>
            <div className="awsui-util-container-header">
                <VersionTitle title={title} lineVersion={lineVersion} showDetails={true} />
            </div>
            <div className={`awsui-grid ${styles['line-version-content']}`}>
                <div className="awsui-row">
                    <div className="col-1">
                        <NameCard
                            login={lineVersion.updaterLogin}
                            firstName={lineVersion.updaterFirstName}
                            lastName={lineVersion.updaterLastName}
                        />
                    </div>
                    <div className="col-9">
                        <strong>
                            {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
                        </strong>
                        <div>
                            {lineVersion.receiptVersions.length > 0 &&
                                lineVersion.receiptVersions[0].versionCategory &&
                                t(`line.updateReasons.${lineVersion.receiptVersions[0].versionCategory}`)}
                            <div>
                                {lineVersion.receiptVersions.length > 0 &&
                                    lineVersion.receiptVersions[0].versionDescription}
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <ChangedLineAmount amount={lineVersion.changedLineAmount} currency={lineVersion.currencyCode} />
                    </div>
                </div>
            </div>
        </div>
    );
};
