import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineVersion } from 'modules/audit/models';
import { VersionTitle } from '../VersionTitle/VersionTitle';

export interface InvoiceMatchedVersionProps {
    lineVersions: LineVersion[];
}

export const InvoiceMatchedVersion = (props: InvoiceMatchedVersionProps) => {
    const { lineVersions } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    const containerClass = 'awsui-util-container awsui-util-no-gutters';

    return (
        <div className={containerClass}>
            <div className="awsui-util-container-header">
                <VersionTitle
                    title={t('version.newInvoiceMatchedTitle')}
                    lineVersion={lineVersion}
                    showDetails={true}
                />
            </div>
        </div>
    );
};
