import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import styles from './ErrorContent.module.scss';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { resetErrorPage } from 'modules/app/store';
import { useDispatch } from 'react-redux';

interface ErrorContentProps {
    message: string;
    btnContent: string;
    btnFunction?: () => void;
}
export const ErrorContent = ({ message, btnContent, btnFunction }: ErrorContentProps) => {
    const { t } = useTranslation('purchase');

    const dispatch = useDispatch();

    const reloadPage = () => {
        navigate('/');
        return;
    };

    const navigatePage = () => {
        dispatch(resetErrorPage());
        btnFunction ? btnFunction() : reloadPage();
    };

    return (
        <div className={`awsui-grid ${styles.wrapper}`}>
            <div className="awsui-row">
                <div className="col-xxs-12 col-m-1"></div>
                <div className="col-xxs-12 col-m-10 awsui-util-container">
                    <div>
                        <strong>{t(message)}</strong>
                    </div>
                    <div>
                        <Button variant="primary" onClick={navigatePage}>
                            {t(btnContent)}
                        </Button>
                    </div>
                </div>
                <div className="col-xxs-12 col-m-1"></div>
            </div>
        </div>
    );
};
