/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Pagination, Table, TableProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { EmptyListContent } from 'modules/dashboard/components/emptyListContent';
import { TableRow } from 'modules/core/model';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface BudgetTableTemplateProps {
    columns: any[];
    items: readonly any[];
    loading: boolean;
    header: JSX.Element;
    textFilter?: JSX.Element;
    pagination?: JSX.Element;
    selection?: boolean;
    selectedItems?: any[];
    updateSelectedItems?: React.Dispatch<React.SetStateAction<TableRow<any>[] | undefined>>;
}

export const BudgetTableTemplate = (props: BudgetTableTemplateProps) => {
    const {
        columns,
        items: allItems,
        loading,
        header,
        textFilter,
        selection,
        selectedItems,
        updateSelectedItems
    } = props;

    const { items, collectionProps, paginationProps } = useCollection(allItems, {
        pagination: { pageSize: 15 }
    });

    const { t } = useTranslation('budgetManagement');

    const selectionChange = (event: { detail: TableProps.SelectionChangeDetail<any> }) => {
        const { selectedItems } = event.detail;

        if (updateSelectedItems) {
            updateSelectedItems(selectedItems);
        }
    };

    return selection ? (
        <Table
            {...collectionProps}
            loadingText={t('loading')}
            loading={loading}
            items={items}
            columnDefinitions={columns}
            header={header}
            empty={<EmptyListContent header={t('noResult')} message={t('noDisplay')} />}
            resizableColumns
            pagination={<Pagination {...paginationProps} />}
            selectionType={selection ? 'multi' : undefined}
            selectedItems={selectedItems}
            filter={textFilter}
            onSelectionChange={selectionChange}
        />
    ) : (
        <Table
            {...collectionProps}
            loadingText={t('loading')}
            loading={loading}
            items={items}
            columnDefinitions={columns}
            header={header}
            empty={<EmptyListContent header={t('noResult')} message={t('noDisplay')} />}
            resizableColumns
            pagination={<Pagination {...paginationProps} />}
        />
    );
};
