import BigNumber from 'bignumber.js';
import { BulkReceivingRow } from '../models';
import { maxEditsReached } from './maxEditsReached';
import { getMaxDecimalDigits, getMaxDecimalDigitsRe } from 'modules/core/services/precision.service';

export const validateReceivingAmount = (row: BulkReceivingRow, value: number, numberOfEdits: number) => {
    if (row.isQuantityBased) {
        return 'Po line must be an amount based line';
    }

    if (maxEditsReached(numberOfEdits, row.receivingAmount)) {
        return 'Maximum edits reached';
    }

    const maxDecimalDigitsRe = getMaxDecimalDigitsRe();
    const bigNumValue = new BigNumber(value);
    if (bigNumValue.toFixed().match(maxDecimalDigitsRe) != null) {
        return 'Invalid decimals in amount';
    }

    const maxDecimalDigits = getMaxDecimalDigits();
    const amount = bigNumValue.decimalPlaces(maxDecimalDigits);

    if (amount.isLessThan(0.0)) {
        return 'Amount must be greater than or equal to zero';
    }

    if (amount.isGreaterThan(new BigNumber(row.unusedAmount).decimalPlaces(maxDecimalDigits))) {
        return 'Amount must not exceed unused amount';
    }
};
