import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';

interface BulkReceivingConfirmSaveModalProps {
    isVisible: boolean;
    isReceiveInFull: boolean;
    selectedItemsCount: number;
    onConfirm: () => void;
    onCancel: () => void;
}

export const BulkReceivingConfirmSaveModal = ({
    isVisible,
    isReceiveInFull,
    selectedItemsCount,
    onConfirm,
    onCancel
}: BulkReceivingConfirmSaveModalProps) => {
    const { t } = useTranslation('bulkReceiving');

    const [acknowledged, setAcknowledged] = useState(false);

    const onCancelClick = () => {
        setAcknowledged(false);
        onCancel();
    };

    const onConfirmClickThrottled = useCallback(debounce(onConfirm, 1500), [onConfirm]);

    return (
        <Modal
            data-cy="bulkReceiving.confirmSaveModal"
            visible={isVisible}
            header={isReceiveInFull ? t('confirmSave.receiveInFullHeader') : t('confirmSave.header')}
            onDismiss={onCancelClick}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" data-cy="confirmSave.cancel.btn" onClick={onCancelClick}>
                            {t('actions.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            data-cy="confirmSave.confirm.btn"
                            onClick={onConfirmClickThrottled}
                            disabled={isReceiveInFull && !acknowledged}
                        >
                            {t('actions.confirm')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {isReceiveInFull
                ? t('confirmSave.receiveInFullContent', { count: selectedItemsCount })
                : t('confirmSave.content', { count: selectedItemsCount })}
            <br />
            <br />
            {isReceiveInFull && t('confirmSave.receiveInFullWarning')}
            <br />
            <br />
            {isReceiveInFull && (
                <Button
                    variant="primary"
                    data-cy="confirmSave.acknowledge.btn"
                    onClick={() => {
                        setAcknowledged(true);
                    }}
                    disabled={acknowledged}
                >
                    {t('confirmSave.acknowledged')}
                </Button>
            )}
        </Modal>
    );
};
