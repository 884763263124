import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Header } from '@amzn/awsui-components-react';

import { MspTask, YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';
import { TaskTable } from 'modules/budgetManagement/components/TaskTable';
import { useTask, useBudget, useTableRowForecast } from 'modules/budgetManagement/hooks';
import { BudgetPlanCycleTable } from 'modules/budgetManagement/components/BudgetPlanCycleTable';
import { UploadBudgetModal } from 'modules/budgetManagement/components/UploadBudgetModal';

export interface OwnerDashboardProps {
    onTasksUpdate: (task: MspTask) => void;
    onUploadComplete: (success: boolean) => void;
    onBudgetApproved: () => void;
}
export const OwnerDashboard = (props: OwnerDashboardProps) => {
    const { t } = useTranslation('budgetManagement');

    const { onTasksUpdate, onUploadComplete, onBudgetApproved } = props;

    const { loadingTasks, tasks } = useTask();
    const { loadingForecast, teamName, yearBudgetitems, budgetUpdated, uploadBudget } = useTableRowForecast();

    const { budgetsApproved } = useBudget('', true, teamName);

    const [isUploadModalVisible, showUploadModal] = useState(false);

    useEffect(() => {
        for (const { payload } of tasks || []) {
            onTasksUpdate(payload);
        }
    }, [tasks]);

    useEffect(() => {
        if (budgetsApproved) {
            onBudgetApproved();
        }
    }, [budgetsApproved]);

    useEffect(() => {
        if (budgetUpdated === undefined) return;

        onUploadComplete(budgetUpdated);
    }, [budgetUpdated]);

    const onBudgetUpload = (forecasts: YearlyAccountingForecast[]) => {
        uploadBudget(forecasts);
        showUploadModal(false);
    };

    return (
        <>
            {isUploadModalVisible && (
                <UploadBudgetModal onUpload={onBudgetUpload} onCancel={() => showUploadModal(false)} />
            )}
            <Form header={<Header variant="h1">{t('dashboard')}</Header>}>
                <TaskTable items={tasks ?? []} loading={loadingTasks} yearBudgetitems={yearBudgetitems ?? []} />
                <br />
                <BudgetPlanCycleTable
                    items={yearBudgetitems ?? []}
                    loading={loadingForecast}
                    showUploadModal={showUploadModal}
                />
            </Form>
        </>
    );
};
