import React from 'react';
import { Error } from 'modules/error/views';

import { AppConfig } from 'modules/core';

interface ErrorProps {
    errorStatusCode: number;
    dashboardError?: boolean;
    globalFeature?: true;
}

const reportErrorFunc = () => {
    window.location.href = AppConfig.troubleTicketLink || '';
};

const refreshFunc = () => {
    window.location.reload();
};

const errorMappings = new Map<string, JSX.Element>([
    ['404', <Error key={404} message="error.noResult" btnContent="error.noResultBtn" />],
    ['401', <Error key={401} message="error.notAuthorized" btnContent="error.noResultBtn" />],
    ['400', <Error key={400} message="error.notAuthorized" btnContent="error.noResultBtn" />],
    [
        '403-dashboard',
        <Error key={403} message="error.noAccessRetry" btnContent="error.refreshPage" btnFunction={refreshFunc} />
    ],
    [
        '403-globalFeature',
        <Error
            key={403}
            message="error.globalUserAccess"
            btnContent="error.requestAccess"
            btnFunction={reportErrorFunc}
        />
    ],
    [
        '503',
        <Error key={503} message="error.serviceDown" btnContent="error.reportError" btnFunction={reportErrorFunc} />
    ],
    [
        '500',
        <Error key={500} message="error.serviceDown" btnContent="error.reportError" btnFunction={reportErrorFunc} />
    ]
]);

export const ErrorRouter = ({ errorStatusCode, dashboardError, globalFeature }: ErrorProps) => {
    let statusCode = errorStatusCode.toString();

    if (dashboardError) {
        statusCode = '403-dashboard';
    }

    if (globalFeature) {
        statusCode = '403-globalFeature';
    }

    return errorMappings.get(statusCode) || <></>;
};
