import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'modules/core/config/i18n';
import { CustomDetailEvent, Select } from '@amzn/awsui-components-react-v2';

import { ApplicationState } from 'modules/app/store';

import styles from './UserCard.module.scss';

const langs = [
    {
        label: 'English',
        id: 'en'
    },
    {
        label: 'Spanish',
        id: 'es'
    },
    {
        label: 'German',
        id: 'de'
    }
];

export function UserCard(): JSX.Element {
    const user = useSelector((state: ApplicationState) => state.user);
    const fullName = `${user.firstName} ${user.lastName}`;

    const { t } = useTranslation('app');

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const [language, setLanguage] = useState(langs[0]);

    function onLanguageSelect(event: CustomDetailEvent<Select.ChangeDetail>) {
        changeLanguage(event.detail.selectedOption.id);
        setLanguage(event.detail.selectedOption);
    }
    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>{t('personalDetails')}</h2>
            </div>

            <div className="awsui-util-container-no-gutters" id={styles.user}>
                <img src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${user.alias}`} alt="avatar" />

                <div className="awsui-util-container-no-gutters" id={styles.item}>
                    <h2>
                        {t('hello')}, {user.firstName}!
                    </h2>
                    <b>{t('fullName')}: </b>
                    {fullName}
                    <br />
                    <b>Email: </b>
                    {user.email}
                </div>
            </div>

            <div className="awsui-util-container-no-gutters" id={styles.item}>
                <b>{t('changeLanguage')}: </b>
                <div className="awsui-util-container-no-gutters" id={styles.langDrop}>
                    <Select
                        id={styles.selectmenu}
                        onChange={onLanguageSelect}
                        selectedOption={language}
                        options={langs}
                    />
                </div>
            </div>
        </div>
    );
}
