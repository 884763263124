import { LineVersion, ReceiptVersion } from 'modules/audit/models';

export function mapToReceiptVersion(json: unknown): ReceiptVersion {
    return Object.assign(new ReceiptVersion(), json || {});
}

export function mapToLineVersion(json: unknown): LineVersion {
    const lineVersion = Object.assign(new LineVersion(), json || {});

    lineVersion.receiptVersions = lineVersion.receiptVersions.map(mapToReceiptVersion);

    return lineVersion;
}
