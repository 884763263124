import { PurchaseLineStatus, Receipt, PurchaseLine } from 'modules/purchase/models';
import { CurrencyCode } from 'modules/core/model';
import { v4 as uuidV4 } from 'uuid';
import { VersionType } from '../models';

export interface EventDetailsResult {
    versionType?: string;
    originalPurchaseLine?: PurchaseLine;
    modifiedPurchaseLine?: PurchaseLine;
    lineVersionLineNumber?: string;
    status?: number;
    requestId?: string;
}

export const buildEventVersionData = function() {
    const allocations = [
        {
            invoiceId: '123123',
            invoiceOfaId: '000001',
            allocatedAmount: 100,
            invoiceTotal: 1300
        },
        {
            invoiceId: '1231',
            invoiceOfaId: '000002',
            allocatedAmount: 180,
            invoiceTotal: 180
        },
        {
            invoiceId: '12322',
            invoiceOfaId: '000003',
            allocatedAmount: 200,
            invoiceTotal: 200
        }
    ];

    const allocation = [
        {
            invoiceId: '123123',
            invoiceOfaId: '000004',
            allocatedAmount: 1200,
            invoiceTotal: 1300
        }
    ];

    const channelAllocations = [
        {
            type: 'tv',
            amount: 300
        },
        {
            type: 'ooh',
            amount: 400
        },
        {
            type: 'radio',
            amount: 500
        }
    ];

    const channelAllocation = [
        {
            type: 'paidSearch',
            amount: 300
        }
    ];

    const receipts = [
        Object.assign(new Receipt(), {
            receiptId: 'a63d0a06-221e-4bf6-afa3-cffcb37724dd',
            receiptAmount: 1200,
            receiptTime: 1580803200000,
            billedAmount: 720,
            allocations: allocation,
            channelAllocations: channelAllocation
        }),
        Object.assign(new Receipt(), {
            receiptId: '45412abc-5a1a-40e7-a5ff-76239cd48d45',
            receiptAmount: 1200,
            receiptTime: 1586905114708,
            billedAmount: 1500,
            allocations: allocation,
            channelAllocations: channelAllocations
        }),
        Object.assign(new Receipt(), {
            receiptId: '02f0a372-2825-4061-9dad-3d9b07d6e887',
            receiptAmount: 1200,
            receiptTime: Date.now(),
            billedAmount: 1200,
            allocations,
            channelAllocations: channelAllocations
        }),
        Object.assign(new Receipt(), {
            receiptId: '8999cd6a-ff3c-4c78-9076-f487a29c94f1',
            receiptAmount: 1200,
            receiptTime: 1580803200000,
            billedAmount: 480,
            allocations,
            channelAllocations: channelAllocation
        })
    ];

    const line = Object.assign(new PurchaseLine(), {
        purchaseId: uuidV4(),
        amount: 60000,
        lineNumber: 1,
        description: 'AQI.FIRE.MONITOR - Smart Sense AQI monitor for 6 months',
        currencyCode: CurrencyCode.USD,
        needByDate: 1580803200000,
        account: {
            accountingString: '01-6037-8200-16999-0000-0000-0000',
            active: true,
            id: '390dead1-d670-4c64-825f-6bafe6cdf91e',
            name: 'Ama"zon.com, Inc.-SEA37 - Roxanne (Seattle, WA, US)-Procurement',
            costCenter: '430',
            code: '6037'
        },
        purchaseLineStatus: PurchaseLineStatus.PENDING_RECEIPTS_BY_USER,
        receipts: receipts
    });

    return line;
};

const purchase1Repository = buildEventVersionData();

class MockedAuditEventDetailsService {
    public async getEventDetailsAsync(_purchaseId: string, lineVersionLineNumber: string): Promise<EventDetailsResult> {
        const records = [
            {
                versionType: VersionType.SPEND_EDIT,
                originalLine: purchase1Repository,
                modifiedLine: purchase1Repository,
                lineVersionLineNumber: lineVersionLineNumber
            }
        ];

        if (!records) {
            throw Error('Unable to fetch records');
        }

        return new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    versionType: VersionType.SPEND_EDIT,
                    originalLine: purchase1Repository,
                    modifiedLine: purchase1Repository,
                    lineVersionLineNumber: lineVersionLineNumber
                } as EventDetailsResult);
            }, 500);
        });
    }
}

export const mockedAuditEventDetailsService = new MockedAuditEventDetailsService();
