import { BigObjectiveRollUp, ChannelRollUp, SMARTeRollUp, YTDRollUp } from '@amzn/merp-core/models';
import { CSVTemplate } from 'modules/dashboard/models';
import { AbbreviatedMonths } from '../constants';

export class CsvConverter {
    public bigObjective(rollups: BigObjectiveRollUp[]): CSVTemplate {
        const result = new CSVTemplate();

        const headers = ['Big Objective', 'Year', 'Country', 'YTM Reported Spend', ...AbbreviatedMonths];
        result.setHeaders(headers);

        for (const rollup of rollups) {
            const { bigObjective, year, countries } = rollup;
            const overallRow = this.processYTDRow(bigObjective, year, 'all', rollup);

            result.setCSVData(overallRow as string[]);

            for (const [key, value] of Object.entries(countries)) {
                const countryRow = this.processYTDRow(bigObjective, year, key, value);

                result.setCSVData(countryRow as string[]);
            }
        }

        return result;
    }

    public channel(rollups: ChannelRollUp[]): CSVTemplate {
        const result = new CSVTemplate();

        const headers = ['Channel', 'Year', 'Country', 'YTM Reported Spend', ...AbbreviatedMonths];
        result.setHeaders(headers);

        for (const rollup of rollups) {
            const { channel, year, countries } = rollup;
            const overallRow = this.processYTDRow(channel, year, 'all', rollup);

            result.setCSVData(overallRow as string[]);

            for (const [key, value] of Object.entries(countries)) {
                const countryRow = this.processYTDRow(channel, year, key, value);

                result.setCSVData(countryRow as string[]);
            }
        }

        return result;
    }

    public smartE(rollups: SMARTeRollUp[]): CSVTemplate {
        const result = new CSVTemplate();

        const headers = ['Country', 'Channel', 'SMARTe', 'Previous Year', 'Year', 'Next year'];
        result.setHeaders(headers);

        for (const rollUp of rollups) {
            const { country, channel, smartE, previousYear, currentYear, nextYear } = rollUp;
            const row = [country, channel, smartE, previousYear, currentYear, nextYear].map(this.pruneCsv);

            result.setCSVData(row as string[]);
        }

        return result;
    }

    private processYTDRow(rollupType: string, year: number, country: string, rollup: YTDRollUp) {
        const { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec, ytd } = rollup;

        return [rollupType, year, country, ytd, jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec].map(
            this.pruneCsv
        );
    }

    private pruneCsv(value: unknown) {
        if (typeof value === 'string' && value) {
            return value.replace(/,/g, ' ');
        }

        return value;
    }
}
