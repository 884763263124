import { CoupaPurchaseLineType } from '@amzn/merp-core/models';

import { Receipt } from 'modules/purchase/models';
import { CoupaReceiptType } from 'modules/purchase/models/CoupaReceiptType';
import { BulkReceivingRequest } from 'modules/bulkEdit/models';

export function mapToBulkReceivingRequest(receipt: Receipt, receiveInFull = false): BulkReceivingRequest {
    const { purchaseId, lineNumber, orderNumber, receiptAmount, receivingType, quantity } = receipt;

    const lineType =
        receivingType === CoupaReceiptType.QUANTITY
            ? CoupaPurchaseLineType.ORDER_QUANTITY_LINE
            : CoupaPurchaseLineType.ORDER_AMOUNT_LINE;

    return Object.assign(new BulkReceivingRequest(), {
        lineType,
        orderNumber,
        purchaseId,
        lineNumber,
        quantity,
        amount: receiptAmount,
        receiveInFull: receiveInFull
    });
}
