import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { versionListService } from '../services';
import { showErrorPage } from 'modules/app/store';
import { aggregateLineVersions, VersionEvent } from './aggregateVersions.hook';

export interface UsePaginatedVersionsProps {
    purchaseId: string;
    filter: string;
}

export function usePaginatedVersions(props: UsePaginatedVersionsProps) {
    const { purchaseId, filter } = props;
    const [versionEvents, updateVersionEvents] = useState<VersionEvent[]>();
    const [loading, setLoading] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(false);
    const lastEvaluatedKey = useRef<Map<string, unknown> | undefined>();

    const dispatch = useDispatch();

    const onPaginatedResultUpdated = (items?: VersionEvent[], _isPaginated?: boolean) => {
        if (_isPaginated) {
            updateVersionEvents(current => {
                const newItems = current || [];
                if (items) {
                    newItems.push(...items);
                }
                return newItems;
            });
            return;
        }
        updateVersionEvents(items);
    };

    const paginateFromServerAsync = useCallback(
        async (_isPaginated?: boolean) => {
            setLoading(true);
            const query = {
                lastEvaluatedKey: lastEvaluatedKey.current,
                filterBy: filter,
                pageSize: 10
            };

            const result = await versionListService.getVersionsAsync(purchaseId, query);
            const aggregatedItems = result.items ? aggregateLineVersions(result.items) : result.items;

            lastEvaluatedKey.current = result.lastEvaluatedKey;
            setHasNextPage(lastEvaluatedKey.current !== null);

            if (result.items === undefined && result.status) {
                dispatch(showErrorPage({ errorCode: result.status }));
            } else {
                onPaginatedResultUpdated(aggregatedItems, _isPaginated);
            }

            setLoading(false);
        },
        [purchaseId, filter, dispatch]
    );

    useEffect(() => {
        paginateFromServerAsync();
    }, [paginateFromServerAsync]);

    return { versionEvents, loading, hasNextPage, updateVersionEvents, paginateFromServerAsync };
}
