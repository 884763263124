import { ApiClient } from '../class/api/ApiClient';

class ApiService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'purchases';

    public async callApiAsync(): Promise<unknown> {
        return await this.get('purchaseId');
    }
}

export const apiService = new ApiService();
