/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Pagination, PaginationProps } from '@amzn/awsui-components-react';

interface BudgetTablePaginationProps {
    getItems: () => Promise<void>;
    openEnded: boolean;
}

export const BudgetTablePagination = (props: BudgetTablePaginationProps) => {
    const { getItems, openEnded } = props;

    const [isDisabled, setDisabled] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(1);

    const onNextPageClick = async (event: { detail: PaginationProps.PageClickDetail }) => {
        const { requestedPageAvailable, requestedPageIndex } = event.detail;

        if (!requestedPageAvailable && openEnded) {
            setDisabled(true);
            await getItems();
            setCurrentIndex(requestedPageIndex);
            setDisabled(false);
        }
    };

    const onPaginationChange = (event: { detail: PaginationProps.ChangeDetail }) => {
        setCurrentIndex(event.detail.currentPageIndex);
    };

    return (
        <Pagination
            currentPageIndex={currentIndex}
            onNextPageClick={onNextPageClick}
            onChange={onPaginationChange}
            pagesCount={1}
            openEnd={openEnded}
            disabled={isDisabled}
        />
    );
};
