import * as yup from 'yup';

export const ChannelAllocationInputSchema = yup.object({
    allocationAmount: yup
        .number()
        .typeError('Enter Valid Amount')
        .min(0, 'Amount should be non negative')
        .required('Amount is required')
        .test('is-valid-decimal', 'Invalid decimals in amount', value => {
            return (value + '').match(/^\d*\.{1}\d{3,}$/g) === null;
        })
});
