/* eslint-disable react/display-name */
import React from 'react';
import { HelpPanel } from 'modules/core/hooks/useHelpPanel.hook';
import { ToolContent } from 'modules/purchase/components';

export type TeamNameSmartEMappingPagePanels = 'SMARTe_HELP';

export const teamNameSmartEMappingPanels: HelpPanel<TeamNameSmartEMappingPagePanels>[] = [
    {
        key: 'SMARTe_HELP',
        content: () => (
            <ToolContent
                header="helpPanel.SMARTeHeader"
                paragraphs={[
                    'helpPanel.SMARTePara',
                    'helpPanel.MIEPara',
                    'helpPanel.DMUYPara',
                    'helpPanel.FeelsRightPara',
                    'helpPanel.AccessPara'
                ]}
            />
        )
    }
];
