import { logger } from '../logger';

interface Runnable {
    run(): void;
}

export interface RetryProps {
    maxCount: number;
    stopCondition: (...args: unknown[]) => boolean;
    done: (...args: unknown[]) => unknown;
    interval?: number;
    taskName?: string;
}

export function retry(retryProps: RetryProps): Runnable {
    const { taskName, maxCount, stopCondition, done, interval } = retryProps;

    const maxRetry = maxCount;
    let retry: unknown;
    let retryCount = 0;

    const kill = (): void => {
        clearInterval(retry as NodeJS.Timeout);
        retry = null;
        done();
    };

    return {
        run(): void {
            retry = setInterval(() => {
                if (retryCount === maxRetry || stopCondition()) {
                    kill();
                    return;
                }
                retryCount++;
                logger.debug(`[Retry] ${taskName || 'task'}, retry count ${retryCount}`);
            }, interval || 100);
        }
    };
}
