/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableRow } from '../model';
import { DeepCopy } from 'modules/purchase/class';
import { InvoiceLine } from './../../invoices/models/InvoiceLine';
import { PurchaseIndex, PurchaseLineIndex } from '@amzn/merp-core/models/purchase';
import { Purchase, PurchaseLine, Receipt, ConfirmStatus } from 'modules/purchase/models';
import { calculatePurchaseConfirmStatus } from 'modules/purchase/helpers';

export function mapToInvoiceLines(invoiceLines: unknown[]): InvoiceLine[] {
    return invoiceLines?.map((invoiceLine: any) => Object.assign([], new Receipt(), invoiceLine));
}

export function mapToReceipt(json: unknown): Receipt {
    if (!json) {
        throw new Error('JSON receipt is not defined');
    }

    const receipt = DeepCopy.receipt((json || {}) as Receipt);

    return receipt;
}

export function mapToReceipts(receipts: unknown[]): Receipt[] {
    return receipts?.map(mapToReceipt) || [];
}

export function mapToPurchaseLine(json: unknown): PurchaseLine {
    const pline = (json as PurchaseLine) || ({} as PurchaseLine);
    const purchaseLine = DeepCopy.purchaseLine(pline);

    purchaseLine.receipts = purchaseLine.receipts.map(mapToReceipt);
    purchaseLine.receipts?.sort((a: Receipt, b: Receipt) => a.receiptTime - b.receiptTime);

    return purchaseLine;
}

export function mapToPurchase(json: any): Purchase {
    json.confirmStatus = ConfirmStatus[json.confirmStatus];

    const purchase = DeepCopy.purchase(json || {});

    purchase.lines = purchase.lines.map(mapToPurchaseLine);

    return purchase;
}

export function mapToTableRow(purchase: Purchase, index: number): TableRow<Purchase> {
    const row = { payload: purchase };

    Object.defineProperties(row, {
        number: { value: index, writable: true },
        expanded: { value: false, writable: true },
        selected: { value: false, writable: true },
        rowId: { value: purchase.purchaseId, writable: true },
        disableSelection: { value: false, writable: true }
    });

    return row as TableRow<Purchase>;
}

export function mapToPurchaseLineIndex(json: unknown): PurchaseLineIndex {
    const pline = (json as PurchaseLineIndex) || ({} as PurchaseLineIndex);
    const purchaseLineIndex = DeepCopy.purchaseLineIndex(pline);

    return purchaseLineIndex;
}

export function mapToPurchaseIndex(json: any): PurchaseIndex {
    const purchaseIndex = DeepCopy.purchaseIndex(json || {});

    purchaseIndex.purchaseLines = purchaseIndex.purchaseLines?.map(mapToPurchaseLineIndex);

    return purchaseIndex;
}

export function mapToPurchaseIndexTableRow(purchaseIndex: PurchaseIndex, index: number): TableRow<PurchaseIndex> {
    const row = { payload: purchaseIndex };

    const { purchaseId, status, receipts = new Map() } = purchaseIndex;
    const confirmStatus = calculatePurchaseConfirmStatus(status, receipts);

    Object.defineProperties(row, {
        number: { value: index, writable: true },
        expanded: { value: false, writable: true },
        selected: { value: false, writable: true },
        rowId: { value: purchaseId, writable: true },
        disableSelection: {
            value: confirmStatus !== ConfirmStatus.NOT_CONFIRMED,
            writable: true
        }
    });

    return row as TableRow<PurchaseIndex>;
}
