export type BulkReceivingResponseErrorKey =
    | 'PurchaseNotExist'
    | 'PurchaseNotEligible'
    | 'UserAliasIsNull'
    | 'UserUnauthorized'
    | 'PurchaseHasFutureReceipts'
    | 'LineTypeNotValid'
    | 'AmountNotValid'
    | 'QuantityNotValid'
    | 'UncommittedBalanceLessThanZero'
    | 'AuditHistoryExeption'
    | 'ContextCreationException'
    | 'ReceiptCreationException'
    | 'ReceiptPublishException';

export class PurchaseLineResponse {
    public purchaseId?: string;
    public lineNumber?: number;
    public orderNumber?: string;
    public errors?: BulkReceivingResponseErrorKey[];

    public static from(json: Partial<PurchaseLineResponse>): PurchaseLineResponse {
        return Object.assign(new PurchaseLineResponse(), { ...json });
    }
}

export class BulkReceivingResponse {
    public successes: PurchaseLineResponse[] = [];
    public failures: PurchaseLineResponse[] = [];

    public static from(json: Record<string, Record<string, unknown>[]>): BulkReceivingResponse {
        return Object.assign(new BulkReceivingResponse(), {
            successes: json.successes?.map(s => PurchaseLineResponse.from(s)),
            failures: json.failures?.map(f => PurchaseLineResponse.from(f))
        });
    }
}
