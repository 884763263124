import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrgMapping, UpdateStatus } from 'modules/mappings/models';
import { orgMappingService } from 'modules/mappings/services/orgMappings';

import {
    ApplicationState,
    initializeCostCenterList,
    showErrorPage,
    updateCostCenterMapping,
    removeCostCenterMapping
} from 'modules/app/store';

interface UpdateResult {
    status: UpdateStatus;
    reason: string;
}

export function useOrgMappings() {
    const dispatch = useDispatch();

    const { costCentersDetails } = useSelector((state: ApplicationState) => state.orgCostCenters);

    const [orgMappings, setOrgMappings] = useState<OrgMapping[]>();
    const [updateSuccess, setUpdateSuccess] = useState<UpdateResult>({
        status: UpdateStatus.NOT_SUBMITTED,
        reason: ''
    });

    async function saveOrgMappingsAsync(): Promise<void> {
        const updateRequests = computeUpdateRequests();
        const { result, message } = await orgMappingService.updateOrgMappingsAsync(updateRequests);

        const resultStatus: UpdateStatus = result ? UpdateStatus.SUBMIT_SUCCESS : UpdateStatus.SUBMIT_FAILURE;
        setUpdateSuccess({ status: resultStatus, reason: message });

        if (result) await getMappingsAsync();
    }

    const computeUpdateRequests = () => {
        const updateRequests: OrgMapping[] = [];

        orgMappings?.forEach(mapping => {
            const initialCostCenters = getOrgInitialCostCenters(mapping.orgName);

            if (hasSameCostCenters(initialCostCenters, mapping.costCenters)) updateRequests.push(mapping);
        });

        return updateRequests;
    };

    const getOrgInitialCostCenters = (orgName: string) => {
        const costCenters = (costCentersDetails || [])
            .filter(costCentersDetail => costCentersDetail.mappedOrg === orgName)
            .map(costCentersDetail => costCentersDetail.costCenterName);

        return costCenters;
    };

    const hasSameCostCenters = (ccSet1: string[], ccSet2: string[]) => {
        return ccSet1 && ccSet2 && ccSet1.length === ccSet2.length && ccSet1.every(cc => ccSet2.includes(cc));
    };

    const getMappingsAsync = useCallback(async () => {
        const { response, status } = await orgMappingService.getOrgMappingsAsync();
        if (!response && status) {
            dispatch(showErrorPage({ errorCode: status }));
        }

        if (response) setInitialValues(response.unassignedCostCenters, response.orgMappings);
    }, [setOrgMappings]);

    const setInitialValues = (unassignedCostCenters: string[], mappings?: OrgMapping[]) => {
        setOrgMappings(mappings);
        dispatch(initializeCostCenterList({ mappings, unassignedCostCenters }));
    };

    const onOrgMappingUpdate = (mapping: OrgMapping, removedCostCenter?: string) => {
        if (!orgMappings) return;

        const updatedMappings = orgMappings.map(current => (current.orgName === mapping.orgName ? mapping : current));

        if (removedCostCenter) dispatch(removeCostCenterMapping(removedCostCenter));
        dispatch(updateCostCenterMapping(mapping));

        setOrgMappings(updatedMappings);
    };

    const onAddingNewOrg = (newOrgName: string) => {
        const newOrg = new OrgMapping(newOrgName);
        setOrgMappings(prev => {
            if (!prev) return Array(newOrg);
            prev.push(newOrg);
            return prev;
        });
    };

    useEffect(() => {
        if (!orgMappings) {
            getMappingsAsync();
        }
    }, [getMappingsAsync]);

    return {
        orgMappings,
        saveOrgMappingsAsync,
        onOrgMappingUpdate,
        onAddingNewOrg,
        updateSuccess
    };
}
