import React from 'react';
import { Button, Box, SpaceBetween } from '@amzn/awsui-components-react';

import style from '../BigOChannelMappings/BigOChannelMappings.module.scss';
import { useTranslation } from 'react-i18next';

interface BigOChannelMappingsHeaderProps {
    mappingsCount: number;
    onAddCoaModalOpen: () => void;
}

export const BigOChannelMappingsHeader = (props: BigOChannelMappingsHeaderProps) => {
    const { mappingsCount, onAddCoaModalOpen } = props;
    const { t } = useTranslation('mappings');

    return (
        <div>
            <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">
                    <h2 className={'awsui-util-d-ib'}>
                        {t('bigOChannelMappings.coaCombination')}
                        <span id="custom-action-stripe-counter" className="awsui-util-header-counter">
                            &nbsp;&nbsp;({mappingsCount})
                        </span>
                    </h2>
                </div>
                <div className="awsui-util-action-stripe-group">
                    <Box float="right" className={style['table-header-div']}>
                        <SpaceBetween direction="horizontal" size="m">
                            <Button variant="normal" onClick={onAddCoaModalOpen}>
                                {t('bigOChannelMappings.addCoaCombination')}
                            </Button>
                        </SpaceBetween>
                    </Box>
                </div>
            </div>
        </div>
    );
};
