import { PurchaseStatus, Receipt } from '../models';
import { Receipt as TsCoreReceipt } from '@amzn/merp-core/models';
import { DateGenerator } from '../../core/class';
import { ConfirmStatus } from '../models/ConfirmStatus';

const convertToReceipt = (r: TsCoreReceipt): Receipt => Object.assign(new Receipt(), r);

const closedStatuses = new Set([
    PurchaseStatus.CLOSED,
    PurchaseStatus.CANCELLED,
    PurchaseStatus.SOFT_CLOSED,
    PurchaseStatus.PENDING_SOFT_CLOSED
]);

export const calculatePurchaseConfirmStatus = (
    purchaseStatus: PurchaseStatus,
    receipts: Map<number, TsCoreReceipt[]>
) => {
    if (closedStatuses.has(purchaseStatus)) {
        return ConfirmStatus.PURCHASE_CLOSED;
    }

    if (purchaseStatus === PurchaseStatus.PURCHASE_REQUEST) {
        return ConfirmStatus.NOT_APPLICABLE;
    }

    const endOfCurrentMonth = DateGenerator.getEndOfTheLastDayOfTheCurrentMonth();
    let hasReceiptsBeforeMonthEnd = false;
    let hasUnconfirmedReceipts = false;

    findUnconfirmedReceipts: for (const receiptArray of receipts.values()) {
        for (const receipt of receiptArray) {
            if (receipt.receiptTime <= endOfCurrentMonth) {
                hasReceiptsBeforeMonthEnd = true;
                if (convertToReceipt(receipt).qualifiesForConfirmation) {
                    hasUnconfirmedReceipts = true;
                    break findUnconfirmedReceipts;
                }
            }
        }
    }

    if (!hasReceiptsBeforeMonthEnd) {
        return ConfirmStatus.NOT_APPLICABLE;
    }

    return hasUnconfirmedReceipts ? ConfirmStatus.NOT_CONFIRMED : ConfirmStatus.CONFIRMED;
};
