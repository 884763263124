import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { BigONewCoaSchema, SMARTeSchema, NewOrgSchema, ReassignCostCenterSchema } from 'modules/mappings/forms';

type SMARTeInputs = yup.InferType<typeof SMARTeSchema>;
type NewOrgInputs = yup.InferType<typeof NewOrgSchema>;
type ReassignCostCenterInputs = yup.InferType<typeof ReassignCostCenterSchema>;
type BigONewCoaInputs = yup.InferType<typeof BigONewCoaSchema>;

export class FormSchema {
    public static getSMARTeUseForm() {
        const methods = useForm<SMARTeInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: SMARTeSchema
        });

        return methods;
    }

    public static getNewOrgUseForm() {
        const methods = useForm<NewOrgInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: NewOrgSchema
        });

        return methods;
    }

    public static getReassignCostCenterUseForm() {
        const methods = useForm<ReassignCostCenterInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: ReassignCostCenterSchema
        });

        return methods;
    }

    public static BigONewCoaUseForm() {
        const methods = useForm<BigONewCoaInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: BigONewCoaSchema
        });

        return methods;
    }
}
