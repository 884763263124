/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Budget, BudgetStatus } from '@amzn/merp-core/models/budgetManagement';

import { TableRow } from 'modules/core/model';
import { logger } from 'modules/core/logger';
import { showErrorPage } from 'modules/app/store';
import { YearForecast } from '../model/YearForecast';
import { mapToYearForecast } from '../mappers';
import { budgetService } from '../services';
import { UpdateStatus } from '@amzn/merp-core/models';

interface UpdateResult {
    status: UpdateStatus;
}

export function useBudget(entityId: string, ownerView = false, teamName?: string, teamNames?: string[]) {
    const [yearForecasts, updateYearForecasts] = useState<TableRow<YearForecast>[]>();
    const [cycle, updateCycle] = useState<string>('3YF');
    const [budgetsApproved, setBudgetsApproved] = useState(false);
    const [ongoingBudgets, updateOngoingBudgets] = useState<Budget[]>();
    const [loadingBudget, setLoadingBudget] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState<UpdateResult>({
        status: UpdateStatus.PENDING
    });
    const dispatch = useDispatch();

    useEffect(() => {
        async function areActiveBudgetsApproved(): Promise<void> {
            let activeApprovedBudget = false;

            const response = await budgetService.getBudgets(teamName || '', BudgetStatus.STARTED);

            const { status, budget } = response;

            if (status !== 200 || !budget || budget.length <= 0) {
                activeApprovedBudget = false;
            } else {
                activeApprovedBudget = budget?.every(value => value.approvedBy) || false;
            }

            setBudgetsApproved(activeApprovedBudget);
        }

        if (ownerView && teamName) {
            areActiveBudgetsApproved();
        }
    }, [ownerView, teamName]);

    async function approveBudget(budgetId: string) {
        try {
            const { success } = await budgetService.approveBudgetBasedOnBudgetId(budgetId);
            const resultStatus: UpdateStatus = success ? UpdateStatus.COMPLETED : UpdateStatus.FAILED;
            setUpdateSuccess({ status: resultStatus });
        } catch (error) {
            logger.error(error);
        }
    }

    useEffect(() => {
        async function fetchForecasts(entityId: string) {
            setLoadingBudget(true);

            const response = await budgetService.getBudgetsBasedOnEntityId(entityId);

            if (response.status !== 200) {
                dispatch(showErrorPage({ errorCode: response.status || 404 }));
            } else {
                updateYearForecasts(mapToYearForecast(response.forecastsResult));
                updateCycle(response.budgetResult?.guidanceCycle ?? '3YF');
            }

            setLoadingBudget(false);
        }

        if (!yearForecasts && entityId) {
            fetchForecasts(entityId);
        }
    }, [entityId]);

    useEffect(() => {
        async function fetchOngoingBudgets(teamNames: string[]) {
            let ongoingBudgetsList: Budget[] = [];

            for (let i = 0; i < teamNames.length; i++) {
                const startedResponse = await budgetService.getBudgets(teamNames[i], BudgetStatus.STARTED);

                const startedStatus = startedResponse.status;
                const startedBudget = startedResponse.budget;

                if (startedStatus === 200 && startedBudget) {
                    ongoingBudgetsList = ongoingBudgetsList.concat(startedBudget);
                    updateOngoingBudgets(ongoingBudgetsList);
                }

                const completedResponse = await budgetService.getBudgets(teamNames[i], BudgetStatus.COMPLETED);

                const completedStatus = completedResponse.status;
                const completedBudget = completedResponse.budget;

                if (completedStatus === 200 && completedBudget) {
                    ongoingBudgetsList = ongoingBudgetsList.concat(completedBudget);
                    updateOngoingBudgets(ongoingBudgetsList);
                }
            }
        }

        if (!ongoingBudgets && teamNames) {
            fetchOngoingBudgets(teamNames);
        }
    }, [ongoingBudgets, teamNames]);

    return {
        loadingBudget,
        cycle,
        yearForecasts,
        budgetsApproved,
        ongoingBudgets,
        updateYearForecasts,
        approveBudget,
        updateSuccess
    };
}
