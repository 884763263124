import { logger } from 'modules/core';
import { ApiClient } from 'modules/core/class/api/ApiClient';
import { BulkReceivingRow } from '../models';
import { FilteringCriteria } from '../types';
import { DateGenerator } from 'modules/core/class';
import { ApiError } from 'modules/core/types';
import { GetRequestProps } from 'modules/core/class/api/RequestProps';

const START_YEAR = 2021;

export type GetColumnValuesParameters = {
    columnName: string;
    searchInput: string;
};

export type GetBulkReceivingResponse<T> = {
    data?: T[];
    error?: ApiError;
};

type PurchaseLinesReport = {
    base64: string;
    sha256: string;
};

const poCreationTimeRange = () => ({
    poCreationTimeFrom: DateGenerator.getStartOfYear(START_YEAR),
    poCreationTimeTo: DateGenerator.getEndOfYear()
});

export class BulkReceivingService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'purchaseLines';

    public async getRowsAsync(
        filteringCriteria: FilteringCriteria | undefined,
        pagination?: { from: number; size: number }
    ): Promise<GetBulkReceivingResponse<BulkReceivingRow>> {
        try {
            logger.debug(
                `Making request ${this.resource}/ with ${
                    filteringCriteria ? JSON.stringify(filteringCriteria) : 'no filters'
                }`
            );

            const rows = await this.getV2(undefined, {
                queryStringParameters: {
                    ...poCreationTimeRange(),
                    ...filteringCriteria,
                    ...pagination
                }
            });

            logger.info(`Response has ${Array.isArray(rows) ? rows.length : 0} rows`);

            return {
                data: (rows ?? []).map((r: Record<string, unknown>) => BulkReceivingRow.from(r))
            };
        } catch (error) {
            const apiError: ApiError = error;

            logger.error(apiError);

            return {
                error: apiError
            };
        }
    }

    public async getColumnValuesAsync(parameters: GetColumnValuesParameters): Promise<string[]> {
        const path = 'column';
        try {
            logger.info(`Making request ${this.resource}/${path}`);

            const columnValues = await this.getV2(path, {
                queryStringParameters: {
                    ...poCreationTimeRange(),
                    ...parameters
                }
            });

            logger.info(`Response: ${columnValues}`);

            return columnValues;
        } catch (error) {
            logger.error(error);

            return [];
        }
    }

    public async downloadRowsAsync(filteringCriteria: FilteringCriteria | undefined) {
        const path = 'spend-report';
        try {
            logger.info(`Making request ${this.resource}/${path}`);

            const reportData = await this.getV2(path, {
                queryStringParameters: {
                    ...poCreationTimeRange(),
                    ...filteringCriteria
                }
            } as GetRequestProps);

            const xlsxBuffer = await this.getReportBytes(reportData);
            this.createLink(xlsxBuffer);
        } catch (error) {
            logger.error(error);
            throw error;
        }
    }

    private async getReportBytes(reportData: PurchaseLinesReport): Promise<ArrayBuffer> {
        const base64ToArrayBuffer = (base64: string) => {
            const binaryString = atob(base64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        };

        const sha256AsString = async (data: ArrayBuffer) => {
            const digest = await crypto.subtle.digest('SHA-256', data);
            return [...new Uint8Array(digest)].map(x => x.toString(16).padStart(2, '0')).join('');
        };

        const xlsxBuffer = base64ToArrayBuffer(reportData.base64);

        const actualSha256 = await sha256AsString(xlsxBuffer);
        if (actualSha256 !== reportData.sha256) {
            throw new Error('Report data was corrupted');
        }

        const formatFileSize = (size: number) =>
            size < 1024
                ? `${size} bytes`
                : size < 1024 * 1024
                ? `${(size / 1024).toFixed(2)} KB`
                : `${(size / 1024 / 1024).toFixed(2)} MB`;
        logger.info(`Report data is correct ${formatFileSize(xlsxBuffer.byteLength)}`);
        return xlsxBuffer;
    }

    private async createLink(xlsxBuffer: ArrayBuffer) {
        const a = document.createElement('a');
        a.setAttribute('style', 'display: none');
        const blob = new Blob([xlsxBuffer], { type: 'application/vnd.ms-excel' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        const dateStr = new Date()
            .toISOString()
            .slice(0, 19)
            .replaceAll(':', '-');
        a.download = `ASP-Bulk-Edit-Spend_${dateStr}.xls`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
}

export const bulkReceivingService = new BulkReceivingService();
