/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Button } from '@amzn/awsui-components-react';

import { ApplicationState, isFinancialAnalyst } from 'modules/app/store';
import { TableRow } from 'modules/core/model';
import { CSVTemplate } from 'modules/dashboard/models';
import { BudgetPlanCsvConverter, YearForecast } from 'modules/budgetManagement/model';
import { YearBudgetTable } from '../YearBudgetTable';
import { navigate } from '@reach/router';

interface BudgetPlanCycleTableProps {
    items: TableRow<YearForecast>[];
    cycle?: string;
    loading: boolean;
    approveBudget?: () => void;
    showUploadModal?: (value: boolean) => void;
    budgetId?: string;
}

export const BudgetPlanCycleTable = (props: BudgetPlanCycleTableProps) => {
    const { items, cycle, loading, approveBudget, showUploadModal, budgetId } = props;

    const { t } = useTranslation('budgetManagement');

    const userClaims = useSelector((state: ApplicationState) => state.user);
    const isFA = useSelector(isFinancialAnalyst);

    const csvLink = useRef<any>();
    const [csvExport, setCsvExport] = useState<CSVTemplate | undefined>(undefined);
    const [loadingPlan, setLoadingPlan] = useState(false);

    useEffect(() => {
        if (!csvExport) return;

        setTimeout(() => {
            csvLink.current?.link.click();
            setLoadingPlan(false);
            setCsvExport(undefined);
        }, 500);
    }, [csvExport]);

    const download = (
        <>
            <Button loading={loadingPlan} onClick={onClickDownload}>
                {t('tableButton.download')}
            </Button>
            <>
                {csvExport && (
                    <CSVLink
                        data={csvExport.getCSVData()}
                        headers={csvExport.getHeaders()}
                        filename={`${userClaims.alias}_msp_budgetPlan.csv`}
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                    />
                )}
            </>
        </>
    );

    const requestChanges = <Button onClick={onRequestChange}>{t('tableButton.requestChange')}</Button>;
    const approve = (
        <Button variant="primary" onClick={onClickApproval}>
            {t('tableButton.approval')}
        </Button>
    );
    const upload = (
        <Button variant="primary" onClick={onClickUpload}>
            {t('tableButton.upload')}
        </Button>
    );

    function onClickApproval() {
        if (approveBudget) {
            approveBudget();
        }
    }

    function onRequestChange() {
        return navigate(`/budget/requestChanges/${budgetId}`);
    }

    function onClickUpload() {
        if (showUploadModal) {
            showUploadModal(true);
        }
    }

    function onClickDownload() {
        setLoadingPlan(true);

        const csvTemplate = new BudgetPlanCsvConverter().build(
            items.map(r => r.payload),
            isFA
        );
        setCsvExport(csvTemplate);
    }

    const getTitle = () => {
        return isFA
            ? t('table.budgetCyclePlan.superUserTitle', { cycle: cycle || '' })
            : t('table.budgetCyclePlan.generalUserTitle');
    };

    const getHeaderButtons = () => {
        return isFA ? [download, requestChanges, approve] : [download, upload];
    };

    return (
        <YearBudgetTable
            loading={loading}
            title={getTitle()}
            emptyTitle={t('table.budgetCyclePlan.emptyTitle')}
            items={items}
            headerButtons={getHeaderButtons()}
        />
    );
};
