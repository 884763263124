import { logger } from 'modules/core';
import { ApiClient } from 'modules/core/class/api/ApiClient';
import { GetRequestProps } from 'modules/core/class/api/RequestProps';
import { COACombination } from 'modules/onboarding/model';
import { ApiError } from 'modules/core/types';

export type GetCOAsRequest = {
    teamName?: string;
    pageSize?: number;
    isOnboarded?: boolean;
    lastEvaluatedKey?: string;
};

export type GetCOAsResponse = {
    status: number;
    lastEvaluatedKey?: string;
    coaCombinations?: COACombination[];
};

export type LastEvaluatedKey = {
    filterId: {
        s: string;
    };
};

export type GetCOAsRawResponse = {
    requestId?: string;
    lastEvaluatedKey?: LastEvaluatedKey;
    result?: COACombination[];
};

export class COAService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'coa';

    public async getCOAsAsync(getCOAsRequest: GetCOAsRequest): Promise<GetCOAsResponse> {
        const cleanGetCOAsRequest: GetCOAsRequest = Object.fromEntries(
            Object.entries(getCOAsRequest).filter(([_, v]) => v != null && v != undefined)
        );
        const getRequestProps: GetRequestProps = {
            queryStringParameters: cleanGetCOAsRequest
        };

        try {
            const response: GetCOAsRawResponse = await this.getV2('', getRequestProps);

            logger.info(`getCOAs raw response: ${response}`);

            const lastEvaluatedKey = response.lastEvaluatedKey?.filterId.s;
            const coaCombinations = response.result;

            const getCOAsResponse: GetCOAsResponse = {
                status: 200,
                lastEvaluatedKey: lastEvaluatedKey,
                coaCombinations: coaCombinations
            };

            return getCOAsResponse;
        } catch (error) {
            const apiError: ApiError = error as ApiError;
            logger.error(apiError);
            return { status: apiError?.response?.status };
        }
    }
}

export const coaService = new COAService();
