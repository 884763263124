import { useTranslation } from 'react-i18next';
import { PropertyFilterProps } from '@amzn/awsui-components-react';

export const COAPropertyStrings = () => {
    const { t } = useTranslation('purchase');

    const COAProperties: PropertyFilterProps.FilteringProperty[] = [
        {
            propertyLabel: t('dashboard.search.properties.companyLabel'),
            key: t('dashboard.search.properties.companyKey'),
            groupValuesLabel: t('dashboard.search.properties.companyLabel') + ' name values',
            operators: ['=']
        },
        {
            propertyLabel: t('dashboard.search.properties.costCenterLabel'),
            key: t('dashboard.search.properties.costCenterKey'),
            groupValuesLabel: t('dashboard.search.properties.costCenterLabel') + ' name values',
            operators: ['=']
        },
        {
            propertyLabel: t('dashboard.search.properties.locationLabel'),
            key: t('dashboard.search.properties.locationKey'),
            groupValuesLabel: t('dashboard.search.properties.locationLabel') + ' name values',
            operators: ['=']
        },
        {
            propertyLabel: t('dashboard.search.properties.glAccountLabel'),
            key: t('dashboard.search.properties.glAccountKey'),
            groupValuesLabel: t('dashboard.search.properties.glAccountLabel') + ' name values',
            operators: ['=']
        },
        {
            propertyLabel: t('dashboard.search.properties.productLineLabel'),
            key: t('dashboard.search.properties.productLineKey'),
            groupValuesLabel: t('dashboard.search.properties.productLineLabel') + ' name values',
            operators: ['=']
        },
        {
            propertyLabel: t('dashboard.search.properties.channelLabel'),
            key: t('dashboard.search.properties.channelKey'),
            groupValuesLabel: t('dashboard.search.properties.channelLabel') + ' name values',
            operators: ['=']
        },
        {
            propertyLabel: t('dashboard.search.properties.projectLabel'),
            key: t('dashboard.search.properties.projectKey'),
            groupValuesLabel: t('dashboard.search.properties.projectLabel') + ' name values',
            operators: ['=']
        },
        {
            propertyLabel: t('dashboard.search.properties.customEighthSegmentLabel'),
            key: t('dashboard.search.properties.customEighthSegmentKey'),
            groupValuesLabel: t('dashboard.search.properties.customEighthSegmentLabel') + ' name values',
            operators: ['=']
        }
    ];

    return COAProperties;
};
