import React from 'react';
import styles from './MessageCard.module.scss';
import { Icon, IconProps } from '@amzn/awsui-components-react';

interface MessageCardProps {
    iconName: IconProps.Name;
    content: string;
    iconColor: string;
}
export const MessageCard = (props: MessageCardProps) => {
    return (
        <div className={styles.full_screen_container}>
            <div className={styles.wrapper}>
                <div className={'awsui-util-container awsui-util-m-xxl awsui-util-d-b'}>
                    <div className={styles.txt}>{props.content}</div>
                    <div className={`${styles.img} ${styles[props.iconColor]}`}>
                        <Icon name={props.iconName} size="large"></Icon>
                    </div>
                </div>
            </div>
        </div>
    );
};
