import React, { useEffect } from 'react';
import { Router, RouteComponentProps, Redirect } from '@reach/router';
import { useDispatch } from 'react-redux';

import { Error } from 'modules/error/views';
import { resetPurchase } from 'modules/app/store';
import { RollUpRoutes } from 'modules/rollups/routes';
import { NavBar } from 'modules/app/components/navbar';
import { PoRoutes } from 'modules/purchaseOrder/routes';
import { MappingRoutes } from 'modules/mappings/routes';
import { UserPreferences } from 'modules/preferences/views';
import { AuditDetail, AuditList } from 'modules/audit/views';
import { RequisitionRoutes } from 'modules/requisition/routes';
import { BudgetRoutes } from 'modules/budgetManagement/routes';
import { BulkEditCoa } from 'modules/accountingStrings/views';
import { BulkEdit, BulkReceivingView } from 'modules/bulkEdit/views';
import { CustomFieldAndSelection } from 'modules/customCategory/views';
import { OnboardingRoutes } from 'modules/onboarding/routes';
import { AppNotifications } from 'modules/app/components/appNotifications';

export function AppRoutes(props: RouteComponentProps): JSX.Element {
    const dispatch = useDispatch();

    const Routes = {
        Base: '/',
        BasePR: '/purchaseRequest/*',
        BasePO: '/purchaseOrder/*',
        PurchaseOrder: 'purchaseOrder',
        Preferences: '/preferences',
        Audit: '/audit/:purchaseId',
        AuditDetail: '/audit/:purchaseId/:lineVersionLineNumber',
        Mappings: '/mappings/*',
        RollUps: '/rollups/*',
        BulkEdit: '/bulkEdit',
        BulkEditCoa: '/bulkEdit/accountingStrings',
        Budget: '/budget/*',
        BulkReceiving: '/receiving',
        CustomCategory: '/customCategory',
        Onboarding: '/onboarding/*'
    };

    useEffect(() => {
        dispatch(resetPurchase());
    }, [props.location?.pathname]);

    return (
        <div>
            <NavBar {...props} />
            <AppNotifications />
            <Router>
                <Redirect from={Routes.Base} to={Routes.PurchaseOrder} noThrow />
                <UserPreferences path={Routes.Preferences} />
                <RequisitionRoutes path={Routes.BasePR} />
                <PoRoutes path={Routes.BasePO} />
                <AuditList path={Routes.Audit} />
                <AuditDetail path={Routes.AuditDetail} />
                <MappingRoutes path={Routes.Mappings} />
                <RollUpRoutes path={Routes.RollUps} />
                <BulkEdit path={Routes.BulkEdit} />
                <BulkReceivingView path={Routes.BulkReceiving} />
                <BulkEditCoa path={Routes.BulkEditCoa} />
                <BudgetRoutes path={Routes.Budget} />
                <CustomFieldAndSelection path={Routes.CustomCategory} />
                <OnboardingRoutes path={Routes.Onboarding} />
                <Redirect from="/dashboard/purchaseOrder" to="/purchaseOrder" noThrow />
                <Redirect from="/dashboard/purchaseRequest" to="/purchaseRequest" noThrow />
                <Error message="error.urlError" btnContent="error.urlBtn" default />
            </Router>
        </div>
    );
}
