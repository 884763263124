const reasons = Object.freeze({
    initialInput: null,
    actualSpendBelowPlanned: null,
    actualSpendAbovePlanned: null,
    workExpedited: null,
    workDelayed: null,
    workCancelled: null,
    changeInDeliverablesOrScope: null,
    editInvoiceMatch: null,
    receiptUpdateForGoodsOrServices: null
});

export type UpdateMotive = keyof typeof reasons;

export const MotiveOptions = Object.keys(reasons);

export class UpdateReceiptsReason {
    public motive: UpdateMotive = 'receiptUpdateForGoodsOrServices';
    public explanation?: string;

    public get isValid() {
        return this.motive !== undefined;
    }
}
