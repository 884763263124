import React from 'react';
import { withKnobs } from '@storybook/addon-knobs';
import { SoftClosedVersion } from './SoftClosedVersion';
import { LineVersion } from 'modules/audit/models';

export default {
    component: SoftClosedVersion,
    title: 'Audit/components/SoftClosedVersion/SoftClosedVersion',
    decorators: [withKnobs]
};

export const softClosedVersion = (lineVersions: LineVersion[]) => <SoftClosedVersion lineVersions={lineVersions} />;
