import { ApiClient } from 'modules/core/class/api/ApiClient';
import { logger } from 'modules/core';

import { UpdateOwnerRequest, MspOwner } from '../models';

interface UpdateOwnersResult {
    requestId?: string;
    owners: string[] | undefined;
    errorDescription: string;
}
export class OwnerService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'purchases';

    public async updateMspOwnersAsync(purchaseId: string, request: MspOwner[]): Promise<UpdateOwnersResult> {
        try {
            const body = { ownerRequests: request } as UpdateOwnerRequest;
            const jsonResponse = await this.put(`${purchaseId}/owners`, { body });
            if (jsonResponse.result) {
                const { result, requestId } = jsonResponse;
                const owners = result.owners;
                const errorDescription = result.errorDescription;
                return { requestId, owners, errorDescription };
            }
            return jsonResponse as UpdateOwnersResult;
        } catch (error) {
            logger.error(error);
            return {
                owners: undefined,
                errorDescription: 'Unable to add/revoke owners request'
            };
        }
    }
}

export const ownerService = new OwnerService();
