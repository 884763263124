import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { InvoiceLine } from 'modules/invoices/models';
import { Receipt } from 'modules/purchase/models/Receipt';
import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse, SoftCloseResponse } from 'modules/core/model/response';
import { PaginatedResult, PaginatedQuery } from 'modules/core/model';
import { RequestParams } from 'modules/core/class/api/RequestParams';
import { Purchase, UpdatePurchaseRequest, UpdateReceiptsRequest } from '../models';
import { mapToInvoiceLines, mapToPurchase, mapToReceipts } from 'modules/core/mappers';

interface ConfirmReceiptsResult {
    confirmSuccess: boolean;
    purchaseId: string;
    errorDescription: string;
}

interface PurchaseResult {
    purchase: Purchase | undefined;
    requestId?: string;
    status?: number;
}

interface GetReceiptsResponse {
    receipts: Receipt[];
    invoiceLines: InvoiceLine[];
    lastEvaluatedKey: Map<string, unknown>;
}

interface ReceiptResult {
    status?: number;
    requestId?: string;
    receipts: Receipt[];
    lastEvaluatedKey?: Map<string, unknown>;
}

interface GetInvoiceLinesResponse {
    invoiceLines: InvoiceLine[];
    lastEvaluatedKey: Map<string, unknown>;
}

export class PurchaseService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'purchases';

    public async getPurchaseAsync(id: string, excludeReceipts = true): Promise<PurchaseResult> {
        try {
            const json: ResourceResponse = await this.get(`${id}?excludeReceipts=${excludeReceipts}`);

            if (json.result) {
                const { result, requestId } = json;
                return { requestId, purchase: mapToPurchase(result), status: 200 };
            }

            return { purchase: mapToPurchase(json), status: 200 };
        } catch (error) {
            logger.error(error);
            return { purchase: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    public async approvePurchaseAsync(id: string): Promise<boolean> {
        try {
            const params = { body: {} };
            const result: { approved: boolean; errorDescription: string } = await this.put(`${id}/approve`, params);
            if (!result.approved) {
                logger.error(result.errorDescription);
            }

            return result.approved;
        } catch (error) {
            logger.error(error);
            return false;
        }
    }

    public async updateConfirmAndSubmitReceiptsAsync(purchaseId: string, body?: UpdateReceiptsRequest[]) {
        try {
            const { result } = await this.put(`${purchaseId}/receipts`, { body });

            return result;
        } catch (error) {
            logger.error(error);
            return {
                updateSuccess: false,
                errorDescription: 'Unable to submit request'
            };
        }
    }

    public async confirmReceiptsAsync(purchaseIds: string[]): Promise<Map<string, string>> {
        const result = new Map<string, string>();

        try {
            const body = { purchaseIds };

            const jsonArray: ConfirmReceiptsResult[] = await this.put('confirmReceipts', { body });

            for (const { purchaseId, confirmSuccess, errorDescription } of jsonArray) {
                result.set(purchaseId, confirmSuccess ? '' : errorDescription);
            }

            return result;
        } catch (error) {
            logger.error(error);
            return result;
        }
    }

    public async getInvoiceLinesAsync(purchaseId: string, lineNumber: number): Promise<InvoiceLine[]> {
        try {
            const { result } = await this.get(`${purchaseId}/invoiceLines?purchaseLineNumber=${lineNumber}`);

            return result;
        } catch (error) {
            logger.error(error);
            return [];
        }
    }

    public async markFullyBilled(purchaseId: string): Promise<SoftCloseResponse> {
        try {
            const params = { body: {} };
            await this.post(`markAsFullyBilled/${purchaseId}`, params);
            return {
                softCloseSuccess: true,
                status: 200,
                ErrorMap: {}
            };
        } catch (error) {
            logger.error(error);
            const response = (error as ApiError)?.response;
            const responseStatus = response ? response.status : 500;
            const errorMap = response?.data.result?.errors
                ? response.data.result.errors
                : { purchaseId: ['Unexpected Error occurred while soft closing PO. Please try again'] };
            return {
                softCloseSuccess: false,
                status: responseStatus,
                ErrorMap: errorMap
            };
        }
    }

    public async getReceiptsAsync(purchaseId: string, lineNumber: number): Promise<ReceiptResult> {
        try {
            const json: ResourceResponse = await this.get(`${purchaseId}/receipts?purchaseLineNumber=${lineNumber}`);
            const { result, requestId } = json;
            const { receipts } = result as GetReceiptsResponse;
            return { requestId, receipts: mapToReceipts(receipts) };
        } catch (error) {
            logger.error(error);
            return { receipts: [], status: (error as ApiError)?.response?.status };
        }
    }

    public async getPaginatedReceiptsAsync(
        purchaseId: string,
        query?: PaginatedQuery
    ): Promise<PaginatedResult<Receipt>> {
        try {
            const queryStringParameters = this.mapQueryToStringParams({ purchaseId }, query);

            const jsonResponse = await this.getV2(`${purchaseId}/receipts`, { queryStringParameters });
            const { result, requestId } = jsonResponse;
            const { receipts, lastEvaluatedKey } = result as GetReceiptsResponse;

            return { requestId, items: mapToReceipts(receipts), lastEvaluatedKey };
        } catch (error) {
            logger.error(error);
            return { items: [], status: (error as ApiError)?.response?.status };
        }
    }

    public async getPaginatedInvoiceLinesAsync(
        purchaseId: string,
        query?: PaginatedQuery
    ): Promise<PaginatedResult<InvoiceLine>> {
        try {
            const queryStringParameters = this.mapQueryToStringParams({ purchaseId }, query);

            const jsonResponse = await this.getV2(`${purchaseId}/invoiceLines`, { queryStringParameters });
            const { result, requestId } = jsonResponse;
            const { invoiceLines, lastEvaluatedKey } = result as GetInvoiceLinesResponse;

            return { requestId, items: mapToInvoiceLines(invoiceLines), lastEvaluatedKey };
        } catch (error) {
            logger.error(error);
            return { items: [], status: (error as ApiError)?.response?.status };
        }
    }

    public async updatePurchaseDetailsAsync(
        purchaseId: string,
        body: UpdatePurchaseRequest
    ): Promise<ResourceResponse> {
        try {
            const json: ResourceResponse = await this.put(`${purchaseId}`, { body });

            return json;
        } catch (error) {
            logger.error(error);

            return {
                result: false,
                message: 'Unable to update Purchase',
                requestId: ''
            } as ResourceResponse;
        }
    }

    private mapQueryToStringParams(target: RequestParams, source?: PaginatedQuery): RequestParams {
        if (source?.lastEvaluatedKey) {
            target.lastEvaluatedKey = JSON.stringify(source.lastEvaluatedKey);
        }
        if (source?.fromLineNumber) {
            target.fromLineNumber = source.fromLineNumber;
        }
        if (source?.toLineNumber) {
            target.toLineNumber = source.toLineNumber;
        }

        return target;
    }
}

export const purchaseService = new PurchaseService();
