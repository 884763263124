import { logger } from 'modules/core';
import { ApiClient } from 'modules/core/class/api/ApiClient';

export class BigRockObjectiveService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'purchases';

    public async updateBigObjectiveAsync(purchaseId: string, newObjective: string) {
        try {
            const body = { bigObjective: newObjective };
            const { json } = await this.put(`${purchaseId}/bigObjective`, { body });
            return json;
        } catch (error) {
            logger.error(error);
            return {
                updateSuccess: false,
                errorDescription: 'Unable to update Purchase big objective'
            };
        }
    }
}

export const bigRockObjectiveService = new BigRockObjectiveService();
