import React from 'react';
import { useTranslation } from 'react-i18next';

interface RuleContentProps {
    customFields: string[];
    customSelections: Map<string, string[]>;
}

export const RuleContent = (props: RuleContentProps) => {
    const { customFields, customSelections } = props;

    const { t } = useTranslation('customCategory');

    const DisplayField = ({ field }: { field: string }) => <div>{field}</div>;

    const DisplaySelections = ({ group }: { group: string }) => {
        return (
            <>
                <div className="awsui-util-label">{group}</div>
                <div>{t('ruleContent.options')}</div>
                {customSelections.get(group)?.map(selection => (
                    <DisplayField key={selection} field={selection} />
                ))}
                <br />
            </>
        );
    };

    return customFields.length > 0 || customSelections.size > 0 ? (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>
                    <span>{t('ruleContent.title')}</span>
                </h2>
            </div>
            {customSelections.size > 0 && (
                <div>
                    <div className="awsui-util-label">{t('ruleContent.selectionTitle')}</div>
                    {[...customSelections.keys()].map(group => (
                        <DisplaySelections key={group} group={group} />
                    ))}
                </div>
            )}
            {customFields.length > 0 && (
                <div>
                    <div className="awsui-util-label">{t('ruleContent.fieldTitle')}</div>
                    {customFields.map(field => (
                        <DisplayField key={field} field={field} />
                    ))}
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};
