/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { Dashboard } from 'modules/dashboard/views';
import { PurchaseDetailPage } from 'modules/purchase/views/purchaseDetail';
import { usePurchase } from 'modules/purchase/hooks';

interface WrapperProps extends RouteComponentProps {
    purchaseId?: string;
}

export function RequisitionRoutes(props: RouteComponentProps): JSX.Element {
    const Routes = {
        Base: '/',
        BasePR: '/purchaseRequest/*',
        PurchaseId: ':purchaseId',
        BasePO: '/purchaseOrder/*',
        PurchaseRequest: 'PurchaseRequest',
        PurchaseOrder: 'purchaseOrder',
        Preferences: '/preferences',
        Audit: '/audit/:purchaseId'
    };

    const RequisitionWrapper = ({ purchaseId }: WrapperProps) => {
        usePurchase(purchaseId!);

        return <PurchaseDetailPage {...props} />;
    };

    return (
        <Router>
            <Dashboard path={Routes.BasePR} default />
            <RequisitionWrapper path={Routes.PurchaseId} />
        </Router>
    );
}
