import { useCallback, useEffect, useRef, useState } from 'react';
import { TableRow } from 'modules/core/model';
import { Receipt } from 'modules/purchase/models';
import { receiptService } from 'modules/purchase/services';

import { FilteringCriteria } from '../types';
import { bulkReceivingService } from '../services';
import { BulkReceivingRow } from '../models';
import { ReceivingTransaction } from '../models/ReceivingTransaction';

const reloadTimeMilliseconds = 30000;

export function useBulkReceiving(reloadTimeout = reloadTimeMilliseconds) {
    const defaultPageSize = 120;

    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [receivingTx, setReceivingTx] = useState<ReceivingTransaction | undefined>();
    const [dataRetrievalErrorMessage, setDataRetrievalErrorMessage] = useState<string>();

    const [rows, updateRows] = useState<TableRow<BulkReceivingRow>[]>([]);
    const [filteringCriteria, updateFilteringCriteria] = useState<FilteringCriteria>();
    const from = useRef<number>(0);

    const mapToRow = (poLineRow: BulkReceivingRow, index: number): TableRow<BulkReceivingRow> => {
        const row: TableRow<BulkReceivingRow> = {
            payload: poLineRow,
            rowId: undefined,
            selected: false,
            number: 0
        };

        const { unusedAmount, id } = poLineRow;

        Object.defineProperties(row, {
            number: { value: index, writable: true },
            selected: { value: false, writable: true },
            rowId: { value: id, writable: true },
            disableSelection: {
                value: unusedAmount && unusedAmount === 0,
                writable: true
            }
        });

        return row;
    };

    const onPaginatedResultUpdated = (items: TableRow<BulkReceivingRow>[] = []) => {
        updateRows(current => {
            const newItems = [];

            items.map(row => (row.number = newItems.length + row.number));
            newItems.push(...(current ?? []), ...items);

            return newItems;
        });
    };

    const paginateAsync = useCallback(async (filteringCriteria?: FilteringCriteria) => {
        const fetchRecords = async () => {
            const pagination = { from: from.current, size: defaultPageSize };

            const { data: rows, error } = await bulkReceivingService.getRowsAsync(filteringCriteria, pagination);

            setDataRetrievalErrorMessage(error?.message);

            if (rows) {
                const newFrom = rows.length > 0 ? from.current + rows.length : 0;
                from.current = newFrom;
            }

            return rows ?? [];
        };

        setLoading(true);

        const rows: BulkReceivingRow[] = await fetchRecords();

        setHasNextPage(rows.length !== 0 && rows.length >= defaultPageSize);

        const newRows = rows.map(mapToRow);
        onPaginatedResultUpdated(newRows);

        setLoading(false);
    }, []);

    const bulkReceiveAsync = useCallback(async (receipts: Receipt[], receiveInFull = false) => {
        const count = receipts.length;

        setReceivingTx({ loading: true, status: 'info', count });

        const result = await receiptService.create(receipts, receiveInFull);

        const errors = result?.failures;

        const hasErrors = result?.failures?.length ?? 0 > 0;
        const allFailed = hasErrors && result?.failures.length === receipts.length;

        const loading = !allFailed;
        const status = hasErrors ? (allFailed ? 'error' : 'warning') : 'success';

        setReceivingTx({ status, loading, errors, count });

        if (allFailed) {
            setReceivingTx({ loading, errors, status, count });
            return;
        }

        setTimeout(() => {
            setReceivingTx(() => {
                if (!hasErrors) return undefined;

                return { loading: false, errors: result?.failures, status, count };
            });

            updateFilteringCriteria(Object.assign({ ...filteringCriteria }));
        }, reloadTimeout);
    }, []);

    const dismissTransaction = () => {
        if (!receivingTx || receivingTx.loading) return;

        setReceivingTx(undefined);
    };

    useEffect(() => {
        paginateAsync();
    }, [paginateAsync]);

    useEffect(() => {
        if (!filteringCriteria) return;

        updateRows([]);
        from.current = 0;
        paginateAsync(filteringCriteria);
    }, [filteringCriteria]);

    return {
        rows,
        loading,
        receivingTx,
        hasNextPage,
        filteringCriteria,
        paginateAsync,
        bulkReceiveAsync,
        dismissTransaction,
        updateFilteringCriteria,
        dataRetrievalErrorMessage
    };
}
