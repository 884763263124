import { API } from '@aws-amplify/api';
import { RequestProps, GetRequestProps } from './RequestProps';

export abstract class ApiClient {
    protected abstract readonly resource: string;
    protected abstract readonly apiName: string;

    private readonly api = API;

    protected async get(path?: string, props?: GetRequestProps) {
        const url = this.buildUrl(path);
        return await this.api.get(this.apiName, url, {
            headers: { 'MSP-API-VERSION': '1.0' },
            response: false,
            ...props
        });
    }

    protected async getV1(path?: string, props?: GetRequestProps) {
        const url = this.buildUrl(path);
        return await this.api.get(this.apiName, url, {
            headers: { 'MSP-API-VERSION': '1.0' },
            response: false,
            ...props
        });
    }

    protected async getV2(path?: string, props?: GetRequestProps) {
        const url = this.buildUrl(path);
        return await this.api.get(this.apiName, url, {
            headers: { 'MSP-API-VERSION': '2.0' },
            response: false,
            ...props
        });
    }

    protected async post(path?: string, props?: RequestProps) {
        const url = this.buildUrl(path);
        return await this.api.post(this.apiName, url, { response: false, ...props });
    }

    protected async put(path?: string, props?: RequestProps) {
        const url = this.buildUrl(path);
        return await this.api.put(this.apiName, url, { response: false, ...props });
    }

    protected async del(path?: string, props?: RequestProps) {
        const url = this.buildUrl(path);
        return await this.api.del(this.apiName, url, { response: false, ...props });
    }

    private buildUrl(path?: string): string {
        return path ? `/${this.resource}/${path}` : `/${this.resource}`;
    }
}
