import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal } from '@amzn/awsui-components-react';

export const BulkReceivingExcelActionsModal = (props: {
    modalVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    header: string;
    message?: string;
    children?: React.ReactNode;
}) => {
    const { t } = useTranslation('bulkReceiving');

    const { modalVisible, header, onCancel, onConfirm, message, children } = props;

    return (
        <Modal
            data-testid="uploadDownloadModal"
            visible={modalVisible}
            header={header}
            onDismiss={onCancel}
            footer={
                <Box float="right">
                    <Button variant="link" onClick={onCancel}>
                        {t('uploadDownloadModal.cancel')}
                    </Button>
                    <Button variant="primary" onClick={onConfirm} data-testid="uploadDownloadModal.confirmBtn">
                        {t('uploadDownloadModal.ok')}
                    </Button>
                </Box>
            }
        >
            {message && <p>{message}</p>}
            {children && <>{children}</>}
        </Modal>
    );
};
