/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

export type CustomerTeamListTablePropertyFilterProps = {
    setTeamName: (teamName: string) => void;
};

export const CustomerTeamListTablePropertyFilter = (props: CustomerTeamListTablePropertyFilterProps) => {
    const { setTeamName } = props;

    const { t } = useTranslation('onboarding');

    const [query, setQuery] = useState({
        tokens: [],
        operation: 'and' as PropertyFilterProps.JoinOperation
    });

    const filteringProperties: PropertyFilterProps.FilteringProperty[] = [
        {
            propertyLabel: t('CustomerTeamListTablePropertyFilter.properties.teamNameLabel'),
            key: t('CustomerTeamListTablePropertyFilter.properties.teamNameKey'),
            groupValuesLabel: t('CustomerTeamListTablePropertyFilter.properties.teamNameLabel') + ' name values',
            operators: ['=']
        }
    ];

    const i18nStrings: PropertyFilterProps.I18nStrings = {
        filteringAriaLabel: t('CustomerTeamListTablePropertyFilter.filteringAriaLabel'),
        dismissAriaLabel: t('CustomerTeamListTablePropertyFilter.dismissAriaLabel'),

        filteringPlaceholder: t('CustomerTeamListTablePropertyFilter.placeholder'),
        groupValuesText: t('CustomerTeamListTablePropertyFilter.groupValuesText'),
        groupPropertiesText: t('CustomerTeamListTablePropertyFilter.groupPropertiesText'),
        operatorsText: t('CustomerTeamListTablePropertyFilter.operatorsText'),

        operationAndText: t('CustomerTeamListTablePropertyFilter.operationAndText'),
        operationOrText: t('CustomerTeamListTablePropertyFilter.operationOrText'),

        operatorLessText: t('CustomerTeamListTablePropertyFilter.operatorLessText'),
        operatorLessOrEqualText: t('CustomerTeamListTablePropertyFilter.operatorLessOrEqualText'),
        operatorGreaterText: t('CustomerTeamListTablePropertyFilter.operatorGreaterText'),
        operatorGreaterOrEqualText: t('CustomerTeamListTablePropertyFilter.operatorGreaterOrEqualText'),
        operatorContainsText: t('CustomerTeamListTablePropertyFilter.operatorContainsText'),
        operatorDoesNotContainText: t('CustomerTeamListTablePropertyFilter.operatorDoesNotContainText'),
        operatorEqualsText: t('CustomerTeamListTablePropertyFilter.operatorEqualsText'),
        operatorDoesNotEqualText: t('CustomerTeamListTablePropertyFilter.operatorDoesNotEqualText'),

        editTokenHeader: t('CustomerTeamListTablePropertyFilter.editTokenHeader'),
        propertyText: t('CustomerTeamListTablePropertyFilter.propertyText'),
        operatorText: t('CustomerTeamListTablePropertyFilter.operatorText'),
        valueText: t('CustomerTeamListTablePropertyFilter.valueText'),
        cancelActionText: t('CustomerTeamListTablePropertyFilter.cancelActionText'),
        applyActionText: t('CustomerTeamListTablePropertyFilter.applyActionText'),
        allPropertiesLabel: t('CustomerTeamListTablePropertyFilter.allPropertiesLabel'),

        tokenLimitShowMore: t('CustomerTeamListTablePropertyFilter.tokenLimitShowMore'),
        tokenLimitShowFewer: t('CustomerTeamListTablePropertyFilter.tokenLimitShowFewer'),
        clearFiltersText: t('CustomerTeamListTablePropertyFilter.clearFiltersText'),
        removeTokenButtonAriaLabel: () => 'Remove token',
        enteredTextLabel: text => `Use: "${text}"`
    };

    const onSearchRecords = async (detail: any) => {
        setQuery(detail);
        if (detail.tokens.length == 1) {
            setTeamName(detail.tokens[0].value);
        } else {
            setTeamName('');
        }
    };

    return (
        <PropertyFilter
            query={query}
            filteringProperties={filteringProperties}
            filteringOptions={[]}
            onChange={({ detail }: { detail: any }) => {
                onSearchRecords(detail);
            }}
            i18nStrings={i18nStrings}
        />
    );
};
