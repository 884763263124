import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Box, SpaceBetween, FormField } from '@amzn/awsui-components-react';
import { StartCycleDropdown } from '../StartCycleDropdown';

interface StartCycleModalProps {
    cycleOptions: string[];
    ongoingCycles: string[];
    onDismissed: () => void;
    onChange: (selection: string) => void;
}

export const StartCycleModal = (props: StartCycleModalProps) => {
    const { t } = useTranslation('budgetManagement');

    const { cycleOptions, ongoingCycles, onChange, onDismissed } = props;

    const [selection, updateSelection] = useState<string>();
    const [ongoingCyclesSelected, udpateOngoingCyclesSelected] = useState<boolean>(false);

    const onSelectionChanged = (selection: string) => {
        udpateOngoingCyclesSelected(ongoingCycles.includes(selection));
        updateSelection(selection);
    };

    const onSave = () => {
        onChange(selection as string);
        onDismissed();
    };

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={onDismissed}>
                    {t('startCycleModal.cancel')}
                </Button>
                <Button variant="primary" disabled={ongoingCyclesSelected} onClick={onSave}>
                    {t('startCycleModal.ok')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal visible={true} header={t('startCycleModal.title')} footer={<Footer />} onDismiss={onDismissed}>
            <div className="awsui">
                <FormField
                    label={<span>{t('startCycleModal.content')}</span>}
                    errorText={ongoingCyclesSelected && t('startCycleModal.errorText')}
                >
                    <StartCycleDropdown
                        onChange={onSelectionChanged}
                        cycleOptions={cycleOptions}
                        ongoingOptions={ongoingCycles}
                    />
                </FormField>
            </div>
        </Modal>
    );
};
