import React, { useMemo } from 'react';
import { TableRow } from 'modules/core/model';
import { ExpandableCell } from 'modules/core/components/Table';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { calculatePurchaseConfirmStatus } from 'modules/purchase/helpers';

import styles from './ConfirmStatusCell.module.scss';
import { ConfirmStatus } from 'modules/purchase/models';
import { useTranslation } from 'react-i18next';

interface PurchaseStatusCellProps {
    row: TableRow<PurchaseIndex>;
}

const getClassName = (status: string) => status.toLowerCase().replace(/\s/g, '') || '';

export const ConfirmStatusCell = ({ row }: PurchaseStatusCellProps) => {
    const { status, statusKeyword, receipts, purchaseLines } = row.payload;
    const { t } = useTranslation('purchase');

    const confirmStatus = useMemo(() => calculatePurchaseConfirmStatus(status, receipts ?? new Map()), [
        status,
        receipts
    ]);

    const statusTranslations = useMemo(
        () => ({
            [ConfirmStatus.PURCHASE_CLOSED]: statusKeyword ?? '',
            [ConfirmStatus.CONFIRMED]: t('confirmation.isConfirmed'),
            [ConfirmStatus.NOT_CONFIRMED]: t('confirmation.unconfirmed'),
            [ConfirmStatus.NOT_APPLICABLE]: t('line.noSpendActivity')
        }),
        [t, statusKeyword]
    );

    const receiptConfirmationStatus = statusTranslations[confirmStatus] ?? '-';
    const className = getClassName(receiptConfirmationStatus);

    return (
        <ExpandableCell
            row={row}
            cell={<div className={styles[className]}>{receiptConfirmationStatus}</div>}
            content={purchaseLines?.map(l => (
                <div key={l.lineNumber}></div>
            ))}
        />
    );
};
