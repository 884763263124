/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { CountryAndOrgMappings } from 'modules/mappings/components/CountryAndOrgMappings/CountryAndOrgMappings';
import { ChannelMeasurement } from 'modules/mappings/views/ChannelMeasurement';
import { BigOChannelMappings } from 'modules/mappings/components/BigOChannelMappings/BigOChannelMappings';

export function MappingRoutes(_props: RouteComponentProps) {
    const Routes = {
        CountryAndOrgMapping: '/countryAndOrgMapping',
        ChannelMeasurement: '/channelMeasurements',
        BigOChannelMapping: '/bigOChannelMappings'
    };

    return (
        <Router>
            <CountryAndOrgMappings path={Routes.CountryAndOrgMapping} />
            <ChannelMeasurement path={Routes.ChannelMeasurement} />
            <BigOChannelMappings path={Routes.BigOChannelMapping} />
        </Router>
    );
}
