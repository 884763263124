import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isRequisition, hasBeenApprovedByMsp, updateChannels } from 'modules/app/store';

import { Receipt } from 'modules/purchase/models';
import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { BigNumber } from 'bignumber.js';

export function useChannelAllocations(receipts: Receipt[], defaultLineChannelType?: string) {
    const dispatch = useDispatch();

    const isPurchaseRequest = useSelector(isRequisition);
    const isCreationPage = isPurchaseRequest && useSelector(hasBeenApprovedByMsp) === false;
    const defaultChannelType = defaultLineChannelType || '';

    const allocations = receipts.reduce(
        (prev, curr) => [...prev, ...(curr.channelAllocations || [])],
        [] as ChannelSpend[]
    );
    const uniqueChannels = Array.from(new Set(allocations.map(({ type }) => type)));
    if (uniqueChannels.length === 0) {
        uniqueChannels.push('');
    }

    const [modified, setModified] = useState<boolean>(false);
    const [currentReceipts, onReceiptsUpdate] = useState<Receipt[]>(receipts);

    const showDefaultAllocations = needDefaultAllocations(receipts);
    const [selectedChannels, onSelectedChannelsUpdated] = useState<string[]>(
        showDefaultAllocations ? [defaultChannelType] : uniqueChannels
    );
    const [channelAllocations, setChannelAllocations] = useState<ChannelSpend[][]>(
        showDefaultAllocations
            ? receipts.map(receipt => Array.from(generateDefaultAllocations(receipt.receiptAmount)))
            : receipts.map(receipt => Array.from(receipt.channelAllocations || []))
    );
    function needDefaultAllocations(receipts: Receipt[]) {
        let needDefault = true;

        if (receipts !== undefined) {
            for (let i = 0; i < receipts.length; i++) {
                needDefault = needDefault && receipts[i].channelAllocations?.length === 0;
            }
        }

        return isCreationPage && needDefault && !modified;
    }

    function generateDefaultAllocations(amount: number) {
        const deafultChannelSpend = {
            type: defaultChannelType,
            amount: amount
        } as ChannelSpend;

        return [deafultChannelSpend] as ChannelSpend[];
    }

    function onChannelAllocationsUpdated(allocations: ChannelSpend[][]) {
        setChannelAllocations(allocations);

        setModified(true);

        const lineNumber = receipts.length > 0 ? receipts[0].lineNumber : 1;

        dispatch(updateChannels({ lineNumber, channelAllocations }));
    }

    function onChannelAllocationChange(receiptIndex: number, channelIndex: number, amount: number) {
        if (!channelAllocations[receiptIndex][channelIndex]) {
            return;
        }

        channelAllocations[receiptIndex][channelIndex].amount = amount;
        channelAllocations[receiptIndex] = updateReceiptChannelAllocations(
            receiptIndex,
            channelAllocations[receiptIndex]
        );

        onChannelAllocationsUpdated([...channelAllocations]);
    }

    function updateReceiptChannelAllocations(receiptIndex: number, receiptChannelAllocation: ChannelSpend[]) {
        let receiptAllocatedTotal = 0;

        for (let i = 1; i < receiptChannelAllocation.length; i++) {
            receiptAllocatedTotal = new BigNumber(receiptAllocatedTotal)
                .plus(receiptChannelAllocation[i].amount)
                .toNumber();
        }

        if (receiptChannelAllocation.length >= 1) {
            const remainingChannelAmount = new BigNumber(receipts[receiptIndex].receiptAmount)
                .minus(receiptAllocatedTotal)
                .toNumber();

            receiptChannelAllocation[0].amount = isNaN(remainingChannelAmount) ? 0 : remainingChannelAmount;
        }

        return receiptChannelAllocation;
    }

    function onChannelRemove(removedChannel: string) {
        const updatedChannels = selectedChannels.filter(selectedChannel => selectedChannel !== removedChannel);

        for (let i = 0; i < channelAllocations.length; i++) {
            const filteredAllocations = channelAllocations[i].filter(allocations => allocations.type != removedChannel);
            channelAllocations[i] = updateReceiptChannelAllocations(i, filteredAllocations);
        }

        onSelectedChannelsUpdated(updatedChannels);
        onChannelAllocationsUpdated(channelAllocations);
    }

    function onChannelAdd() {
        channelAllocations.forEach((receiptChannelAllocation, receiptIndex) => {
            const initialAllocatedAmount =
                (selectedChannels.length === 1 && selectedChannels.includes('')) || selectedChannels.length === 0
                    ? receipts[receiptIndex].receiptAmount
                    : 0;

            receiptChannelAllocation.push({ type: '', amount: initialAllocatedAmount });
        });

        onChannelAllocationsUpdated(channelAllocations);
        onSelectedChannelsUpdated([...selectedChannels, '']);
    }

    function onChannelUpdate(selectedChannel: string, channelIndex: number) {
        channelAllocations.forEach(receiptChannelAllocation => {
            if (receiptChannelAllocation[channelIndex]) {
                receiptChannelAllocation[channelIndex].type = selectedChannel;
            }
        });

        onChannelAllocationsUpdated(channelAllocations);
        onSelectedChannelsUpdated(
            selectedChannels.map((channel, index) => (index === channelIndex ? selectedChannel : channel))
        );
    }

    function onModalChannelAdd(receiptIndex: number) {
        if (!channelAllocations[receiptIndex]) {
            return;
        }

        const newChannelSpend = { type: '', amount: 0 };

        channelAllocations[receiptIndex] = [...channelAllocations[receiptIndex], newChannelSpend];

        channelAllocations[receiptIndex] = updateReceiptChannelAllocations(
            receiptIndex,
            channelAllocations[receiptIndex]
        );
        onChannelAllocationsUpdated([...channelAllocations]);
    }

    function onModalChannelUpdate(selectedChannel: string, channelIndex: number, receiptIndex: number) {
        const updatedAllocations = channelAllocations.map((receiptChannelAllocation, index) => {
            const channel = channelAllocations[receiptIndex][channelIndex];

            if (index === receiptIndex && channel) {
                channel.type = selectedChannel;
            }
            return receiptChannelAllocation;
        });

        onChannelAllocationsUpdated(updatedAllocations);
    }

    function onModalChannelRemove(removedChannel: string, receiptIndex: number) {
        const removedAmount = channelAllocations[receiptIndex].find(a => a.type == removedChannel)?.amount || 0;
        channelAllocations[receiptIndex] = channelAllocations[receiptIndex].filter(a => a.type != removedChannel);

        const updatedChannelAllocations = [...channelAllocations];
        if (updatedChannelAllocations[receiptIndex].length > 0) {
            updatedChannelAllocations[receiptIndex][0].amount += removedAmount;
        }

        onChannelAllocationsUpdated(updatedChannelAllocations);
    }

    function onModalChannelAllocationChange(receiptIndex: number, channelIndex: number, amount: number) {
        const updatedAllocations = channelAllocations.map((allocation, index) => {
            if (index === receiptIndex && allocation[channelIndex]) {
                allocation[channelIndex].amount = amount;

                allocation = updateReceiptChannelAllocations(receiptIndex, allocation);
            }
            return allocation;
        });

        onChannelAllocationsUpdated(updatedAllocations);
    }

    function channelAllocatedAmountSum(allocations: ChannelSpend[]) {
        return allocations.reduce((prevSum, { amount }) => new BigNumber(prevSum).plus(amount).toNumber(), 0);
    }

    function hasValidChannelAllocations() {
        const validAllocations = channelAllocations.map(allocations =>
            allocations.every(alloc => alloc.type != '' && alloc.amount >= 0 && !isNaN(alloc.amount))
        );

        const receiptSumValidation = channelAllocations.every(
            (allocations, index) => currentReceipts[index].receiptAmount == channelAllocatedAmountSum(allocations)
        );

        return validAllocations.every(val => val) && selectedChannels.length > 0 && receiptSumValidation;
    }

    function getIndexFromExitingReceipts(receiptNumber: string) {
        return currentReceipts.findIndex(receipt => receipt.receiptNumber === receiptNumber);
    }

    function addOrRemoveAllocations(updatedReceipts: Receipt[]) {
        const updatedAllocations: ChannelSpend[][] = [];

        updatedReceipts.forEach((updatedReceipt, receiptIndex) => {
            if (!channelAllocations[receiptIndex]) {
                channelAllocations[receiptIndex] = [];
            }

            const existingReceiptIndex = getIndexFromExitingReceipts(updatedReceipt.receiptNumber);
            const updatedAllocation =
                existingReceiptIndex === -1 || channelAllocations[receiptIndex].length === 0
                    ? selectedChannels.map(channel => ({ type: channel, amount: 0 }))
                    : channelAllocations[existingReceiptIndex];

            updatedAllocations.push(updatedAllocation);
        });
        return updatedAllocations;
    }

    function onAutoAdjustChannelAllocations(updatedReceipts: Receipt[], isCreateMode?: boolean) {
        let updatedAllocations = channelAllocations;

        if (isCreateMode) {
            updatedAllocations = addOrRemoveAllocations(updatedReceipts);
        }

        let removeAllocationsNeeded = false;
        for (let i = 0; i < updatedReceipts.length; i++) {
            const allocation = updatedAllocations[i];

            if (allocation && allocation.length === 1) {
                allocation[0].amount = updatedReceipts[i].receiptAmount;
            } else if (allocation && allocation.length > 1) {
                removeAllocationsNeeded = true;
            }
        }

        if (removeAllocationsNeeded && isCreateMode) {
            for (let i = 0; i < updatedReceipts.length; i++) {
                updatedAllocations[i] = [];
                updatedReceipts[i].channelAllocations = [];
            }
            onSelectedChannelsUpdated([]);
        }

        onReceiptsUpdate(updatedReceipts);
        onChannelAllocationsUpdated(updatedAllocations);
    }

    return {
        selectedChannels,
        channelAllocations,
        onChannelRemove,
        onChannelAdd,
        onChannelUpdate,
        onChannelAllocationChange,
        onChannelAllocationsUpdated,
        onModalChannelAdd,
        onModalChannelUpdate,
        onModalChannelRemove,
        onModalChannelAllocationChange,
        onAutoAdjustChannelAllocations,
        hasValidChannelAllocations
    };
}
