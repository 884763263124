import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ExpandableSection, Table } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { InvoiceLine } from 'modules/invoices/models';
import { ApplicationState, getInvoiceLines } from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';

import styles from './InvoiceLineTable.module.scss';

interface InvoiceLineTableProps {
    loading?: boolean;
    lineNumber: number;
    isQuantityBased?: boolean;
}

export const InvoiceLineTable = ({ loading, lineNumber, isQuantityBased }: InvoiceLineTableProps) => {
    const { t } = useTranslation('purchase');
    const [expanded, expandSection] = useState(true);

    const invoiceLines = useSelector((state: ApplicationState) => getInvoiceLines(state, lineNumber));

    const {
        items,
        actions: { setSorting }
    } = useCollection(invoiceLines, { sorting: {} });

    const displayNegativeAmount = (amount: number) => {
        const abs = I18nFormatter.amount(t, Math.abs(amount));
        return `(${abs})`;
    };

    let columns = [
        {
            id: 'type',
            header: t('invoices.type'),
            cell: (item: InvoiceLine) => `${item.amount < 0 ? t('invoices.credit') : t('invoices.invoice')}`
        },
        {
            id: 'invoiceId',
            header: t('invoices.invoiceId'),
            cell: (item: InvoiceLine) => item.ofaId,
            sortingComparator: (item1: InvoiceLine, item2: InvoiceLine) => Number(item1.ofaId) - Number(item2.ofaId)
        },
        {
            id: 'invoiceNumber',
            header: t('invoices.invoiceNumber'),
            cell: (item: InvoiceLine) => item.invoiceNumber
        },
        {
            id: 'lineNumber',
            header: t('invoices.lineNumber'),
            cell: (item: InvoiceLine) => item.lineNumber
        },
        {
            id: 'total',
            header: t('invoices.total'),
            cell: (item: InvoiceLine) =>
                `${item.amount < 0 ? displayNegativeAmount(item.amount) : I18nFormatter.amount(t, item.amount)}`
        },
        {
            id: 'unmatched',
            header: t('invoices.unmatched'),
            cell: (item: InvoiceLine) =>
                `${
                    item.unmatchedAmount < 0
                        ? displayNegativeAmount(item.unmatchedAmount)
                        : I18nFormatter.amount(t, item.unmatchedAmount)
                }`
        },
        {
            id: 'status',
            header: t('invoices.status'),
            cell: (item: InvoiceLine) => item.status
        },
        {
            id: 'creationDate',
            header: t('invoices.creationDate'),
            cell: (item: InvoiceLine) => (item.createdAtFns ? I18nFormatter.date(t, item.createdAtFns) : ''),
            sortingComparator: (item1: InvoiceLine, item2: InvoiceLine) => item1.createdAt - item2.createdAt
        },
        {
            id: 'servicePeriod',
            header: t('invoices.servicePeriod'),
            cell: (item: InvoiceLine) =>
                `${item.servicePeriodStartDate ? I18nFormatter.date(t, item.servicePeriodStartDate) : ''} - 
                ${item.servicePeriodEndDate ? I18nFormatter.date(t, item.servicePeriodEndDate) : ''}`
        }
    ];

    if (isQuantityBased) columns = columns.filter(column => column.id !== 'unmatched');
    const EmptyTable = () => (
        <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>{t('line.invoiceLines.noInvoiceLinesLoaded')}</b>
            </div>
        </div>
    );

    return (
        <ExpandableSection
            variant="container"
            disableContentPaddings
            expanded={expanded}
            onChange={e => expandSection(e.detail.expanded)}
            headerText={t('invoices.invoiceList')}
        >
            <div className={styles['invoice-table']}>
                <Table
                    columnDefinitions={columns}
                    items={items || []}
                    empty={<EmptyTable />}
                    loading={loading || false}
                    resizableColumns={true}
                    sortingDescending={true}
                    onSortingChange={e => setSorting(e.detail)}
                />
            </div>
        </ExpandableSection>
    );
};
