import { BigNumber } from 'bignumber.js';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Button } from '@amzn/awsui-components-react';
import { Modal } from '@amzn/awsui-components-react-v2';

import { Receipt } from 'modules/purchase/models/Receipt';
import { useChannelAllocations } from 'modules/channel/hooks';
import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { EditChannelSpendModalRows } from '../EditChannelSpendModalRows';

import styles from './EditChannelSpendModal.module.scss';
interface EditChannelSpendModalProps {
    receipts: Receipt[];
    receiptIndex: number;
    channelAllocationsIp: ChannelSpend[][];
    onModalSave?: (receiptChannelSpend: ChannelSpend[], receiptIndex: number) => void;
    onModalCancel?: () => void;
}

export const EditChannelSpendModal = (props: EditChannelSpendModalProps) => {
    const { receipts, receiptIndex, channelAllocationsIp, onModalSave, onModalCancel } = props;
    const receipt = receipts[receiptIndex];
    const { t } = useTranslation('channelSpend');
    const [channelAllocationSum, updateChannelAllocationSum] = useState<number>(0);
    const [allowSaveButton, setAllowSaveButton] = useState<boolean>(true);

    const {
        channelAllocations,
        onChannelAllocationsUpdated,
        onModalChannelAdd,
        onModalChannelUpdate,
        onModalChannelRemove,
        onModalChannelAllocationChange,
        onAutoAdjustChannelAllocations
    } = useChannelAllocations(receipts);

    useEffect(() => {
        onAutoAdjustChannelAllocations(receipts);
    }, [receipts]);

    useEffect(() => {
        onChannelAllocationsUpdated(cloneDeep(channelAllocationsIp));
    }, []);

    useEffect(() => {
        if (!channelAllocations[receiptIndex]) {
            return;
        }

        let updatedAllocationsSum = 0;
        channelAllocations[receiptIndex].forEach(receiptChannelAllocations => {
            updatedAllocationsSum = new BigNumber(updatedAllocationsSum)
                .plus(receiptChannelAllocations.amount)
                .toNumber();
        });
        updateChannelAllocationSum(isNaN(updatedAllocationsSum) ? 0 : updatedAllocationsSum);
    }, [channelAllocations]);

    const AllocationAndReceiptAmountDifference = () => (
        <>
            {new BigNumber(channelAllocationSum).isGreaterThan(receipt.receiptAmount)
                ? new BigNumber(channelAllocationSum).minus(receipt.receiptAmount).toNumber() + ' ' + t('overflow')
                : new BigNumber(receipt.receiptAmount).minus(channelAllocationSum).toNumber() + ' ' + t('underflow')}
        </>
    );

    const SpendDetailsRow = () => (
        <>
            <div className={`awsui-util-font-size-2  ${styles['channel-allocation-row']}`}>
                <strong>
                    <div className={`${styles['channel-allocation-cell']}`}>{t('spend')}</div>
                </strong>
                <div className={`${styles['channel-allocation-cell']}`}>{receipt.receiptAmount}</div>
            </div>
        </>
    );

    const ChannelDetailsRow = () => (
        <>
            <div className={`awsui-util-font-size-2 ${styles['channel-allocation-row']}`}>
                <strong>
                    <div className={`${styles['channel-allocation-cell']}`}>{t('channel')}</div>
                </strong>
                <div className={`${styles['channel-allocation-cell']}`}>{channelAllocationSum}</div>
                <small>
                    {channelAllocationSum != receipt.receiptAmount && !isNaN(channelAllocationSum) && (
                        <div className={`awsui-util-help-info-link ${styles['invalid-allocation']}`}>
                            <AllocationAndReceiptAmountDifference />
                        </div>
                    )}
                </small>
            </div>
        </>
    );

    const saveUpdatedChannelAllocations = () => {
        if (onModalSave) {
            onModalSave(channelAllocations[receiptIndex], receiptIndex);
        }
    };

    const cancelChannelAllocationUpdates = () => {
        if (onModalCancel) {
            channelAllocations[receiptIndex] = receipts[receiptIndex].channelAllocations;
            onChannelAllocationsUpdated(channelAllocations);
            onModalCancel();
        }
    };

    const updateSaveButton = (formState: boolean) => {
        setAllowSaveButton(formState);
    };

    const ModalFooter = () => (
        <span className="awsui-util-f-r">
            <Button variant="link" onClick={cancelChannelAllocationUpdates}>
                {t('cancel')}
            </Button>
            {allowSaveButton ? (
                <Button variant="primary" onClick={saveUpdatedChannelAllocations}>
                    {t('saveChanges')}
                </Button>
            ) : (
                <Button disabled variant="primary">
                    {t('saveChanges')}
                </Button>
            )}
        </span>
    );

    const onAdd = () => onModalChannelAdd(receiptIndex);
    const onRemove = (channel: string) => onModalChannelRemove(channel, receiptIndex);
    const onUpdate = (channel: string, index: number) => onModalChannelUpdate(channel, index, receiptIndex);
    const onChange = (index: number, amount: number) => onModalChannelAllocationChange(receiptIndex, index, amount);

    return (
        <>
            {receipt && (
                <Modal
                    onDismiss={cancelChannelAllocationUpdates}
                    key={`channel-allocation-modal-${receipt.receiptNumber}`}
                    header={t('editChannels')}
                    visible={true}
                    footer={<ModalFooter />}
                    className={`${styles['channel-allocation-modal']}`}
                    expandToFit={true}
                >
                    <SpendDetailsRow />
                    <ChannelDetailsRow />
                    <EditChannelSpendModalRows
                        channelAllocations={channelAllocations[receiptIndex]}
                        onModalChannelRemove={onRemove}
                        onModalChannelUpdate={onUpdate}
                        onModalChannelAllocationChange={onChange}
                        onModalChannelAdd={onAdd}
                        updateSaveButton={updateSaveButton}
                    />
                </Modal>
            )}
        </>
    );
};
