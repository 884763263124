import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { logger } from 'modules/core';
import { showErrorPage } from 'modules/app/store';
import { COACombination } from 'modules/onboarding/model';
import { coaService, GetCOAsRequest, GetCOAsResponse } from 'modules/onboarding/services';
import { TableRow } from 'modules/core/model';
import { mapCOACombinationToTableRow } from 'modules/core/mappers';
import { intersection } from 'lodash';

export const useCOACombinations = (isOnboarded = true) => {
    const [allTableRowCOACombinations, setAllTableRowCOACombinations] = useState<TableRow<COACombination>[]>([]);
    const [numberOfTableRowCOACombinations, setNumberOfTableRowCOACombinations] = useState(0);
    const [currentTableRowCOACombinations, setCurrentTableRowCOACombinations] = useState<TableRow<COACombination>[]>(
        []
    );
    const [currentTablePageIndex, setCurrentTablePageIndex] = useState(1);
    const [selectedTableRowCOACombinations, setSelectedTableRowCOACombinations] = useState<TableRow<COACombination>[]>(
        []
    );
    const [numberOfTablePages, setNumberOfTablePages] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [teamName, setTeamName] = useState('');

    const lastEvaluatedKey = useRef<string | undefined>();

    const queryPageSize = 60;
    const numberOfItemsPerTablePage = 15;

    const dispatch = useDispatch();

    const fetchCOACombinations = async (
        callback: (newlyFetchedCOACombinations: COACombination[]) => void
    ): Promise<void> => {
        try {
            callback(await executeGetCOAsRequest());
        } catch (error) {
            logger.error(error);
        }
    };

    const updateValuesAfterDataIsFetched = (newlyFetchedCOACombinations: COACombination[]): void => {
        const newlyFetchedTableRowCOACombinations: TableRow<COACombination>[] = newlyFetchedCOACombinations.map(
            mapCOACombinationToTableRow
        );
        newlyFetchedTableRowCOACombinations.forEach(
            (newlyFetchedTableRowCOACombination, index) =>
                (newlyFetchedTableRowCOACombination.number = numberOfTableRowCOACombinations + index + 1)
        );

        setAllTableRowCOACombinations(old => old.concat(newlyFetchedTableRowCOACombinations));

        sliceCOACombinations();

        setHasNextPage(lastEvaluatedKey.current !== undefined);
        setLoading(false);
    };

    const executeGetCOAsRequest = async () => {
        const getCOAsRequest: GetCOAsRequest = makeGetCOAsRequest();
        let result: GetCOAsResponse;

        const newlyFetchedCOACombinations: COACombination[] = [];

        do {
            result = await coaService.getCOAsAsync(getCOAsRequest);

            if (result.coaCombinations === undefined) {
                logger.error('result.coaCombinations is undefinded');
                dispatch(showErrorPage({ errorCode: result.status }));
                break;
            }

            newlyFetchedCOACombinations.push(...result.coaCombinations);

            lastEvaluatedKey.current = result.lastEvaluatedKey;
            getCOAsRequest.lastEvaluatedKey = lastEvaluatedKey.current;
        } while (lastEvaluatedKey.current && newlyFetchedCOACombinations.length < numberOfItemsPerTablePage);

        return newlyFetchedCOACombinations;
    };

    const makeGetCOAsRequest = (): GetCOAsRequest => {
        const getCOAsRequest: GetCOAsRequest = {
            pageSize: queryPageSize,
            isOnboarded: isOnboarded,
            lastEvaluatedKey: lastEvaluatedKey.current
        };

        if (teamName) {
            const trimmedTeamName: string = teamName.trim();

            if (trimmedTeamName != '') {
                getCOAsRequest.teamName = trimmedTeamName;
            }
        }

        return getCOAsRequest;
    };

    const sliceCOACombinations = () => {
        const startSliceIndex = (currentTablePageIndex - 1) * numberOfItemsPerTablePage;
        const endSliceIndex = startSliceIndex + numberOfItemsPerTablePage;
        setCurrentTableRowCOACombinations(allTableRowCOACombinations.slice(startSliceIndex, endSliceIndex));
    };

    const resetCOACombinationsList = () => {
        setAllTableRowCOACombinations([]);
        setCurrentTableRowCOACombinations([]);
        setCurrentTablePageIndex(1);
        setSelectedTableRowCOACombinations([]);
        setNumberOfTablePages(0);
        setHasNextPage(false);
    };

    useEffect(() => {
        setSelectedTableRowCOACombinations(oldSelected => intersection(allTableRowCOACombinations, oldSelected));
        setNumberOfTableRowCOACombinations(allTableRowCOACombinations.length);
        setNumberOfTablePages(Math.ceil(allTableRowCOACombinations!.length / numberOfItemsPerTablePage));
    }, [allTableRowCOACombinations]);

    useEffect(() => {
        if (currentTablePageIndex > numberOfTablePages) {
            setLoading(true);
            fetchCOACombinations(updateValuesAfterDataIsFetched);
        } else {
            sliceCOACombinations();
        }
    }, [currentTablePageIndex, numberOfTablePages]);

    return {
        numberOfTableRowCOACombinations,
        currentTableRowCOACombinations,
        currentTablePageIndex,
        setCurrentTablePageIndex,
        selectedTableRowCOACombinations,
        setSelectedTableRowCOACombinations,
        numberOfTablePages,
        setNumberOfTablePages,
        loading,
        setLoading,
        teamName,
        setTeamName,
        hasNextPage,
        fetchCOACombinations,
        resetCOACombinationsList
    };
};
