import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { updateFreeTextFields, updateSelectionFields, showErrorPage } from 'modules/app/store';
import { logger } from 'modules/core';
import { customFieldService } from '../services';
import { CustomField, CustomFieldType } from '../models';

export function useCustomField(purchaseId: string) {
    const [loading, updateLoading] = useState(false);
    const [success, updateSuccess] = useState(false);

    const [defaultCustomFields, setDefaultCustomFields] = useState<CustomField[]>();
    const isMountedRef = useRef(true);

    const dispatch = useDispatch();

    async function updateCustomFieldsForPurchaseAsync(purchaseId: string, customFields: CustomField[]): Promise<void> {
        try {
            updateLoading(true);
            updateSuccess(false);

            await customFieldService.updateCustomFieldForPurchase(purchaseId, customFields);

            const freeTextFields = customFields.filter(f => {
                return CustomFieldType.TEXT === f.fieldType;
            });
            if (freeTextFields.length > 0) {
                dispatch(updateFreeTextFields({ customFields: freeTextFields }));
            }

            const selectionFields = customFields.filter(f => {
                return CustomFieldType.SELECTION === f.fieldType;
            });
            if (selectionFields.length > 0) {
                dispatch(updateSelectionFields({ customFields: selectionFields }));
            }

            updateSuccess(true);
        } catch (error) {
            logger.error(error);
            updateSuccess(false);
        } finally {
            updateLoading(false);
        }
    }

    const getDefaultCustomFieldsAsync = useCallback(async () => {
        const { customFields, status } = await customFieldService.getCustomFieldsByPurchaseId(purchaseId);
        if (!customFields && status) {
            dispatch(showErrorPage({ errorCode: status }));
        }

        if (!isMountedRef.current) {
            return;
        }

        if (customFields) {
            setDefaultCustomFields(customFields);
            return;
        }

        return setDefaultCustomFields([]);
    }, [purchaseId, setDefaultCustomFields]);

    useEffect(() => {
        if (!defaultCustomFields) {
            getDefaultCustomFieldsAsync();
        }
    }, [getDefaultCustomFieldsAsync]);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return { loading, success, defaultCustomFields, updateCustomFieldsForPurchaseAsync };
}
