import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PurchaseLine, Receipt } from 'modules/purchase/models';
import { PurchaseDetailTableRow } from '../PurchaseDetailTableRow';
import { isRequisition, isQuantityBasedLine } from 'modules/app/store';

import styles from './PurchaseDetailTable.module.scss';
import { Link, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import BigNumber from 'bignumber.js';
import { I18nFormatter } from 'modules/core/class';

interface PurchaseDetailTableProps {
    line: PurchaseLine;
    receipts: Receipt[];
    uncommittedBalance: number;
    onSpendInfoClicked?: () => void;
    onSpendConfirmationInfoClicked?: () => void;
    onReceiptInfoClicked?: () => void;
    onReceiptStatusInfoClicked?: () => void;
}

export const PurchaseDetailTable = (props: PurchaseDetailTableProps) => {
    const { line, receipts, onSpendInfoClicked, onReceiptInfoClicked, onReceiptStatusInfoClicked } = props;

    const { t } = useTranslation('purchase');

    const isPurchaseRequest = useSelector(isRequisition);

    const isQuantityBased = isQuantityBasedLine(line);
    const sortByTime = (a: Partial<Receipt>, b: Partial<Receipt>) =>
        (a as Receipt).receiptTime - (b as Receipt).receiptTime;

    const TableHeaderTitle = () => (
        <thead>
            <tr className={styles['bottom-border']}>
                <th>
                    <SpaceBetween direction="horizontal" size="xs">
                        <span>{t('line.estimatedReceiptDate')}</span>
                        <Link onFollow={onSpendInfoClicked} data-cy="spendInfoLink">
                            {t('info')}
                        </Link>
                    </SpaceBetween>
                </th>
                {isQuantityBased && <th>{t('line.quantity')}</th>}
                <th>
                    <SpaceBetween direction="horizontal" size="xs">
                        <span>{t('line.receipt')}</span>
                        {!isPurchaseRequest && (
                            <Link onFollow={onReceiptInfoClicked} data-cy="spendInfoLink">
                                {t('info')}
                            </Link>
                        )}
                    </SpaceBetween>
                </th>

                {!isPurchaseRequest && (
                    <th>
                        <SpaceBetween direction="horizontal" size="xs">
                            <span>{t('line.receiptStatus')}</span>
                            {!isPurchaseRequest && (
                                <Link onFollow={onReceiptStatusInfoClicked} data-cy="receiptStatusInfoLink">
                                    {t('info')}
                                </Link>
                            )}
                        </SpaceBetween>
                    </th>
                )}

                {!isQuantityBased && <th className={styles['title-left-padding']}>{t('channels.title')}</th>}
                {!isPurchaseRequest && !isQuantityBased && (
                    <th className={styles['title-left-padding']}>{t('invoices.invoice')}</th>
                )}
            </tr>
        </thead>
    );

    const UncommittedBalance = () => (
        <>
            <tr className={styles['bottom-border']}>
                <td>
                    {t('line.uncommittedBalance')}
                    <span> </span>
                    <Popover
                        dismissAriaLabel="Close"
                        header={t('helpPanel.UnusedBalanceUpdateHeader')}
                        size="small"
                        content={<React.Fragment>{t('helpPanel.UnusedBalanceUpdateInfo')}</React.Fragment>}
                    >
                        <StatusIndicator type="info"></StatusIndicator>
                    </Popover>
                </td>
                <td>
                    {I18nFormatter.amount(
                        t,
                        new BigNumber(line.amount ?? 0).minus(new BigNumber(line.estimatedAmount)).toNumber()
                    )}
                </td>
                <td></td>
                <td></td>
            </tr>
        </>
    );

    const UncommittedQuantity = () => (
        <>
            <tr className={styles['bottom-border']}>
                <td>
                    {t('line.uncommittedQuantity')}
                    <span> </span>
                    <Popover
                        dismissAriaLabel="Close"
                        header={t('helpPanel.UnusedQuantityUpdateHeader')}
                        size="small"
                        content={<React.Fragment>{t('helpPanel.UnusedQuantityUpdateInfo')}</React.Fragment>}
                    >
                        <StatusIndicator type="info"></StatusIndicator>
                    </Popover>
                </td>
                <td>
                    {I18nFormatter.quantity(
                        t,
                        new BigNumber(line.quantity ?? 0).minus(new BigNumber(line.estimatedQuantity)).toNumber()
                    )}
                </td>
                <td></td>
                <td></td>
            </tr>
        </>
    );

    return (
        <div className={`awsui-grid ${styles['invoice-table']}`}>
            <table>
                <TableHeaderTitle />
                <tbody>
                    {receipts?.sort(sortByTime).map((receipt, index) => (
                        <PurchaseDetailTableRow receipt={receipt} key={index} index={index} line={line} />
                    ))}
                    {!isPurchaseRequest && (isQuantityBased ? <UncommittedQuantity /> : <UncommittedBalance />)}
                </tbody>
            </table>
        </div>
    );
};
