import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';

import style from '../OrgMappings/OrgMappings.module.scss';
import { OrgMapping } from 'modules/mappings/models';

interface OrgMappingsUpdatesRowProps {
    orgMapping: OrgMapping;
}

export const OrgMappingsUpdatesRow = (props: OrgMappingsUpdatesRowProps) => {
    const { orgMapping } = props;

    return (
        <div className={style['org-updates-div']}>
            <div className={style['grey-text']}>{orgMapping.orgName}</div>
            <SpaceBetween direction="horizontal" size="xs">
                {orgMapping.costCenters.map(costCenter => (
                    <div key={costCenter} className={style['div-right-padding']}>
                        {costCenter}
                    </div>
                ))}
            </SpaceBetween>
        </div>
    );
};
