import React from 'react';

import { ExpandableCell } from 'modules/core/components/Table';
import styles from './PurchaseStatusCell.module.scss';

import { TableRow } from 'modules/core/model';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';

interface PurchaseStatusCellProps {
    row: TableRow<PurchaseIndex>;
}

export const PurchaseStatusCell = ({ row }: PurchaseStatusCellProps) => {
    const { statusKeyword } = row.payload;

    const className = statusKeyword?.toLowerCase().replace(/\s/g, '') || '';

    return (
        <ExpandableCell
            row={row}
            cell={<div className={styles[className]}>{statusKeyword || '-'}</div>}
            content={row.payload.purchaseLines?.map(l => (
                <div key={l.lineNumber}></div>
            ))}
        />
    );
};
