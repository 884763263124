import BigNumber from 'bignumber.js';

import { CurrencyCode } from 'modules/core/model/CurrencyCode';
import { PurchaseLineStatus, Account, Receipt, UpdateReceiptsReason, ReceiptStatus } from '.';
import { DateGenerator } from 'modules/core/class';
import { CoupaPurchaseLineType } from '@amzn/merp-core/models';

export class PurchaseLine {
    public purchaseId: string;
    public lineNumber: number;
    public version?: number;
    public amount: number;
    public uncommittedBalance: number;
    public creationTime?: number;
    public lastModifiedTime?: number;
    public commodity?: string;
    public currencyCode: CurrencyCode;
    public description: string;
    public needByDate?: number;
    public paymentTermCode?: string;
    public supplierId?: string;
    public lineId?: number;
    public lineType?: CoupaPurchaseLineType;
    public company: string;
    public location: string;
    public productLine: string;
    public channel: string;
    public project: string;
    public customEighthSegment: string;
    public receipts: Receipt[];
    public allocatedAmount: number;
    public defaultChannelType: string;
    public pricePerUnit?: number;
    public quantity?: number;
    public uom?: string;

    public account: Account;

    public purchaseLineStatus: PurchaseLineStatus;

    public reason: UpdateReceiptsReason;

    constructor() {
        this.purchaseId = '';
        this.amount = 0;
        this.uncommittedBalance = 0;
        this.currencyCode = CurrencyCode.USD;
        this.receipts = [];
        this.lineNumber = 1;
        this.account = new Account();
        this.company = '';
        this.location = '';
        this.productLine = '';
        this.channel = '';
        this.project = '';
        this.customEighthSegment = '' || '0000';
        this.description = '';
        this.allocatedAmount = 0;
        this.defaultChannelType = '';

        this.purchaseLineStatus = PurchaseLineStatus.UPDATED;

        this.reason = new UpdateReceiptsReason();
    }

    public get id() {
        return `${this.purchaseId}-${this.lineNumber}`;
    }

    public get hasValidDistribution() {
        return this.isQuantityBasedLine ? this.remainingQuantity >= 0 : this.amount >= this.estimatedAmount;
    }

    public get hasInvalidReceipts(): boolean {
        return this.receipts.some(receipt => {
            const value = this.isQuantityBasedLine ? receipt.quantity || 0 : receipt.receiptAmount;

            return isNaN(Number(value)) || new BigNumber(value).isEqualTo(new BigNumber(0));
        });
    }

    public get isQuantityBasedLine() {
        if (!this.lineType) return false;

        return [CoupaPurchaseLineType.ORDER_QUANTITY_LINE, CoupaPurchaseLineType.REQUEST_QUANTITY_LINE].includes(
            this.lineType
        );
    }

    public get estimatedAmount() {
        return this.receipts
            ?.filter(r => new BigNumber(r.receiptAmount).isFinite() && r.receiptStatus != ReceiptStatus.VOIDED)
            .map(r => new BigNumber(r.receiptAmount))
            .reduce((p, receiptAmount) => new BigNumber(p).plus(new BigNumber(receiptAmount)), new BigNumber(0))
            .toNumber();
    }

    public get estimatedQuantity() {
        return this.receipts
            ?.filter(r => new BigNumber(r.quantity ?? 0).isFinite() && r.receiptStatus != ReceiptStatus.VOIDED)
            .map(r => new BigNumber(r.quantity ?? 0))
            .reduce((p, receiptQuantity) => new BigNumber(p).plus(new BigNumber(receiptQuantity)), new BigNumber(0))
            .toNumber();
    }

    public get remainingQuantity() {
        return this.quantity ? new BigNumber(this.quantity).minus(new BigNumber(this.estimatedQuantity)).toNumber() : 0;
    }

    public get remainingAmount() {
        return new BigNumber(this.amount).minus(this.estimatedAmount).toNumber();
    }

    public get hasAllocations() {
        return this.receipts.some(receipt => receipt.hasAllocations);
    }

    public get billedAmount() {
        const totalBilledAmount = this.receipts.reduce((p, { billedAmount }) => p.plus(billedAmount), new BigNumber(0));

        return parseFloat(totalBilledAmount.toFixed(2));
    }

    public get expectedBilledAmount() {
        const coalescedUncommittedBalance = isNaN(this.uncommittedBalance) ? 0 : this.uncommittedBalance;

        const expectedAmount = new BigNumber(this.amount).minus(coalescedUncommittedBalance || 0).toNumber();

        return expectedAmount.toFixed(2);
    }

    public get unBilledAmount() {
        const totalUnBilledAmount = this.receipts.reduce((p, { unBilled }) => p.plus(unBilled), new BigNumber(0));

        return parseFloat(totalUnBilledAmount.toFixed(2));
    }

    public get receiptsHasValidAllocations() {
        return this.receipts.every(receipt => receipt.hasValidBilledAmount && receipt.hasValidAllocationTotal);
    }

    public get receiptsHasValidChannelAllocations() {
        return this.receipts.every(receipt => receipt.hasValidChannels);
    }

    public get requiresConfirmation() {
        return this.receipts.find(r => r.qualifiesForConfirmation) !== undefined;
    }

    public get isConfirmed() {
        if (!this.receipts || this.receipts.length === 0) {
            return false;
        }

        const endOfLastDayOfTheMonth = DateGenerator.getEndOfTheLastDayOfTheCurrentMonth();

        const receiptsInPastAndCurrentMonth = this.receipts.filter(r => r.receiptTime <= endOfLastDayOfTheMonth);

        return (
            receiptsInPastAndCurrentMonth.length > 0 &&
            receiptsInPastAndCurrentMonth.every(r => !r.qualifiesForConfirmation)
        );
    }
}
