import React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { ReceiptVersionRequest, ReceiptVersion } from 'modules/audit/models';
import { useTranslation } from 'react-i18next';
import { ReceiptVersionList } from 'modules/audit/components/ReceiptVersionList/ReceiptVersionList';
import styles from './ViewReceiptVersion.module.scss';

export interface ViewReceiptVersionProps {
    currency: string;
    receiptVersions: ReceiptVersion[];
    originalUncommittedBalance: number;
    modifiedUncommittedBalance: number;
}

export const ViewReceiptVersion = (props: ViewReceiptVersionProps) => {
    const { currency, receiptVersions, originalUncommittedBalance, modifiedUncommittedBalance } = props;
    const { t } = useTranslation('purchase');

    function getOriginalRequests() {
        return receiptVersions.map(
            ({ originalReceiptAmount, originalReceiptTime }) =>
                new ReceiptVersionRequest(originalReceiptAmount, originalReceiptTime)
        );
    }

    function getModifiedRequests() {
        return receiptVersions.map(
            ({ modifiedReceiptAmount, modifiedReceiptTime }) =>
                new ReceiptVersionRequest(modifiedReceiptAmount, modifiedReceiptTime)
        );
    }

    return (
        <>
            <div className={`awsui-util-spacing-v-s ${styles['receipt-version-container']}`}>
                <ColumnLayout borders="vertical" columns={2}>
                    <div data-awsui-column-layout-root="true">
                        <div className="awsui-util-spacing-v-s">
                            <div>
                                <strong>{t('version.original')}</strong>
                            </div>
                            <ReceiptVersionList
                                requests={getOriginalRequests()}
                                currency={currency}
                                uncommittedBalance={originalUncommittedBalance}
                            />
                        </div>
                        <div className={`awsui-util-spacing-v-s ${styles['receipt-version-modified']}`}>
                            <div>
                                <strong>{t('version.modified')}</strong>
                            </div>
                            <ReceiptVersionList
                                requests={getModifiedRequests()}
                                currency={currency}
                                uncommittedBalance={modifiedUncommittedBalance}
                            />
                        </div>
                    </div>
                </ColumnLayout>
                <div className={styles['receipt-version-category']}>
                    <strong>
                        {t('line.updateReason')}:&nbsp;
                        {receiptVersions[0].versionCategory &&
                            t(`line.updateReasons.${receiptVersions[0].versionCategory}`)}
                    </strong>
                    <div>{receiptVersions[0].versionDescription}</div>
                </div>
            </div>
        </>
    );
};
