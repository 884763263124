import BigNumber from 'bignumber.js';
import { BulkReceivingRow } from '../models';
import { maxEditsReached } from './maxEditsReached';
import { getMaxDecimalDigits, getMaxDecimalDigitsRe } from 'modules/core/services/precision.service';

export const validateReceivingQuantity = (row: BulkReceivingRow, value: number, numberOfEdits: number) => {
    if (!row.isQuantityBased) {
        return 'Po line must be a quantity based line';
    }

    if (maxEditsReached(numberOfEdits, row.receivingQuantity)) {
        return 'Maximum edits reached';
    }

    const maxDecimalDigitsRe = getMaxDecimalDigitsRe();
    const bigNumValue = new BigNumber(value);
    if (bigNumValue.toFixed().match(maxDecimalDigitsRe) != null) {
        return 'Invalid decimals in quantity';
    }

    const maxDecimalDigits = getMaxDecimalDigits();
    const quantity = bigNumValue.decimalPlaces(maxDecimalDigits);

    if (quantity.isLessThan(0.0)) {
        return 'Quantity must be greater than or equal to zero';
    }

    if (quantity.isGreaterThan(new BigNumber(row.unusedQuantity).decimalPlaces(maxDecimalDigits))) {
        return 'Quantity must not exceed unused quantity';
    }
};
