import BigNumber from 'bignumber.js';
import { QBPO_BULK_EDIT_TAB, QBPO_BULK_RECEIVING_SPEND_HEADER } from 'modules/bulkEdit/constants';
import { getMaxDecimalDigits } from 'modules/core/services';

type ParsedWorkbook = { [sheetName: string]: string[][] };

export const validateReceivingQuantityPrecision = (workbook: ParsedWorkbook, errors: string[]): void => {
    if (workbook[QBPO_BULK_EDIT_TAB] == null) return;
    const qbpoAllRows = workbook[QBPO_BULK_EDIT_TAB];
    if (qbpoAllRows.length <= 0) return;

    const spendColumnIndex = qbpoAllRows[0].indexOf(QBPO_BULK_RECEIVING_SPEND_HEADER);
    if (spendColumnIndex < 0) return;

    const rowsWithTooMuchPrecision = [];
    for (let i = 1; i < qbpoAllRows.length; i++) {
        const row = qbpoAllRows[i];
        const quantityStr = row[spendColumnIndex]?.trim() ?? '0';
        const quantityParsed = new BigNumber(quantityStr);
        if (quantityParsed.dp() > getMaxDecimalDigits()) {
            rowsWithTooMuchPrecision.push(i);
        }
    }

    if (rowsWithTooMuchPrecision.length === 1) {
        errors.push(
            `The receiving quantity for row ${rowsWithTooMuchPrecision[0]} in QBPO Lines ` +
                `has more than ${getMaxDecimalDigits()} decimal digits. Only ${getMaxDecimalDigits()} are allowed.`
        );
    } else if (rowsWithTooMuchPrecision.length > 0) {
        errors.push(
            `The receiving quantity for rows ${rowsWithTooMuchPrecision.join(', ')} in QBPO Lines ` +
                `has more than ${getMaxDecimalDigits()} decimal digits. Only ${getMaxDecimalDigits()} are allowed.`
        );
    }
};
