import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Alert, Spinner, SpaceBetween, ExpandableSection } from '@amzn/awsui-components-react';
import { ReceivingTransaction } from 'modules/bulkEdit/models';

interface TransactionAlertProps {
    transaction?: ReceivingTransaction;
    onDismiss?: () => void;
}

export const TransactionAlert = ({ transaction, onDismiss }: TransactionAlertProps) => {
    const { t } = useTranslation('bulkReceiving');
    const downloadRef = useRef<HTMLAnchorElement>(null);

    if (!transaction) return <></>;

    const { loading, status, errors, count } = transaction;

    const onDownload = () => {
        const csvRows = errors?.flatMap(({ errors, lineNumber, orderNumber }) => {
            const com = [orderNumber, lineNumber];

            const rows = errors?.map(e => e.toString()) ?? [];

            return rows.map(r => [...com, ...r].join(','));
        });

        if (!csvRows) return;

        const csvString = ['Order Number, Line Number, Error', ...csvRows].join('\n');

        const csvBlob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(csvBlob);

        const downloadLink = downloadRef.current;

        if (!downloadLink) return;

        downloadLink.href = url;
        downloadLink.download = 'BulkReceivingErrors.csv';
        downloadLink.click();
    };

    return (
        <Alert
            header={t(`alert.heading.${status}`, {
                errors: errors?.length,
                total: count
            })}
            dismissible={!loading}
            type={status}
            action={errors?.length && <Button onClick={onDownload}>{t('alert.download')}</Button>}
            onDismiss={onDismiss}
        >
            <SpaceBetween size="xs" direction="horizontal">
                <div>{t(`alert.message.${status}`, { total: count })}</div>
                <div>{loading && <Spinner size="normal" variant="normal"></Spinner>}</div>
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="xxxs">
                {errors?.map(({ errors, lineNumber, orderNumber }) => (
                    <ExpandableSection
                        key={orderNumber! + lineNumber!}
                        headerText={`Purchase ${orderNumber} ${lineNumber ? `Line ${lineNumber}` : ''} (${
                            errors?.length
                        } errors)`}
                    >
                        <SpaceBetween direction="vertical" size="xxxs">
                            {errors?.map(error => (
                                <div key={`${orderNumber}_${lineNumber}_${error.toString}`}>
                                    {t(`receivingAPIErrors.${error}`)}
                                </div>
                            ))}
                        </SpaceBetween>
                    </ExpandableSection>
                ))}
            </SpaceBetween>
            <a data-cy="download.anchor" style={{ display: 'none' }} href="/" ref={downloadRef} />
        </Alert>
    );
};
