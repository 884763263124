import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse } from 'modules/core/model/response';
import { mapToCustomFields } from 'modules/core/mappers';
import { CustomField, BulkCustomFieldUpdateRequest } from '../models';

interface GetCustomFieldsResponse {
    customFields: CustomField[];
    status?: number;
    requestId?: string;
}

export class CustomFieldService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'customField';

    public async getCustomFieldsByPurchaseId(purchaseId: string): Promise<GetCustomFieldsResponse> {
        try {
            const json: ResourceResponse = await this.get(`${purchaseId}`);

            const { result, requestId } = json;

            return { requestId, status: 200, customFields: mapToCustomFields(result as unknown[]) };
        } catch (error) {
            logger.error(error);
            return { customFields: [], status: (error as ApiError)?.response?.status };
        }
    }

    public async updateCustomFieldForPurchase(purchaseId: string, customFields: CustomField[]) {
        try {
            const body = ({ purchaseId, customFields } as unknown) as BulkCustomFieldUpdateRequest;
            const { json } = await this.post('update', { body });
            return json;
        } catch (error) {
            logger.error(error);
            return {
                updateSuccess: false,
                errorDescription: `Unable to update custom fields for purchase ${purchaseId}`
            };
        }
    }
}

export const customFieldService = new CustomFieldService();
