import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, FormField } from '@amzn/awsui-components-react-v2';
import { SmartEOptions } from '../../constants';
import { FormSchema } from 'modules/mappings/models';
import style from './ChannelTypeSmartE.module.scss';

interface ChannelTypeSmartEProps {
    defaultSelection?: string;
    channelType: string;
    onChange?: (id: string) => void;
}

export const ChannelTypeSmartE = (props: ChannelTypeSmartEProps) => {
    const { onChange, defaultSelection, channelType } = props;

    const { t } = useTranslation('mappings');

    const { register, setValue, triggerValidation, errors } = FormSchema.getSMARTeUseForm();

    const optionsList = Object.values(SmartEOptions).map(id => ({
        id: `${channelType}$${id}`,
        label: t(`smartEMappings.dropdown.smartEList.${id}`)
    }));

    const [selection, updateSelection] = useState(
        optionsList.find(option => option.id.split('$')[1] === defaultSelection)
    );

    useEffect(() => {
        register({ name: 'smartE', type: 'custom' });
    }, [register]);

    const setSmartEValue = (smartEValue: string) => {
        setValue('smartE', smartEValue, true);
    };

    const onSmartEChange = ({ detail }: CustomEvent<Select.ChangeDetail>) => {
        const { selectedOption } = detail;

        setSmartEValue(selectedOption.label);
        triggerValidation('smartE');

        if (onChange) {
            const { id } = selectedOption;
            onChange(id);
        }
        updateSelection(selectedOption);
    };

    return (
        <div className={style['awsui-select-dropdown']}>
            <FormField label={t(`dropdown.channelsList.${channelType}`)} errorText={errors?.smartE?.message}>
                <Select
                    selectedOption={selection}
                    options={optionsList}
                    data-cy="SmartEListDropDown"
                    placeholder={t('smartEMappings.dropdown.placeholderSelect')}
                    onChange={onSmartEChange}
                />
            </FormField>
        </div>
    );
};
