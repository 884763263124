import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SoftClosedVersion.module.scss';
import { LineVersion } from 'modules/audit/models';
import { NameCard } from '../NameCard';

export interface SoftClosedVersionProps {
    lineVersions: LineVersion[];
}

export const SoftClosedVersion = (props: SoftClosedVersionProps) => {
    const { lineVersions } = props;
    const { t } = useTranslation('purchase');

    return (
        <div className="awsui-util-container awsui-util-no-gutters">
            <div className={`awsui-util-container-header ${styles['soft-closed-version-title']}`}>
                <h2>{t('version.poSoftClosedTitle')}</h2>
            </div>
            <div className={`awsui-grid ${styles['soft-close-version-content']}`}>
                <div className="awsui-row">
                    <div className="col-1">
                        <NameCard
                            login={lineVersions[0].updaterLogin}
                            firstName={lineVersions[0].updaterFirstName}
                            lastName={lineVersions[0].updaterLastName}
                        />
                    </div>
                    <div className="col-9">
                        <strong>{`PO was soft closed by ${lineVersions[0].updaterLogin}`}</strong>
                    </div>
                </div>
            </div>
        </div>
    );
};
