import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Select, FormField } from '@amzn/awsui-components-react-v2';

import { FormSchema } from 'modules/mappings/models';
import style from '../OrgMappings/OrgMappings.module.scss';

interface ReassignCostCenterOrgMappingProps {
    costCenter: string;
    orgList: string[];
    onCreateNewOrg: () => void;
    onSave: (reassignedOrg: string) => void;
    onCancel: () => void;
    currentOrg: string;
}

export const ReassignCostCenterOrgMapping = (props: ReassignCostCenterOrgMappingProps) => {
    const { onCreateNewOrg, costCenter, orgList, onSave, onCancel, currentOrg } = props;
    const { t } = useTranslation('mappings');
    const title = t('orgMappings.reassignCostCenterModalHeader');

    const InitialDropDownOptions: Select.Option[] = [];
    const [orgDropDownOptions, setOrgDropDownOptions] = useState(InitialDropDownOptions);
    const [reassignedOrgName, setReassignedOrgName] = useState<Select.Option | null>(null);
    const { register, setValue, errors, formState } = FormSchema.getReassignCostCenterUseForm();

    useEffect(() => {
        register({ name: 'reassignedOrg', type: 'custom' });
    }, [register]);

    useEffect(() => {
        const dropDownOptions: Select.Option[] = [];
        orgList.forEach(org => {
            if (org != currentOrg) dropDownOptions.push({ label: org, id: org.replace(' ', '') });
        });
        setOrgDropDownOptions(dropDownOptions);
    }, [orgList]);

    const onOrgSelection = ({ detail: { selectedOption } }: CustomEvent<Select.ChangeDetail>) => {
        setReassignedOrgName(selectedOption);
        setValue('reassignedOrg', selectedOption.label, true);
    };

    const onModalSave = () => {
        if (!reassignedOrgName) {
            setValue('reassignedOrg', '', true);
        }
        if (formState.isValid && reassignedOrgName) {
            onSave(reassignedOrgName.label);
        }
    };

    const content = (
        <div className={style['modal-div']}>
            <p>{`${t('costCenter')}: ${costCenter}`}</p>
            <FormField errorText={errors?.reassignedOrg?.message}>
                <Select
                    selectedOption={reassignedOrgName}
                    options={orgDropDownOptions}
                    placeholder={t('orgMappings.reassignOrgSelectPlaceholder')}
                    onChange={onOrgSelection}
                    data-cy="reassignedOrgDropdown"
                />
            </FormField>
        </div>
    );

    return (
        <Modal
            visible={true}
            header={title}
            expandToFit={true}
            onDismiss={onCancel}
            footer={
                <span className="awsui-util-f-r">
                    <Button variant="link" onClick={onCreateNewOrg}>
                        {t('orgMappings.createNewOrg')}
                    </Button>
                    <Button variant="primary" onClick={onModalSave}>
                        {t('save')}
                    </Button>
                </span>
            }
        >
            {content}
        </Modal>
    );
};
