import { API } from '@aws-amplify/api';
import { logger } from '../logger';

export const DEFAULT_MAX_DECIMAL_DIGITS = 2;

let maxDecimalDigits = DEFAULT_MAX_DECIMAL_DIGITS;

export function setMaxDecimalDigits(max: number): void {
    maxDecimalDigits = max;
}

export function getMaxDecimalDigits(): number {
    return maxDecimalDigits;
}

export function getMaxDecimalDigitsRe(): RegExp {
    const maxDigits = getMaxDecimalDigits();
    return new RegExp(`^\\d*\\.{1}\\d{${maxDigits + 1},}$`, 'g');
}

export async function initPrecisionService() {
    const maxRetries = 5;
    for (let i = 0; i < maxRetries; i++) {
        try {
            const res = await API.get('MERP_API', '/config/maxReceivingDecimalDigits', {});
            if (typeof res === 'object' && 'result' in res && typeof res.result === 'number') {
                maxDecimalDigits = res.result;
                logger.log(`Setting maxDecimalDigits to ${maxDecimalDigits}`);
                return;
            } else {
                logger.warn(`Unexpected response for maxReceivingDecimalDigits: ${JSON.stringify(res)}`);
            }
        } catch (e) {
            logger.warn(e);
        }

        await new Promise(resolve => setTimeout(resolve, i * 500));
    }
}
