import React, { useEffect } from 'react';
import { ButtonDropdown, Icon } from '@amzn/awsui-components-react-v2';
import { useTranslation } from 'react-i18next';

import style from './SpendActionDropDown.module.scss';
import { Receipt } from 'modules/purchase/models';
import { InvoiceLine } from 'modules/invoices/models';

interface SpendActionDropDownProps {
    receipt: Receipt;
    invoiceEdited?: string;
    invoiceLines?: InvoiceLine[];
    onActionSelected: (...args: unknown[]) => void;
}

export const SpendDropDownActions = {
    RemoveInvoice: 'removeInvoice',
    AddInvoice: 'addInvoice',
    MatchSpend: 'matchSpend'
};

export const SpendActionDropDown = ({
    receipt,
    invoiceEdited,
    invoiceLines,
    onActionSelected
}: SpendActionDropDownProps) => {
    const { t } = useTranslation('purchase');

    const { allocations } = receipt;

    const hasInvoiceLines = invoiceLines ? invoiceLines.length > 0 : false;

    const allocatedLines = new Set(receipt.allocations.map(({ invoiceLineId }) => invoiceLineId));
    const hasAllocatedLines = invoiceLines?.filter(x => x.unmatchedAmount && !allocatedLines.has(x.id)).length;

    const disableActions = invoiceEdited !== '' || (!receipt.hasAllocations && !hasAllocatedLines);

    const items: Array<ButtonDropdown.Item> = [
        {
            id: SpendDropDownActions.RemoveInvoice,
            text: t('invoices.removeInvoice'),
            items: Array.from(allocations, ({ invoiceOfaId, invoiceLineId, invoiceNumber }) => ({
                id: `${SpendDropDownActions.RemoveInvoice}:::${invoiceLineId}`,
                text: `#${invoiceNumber || invoiceOfaId}`
            }))
        }
    ];

    useEffect(() => {
        if (!receipt.hasAllocations) {
            items.shift();
        }

        if (hasAllocatedLines) {
            items.push({
                id: SpendDropDownActions.AddInvoice,
                text: t('invoices.addInvoice')
            });
        }

        if (receipt.hasAllocations && receipt.unBilled) {
            items.push({
                id: SpendDropDownActions.MatchSpend,
                text: t('invoices.matchSpend')
            });
        }
    });

    const onDropDownClick = (event: CustomEvent<ButtonDropdown.ItemClick>) => {
        const { id } = event.detail;

        onActionSelected(...id.split(':::'));
    };

    return hasInvoiceLines ? (
        <ButtonDropdown
            items={items}
            expandableGroups={true}
            disabled={disableActions}
            className={
                disableActions ? `${style['spend-dropdown']} ${style['action-disabled']}` : style['spend-dropdown']
            }
            onItemClick={onDropDownClick}
        >
            <Icon name="menu" size="normal" variant="link" />
        </ButtonDropdown>
    ) : (
        <></>
    );
};
