import React from 'react';
import { Select } from '@amzn/awsui-components-react-v2';
import { useTranslation } from 'react-i18next';

import { InvoiceModal } from '../InvoiceModal';
import { Receipt } from 'modules/purchase/models';
import { CurrencyCode } from 'modules/core/model';
import { I18nFormatter } from 'modules/core/class';

import style from '../ReallocateBudgetModal/ReallocateBudgetModal.module.scss';

export const ReallocateBudgetOptions = {
    MoveSpendToReceipt: 'moveSpendToReceipt',
    UncommittedBalance: 'uncommittedBalance',
    CreateNewSpend: 'createSpend'
};
interface ReallocateBudgetModalProps {
    fromReceipt: Receipt | undefined;
    receipts: Receipt[];
    currencyCode: CurrencyCode;
    unBilledAmount?: number;
    uncommittedBalance: number;
    saveUnbilledAmount?: (dissmissOrSave: boolean, selection?: string) => void;
}

export const ReallocateBudgetModal = ({
    receipts,
    fromReceipt,
    currencyCode,
    unBilledAmount,
    uncommittedBalance,
    saveUnbilledAmount
}: ReallocateBudgetModalProps) => {
    const { t } = useTranslation('purchase');

    const getOptions = () => {
        const options: Array<Select.Option> = receipts
            .filter(
                ({ receiptNumber, receiptTime }) =>
                    receiptNumber !== fromReceipt?.receiptNumber &&
                    receiptTime > (fromReceipt?.receiptTime || Number.MAX_SAFE_INTEGER)
            )
            .map(receipt => ({
                id: `${ReallocateBudgetOptions.MoveSpendToReceipt}:::${receipt.receiptNumber}`,
                label: I18nFormatter.date(t, receipt.receiptTime),
                description: `${I18nFormatter.amount(t, receipt.receiptAmount)} ${currencyCode}`
            }));

        options.push({
            id: ReallocateBudgetOptions.UncommittedBalance,
            label: t('line.uncommittedBalance'),
            description: `${I18nFormatter.amount(t, uncommittedBalance)} ${currencyCode}`
        });

        options.push({
            id: ReallocateBudgetOptions.CreateNewSpend,
            label: t('invoices.createSpend')
        });

        return options;
    };

    return (
        <InvoiceModal
            title={t('invoices.reallocateModalTitle')}
            defaultSelection={ReallocateBudgetOptions.UncommittedBalance}
            content={
                <>
                    <div className={style['content-padding']}>
                        {t('invoices.reallocateBudgetPart1')}
                        <strong>
                            {I18nFormatter.amount(t, unBilledAmount)} {currencyCode}
                        </strong>
                        {t('invoices.reallocateBudgetPart2')}
                    </div>
                    <div>{t('invoices.reallocateBudgetContent2')}</div>
                </>
            }
            options={getOptions()}
            saveOrDismissFunction={saveUnbilledAmount}
        />
    );
};
