import React from 'react';

interface EmptyListContentProps {
    header: string;
    message: string;
}

export const EmptyListContent = ({ header, message }: EmptyListContentProps) => {
    return (
        <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>{header}</b>
            </div>
            <p className="awsui-util-mb-s">{message}</p>
        </div>
    );
};
