import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from '@amzn/awsui-components-react';

import { OptionDefinition } from 'modules/core/types';

interface CustomSelectionDropdownProps {
    selected?: string;
    selections: string[];
    onChange: (objective: string) => void;
}

export const CustomSelectionDropdown = (props: CustomSelectionDropdownProps) => {
    const { selected, selections, onChange } = props;

    const [selectedOption, setSelectedOption] = useState<SelectProps.Option>();

    const onSelectionChange = (selectedOption: OptionDefinition) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption.value ?? '');
    };

    useEffect(() => {
        setSelectedOption({
            value: selected,
            label: selected
        });
    }, [selected]);

    return (
        <Select
            selectedOption={selectedOption ?? null}
            options={Object.values(selections).map(s => ({
                value: s,
                label: s
            }))}
            data-cy="customSelectionDropdown"
            onChange={({ detail }) => onSelectionChange(detail.selectedOption)}
        />
    );
};
