export enum PurchaseStatus {
    ISSUED = 'ISSUED',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED',
    SOFT_CLOSED = 'SOFT_CLOSED',
    PENDING_SOFT_CLOSED = 'PENDING_SOFT_CLOSE',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    CHANGE_PURCHASE_PENDING_MERP_APPROVAL = 'CHANGE_PURCHASE_PENDING_MERP_APPROVAL',
    PURCHASE_CHANGE_CANCELLED = 'CHANGE_PURCHASE_ORDER_CANCELLED',
    PURCHASE_REQUEST_PENDING_MERP_APPROVAL = 'PURCHASE_REQUEST_PENDING_MERP_APPROVAL',
    PURCHASE_REQUEST = 'PURCHASE_REQUEST',
    PURCHASE_REQUEST_CANCELED = 'PURCHASE_REQUEST_CANCELED',
    PURCHASE_REQUEST_DECLINED = 'PURCHASE_REQUEST_DECLINED',
    PURCHASE_REQUEST_ERROR = 'PURCHASE_REQUEST_ERROR',
    PURCHASE_ORDER_ISSUED = 'PURCHASE_ORDER_ISSUED',
    PURCHASE_ORDER_CANCELED = 'PURCHASE_ORDER_CANCELED',
    PURCHASE_ORDER_ERROR = 'PURCHASE_ORDER_ERROR',
    PURCHASE_ORDER_CLOSED = 'PURCHASE_ORDER_CLOSED'
}
