import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Icon, Header, Spinner, SpaceBetween, IconProps } from '@amzn/awsui-components-react';

import { PurchaseLine, PurchaseStatus } from 'modules/purchase/models';
import { I18nFormatter } from 'modules/core/class';

interface LineWrapperHeaderProps {
    title: string;
    loading: boolean;
    editMode: boolean;
    expanded: boolean;
    line: PurchaseLine;
    purchaseStatus: PurchaseStatus;
    onInfoClicked: () => void;
}

export const LineWrapperHeader = ({
    line,
    title,
    loading,
    expanded,
    editMode,
    purchaseStatus,
    onInfoClicked
}: LineWrapperHeaderProps) => {
    const [t] = useTranslation('purchase');

    const { description, amount, currencyCode, lineNumber } = line;

    const closedStatuses = [PurchaseStatus.CLOSED, PurchaseStatus.SOFT_CLOSED, PurchaseStatus.CANCELLED];

    const getConfirmationStatusAndIcon = (status: PurchaseStatus, line: PurchaseLine): [string, IconProps.Name] => {
        if (status && closedStatuses.includes(status)) {
            return [t('confirmation.isClosed'), 'status-stopped'];
        }

        if (line.isConfirmed) {
            return [t('confirmation.isConfirmed'), 'status-positive'];
        }

        const confirmationStatus = line.requiresConfirmation
            ? t('confirmation.unconfirmed')
            : t('line.noSpendActivity');

        const icon = line.requiresConfirmation ? 'status-pending' : 'status-stopped';

        return [confirmationStatus, icon];
    };

    const [confirmationStatus, icon] = getConfirmationStatusAndIcon(purchaseStatus, line);

    const variant = line.requiresConfirmation ? 'subtle' : 'success';

    return (
        <Header
            variant="h3"
            actions={
                <>
                    {!loading && (
                        <SpaceBetween size="s" direction="horizontal">
                            <SpaceBetween size="xxs" direction="horizontal">
                                <Icon variant={variant} name={icon} />
                                <span>{confirmationStatus}</span>
                            </SpaceBetween>
                            {!editMode && !line.hasValidDistribution && (
                                <SpaceBetween size="xxs" direction="horizontal">
                                    <Icon
                                        size="normal"
                                        variant="warning"
                                        name="status-warning"
                                        data-cy="spendWarningIcon"
                                    />
                                    <span>{t('line.invalidSpend')}</span>
                                </SpaceBetween>
                            )}
                        </SpaceBetween>
                    )}
                    {loading && !expanded && <Spinner />}
                </>
            }
        >
            <SpaceBetween size="s" direction="horizontal">
                <span>{`${title} Line ${lineNumber}: ${description}`}</span>
                <span>{I18nFormatter.amount(t, amount)}</span>
                <span>{currencyCode}</span>
                <Link onFollow={onInfoClicked}>{t('info')}</Link>
            </SpaceBetween>
        </Header>
    );
};
