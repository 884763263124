class MockedBigOChannelMappingsService {
    public async getMappingsAsync() {
        const result = {
            channelBigOMappings: [
                {
                    id: '12021309-a275-4289-8692-04ba1ade9701',
                    coaString: '123-5475-7871-323-323-232-1212-2323',
                    channel: 'paidSearch',
                    bigObjective: 'alexa'
                },
                {
                    id: '87789798-a275-4289-8692-04ba1ade9701',
                    coaString: '123-5475-7871-323-323-232-1212-2323',
                    channel: 'socialFixed',
                    bigObjective: 'prime'
                }
            ]
        };
        return {
            requestId: '12021309-a275-4289-8692-04ba1ade9701',
            response: result,
            status: 200
        };
    }

    public async updateMappingsAsync() {
        return {
            requestId: '12021309-a275-4289-8692-04ba1ade9701',
            message: 'Updated Mappings successfully.',
            success: true
        };
    }

    public async createMappingAsync() {
        return {
            requestId: '12021309-a275-4289-8692-04ba1ade9701',
            message: 'Created Mapping successfully.',
            success: true
        };
    }

    public async deleteMappingAsync() {
        return {
            requestId: '12021309-a275-4289-8692-04ba1ade9701',
            message: 'Deleted Mapping successfully.',
            success: true
        };
    }
}

export const mockedBigOChannelMappingsService = new MockedBigOChannelMappingsService();
