import { useState, useEffect, useRef } from 'react';
import { purchaseService } from 'modules/purchase/services';
import { DeepCopy } from '../class';
import { useDispatch } from 'react-redux';
import { updateReceipts } from 'modules/app/store';
import { Receipt } from '../models';

export interface UseReceiptsProps {
    purchaseId: string;
    lineNumber: number;
    currentReceipts?: Receipt[];
}

export function useReceipts(props: UseReceiptsProps) {
    const { purchaseId, lineNumber, currentReceipts } = props;
    const [loadingReceipts, updateLoadingState] = useState(false);

    const receiptCount = useRef(currentReceipts?.length);

    const dispatch = useDispatch();

    useEffect(() => {
        const getReceipts = async () => {
            updateLoadingState(true);

            const result = await purchaseService.getReceiptsAsync(purchaseId, lineNumber);

            const newReceipts = result.receipts.map(DeepCopy.receipt);

            dispatch(updateReceipts({ lineNumber, receipts: newReceipts }));

            updateLoadingState(false);
        };

        if (!receiptCount.current) {
            getReceipts();
        }
    }, [purchaseId, lineNumber, receiptCount, dispatch]);

    return { loadingReceipts };
}
