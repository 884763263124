import { useState, useRef } from 'react';

import { logger } from 'modules/core';
import { COACombination } from 'modules/onboarding/model';
import { coaService, GetCOAsRequest, GetCOAsResponse } from 'modules/onboarding/services';

export const useCoaForExport = () => {
    const [coaCombinations, updateCoaCombinations] = useState<COACombination[]>([]);
    const lastEvaluatedKey = useRef<string | undefined>();

    const queryPageSize = 500;

    const fetchCOACombinationsAcrossTable = async () => {
        const getCOAsRequest: GetCOAsRequest = makeGetCOAsRequest(true);
        let result: GetCOAsResponse;

        const newlyFetchedCOACombinations: COACombination[] = [];

        do {
            result = await coaService.getCOAsAsync(getCOAsRequest);

            if (result.coaCombinations === undefined) {
                logger.error('result.coaCombinations is undefinded while useCoaForExport');
                break;
            }

            newlyFetchedCOACombinations.push(...result.coaCombinations);

            lastEvaluatedKey.current = result.lastEvaluatedKey;
            getCOAsRequest.lastEvaluatedKey = lastEvaluatedKey.current;
        } while (lastEvaluatedKey.current);

        lastEvaluatedKey.current = undefined;

        updateCoaCombinations(prev => {
            newlyFetchedCOACombinations.forEach(rec => prev.push(rec));
            return prev;
        });
    };

    const makeGetCOAsRequest = (isOnboarded: boolean): GetCOAsRequest => {
        const getCOAsRequest: GetCOAsRequest = {
            pageSize: queryPageSize,
            isOnboarded: isOnboarded,
            lastEvaluatedKey: lastEvaluatedKey.current
        };

        return getCOAsRequest;
    };

    return {
        coaCombinations,
        updateCoaCombinations,
        fetchCOACombinationsAcrossTable
    };
};
