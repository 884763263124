import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse } from 'modules/core/model/response';
import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';

interface YearlyAccountingForecastResult {
    forecasts: YearlyAccountingForecast[] | undefined;
    requestId?: string;
    status?: number;
}

export class ForecastService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'yearlyAccountingForecasts';

    public async getForecastsAsync(team?: string): Promise<YearlyAccountingForecastResult> {
        try {
            const path = team ? `?teamName=${team}` : '';
            const json: ResourceResponse = await this.get(path);
            if (json.result) {
                const { result, requestId } = json;
                return { requestId, forecasts: result as YearlyAccountingForecast[], status: 200 };
            }

            return { forecasts: (json as unknown) as YearlyAccountingForecast[], status: 200 };
        } catch (error) {
            logger.error(error);
            return { forecasts: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    public async updateForecastsAsync(forecasts: YearlyAccountingForecast[]) {
        try {
            const params = { body: forecasts };

            await this.put('', params);

            return { success: true, status: 200 };
        } catch (error) {
            logger.error(error);

            return {
                success: false,
                status: (error as ApiError)?.response?.status
            };
        }
    }
}

export const forecastService = new ForecastService();
