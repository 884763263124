import { ApiClient } from 'modules/core/class/api/ApiClient';
import { Receipt } from 'modules/purchase/models/Receipt';
import { BulkReceivingRequest, BulkReceivingResponse } from 'modules/bulkEdit/models';
import { mapToBulkReceivingRequest } from 'modules/bulkEdit/mappers/';
import { chunk, uniqWith } from 'lodash';

const CHUNK_SIZE = 10;
const FULFILLED_RESPONSE_STATUS = 'fulfilled';

export type CreateReceiptResponse = {
    result: Record<string, Record<string, unknown>[]>;
};

export class ReceiptService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'receipts';

    public async create(receipts: Receipt[], receiveInFull = false) {
        const body = receipts.map(r => mapToBulkReceivingRequest(r, receiveInFull));

        const requestChucks = this.chunkRequests(body);

        const responses: PromiseSettledResult<CreateReceiptResponse>[] = await Promise.allSettled(
            requestChucks.map(requestChunk => this.post('create', { body: requestChunk }))
        );

        return this.mergeResponses(responses);
    }

    private chunkRequests(requests: BulkReceivingRequest[]) {
        const uniqueRequests = uniqWith(
            requests,
            (r1, r2) => r1.purchaseId === r2.purchaseId && r1.lineNumber === r2.lineNumber
        );
        return chunk(uniqueRequests, CHUNK_SIZE);
    }

    public mergeResponses(responses: PromiseSettledResult<CreateReceiptResponse>[]): BulkReceivingResponse {
        const aggregateBulkReceivingResponse: BulkReceivingResponse = {
            successes: [],
            failures: []
        };
        for (const response of responses) {
            const responsePayload = this.getResponsePayload(response);
            const bulkReceivingResponse = BulkReceivingResponse.from(responsePayload);
            this.appendResponse(aggregateBulkReceivingResponse, bulkReceivingResponse);
        }

        return aggregateBulkReceivingResponse;
    }

    private appendResponse(aggregateResponse: BulkReceivingResponse, response: BulkReceivingResponse) {
        if (response.successes) {
            aggregateResponse.successes.push(...response.successes);
        }
        if (response.failures) {
            aggregateResponse.failures.push(...response.failures);
        }
    }

    private getResponsePayload(response: PromiseSettledResult<CreateReceiptResponse>) {
        return FULFILLED_RESPONSE_STATUS === response.status
            ? response.value.result
            : response.reason.response.data.result;
    }
}

export const receiptService = new ReceiptService();
