import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse } from 'modules/core/model/response';
import { logger } from 'modules/core/logger';
import { OrgMapping } from 'modules/mappings/models';
import { ApiError } from 'modules/core/types';

interface GetOrgMappingsResponse {
    orgMappings: OrgMapping[];
    unassignedCostCenters: string[];
}

interface OrgMappingsResult {
    status?: number;
    requestId?: string;
    response?: GetOrgMappingsResponse;
}
export class OrgMappingService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'mappings';
    protected path = 'org';

    public async getOrgMappingsAsync(): Promise<OrgMappingsResult> {
        try {
            const json: ResourceResponse = await this.get(this.path);

            const { result, requestId } = json;
            const mappingsResult = result as GetOrgMappingsResponse;
            return { requestId, response: mappingsResult, status: 200 };
        } catch (error) {
            logger.error(error);
            return { response: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    public async updateOrgMappingsAsync(updateRequests: OrgMapping[]) {
        try {
            const body = { orgMappingList: updateRequests };
            const response = await this.put(this.path, { body });

            return response;
        } catch (error) {
            logger.error(error);
            return {
                success: false,
                message: 'Unable to submit request'
            };
        }
    }
}

export const orgMappingService = new OrgMappingService();
