import React from 'react';
import { useTranslation } from 'react-i18next';

import { PurchaseDetailTable } from 'modules/purchase/components/PurchaseDetailTable';
import { PurchaseLine } from 'modules/purchase/models';
import styles from './ViewLineVersion.module.scss';

interface ViewLineVersionProps {
    line: PurchaseLine;
}

export const ViewLineVersion = (props: ViewLineVersionProps) => {
    const { line } = props;

    const { t } = useTranslation('purchase');

    const { receipts, uncommittedBalance } = line;

    const ViewPurchaseDetails = () => {
        return (
            <div>
                <div className="awsui-util-label">
                    {t('line.receiptInformation')} ({receipts?.length ?? 0})
                </div>

                <PurchaseDetailTable receipts={receipts} uncommittedBalance={uncommittedBalance} line={line} />
            </div>
        );
    };

    const LineHeader = () => {
        return (
            <div className="awsui-util-action-stripe awsui-util-spacing-v-s">
                <div className="awsui-util-action-stripe-group awsui-util-spacing-v-s">
                    <div className="awsui-util-label">{t('line.item')}</div>
                    <div className={styles['with-padding']}>{line.description}</div>
                    <br />
                    <div className="awsui-util-label">{t('line.accountingString')}</div>
                    <div className={styles['with-padding']}>{line.account?.accountingString}</div>
                </div>
            </div>
        );
    };

    return (
        <div className="awsui-util-spacing-v-s">
            <LineHeader />
            <ViewPurchaseDetails />
        </div>
    );
};
