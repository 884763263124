import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { DateInputValidationSchema } from 'modules/bulkEdit/forms/DateInputValidationSchema';

type DateInputs = yup.InferType<typeof DateInputValidationSchema>;

export class FormSchema {
    public static getUseForm() {
        const methods = useForm<DateInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: DateInputValidationSchema
        });

        return methods;
    }
}
