import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { logger } from 'modules/core';
import { purchaseService } from 'modules/purchase/services';
import { OwnerUpdateStatus } from '../models/OwnerUpdateStatus';
import { SoftCloseStatus } from '../models/SoftCloseStatus';
import { PurchaseSubmitStatus, UpdateReceiptsRequest } from 'modules/purchase/models';
import { updatePurchase } from 'modules/app/store';

interface SubmitResult {
    status: PurchaseSubmitStatus | OwnerUpdateStatus | SoftCloseStatus;
    reason: string;
}

export function useSubmitPurchase(purchaseId: string) {
    const dispatch = useDispatch();

    const [purchaseSubmitted, updateSubmittedStatus] = useState<SubmitResult>({
        status: PurchaseSubmitStatus.NOT_SUBMITTED,
        reason: ''
    });

    const [submitInProgress, updateSubmitInProgress] = useState(false);

    async function updateConfirmAndSubmitReceiptsAsync(requests?: UpdateReceiptsRequest[]): Promise<void> {
        updateSubmitInProgress(true);

        try {
            const result = await purchaseService.updateConfirmAndSubmitReceiptsAsync(purchaseId, requests);

            const resultStatus = result.updateSuccess
                ? PurchaseSubmitStatus.SUBMIT_SUCCESS
                : PurchaseSubmitStatus.SUBMIT_FAILURE;

            updateSubmittedStatus({ status: resultStatus, reason: result.errorDescription });

            if (result.updateSuccess) {
                await reloadPurchaseAsync();
            }
        } catch (error) {
            logger.error(error);
        } finally {
            updateSubmitInProgress(false);
        }
    }

    async function reloadPurchaseAsync(): Promise<void> {
        try {
            const result = await purchaseService.getPurchaseAsync(purchaseId, false);
            if (result?.purchase !== undefined) {
                dispatch(updatePurchase(result.purchase));
            }
        } catch (error) {
            logger.error(error);
        }
    }

    return {
        submitInProgress,
        purchaseSubmitted,
        updateConfirmAndSubmitReceiptsAsync,
        reloadPurchaseAsync
    };
}
