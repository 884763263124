import React from 'react';
import { useTranslation } from 'react-i18next';

import { NameCard } from '../NameCard';
import { ChangedLineAmount } from '../ChangedLineAmount';
import { VersionTitle } from '../VersionTitle/VersionTitle';
import { LineVersion, VersionType } from 'modules/audit/models';
import styles from '../EditedVersion/EditedVersion.module.scss';
import { OriginalReceiptVersion } from '../OriginalReceiptVersion';

export interface AddedOrGeneratedVersionProps {
    lineVersions: LineVersion[];
}

export const AddedOrGeneratedVersion = (props: AddedOrGeneratedVersionProps) => {
    const { lineVersions } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    function getTitle() {
        if (VersionType.FIRST_EDIT === lineVersion.versionType) return t('version.generatedTitle');
        return t('version.lineAddedTitle');
    }

    let containerClass = 'awsui-util-container awsui-util-no-gutters';
    if (lineVersion.isRejected) {
        containerClass += ` ${styles['transparent-box']}`;
    }

    return (
        <div className={containerClass}>
            <div className="awsui-util-container-header">
                <VersionTitle title={getTitle()} lineVersion={lineVersion} />
            </div>
            <div className={`awsui-grid ${styles['line-version-content']}`}>
                <div className="awsui-row">
                    <div className="col-1">
                        <NameCard
                            login={lineVersion.updaterLogin}
                            firstName={lineVersion.updaterFirstName}
                            lastName={lineVersion.updaterLastName}
                        />
                    </div>
                    <div className="col-9">
                        <strong>
                            {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
                        </strong>
                        <OriginalReceiptVersion
                            receiptVersions={lineVersion.receiptVersions}
                            currency={lineVersion.currencyCode}
                            uncommittedBalance={lineVersion.modifiedUncommittedBalance}
                        />
                    </div>
                    <div className="col-2">
                        <ChangedLineAmount amount={lineVersion.changedLineAmount} currency={lineVersion.currencyCode} />
                    </div>
                </div>
            </div>
        </div>
    );
};
