import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { logger } from 'modules/core';
import { SoftCloseResponse } from 'modules/core/model/response';
import { purchaseService } from 'modules/purchase/services';
import { OwnerUpdateStatus } from '../models/OwnerUpdateStatus';
import { SoftCloseStatus } from '../models/SoftCloseStatus';
import { PurchaseSubmitStatus } from 'modules/purchase/models';
import { updatePurchase } from 'modules/app/store';

interface SubmitResult {
    status: PurchaseSubmitStatus | OwnerUpdateStatus | SoftCloseStatus;
    reason: string;
}

export function useSubmitPurchaseSoftClose(purchaseId: string) {
    const dispatch = useDispatch();

    const [purchaseSoftCloseSubmitted, updateSubmittedStatus] = useState<SubmitResult>({
        status: SoftCloseStatus.NOT_SUBMITTED,
        reason: ''
    });

    const [softCloseErrors, updateSoftCloseError] = useState<Map<string, string[]>>(new Map());

    const [submitSoftCloseInProgress, updateSubmitSoftCloseInProgress] = useState(false);

    async function reloadPurchaseAsyncAfterSoftClose(results: SoftCloseResponse): Promise<void> {
        try {
            const errorMapEntries = Object.entries(results?.ErrorMap);
            const errorMap = new Map<string, string[]>(errorMapEntries.map(([key, value]) => [key, value]));

            updateSoftCloseError(errorMap);
            updateSubmitSoftCloseInProgress(true);

            const resultStatus = results?.softCloseSuccess
                ? SoftCloseStatus.SUBMIT_SUCCESS
                : SoftCloseStatus.SUBMIT_FAILURE;

            updateSubmittedStatus({ status: resultStatus, reason: '' });
            if (results.softCloseSuccess) {
                await reloadPurchaseAsync();
            }
        } catch (error) {
            logger.error(error);
        } finally {
            updateSubmitSoftCloseInProgress(false);
        }
    }

    async function reloadPurchaseAsync(): Promise<void> {
        try {
            const result = await purchaseService.getPurchaseAsync(purchaseId, false);
            if (result?.purchase !== undefined) {
                dispatch(updatePurchase(result.purchase));
            }
        } catch (error) {
            logger.error(error);
        }
    }

    return {
        softCloseErrors,
        submitSoftCloseInProgress,
        purchaseSoftCloseSubmitted,
        reloadPurchaseAsyncAfterSoftClose
    };
}
