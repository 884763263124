/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'modules/purchaseOrder/views/purchaseOrder/PurchaseOrder.module.scss';

interface Loadingrops {
    hasNextPage: boolean;
    loadLinesRef: (jsxElement: any) => void;
}

export const LoadingIndicator = ({ hasNextPage, loadLinesRef }: Loadingrops) => {
    const { t } = useTranslation('purchase');

    return (
        <>
            {hasNextPage && (
                <h1 ref={loadLinesRef} className={styles['lines-loading']}>
                    {t('version.loading')}
                </h1>
            )}
        </>
    );
};
