import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal, TokenGroup, FormField, CustomDetailEvent, Input, Alert } from '@amzn/awsui-components-react-v2';

import { ApplicationState, getCreatedBy, getOwners, getPurchaseId, getRequestedBy } from 'modules/app/store';
import { papiService } from 'modules/purchase/services';
import { validateUserEditService } from 'modules/user/services';
import { FormSchema, Owner, OwnerAction, MspOwner } from '../../models';
import { AppConfig } from 'modules/core';

interface EditMspOwnerProps {
    visible: boolean;
    updateOwners: (updateOwners: MspOwner[]) => void;
    onCancel?: () => void;
}

export const EditMspOwners = (props: EditMspOwnerProps) => {
    const { visible, onCancel, updateOwners } = props;

    const { t } = useTranslation('purchase');

    const loggedUser = useSelector((state: ApplicationState) => state.user);
    const purchaseId = useSelector(getPurchaseId);
    const owners = useSelector(getOwners);
    const createdBy = useSelector(getCreatedBy);
    const requestedBy = useSelector(getRequestedBy);

    const userName = 'userAlias';
    const alias = useRef('');

    const [ownerMap, setOwnerActionMap] = useState<Map<string, OwnerAction>>(new Map([]));
    const [alertVisible, setAlertVisible] = useState(false);
    const [showCentralReceiverAlert, setCentralReceiverAlert] = useState(false);
    const [loading, setButtonLoading] = useState(false);

    const [mspOwners, setOwners] = useState((owners || []).map(login => ({ label: login })));

    const {
        errors,
        register,
        setValue,
        triggerValidation,
        clearError,
        setError,
        formState
    } = FormSchema.getOwnerUseForm();

    useEffect(() => {
        register({ name: userName, type: 'custom' });
    }, [register, userName]);

    const creators = () => {
        const creator = [];
        if (createdBy?.login) {
            const poLabel = `${t('owner.coupaPOOwner')} ${createdBy.login}`;
            creator.push({ label: poLabel, disabled: true });
        }
        if (requestedBy?.login) {
            const requesterLabel = `${t('owner.requestor')} ${requestedBy.login}`;
            creator.push({ label: requesterLabel, disabled: true });
        }
        return creator;
    };

    const setOwnerMap = (alias: string, action: OwnerAction) => {
        setOwnerActionMap(prev => {
            if (prev.has(alias)) {
                prev?.delete(alias);
            } else {
                prev?.set(alias, action);
            }
            return prev;
        });
    };

    const updateInputValue = ($event: CustomDetailEvent<Input.ChangeDetail>) => {
        const { value } = $event.detail;
        alias.current = value;
        setValue(userName, value);
        triggerValidation(userName);
        setCentralReceiverAlert(false);
    };

    const onCancelPress = () => {
        if (onCancel) {
            clearError();
            onCancel();
        }
    };

    const handleDismissOwner = (event: CustomEvent<TokenGroup.DismissDetail>) => {
        const itemIndex = event.detail.itemIndex;
        const aliasRevoked = mspOwners[itemIndex].label;
        setOwners([...mspOwners.slice(0, itemIndex), ...mspOwners.slice(itemIndex + 1)]);
        setOwnerMap(aliasRevoked, 'REVOKE_OWNER');
    };

    const isValid = async (aliasName: string) => {
        const validAlias = await papiService.isValidAliasAsync(aliasName);
        const result = mspOwners?.find(owner => owner.label === aliasName);
        const isAliasCreator = aliasName === createdBy?.login || aliasName === requestedBy?.login;

        if (!validAlias || !formState.isValid) {
            setError(userName, 'manual', 'Alias is not valid');
            return false;
        }
        if (result || isAliasCreator) {
            setError(userName, 'manual', 'Alias is already an owner');
            return false;
        }

        return true;
    };

    const appendOwner = async () => {
        setButtonLoading(true);
        const aliasName = alias.current.trim().toLowerCase();
        const validAlias = await isValid(aliasName);

        if (validAlias) {
            const response = await validateUserEditService.validateUserEditAsync(purchaseId, aliasName);

            if (!response.validUserEdit) {
                setCentralReceiverAlert(true);
            }

            mspOwners.push({ label: aliasName });
            setOwners([...mspOwners]);
            setOwnerMap(aliasName, 'ADD_OWNER');
        }
        setButtonLoading(false);
    };

    const handleKeyDown = (event: CustomDetailEvent<Input.KeyDetail>) => {
        if (event.detail.key === 'Enter') {
            appendOwner();
        }
    };

    const onSave = () => {
        const request = Owner.getOwnerRequest(ownerMap, loggedUser.alias);
        if (request.length > 0) {
            updateOwners(request);
        } else {
            setAlertVisible(true);
        }

        if (onCancel) {
            onCancel();
        }
    };

    const Footer = () => (
        <span className="awsui-util-f-r">
            <Button variant="link" onClick={onCancelPress}>
                {t('owner.cancel')}
            </Button>
            <Button variant="primary" onClick={onSave}>
                {t('owner.save')}
            </Button>
        </span>
    );

    return (
        <Modal visible={visible} header={t('owner.heading')} onDismiss={onCancelPress} footer={<Footer />}>
            <Alert header={t('owner.alertMsg')} visible={alertVisible} type="warning" />
            <Alert
                header={t('owner.centralReceiverWarningMsg', {
                    alias: alias.current.trim().toLowerCase(),
                    link: AppConfig.coupaAccessFormUrl
                })}
                visible={showCentralReceiverAlert}
                type="warning"
                buttonText={t('owner.accessBtn')}
                onButtonClick={() => window.open(AppConfig.coupaAccessFormUrl)}
            />
            <FormField
                label={t('owner.heading')}
                stretch={false}
                errorText={errors.userAlias?.message}
                secondaryControl={
                    <Button onClick={appendOwner} loading={loading}>
                        {t('owner.add')}
                    </Button>
                }
            >
                <Input
                    type="text"
                    name={userName}
                    value={alias.current}
                    placeholder="User alias"
                    onInput={e => updateInputValue(e)}
                    onKeydown={event => handleKeyDown(event)}
                />
            </FormField>

            <TokenGroup items={mspOwners} onDismiss={handleDismissOwner}></TokenGroup>
            <TokenGroup items={creators()}></TokenGroup>
        </Modal>
    );
};
