/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { MspTask, TaskAction } from '@amzn/merp-core/models/budgetManagement';

import { BudgetTableTemplate } from '../BudgetTableTemplate';
import { BudgetTableHeaderTemplate } from '../BudgetTableHeaderTemplate';
import { TableRow } from 'modules/core/model/TableRow';
import { ApplicationState, isFinancialAnalyst } from 'modules/app/store';
import { CSVTemplate } from 'modules/dashboard/models';
import { BudgetPlanCsvConverter, YearForecast } from 'modules/budgetManagement/model';
import { I18nFormatter } from 'modules/core/class';

interface TaskTableProps {
    items: any[];
    loading: boolean;
    forecastsWithNoOwnerCount?: number;
    yearBudgetitems?: TableRow<YearForecast>[];
}

export const TaskTable = (props: TaskTableProps) => {
    const { items, loading, forecastsWithNoOwnerCount, yearBudgetitems } = props;

    const userClaims = useSelector((state: ApplicationState) => state.user);
    const isFA = useSelector(isFinancialAnalyst);

    const { t } = useTranslation('budgetManagement');

    const csvLink = useRef<any>();
    const [csvExport, setCsvExport] = useState<CSVTemplate | undefined>(undefined);

    useEffect(() => {
        if (!csvExport) return;

        setTimeout(() => {
            csvLink.current?.link.click();
            setCsvExport(undefined);
        }, 500);
    }, [csvExport]);

    const buildTaskLink = (row: TableRow<MspTask>) => {
        const { action, entityId } = row.payload;

        const taskName =
            action === TaskAction.ASSIGN_BUDGET_OWNERS
                ? t('table.task.ASSIGN_BUDGET_OWNERS', { count: forecastsWithNoOwnerCount ?? 0 })
                : t(`table.task.${action}`);

        const redirectLink =
            action === TaskAction.ASSIGN_BUDGET_OWNERS ? '/budget/assignOwners' : `/budget/plan/${entityId}`;

        const taskLink =
            action === TaskAction.COMPLETE_BUDGET || action === TaskAction.BUDGET_CHANGE_REQUEST ? '' : redirectLink;

        function onClickDownload() {
            if (action !== TaskAction.COMPLETE_BUDGET && action !== TaskAction.BUDGET_CHANGE_REQUEST) return;
            if (!yearBudgetitems) return;

            const csvTemplate = new BudgetPlanCsvConverter().build(
                yearBudgetitems.map(r => r.payload),
                isFA
            );
            setCsvExport(csvTemplate);
        }

        return (
            <>
                <Link to={taskLink} onClick={onClickDownload}>
                    {taskName}
                </Link>
                {csvExport && (
                    <CSVLink
                        data={csvExport.getCSVData()}
                        headers={csvExport.getHeaders()}
                        filename={`${userClaims.alias}_msp_budgetPlan.csv`}
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                    />
                )}
            </>
        );
    };

    const columns = [
        {
            id: 'taskName',
            header: t('table.task.taskName'),
            cell: (row: TableRow<MspTask>) => buildTaskLink(row)
        }
    ];

    if (isFA) {
        columns.push({
            id: 'owner',
            header: t('table.task.owner'),
            cell: (row: TableRow<MspTask>) => <div>{row.payload.assignedTo}</div>
        });
    } else {
        columns.push({
            id: 'dateGenerated',
            header: t('table.task.dateGenerated'),
            cell: (row: TableRow<MspTask>) => <div>{I18nFormatter.date(t, row.payload.createdAt)}</div>
        });
    }

    return (
        <BudgetTableTemplate
            loading={loading}
            items={items}
            columns={columns}
            header={
                <BudgetTableHeaderTemplate
                    title={t('table.task.title')}
                    emptyTitle={t('table.task.emptyTitle')}
                    loading={loading}
                    tableItemCount={items.length}
                    buttons={[]}
                />
            }
        />
    );
};
