export enum UploadBudgetHeaders {
    FORECAST_ID = 'Forecast Id (DO NOT EDIT)',
    COMPANY_CODE = 'Company Code (DO NOT EDIT)',
    LOCATION_CODE = 'Location Code (DO NOT EDIT)',
    COST_CENTER = 'Cost Center (DO NOT EDIT)',
    ACCOUNT_CODE = 'Account Code (DO NOT EDIT)',
    PRODUCT_LINE = 'Product Line (DO NOT EDIT)',
    CHANNEL_CODE = 'Channel (DO NOT EDIT)',
    PROJECT_CODE = 'Project Code (DO NOT EDIT)',
    CUSTOM_SEGMENT = '8th Segment (DO NOT EDIT)',
    CURRENCY = 'Currency (DO NOT EDIT)',
    EXISTING_OWNER = 'Existing Owner (DO NOT EDIT)',
    ASSIGN_OWNER = 'Assign Owner',
    COMMENTS = 'Comments (from FA)',
    YEAR = 'Year (DO NOT EDIT)',
    BIG_OBJECTIVE = 'Big Objective (DO NOT EDIT)',
    CHANNEL_TYPE = 'Channel Type (DO NOT EDIT)',
    SMARTE = 'SMARTe Classification (DO NOT EDIT)',
    TEAM_NAME = 'Team Name (DO NOT EDIT)'
}
