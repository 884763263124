import { logger } from 'modules/core';
import { PaginatedResult, PaginatedQuery, PurchaseQueryV2, PurchaseResultV2 } from 'modules/core/model';
import { Purchase } from 'modules/purchase/models';
import { mapToPurchase } from 'modules/core/mappers';
import { mapToPurchaseIndex } from 'modules/core/mappers/purchase.mappers';
import { RequestParams } from 'modules/core/class/api/RequestParams';
import { PaginatedResponse } from 'modules/core/model/response';
import { PurchaseService } from 'modules/purchase/services';
import { InvoiceLine } from 'modules/invoices/models';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { ApiError } from 'modules/core/types';

export class PurchaseListService extends PurchaseService {
    DEFAULT_PAGE_SIZE = 60;
    DEFAULT_FROM = 0;

    public async getPurchaseOrdersAsyncV1(query?: PaginatedQuery): Promise<PaginatedResult<Purchase>> {
        return this.getPurchasesWithStatusAsyncV1('purchase_order', query);
    }

    public async getPurchaseRequestsAsyncV1(query?: PaginatedQuery) {
        return this.getPurchasesWithStatusAsyncV1('purchase_request', query);
    }

    public async getInvoiceLinesAsync(purchaseId: string, lineNumber: number): Promise<InvoiceLine[]> {
        try {
            const { result } = await this.get(`${purchaseId}/invoiceLines?purchaseLineNumber=${lineNumber}`);

            return result;
        } catch (error) {
            logger.error(error);
            return [];
        }
    }

    private async getPurchasesWithStatusAsyncV1(
        purchaseType: string,
        query?: PaginatedQuery
    ): Promise<PaginatedResult<Purchase>> {
        try {
            type PaginatedPurchase = { purchases: unknown[]; lastEvaluatedKey: Map<string, unknown> };

            const queryStringParameters = this.mapPaginatedQueryToStringParametersV1({ purchaseType }, query);

            const path = query?.filterBy ? 'search?' : undefined;
            const json: PaginatedResponse = await this.getV1(path, { queryStringParameters });

            const { requestId, lastEvaluatedKey, result } = json;
            const purchases = result || ((json as unknown) as PaginatedPurchase).purchases;

            const items = purchases.map(mapToPurchase);

            return {
                items,
                lastEvaluatedKey,
                status: 200,
                requestId
            };
        } catch (error) {
            logger.error(error);
            return { items: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    private mapPaginatedQueryToStringParametersV1(target: RequestParams, source?: PaginatedQuery): RequestParams {
        if (source?.lastEvaluatedKey) {
            target.lastEvaluatedKey = JSON.stringify(source.lastEvaluatedKey);
        }

        if (source?.sortedBy) {
            target.sortedBy = source.sortedBy;
        }

        if (source?.pageSize) {
            target.pageSize = source.pageSize;
        }

        if (source?.filterBy) {
            target.filterBy = source.filterBy;
        }
        if (source?.report) {
            target.report = source.report;
        }

        return target;
    }

    public async getPurchaseOrdersAsyncV2(query?: PurchaseQueryV2): Promise<PurchaseResultV2<PurchaseIndex>> {
        return this.getPurchasesWithStatusAsyncV2('PURCHASE_ORDER', query);
    }

    public async getPurchaseRequestsAsyncV2(query?: PurchaseQueryV2): Promise<PurchaseResultV2<PurchaseIndex>> {
        return this.getPurchasesWithStatusAsyncV2('PURCHASE_REQUEST', query);
    }

    private async getPurchasesWithStatusAsyncV2(
        purchaseType: string,
        query?: PurchaseQueryV2
    ): Promise<PurchaseResultV2<PurchaseIndex>> {
        try {
            const queryStringParameters = this.mapPaginatedQueryToStringParametersV2(purchaseType, query || {});

            const path = query?.searchBy || query?.combination ? 'search?' : undefined;

            const response = await this.getV2(path, { queryStringParameters });

            const items = response.map(mapToPurchaseIndex);

            return {
                items,
                status: 200
            };
        } catch (error) {
            logger.error(error);
            return { items: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    private mapPaginatedQueryToStringParametersV2(purchaseType: string, source: PurchaseQueryV2): RequestParams {
        const mappedQuery = {} as RequestParams;

        Object.keys(source).forEach(key => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            mappedQuery[key] = (source as any)[key];
        });

        mappedQuery.purchaseType = purchaseType;
        mappedQuery.pageSize = source.pageSize ? source.pageSize : this.DEFAULT_PAGE_SIZE;
        mappedQuery.from = source.from ? source.from : this.DEFAULT_FROM;
        mappedQuery.report = source.report ? source.report : false;

        return mappedQuery;
    }
}

export const purchaseListService = new PurchaseListService();
