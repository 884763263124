/* eslint-disable react/prop-types */
import React from 'react';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { TableRow } from 'modules/core/model';
import { ExpandableCell } from 'modules/core/components/Table';
import styles from './purchaseToggleCell.module.scss';
import { Link } from '@amzn/awsui-components-react';

interface PurchaseToggleCellProps {
    row: TableRow<PurchaseIndex>;
    onChange?: (rowNumber: number) => void;
}

export const PurchaseToggleCell = ({ row, onChange }: PurchaseToggleCellProps) => {
    const mapToLineDescriptions = (row: TableRow<PurchaseIndex>) => (
        <>
            {row.payload.purchaseLines &&
                row.payload.purchaseLines.map(({ lineDescription }, index) => (
                    <React.Fragment key={index}>
                        <div className={styles.ellipsis}>{lineDescription}</div>
                    </React.Fragment>
                ))}
        </>
    );

    const { payload } = row;

    const id = payload.purchaseType === 'PURCHASE_REQUEST' ? payload.purchaseId : payload.orderNumber;

    return (
        <ExpandableCell
            row={row}
            control={true}
            cell={
                <span className="awsui-util-status-info mt-auto mb-auto">
                    <Link href={`${window.location.toString()}/${payload.purchaseId}`} target="_blank">
                        {id}
                    </Link>
                </span>
            }
            content={mapToLineDescriptions(row)}
            onChange={onChange}
        />
    );
};
