import { faker } from '@faker-js/faker';
import { TableRow } from 'modules/core/model/TableRow';
import { CustomField, CustomFieldType } from 'modules/customCategory/models';
import { COACombination } from 'modules/onboarding/model';

export const mockedCustomFieldsStringList = [
    faker.lorem.word({ length: 2 }).toUpperCase(),
    faker.lorem.word({ length: 3 }).toUpperCase(),
    faker.lorem.word({ length: 4 }).toUpperCase(),
    faker.lorem.word({ length: 5 }).toUpperCase()
];

export const mockCustomSelectionsMap = new Map([
    [
        faker.lorem.word({ length: 4 }).toUpperCase(),
        [
            faker.lorem.word({ length: 3 }).toUpperCase(),
            faker.lorem.word({ length: 4 }).toUpperCase(),
            faker.lorem.word({ length: 5 }).toUpperCase()
        ]
    ],
    [
        faker.lorem.word({ length: 4 }).toUpperCase(),
        [
            faker.lorem.word({ length: 3 }).toUpperCase(),
            faker.lorem.word({ length: 4 }).toUpperCase(),
            faker.lorem.word({ length: 5 }).toUpperCase()
        ]
    ]
]);

const coa: COACombination = {
    company: faker.lorem.word({ length: 4 }).toUpperCase(),
    location: faker.lorem.word({ length: 4 }).toUpperCase(),
    costCenter: faker.lorem.word({ length: 4 }).toUpperCase(),
    glAccount: faker.lorem.word({ length: 4 }).toUpperCase(),
    project: faker.lorem.word({ length: 4 }).toUpperCase(),
    productLine: faker.lorem.word({ length: 4 }).toUpperCase(),
    channel: faker.lorem.word({ length: 4 }).toUpperCase(),
    customEighthSegment: faker.lorem.word({ length: 4 }).toUpperCase(),
    teamName: '',
    filterId: ''
};

const nonRandom: COACombination = {
    company: '2D',
    location: '6086',
    costCenter: '4304',
    glAccount: '61200',
    project: 'NA',
    productLine: 'NA',
    channel: 'NA',
    customEighthSegment: 'NA',
    teamName: 'NA',
    filterId: 'NA'
};

const tableRow: TableRow<COACombination> = {
    number: 1,
    rowId: 1,
    selected: false,
    expanded: false,
    payload: coa,
    disableSelection: false
};

const nonnonRandomTableRow: TableRow<COACombination> = {
    number: 1,
    rowId: 1,
    selected: false,
    expanded: false,
    payload: nonRandom,
    disableSelection: false
};

export const mockCoaCombinationList = [tableRow, tableRow, tableRow];
export const mockTestCoaCombinationList = [nonnonRandomTableRow, nonnonRandomTableRow, nonnonRandomTableRow];

const key1 = 'key1';
const key2 = 'key2';
const val1 = 'val1';
const val2 = 'val2';
const val3 = 'val3';
const val4 = 'val4';

export const mockFreeTextField = Object.assign(new CustomField(), {
    fieldKey: key1,
    fieldValues: [val1, val2],
    fieldType: CustomFieldType.TEXT,
    optional: false,
    multiSelect: false
});

export const mockSelectionField = Object.assign(new CustomField(), {
    fieldKey: key2,
    fieldValues: [val3, val4],
    fieldType: CustomFieldType.SELECTION,
    optional: false,
    multiSelect: true
});
