import { ApiClient } from 'modules/core/class/api/ApiClient';
import { logger } from 'modules/core/logger';
import { ResourceResponse } from 'modules/core/model/response';
import { ApiError } from 'modules/core/types';
import { CoaDropdownOptions } from 'modules/mappings/models';

interface SegmentsResult {
    status?: number;
    requestId?: string;
    response?: CoaDropdownOptions;
}

export class CoaSegmentsService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'mappings';
    protected path = 'segments';

    public async getCoaSegments(): Promise<SegmentsResult> {
        try {
            const json: ResourceResponse = await this.get(this.path);

            const { result, requestId } = json;
            const mappingsResult = result as CoaDropdownOptions;
            return { requestId, response: mappingsResult, status: 200 };
        } catch (error) {
            logger.error(error);
            return { response: undefined, status: (error as ApiError)?.response?.status };
        }
    }
}

export const coaSegmentsService = new CoaSegmentsService();
