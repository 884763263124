/* eslint-disable @typescript-eslint/no-explicit-any */
import { YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';

import { UploadBudgetHeaders } from '../model';
import { logger } from 'modules/core/logger';
import { yearlySpendMapper } from '.';

export class YearlyAccountingForecastMapper {
    public mapToYearlyAccountingForecast(row: any): YearlyAccountingForecast {
        const yearlyAccountingForecast = new YearlyAccountingForecast();

        try {
            yearlyAccountingForecast.id = String(row[UploadBudgetHeaders.FORECAST_ID] || '-');
            yearlyAccountingForecast.coa1 = String(row[UploadBudgetHeaders.COMPANY_CODE]);
            yearlyAccountingForecast.coa2 = String(row[UploadBudgetHeaders.LOCATION_CODE]);
            yearlyAccountingForecast.coa3 = String(row[UploadBudgetHeaders.COST_CENTER]);
            yearlyAccountingForecast.coa4 = String(row[UploadBudgetHeaders.ACCOUNT_CODE]);
            yearlyAccountingForecast.coa5 = String(row[UploadBudgetHeaders.PRODUCT_LINE]);
            yearlyAccountingForecast.coa6 = String(row[UploadBudgetHeaders.CHANNEL_CODE]);
            yearlyAccountingForecast.coa7 = String(row[UploadBudgetHeaders.PROJECT_CODE]);
            yearlyAccountingForecast.coa8 = String(row[UploadBudgetHeaders.CUSTOM_SEGMENT]);
            yearlyAccountingForecast.comment = String(row[UploadBudgetHeaders.CUSTOM_SEGMENT]);
            yearlyAccountingForecast.owner = String(row[UploadBudgetHeaders.EXISTING_OWNER]);
            yearlyAccountingForecast.currency = String(row[UploadBudgetHeaders.CURRENCY]);
            yearlyAccountingForecast.team = String(row[UploadBudgetHeaders.TEAM_NAME]);

            yearlyAccountingForecast.year = this.toNumber(row[UploadBudgetHeaders.YEAR]) || new Date().getFullYear();

            const { coa1, coa2, coa3, coa4, coa5, coa6, coa7, coa8, team, year } = yearlyAccountingForecast;

            yearlyAccountingForecast.teamYear = `${team}${year}`;
            yearlyAccountingForecast.accountingString = `${coa1}-${coa2}-${coa3}-${coa4}-${coa5}-${coa6}-${coa7}-${coa8}`;

            yearlySpendMapper.setYearlySpend(row, yearlyAccountingForecast);

            logger.info(
                `Created YearlyAccountingForecast with values ${JSON.stringify(
                    yearlyAccountingForecast
                )} from input row ${JSON.stringify(row)}`
            );
        } catch (error) {
            logger.error(error);
        }

        return yearlyAccountingForecast;
    }

    private toNumber(value: string | undefined): number | undefined {
        if (value === undefined || value === null) return undefined;

        const convertedValue = Number(value.toString().replace(/[^0-9\.]+/g, ''));

        return parseFloat(convertedValue.toFixed(2));
    }
}

export const yearlyAccountingForecastMapper = new YearlyAccountingForecastMapper();
