import React, { useEffect } from 'react';
import { Input, NonCancelableCustomEvent, InputProps } from '@amzn/awsui-components-react';

import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { FormSchema } from 'modules/purchase/models';
import styles from './ChannelAllocationInput.module.scss';

interface ChannelAllocationInputProps {
    channelIndex: number;
    channelAllocation: ChannelSpend;
    onChannelAllocationChange?: (channelIndex: number, amount: number) => void;
    onModalChannelAllocationChange?: (channelIndex: number, amount: number) => void;
    updateSaveButton?: (formState: boolean) => void;
}

export const ChannelAllocationInput = (props: ChannelAllocationInputProps) => {
    const {
        channelAllocation,
        channelIndex,
        onChannelAllocationChange,
        onModalChannelAllocationChange,
        updateSaveButton
    } = props;

    const { register, setValue, formState, errors } = FormSchema.getChannelAllocationUseForm();
    const [inputText, setInputText] = React.useState(channelAllocation.amount.toString());

    useEffect(() => {
        register({ name: 'allocationAmount', type: 'custom' });
        setValue('allocationAmount', channelAllocation.amount, true);
    }, [register]);

    function onAllocationUpdate({ detail: { value } }: NonCancelableCustomEvent<InputProps.ChangeDetail>): void {
        setInputText(value);

        const inputAmount = value.trim();
        setValue('allocationAmount', Number(inputAmount), true);

        if (formState.isValid) {
            if (onChannelAllocationChange) {
                onChannelAllocationChange(channelIndex, parseFloat(inputAmount));
            }

            if (onModalChannelAllocationChange) {
                onModalChannelAllocationChange(channelIndex, parseFloat(inputAmount));
            }
        }

        if (updateSaveButton) {
            updateSaveButton(formState.isValid);
        }
    }

    return (
        <>
            <Input
                onChange={onAllocationUpdate}
                value={inputText}
                className={`awsui-grid ${styles['channel-allocation-input-cell']}`}
                invalid={!formState.isValid}
            />
            <span className={'awsui-util-status-negative awsui-util-font-size-0'}>
                {errors?.allocationAmount?.message}
            </span>
        </>
    );
};
