import { PurchaseLine } from '.';
import { Receipt } from './Receipt';
import { UpdateReceiptsReason, UpdateMotive } from './UpdateReceiptsReason';

export class UpdateReceiptsRequest {
    public lineNumber: number;
    public uncommittedBalance: number;
    public versionCategory: UpdateMotive;
    public versionDescription?: string;
    public receipts: Receipt[];

    constructor(
        isQuantityBased: boolean,
        { lineNumber, receipts, uncommittedBalance }: PurchaseLine,
        { motive, explanation }: UpdateReceiptsReason
    ) {
        if (!lineNumber || !motive) {
            throw new Error('Invalid parameters for update Reason');
        }
        this.lineNumber = lineNumber;
        const filteredReceipts = isQuantityBased
            ? receipts
            : receipts.map(receipt => {
                  const allocations = receipt.channelAllocations.filter(alloc => alloc.amount > 0);
                  receipt.channelAllocations = allocations;
                  return receipt;
              });

        filteredReceipts.forEach(r => (r.confirmStatus = r.requiresConfirmation));

        this.receipts = filteredReceipts;
        this.uncommittedBalance = uncommittedBalance;
        this.versionCategory = motive;
        this.versionDescription = explanation;
    }
}
