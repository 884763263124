import { LineVersion, VersionType } from '../models';

export interface VersionEvent {
    type: VersionType;
    lineVersions: LineVersion[];
    orderId: string;
}

const aggregatedVersions = [VersionType.CONFIRM_PROCESS, VersionType.BIG_OBJECTIVE_UPDATE, VersionType.PO_SOFT_CLOSED];

export function aggregateLineVersions(lineVersions: LineVersion[]) {
    const events: VersionEvent[] = [];
    let currVersion = lineVersions.shift();

    while (currVersion) {
        if (!aggregatedVersions.includes(currVersion.versionType)) {
            events.push({
                type: currVersion.versionType,
                lineVersions: [currVersion],
                orderId: currVersion.orderId || ''
            });
            currVersion = lineVersions.shift();
            continue;
        }

        const { event, nextVersion } = buildAggregatedEvent(currVersion, lineVersions);
        events.push(event);
        currVersion = nextVersion;
    }
    return events;
}

function buildAggregatedEvent(currentVersion: LineVersion, versions: LineVersion[]) {
    const versionType = currentVersion.versionType;
    const event = {
        type: versionType,
        lineVersions: [currentVersion],
        orderId: currentVersion.orderId || ''
    };
    let nextVersion = versions.shift();

    while (versionType === nextVersion?.versionType) {
        event.lineVersions.push(nextVersion);
        nextVersion = versions.shift();
    }

    return { event, nextVersion };
}
