import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Router, navigate } from '@reach/router';

import { AppRoutes } from 'modules/app/routes';
import { updateUser } from 'modules/app/store';
import { Auth } from 'modules/auth';
import { Error } from 'modules/error/views';
import { authService } from 'modules/auth/services/auth.service';
import { logger } from 'modules/core';

export const MainRoutes = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        (async function() {
            logger.debug('Authentication guard invoked');
            const claims = await authService.getUserClaimsAsync();
            if (claims) {
                dispatch(updateUser(claims));
            } else {
                navigate('/auth');
            }
        })();
    }, [dispatch]);

    return (
        <Router>
            <AppRoutes path="/*" />
            <Auth path="auth" />
            <Error message="error.urlError" btnContent="error.urlBtn" default />
        </Router>
    );
};
