import { ApiClient } from 'modules/core/class/api/ApiClient';
import { logger } from 'modules/core/logger';
import { ResourceResponse } from 'modules/core/model/response';
import { ApiError } from 'modules/core/types';
import { BigOChannelMapping } from 'modules/mappings/models';

interface BigOChannelMappingsResult {
    status?: number;
    requestId?: string;
    response?: BigOChannelMapping[];
}

interface MappingUpdateResult {
    result: boolean;
    requestId?: string;
    message?: string;
}

export class BigOChannelMappingsService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'mappings';
    protected path = 'channelBigO';

    public async getMappings(): Promise<BigOChannelMappingsResult> {
        try {
            const json: ResourceResponse = await this.get(this.path);

            const { result, requestId } = json;
            const mappingsResult = result as BigOChannelMapping[];
            return { requestId, response: mappingsResult, status: 200 };
        } catch (error) {
            logger.error(error);
            return { response: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    public async updateMappings(channelBigOMappings: BigOChannelMapping[]): Promise<MappingUpdateResult> {
        try {
            const body = { channelBigOMappings };
            const result = await this.put(this.path, { body });

            return result;
        } catch (error) {
            logger.error(error);
            return { result: false, message: 'Unable to update mappings' };
        }
    }

    public async createMapping(newBigOMapping: BigOChannelMapping): Promise<MappingUpdateResult> {
        try {
            const body = newBigOMapping;
            const { result, message } = await this.post(this.path, { body });

            return { result, message };
        } catch (error) {
            logger.error(error);
            return { result: false, message: 'Unable to creating mapping' };
        }
    }

    public async deleteMapping(mappingId: string): Promise<MappingUpdateResult> {
        try {
            const result = await this.del(`${this.path}?id=${mappingId}`);

            return result;
        } catch (error) {
            logger.error(error);
            return { result: false, message: 'Unable to deleting mapping' };
        }
    }
}

export const bigOChannelMappingsService = new BigOChannelMappingsService();
