import { CoupaPurchaseType } from '@amzn/merp-core/models';
import { mapToLabelAndOption, mapToOption } from '../mappers';
import { ReportName } from 'modules/reports/constants';

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const BULK_EDIT_TAB = 'Edit Tab';
export const ABPO_BULK_EDIT_TAB = 'ABPO Lines';
export const QBPO_BULK_EDIT_TAB = 'QBPO Lines';
export const EDIT_TAB_SHEET_NAMES = [BULK_EDIT_TAB];

export const SPEND_MISSING_MANDATORY_FIELDS = 'Spend mandatory fields are incomplete';
export const INVOICE_MISSING_MANDATORY_FIELDS = 'Spend mandatory fields are incomplete';
export const BULK_RECEIVING_SPEND_HEADER = 'Receiving amount (Spend)';
export const QBPO_BULK_RECEIVING_SPEND_HEADER = 'Receiving Quantity (Spend)';
const BULK_EDIT_SPEND_CHANNEL_HEADER = 'Channel (Select from Channel Selections Tab)';

export const MAX_BULK_RECEIVING_ROWS = 1000;

const BULK_BASE_SPEND_MANDATORY_FIELDS = ['PO Number (DO NOT EDIT)', 'PO Line Number (DO NOT EDIT)'];
const BULK_RECEIVING_SPEND_MANDATORY_FIELDS = [...BULK_BASE_SPEND_MANDATORY_FIELDS, BULK_RECEIVING_SPEND_HEADER];
const QBPO_BULK_RECEIVING_SPEND_MANDATORY_FIELDS = [
    ...BULK_BASE_SPEND_MANDATORY_FIELDS,
    QBPO_BULK_RECEIVING_SPEND_HEADER
];

const BULK_EDIT_SPEND_MANDATORY_FIELDS = [...BULK_BASE_SPEND_MANDATORY_FIELDS, BULK_EDIT_SPEND_CHANNEL_HEADER];

export const EDITABLE_SHEET_NAMES = [ABPO_BULK_EDIT_TAB, QBPO_BULK_EDIT_TAB, BULK_EDIT_TAB];
export type EditableSheetName = typeof ABPO_BULK_EDIT_TAB | typeof QBPO_BULK_EDIT_TAB | typeof BULK_EDIT_TAB;
export function GET_MANDATORY_FIELDS(name: BulkUploadReportName, sheetName: EditableSheetName): string[] {
    return (
        {
            [ReportName.BulkReceivingSpendUpload]: {
                [ABPO_BULK_EDIT_TAB]: BULK_RECEIVING_SPEND_MANDATORY_FIELDS,
                [QBPO_BULK_EDIT_TAB]: QBPO_BULK_RECEIVING_SPEND_MANDATORY_FIELDS
            },
            [ReportName.BulkEditSpendUpload]: {
                [BULK_EDIT_TAB]: BULK_EDIT_SPEND_MANDATORY_FIELDS
            }
        }[name][sheetName] || []
    );
}

export const FILE_READ_ERROR = 'Could not read excel file';
export const NO_VALID_TAB_FOUND_ERROR = 'Could not find any valid sheets';

export type BulkUploadReportName = ReportName.BulkEditSpendUpload | ReportName.BulkReceivingSpendUpload;

export const ALTERNATIVE_BULK_UPLOAD_REPORT_NAME: Record<BulkUploadReportName, BulkUploadReportName> = {
    [ReportName.BulkEditSpendUpload]: ReportName.BulkReceivingSpendUpload,
    [ReportName.BulkReceivingSpendUpload]: ReportName.BulkEditSpendUpload
};

const FRIENDLY_BULK_UPLOAD_REPORT_NAME: Record<BulkUploadReportName, string> = {
    [ReportName.BulkEditSpendUpload]: 'Bulk Upload',
    [ReportName.BulkReceivingSpendUpload]: 'Bulk Receiving'
};

const BULK_UPLOAD_FEATURE_NAME: Record<BulkUploadReportName, string> = {
    [ReportName.BulkEditSpendUpload]: 'Purchase Order dashboard',
    [ReportName.BulkReceivingSpendUpload]: 'Bulk Receiving dashboard'
};

export function INCORRECT_PLACE_TO_UPLOAD(name: BulkUploadReportName) {
    const userFriendlyName = FRIENDLY_BULK_UPLOAD_REPORT_NAME[name];
    const feature = BULK_UPLOAD_FEATURE_NAME[name];
    return `Oops! Looks like you have a ${userFriendlyName} workbook. Please use the ${feature} to upload.`;
}

const receivingStatuses = new Map<string, string>()
    .set('Not Received', 'no_receiving')
    .set('Partially Received', 'partially_received')
    .set('Fully Received', 'fully_received');

export const ReceivingStatusOptions = Array.from(receivingStatuses.entries())
    .map(value => mapToLabelAndOption(value[0], value[1]))
    .sort();

const invoiceStatuses = new Map<string, string>()
    .set('Not Invoiced', 'no_invoices')
    .set('Partially Invoiced', 'partially_invoiced')
    .set('Fully Invoiced', 'fully_invoiced');

export const InvoicedStatusOptions = Array.from(invoiceStatuses.entries())
    .map(value => mapToLabelAndOption(value[0], value[1]))
    .sort();

const lineTypes = [CoupaPurchaseType.AMOUNT_BASED_LINE, CoupaPurchaseType.QUANTITY_BASED_LINE];

export const LineTypeOptions = lineTypes.map(mapToOption);

export const ENTER_KEY_CODE = 13;

export const INDIVIDUAL_EDITS_LIMIT = 60;
export const TABLE_PAGE_SIZE = 60;
export const NUMBER_OF_ROWS_ABLE_TO_SELECT = 60;
export const FILTERING_DELIMITER = '|||';
