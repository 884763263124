export const automatedMarketingChannelOptions = [
    'paidSearch',
    'paidSearchGoogleYouTube',
    'socialAutomated',
    'socialAutomatedFacebookInstagram',
    'associates',
    'otherAutomated',
    'otherNon-Media'
];

export const fixedMarketingChannelOptions = [
    'tv',
    'polv',
    'radio',
    'print',
    'digital',
    'cinema',
    'socialFixed',
    'ooh',
    'sponsorships',
    'access',
    'mediaFee',
    'otherMedia',
    'fixedMediaProductionExpense',
    'otherNon-Media'
];

export const nonMarketingChannelOptions = ['notApplicable'];

export const channelList = [
    ...new Set([...automatedMarketingChannelOptions, ...fixedMarketingChannelOptions, ...nonMarketingChannelOptions])
];
