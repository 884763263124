import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrgCostCentersDetails, OrgMapping } from 'modules/mappings/models';
import { ApplicationState } from '..';

export interface OrgCostCentersMapProps {
    costCentersDetails: OrgCostCentersDetails[];
}

const initialState: OrgCostCentersMapProps = {
    costCentersDetails: []
};

export interface initializeCostCenterInput {
    unassignedCostCenters: string[];
    mappings?: OrgMapping[];
}

const orgCostCentersSlice = createSlice({
    name: 'orgCostCenters',
    initialState,
    reducers: {
        initializeCostCenterList(state, action: PayloadAction<initializeCostCenterInput>) {
            (action.payload.mappings || []).forEach(orgMapping =>
                orgMapping.costCenters.forEach(costCenter => {
                    state.costCentersDetails.push(new OrgCostCentersDetails(costCenter, orgMapping.orgName));
                })
            );
            action.payload.unassignedCostCenters.forEach(costCenter => {
                state.costCentersDetails.push(new OrgCostCentersDetails(costCenter));
            });
        },
        updateCostCenterMapping(state, action: PayloadAction<OrgMapping>) {
            const costCenters = action.payload.costCenters;

            costCenters.forEach(costCenter => {
                const costCenterIndex = state.costCentersDetails.findIndex(
                    detail => detail.costCenterName === costCenter
                );
                state.costCentersDetails[costCenterIndex].mappedOrg = action.payload.orgName;
            });
        },
        removeCostCenterMapping(state, action: PayloadAction<string>) {
            const removedCC = action.payload;

            const costCenterIndex = state.costCentersDetails.findIndex(detail => detail.costCenterName === removedCC);
            state.costCentersDetails[costCenterIndex].mappedOrg = undefined;
        }
    }
});

const getCostCentersList = (state: ApplicationState) =>
    state.orgCostCenters.costCentersDetails.map(details => details.costCenterName);

const { actions, reducer } = orgCostCentersSlice;
const { initializeCostCenterList, updateCostCenterMapping, removeCostCenterMapping } = actions;
export {
    initializeCostCenterList,
    updateCostCenterMapping,
    removeCostCenterMapping,
    getCostCentersList,
    reducer as orgCostCentersReducer
};
