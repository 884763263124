import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { logger } from 'modules/core';
import { showErrorPage } from 'modules/app/store';
import { Team } from 'modules/onboarding/model';
import { TableRow } from 'modules/core/model';
import { mapToTableRow } from 'modules/onboarding/mappers';
import { GetTeamsRequest, onboardingService } from 'modules/onboarding/services';

export const useTeams = () => {
    const [teams, setTeams] = useState<TableRow<Team>[]>([]);
    const [teamName, setTeamName] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchTeams = async (): Promise<void> => {
        try {
            await getTeams();
        } catch (error) {
            logger.error(error);
        }
    };

    const getTeams = async () => {
        const getTeamsRequest: GetTeamsRequest = makeGetTeamsRequest();

        const result = await onboardingService.getTeamsAsync(getTeamsRequest);

        if (result.teams == undefined) {
            logger.error('result.teams is undefined');
            dispatch(showErrorPage({ errorCode: result.status }));
        }

        const fetchedTeams = result.teams?.map(mapToTableRow);

        if (fetchedTeams == undefined) {
            logger.error('could not convert teams to TableRow');
        }

        setTeams(fetchedTeams ?? []);
        setLoading(false);
    };

    const makeGetTeamsRequest = (): GetTeamsRequest => {
        const getTeamsRequest: GetTeamsRequest = {};

        const trimmedTeamName = teamName.trim();

        if (trimmedTeamName != '') {
            getTeamsRequest.teamName = trimmedTeamName;
        }

        return getTeamsRequest;
    };

    useEffect(() => {
        setLoading(true);
        fetchTeams();
    }, [teamName]);

    return {
        teams,
        setTeams,
        setTeamName,
        loading,
        setLoading,
        fetchTeams
    };
};
