import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { ReportResult } from 'modules/reports/model';
import { ReportReqBody } from 'modules/reports/constants';
import { ApiClient } from 'modules/core/class/api/ApiClient';

export class ReportsService extends ApiClient {
    protected resource = 'reports';
    protected apiName = 'MERP_REPORTS';

    public async createReportAsync(body: ReportReqBody, path?: string): Promise<ReportResult> {
        try {
            const payload = await this.put(path, { body });

            return { report: payload.result, succeeded: true };
        } catch (error) {
            const apiError = error as ApiError;
            const errorMessage = apiError?.response?.data?.error ?? apiError.message ?? 'Invalid response';
            logger.error(errorMessage);

            return { succeeded: false, error: errorMessage };
        }
    }
}

export const reportsService = new ReportsService();
