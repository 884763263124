import { OwnerAction } from './OwnerAction';

export class MspOwner {
    public ownerLogin: string;
    public requestedBy: string;
    public action: OwnerAction;

    constructor(alias: string, requestedBy: string, action: OwnerAction) {
        if (!alias || !requestedBy || !action) {
            throw new Error('Invalid parameters for Owner Request');
        }

        this.ownerLogin = alias;
        this.requestedBy = requestedBy;
        this.action = action;
    }
}
