import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Purchase, PurchaseLine, PurchaseSubmitStatus, Receipt } from 'modules/purchase/models';
import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { InvoiceLine } from 'modules/invoices/models';
import { ApplicationState } from '../index';
import { CurrencyCode } from 'modules/core/model';
import { NON_MARKETING_TEAMS, XCM_TEAM } from '../../../core/constants';
import BigNumber from 'bignumber.js';
import { CoupaPurchaseLineType } from '@amzn/merp-core/models';
import { CustomField, CustomFieldType } from 'modules/customCategory/models';

export interface PurchaseProps {
    purchase: Purchase | undefined;
    purchaseSubmitted: PurchaseSubmitStatus;
    invoiceLines: {
        [lineNumber: number]: InvoiceLine[];
    };
    userEditStatus: boolean;
    userSoftCloseAccess: boolean;
}

interface ReceiptProps {
    lineNumber: number;
    receipts: Receipt[];
}

interface CustomFieldProps {
    customFields: CustomField[];
}

interface InvoiceLinesProps {
    lineNumber: number;
    invoiceLines?: InvoiceLine[];
    invoiceLine?: InvoiceLine;
}

interface ChannelProps {
    lineNumber: number;
    channelAllocations: ChannelSpend[][];
}

const initialState: PurchaseProps = {
    purchase: undefined,
    invoiceLines: {},
    purchaseSubmitted: PurchaseSubmitStatus.NOT_SUBMITTED,
    userEditStatus: false,
    userSoftCloseAccess: false
};

const purchaseSlice = createSlice({
    name: 'purchases',
    initialState,
    reducers: {
        updatePurchase(state, action: PayloadAction<Purchase | undefined>) {
            Object.assign(state, { purchase: action.payload });
        },
        updateLine(state, action: PayloadAction<PurchaseLine>) {
            const { lineNumber, receipts, amount } = action.payload;
            if (!state.purchase) {
                return;
            }
            const lineIndex = state.purchase.lines.findIndex(line => line.lineNumber === lineNumber);
            state.purchase.lines[lineIndex] = action.payload;
            state.purchase.lines[lineIndex].uncommittedBalance = updateUncomittedBalance(receipts, amount);
        },
        updateReceipts(state, action: PayloadAction<ReceiptProps>) {
            const { lineNumber, receipts } = action.payload;
            if (state.purchase?.lines) {
                const lineIndex = state.purchase.lines.findIndex(line => line.lineNumber === lineNumber);
                state.purchase.lines[lineIndex].receipts = receipts;
            }
            state.purchase = Object.assign(new Purchase(), state.purchase);
        },
        updateFreeTextFields(state, action: PayloadAction<CustomFieldProps>) {
            const { customFields } = action.payload;

            if (state.purchase) {
                state.purchase.customFields = customFields.filter(field => {
                    return CustomFieldType.TEXT === field.fieldType;
                });
                state.purchase = Object.assign(new Purchase(), state.purchase);
            }
        },
        updateSelectionFields(state, action: PayloadAction<CustomFieldProps>) {
            const { customFields } = action.payload;

            if (state.purchase) {
                state.purchase.customSelections = customFields.filter(field => {
                    return CustomFieldType.SELECTION === field.fieldType;
                });
                state.purchase = Object.assign(new Purchase(), state.purchase);
            }
        },
        updateOwnersList(state, action: PayloadAction<Array<string>>) {
            if (state.purchase) {
                state.purchase.mspOwner = action.payload;
            }
        },
        updateBigObjective(state, action: PayloadAction<string>) {
            if (state.purchase) {
                state.purchase.bigRockObjective = action.payload;
            }
        },
        updateInvoiceLines(state, action: PayloadAction<InvoiceLinesProps>) {
            const { lineNumber, invoiceLines } = action.payload;
            if (invoiceLines) {
                state.invoiceLines[lineNumber] = invoiceLines;
            }
        },
        updateInvoiceLine(state, action: PayloadAction<InvoiceLinesProps>) {
            const { lineNumber, invoiceLine } = action.payload;
            if (!state.invoiceLines[lineNumber]) {
                return;
            }

            const invoiceLines = state.invoiceLines[lineNumber];

            const updatedInvoiceLines = invoiceLines.map(line => (line.id === invoiceLine?.id ? invoiceLine : line));
            state.invoiceLines[lineNumber] = updatedInvoiceLines;
        },
        updateChannels(state, action: PayloadAction<ChannelProps>) {
            const { lineNumber, channelAllocations } = action.payload;
            if (state.purchase) {
                const lineIndex = state.purchase.lines.findIndex(line => line.lineNumber === lineNumber);
                const { receipts } = state.purchase.lines[lineIndex];
                receipts.forEach((receipt, index) => {
                    receipt.channelAllocations = channelAllocations[index];
                });
            }
        },
        updateSubmitStatus(state, action: PayloadAction<boolean>) {
            state.purchaseSubmitted = action.payload
                ? PurchaseSubmitStatus.SUBMIT_SUCCESS
                : PurchaseSubmitStatus.SUBMIT_FAILURE;
        },
        resetSubmitStatus(state) {
            state.purchaseSubmitted = PurchaseSubmitStatus.NOT_SUBMITTED;
        },
        updateUserEditStatus(state, action: PayloadAction<boolean>) {
            state.userEditStatus = action.payload;
        },
        updateUserSoftCloseAccess(state, action: PayloadAction<boolean>) {
            state.userSoftCloseAccess = action.payload;
        }
    }
});

const updateUncomittedBalance = (receipts: Receipt[], lineAmount: number) => {
    const allocatedAmount = receipts
        .map(receipt => receipt.receiptAmount)
        .reduce((partialSum, currentValue) => {
            return new BigNumber(partialSum).plus(new BigNumber(currentValue));
        }, new BigNumber(0));

    return new BigNumber(lineAmount).minus(allocatedAmount).toNumber();
};

const isQuantityBasedLine = (purchaseLine: PurchaseLine) => {
    return purchaseLine.lineType
        ? [CoupaPurchaseLineType.ORDER_QUANTITY_LINE, CoupaPurchaseLineType.REQUEST_QUANTITY_LINE].includes(
              purchaseLine.lineType
          )
        : false;
};
const getLine = (state: PurchaseProps, lineNumber: number) =>
    state.purchase?.lines.find(line => line.lineNumber === lineNumber) as PurchaseLine;

const getLines = (state: PurchaseProps, fromLineNumber: number, toLineNumber: number) =>
    state.purchase?.lines.slice(fromLineNumber, toLineNumber) as PurchaseLine[];

const getDefaultChannelTypeForLine = (state: PurchaseProps, lineNumber: number) =>
    state.purchase?.lines.find(line => line.lineNumber === lineNumber)?.defaultChannelType as string;

const getCanDisableEdit = (state: PurchaseProps) => state.purchase?.canDisableEdit || false;

const getBigObjective = (state: ApplicationState) => state.purchaseDetails.purchase?.bigRockObjective || '';

const getLinesWithLineNumberOnly = (state: ApplicationState) =>
    state.purchaseDetails.purchase?.lines.map(line => line.lineNumber) || [];

const hasBeenApprovedByMsp = (state: ApplicationState) => state.purchaseDetails.purchase?.isPrMspApproved;

const isRequisition = (state: ApplicationState) => state.purchaseDetails.purchase?.isPurchaseRequest || false;

const canPurchaseBeConfirmed = (state: ApplicationState) =>
    state.purchaseDetails.purchase?.canPurchaseBeConfirmed || false;

const getOwners = (state: ApplicationState) => state.purchaseDetails.purchase?.mspOwner || [];

const getPurchaseId = (state: ApplicationState) => state.purchaseDetails.purchase?.purchaseId || '';

const getCreatedBy = (state: ApplicationState) => state.purchaseDetails.purchase?.createdBy;

const getRequestedBy = (state: ApplicationState) => state.purchaseDetails.purchase?.requestedBy;

const getAllInvoiceLines = (state: ApplicationState) => state.purchaseDetails.invoiceLines || [];

const getInvoiceLines = (state: ApplicationState, lineNumber: number) =>
    state.purchaseDetails.invoiceLines[lineNumber] || [];

const getPurchaseSubmitStatus = (state: ApplicationState) =>
    state.purchaseDetails?.purchaseSubmitted || PurchaseSubmitStatus.NOT_SUBMITTED;

const getUserEditStatus = (state: ApplicationState) => state.purchaseDetails?.userEditStatus ?? false;

const getUserSoftCloseAccess = (state: ApplicationState) => state.purchaseDetails?.userSoftCloseAccess ?? false;

const getCurrencyCode = (state: ApplicationState, lineNumber: number) =>
    state.purchaseDetails.purchase?.lines.find(line => line.lineNumber === lineNumber)?.currencyCode ||
    CurrencyCode.USD;

const getReceipts = (state: ApplicationState, lineNumber: number) =>
    state.purchaseDetails.purchase?.lines.find(line => line.lineNumber === lineNumber)?.receipts || [];

const isNonMarketing = (state: ApplicationState) => {
    let isNonMarketing = false;
    NON_MARKETING_TEAMS.forEach(team => {
        isNonMarketing = state.purchaseDetails.purchase?.creatorGroup?.includes(team) || isNonMarketing;
    });

    return isNonMarketing;
};

const isXcm = (state: ApplicationState) => {
    const isXcm = state.purchaseDetails.purchase?.creatorGroup?.includes(XCM_TEAM) || false;

    return isXcm;
};

const { actions, reducer } = purchaseSlice;

const {
    updateLine,
    updateChannels,
    updatePurchase,
    updateReceipts,
    updateFreeTextFields,
    updateSelectionFields,
    updateOwnersList,
    updateInvoiceLine,
    resetSubmitStatus,
    updateBigObjective,
    updateInvoiceLines,
    updateSubmitStatus,
    updateUserEditStatus,
    updateUserSoftCloseAccess
} = actions;

export {
    updateLine,
    updateChannels,
    updateReceipts,
    updateFreeTextFields,
    updateSelectionFields,
    updatePurchase,
    updateOwnersList,
    resetSubmitStatus,
    updateInvoiceLine,
    updateBigObjective,
    updateInvoiceLines,
    updateSubmitStatus,
    updateUserEditStatus,
    updateUserSoftCloseAccess,
    isXcm,
    getLine,
    getLines,
    getOwners,
    getReceipts,
    getCreatedBy,
    isRequisition,
    getPurchaseId,
    getRequestedBy,
    isNonMarketing,
    getBigObjective,
    getInvoiceLines,
    getCurrencyCode,
    getUserEditStatus,
    getUserSoftCloseAccess,
    getCanDisableEdit,
    getAllInvoiceLines,
    hasBeenApprovedByMsp,
    canPurchaseBeConfirmed,
    getPurchaseSubmitStatus,
    getLinesWithLineNumberOnly,
    reducer as purchaseReducer,
    getDefaultChannelTypeForLine,
    isQuantityBasedLine
};
