import { logger } from '../logger';
import { TFunction } from 'i18next';

export class I18nFormatter {
    public static amount(t: TFunction<string, string>, amount = 0): string {
        try {
            return t('{{amount, intlAmount}}', { amount });
        } catch (error) {
            logger.error(error);
            return '-';
        }
    }

    public static quantity(t: TFunction<string, string>, quantity = 0): string {
        try {
            return t('{{quantity, intlQuantity}}', { quantity });
        } catch (error) {
            logger.error(error);
            return '-';
        }
    }

    public static date(t: TFunction<string, string>, date?: number): string {
        if (!date) return '-';
        try {
            return t('{{date, intlDate}}', { date });
        } catch (error) {
            logger.error(error);
            return 'Unknown';
        }
    }
}
