import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TokenGroup } from '@amzn/awsui-components-react';

import { ApplicationState } from 'modules/app/store';

export function GroupCard(): JSX.Element {
    const user = useSelector((state: ApplicationState) => state.user);

    const { t } = useTranslation('app');

    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>{t('permissions')}</h2>
            </div>
            <div>
                <h3>{t('posixGroups')}:</h3>
                <TokenGroup
                    data-cy="posixTokenGroup"
                    items={user.posix?.map((label: string) => ({ label, iconName: 'key' }))}
                    onDismiss={() => undefined}
                />
            </div>
            <div>
                <h3>{t('LDAPGroups')}:</h3>
                <TokenGroup
                    data-cy="ldapTokenGroup"
                    items={user.ldap?.map((label: string) => ({ label, iconName: 'key' }))}
                    onDismiss={() => undefined}
                />
            </div>
        </div>
    );
}
