import React from 'react';

import { ColumnLayout } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { BulkUploadAccountingStrings, DownloadAccountingStrings } from 'modules/accountingStrings/components';
import { useReports } from 'modules/reports/hooks';

export const BulkEditAccountingStrings = () => {
    const { t } = useTranslation('bulkEdit');
    const { createInProgress, createReportAsync, uploadInProgress, uploadReportAsync, report } = useReports();

    return (
        <>
            <div>
                <h1>{t('bulkEditCoa')}</h1>
            </div>
            <br />
            <ColumnLayout columns={2}>
                <div>
                    <h3>{t('bulkEditCoaDownload')}</h3>
                    <br />
                    <DownloadAccountingStrings
                        createInProgress={createInProgress}
                        createReportAsync={createReportAsync}
                        report={report}
                    />
                </div>
                <div>
                    <h3>{t('bulkEditCoaUpload')}</h3>
                    <br />
                    <BulkUploadAccountingStrings
                        uploadInProgress={uploadInProgress}
                        uploadReportAsync={uploadReportAsync}
                        report={report}
                    />
                </div>
            </ColumnLayout>
        </>
    );
};
