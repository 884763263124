import { MonthAbbreviation } from 'modules/core/types';

export const AbbreviatedMonths: MonthAbbreviation[] = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
];

export const RollUpTypes = {
    BigObjective: 'BigObjective',
    Channel: 'Channel',
    SMARTe: 'SMARTe'
};
