/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, Router } from '@reach/router';

import { BudgetDashboard, RequestChange, FinancialAnalystApproving, AssignOwner } from '../views';
import { isFinancialAnalyst, resetErrorPage, showErrorPage } from 'modules/app/store';

export function BudgetRoutes(props: RouteComponentProps): JSX.Element {
    const { location } = props;

    const dispatch = useDispatch();
    const isFA = useSelector(isFinancialAnalyst);

    const Routes = {
        Budget: 'budget',
        Owners: '/assignOwners',
        RequestChange: '/requestChanges/:budgetId',
        Plan: '/plan/:entityId'
    };

    useEffect(() => {
        if (location?.pathname !== '/budget' && !isFA) {
            dispatch(showErrorPage({ errorCode: 401 }));
        } else {
            dispatch(resetErrorPage());
        }
    }, [location?.pathname, isFA]);

    return (
        <Router>
            <BudgetDashboard path={Routes.Budget} default />
            <AssignOwner path={Routes.Owners} />
            <RequestChange path={Routes.RequestChange} />
            <FinancialAnalystApproving path={Routes.Plan} />
        </Router>
    );
}
