import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserPreferences } from 'modules/preferences/model';

const initialState: UserPreferences = {};

const preferencesSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        updatePreferences(state, action: PayloadAction<UserPreferences>) {
            Object.assign(state, action.payload);
        }
    }
});

const { actions, reducer } = preferencesSlice;

const { updatePreferences } = actions;

export { updatePreferences, reducer as userPreferencesReducer };
