import React from 'react';
import { CurrencyCode } from 'modules/core/model';
import { PurchaseLineStatus, PurchaseStatus, ReceiptStatus } from 'modules/purchase/models';
import { RenderWithStore } from 'modules/core/components/RenderWithStore';
import { InvoiceMatchedVersion } from './InvoiceMatchedVersion';
import { withKnobs, object } from '@storybook/addon-knobs';
import { LineVersion, ReceiptVersion, VersionType } from 'modules/audit/models';

export default {
    component: InvoiceMatchedVersion,
    title: 'Audit/components/InvoiceMatchedVersion/InvoiceMatchedVersion',
    decorators: [withKnobs]
};

const mockedReceiptVersion: ReceiptVersion = {
    receiptNumber: '12e42d23',
    purchaseId: '3512109',
    receiptVersion: 111,
    originalReceiptAmount: 20000.0,
    modifiedReceiptAmount: 178300.0,
    originalReceiptTime: 21,
    modifiedReceiptTime: 21,
    receiptStatus: ReceiptStatus.PENDING_POST,
    lineNumber: 1,
    versionCategory: 'Actual spend below planned',
    versionDescription: 'We ordered the plan for 30 days but only used 24 days'
};

const mockedLineVersion = {
    purchaseId: '3512121',
    versionType: VersionType.INVOICE_ADDED,
    lineVersion: 0,
    lineNumber: 1,
    lineDescription: 'AQI.FIRE.MONITOR - Smart Sense AQI monitor for 6 months',
    isConfirmProcess: false,
    isLineAmountChanged: true,
    changedLineAmount: -180000.0,
    updaterLogin: 'weiyunh',
    updaterFirstName: 'Weiyun',
    updaterLastName: 'Hua',
    currencyCode: CurrencyCode.USD,
    purchaseLineStatus: PurchaseLineStatus.DELETED,
    orderStatus: PurchaseStatus.ISSUED,
    isFirst: false,
    receiptVersions: [mockedReceiptVersion, mockedReceiptVersion, mockedReceiptVersion],
    originalUncommittedBalance: 0,
    modifiedUncommittedBalance: 10
};

function getLineVersions(overwrites?: LineVersion) {
    const lv1 = Object.assign(new LineVersion(), mockedLineVersion, overwrites);

    return [object('lineVersion1', lv1)];
}

export const viewInvoiceMatchedVersion = () => (
    <RenderWithStore content={<InvoiceMatchedVersion lineVersions={getLineVersions()} />} />
);
