import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableProps } from '@amzn/awsui-components-react';

import { COACombination } from 'modules/onboarding/model';
import { TableRow } from 'modules/core/model';

export type COACombinationTableProps = Required<Pick<TableProps, 'items' | 'header' | 'pagination' | 'loading'>> &
    Pick<TableProps, 'selectedItems' | 'onSelectionChange' | 'selectionType' | 'filter'>;
// eslint-disable-next-line react/display-name
export const COACombinationTable = React.forwardRef((props: COACombinationTableProps, _) => {
    const { t } = useTranslation('onboarding');

    const columnDefinitions = [
        {
            id: 'company',
            header: t('COACombinationTable.columnDefinitions.company'),
            cell: (row: TableRow<COACombination>) => row.payload.company
        },
        {
            id: 'location',
            header: t('COACombinationTable.columnDefinitions.location'),
            cell: (row: TableRow<COACombination>) => row.payload.location
        },
        {
            id: 'costCenter',
            header: t('COACombinationTable.columnDefinitions.costCenter'),
            cell: (row: TableRow<COACombination>) => row.payload.costCenter
        },
        {
            id: 'account',
            header: t('COACombinationTable.columnDefinitions.account'),
            cell: (row: TableRow<COACombination>) => row.payload.glAccount
        },
        {
            id: 'product',
            header: t('COACombinationTable.columnDefinitions.product'),
            cell: (row: TableRow<COACombination>) => row.payload.productLine
        },
        {
            id: 'channel',
            header: t('COACombinationTable.columnDefinitions.channel'),
            cell: (row: TableRow<COACombination>) => row.payload.channel
        },
        {
            id: 'project',
            header: t('COACombinationTable.columnDefinitions.project'),
            cell: (row: TableRow<COACombination>) => row.payload.project
        },
        {
            id: 'eighthSegment',
            header: t('COACombinationTable.columnDefinitions.eighthSegment'),
            cell: (row: TableRow<COACombination>) => row.payload.customEighthSegment
        },
        {
            id: 'teamName',
            header: t('COACombinationTable.columnDefinitions.teamName'),
            cell: (row: TableRow<COACombination>) => row.payload.teamName
        }
    ];

    const loadingText: string = t('COACombinationTable.loadingText');
    const headerMessage: string = t('COACombinationTable.emptyBox.header');

    const emptyBox: JSX.Element = (
        <Box textAlign="center" color="inherit">
            <b>{headerMessage}</b>
        </Box>
    );

    return <Table {...props} columnDefinitions={columnDefinitions} loadingText={loadingText} empty={emptyBox} />;
});
