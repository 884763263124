import './wdyr';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from '@aws-amplify/core';

import 'assets/styles/index.scss';
import 'modules/core/config/i18n';

import { MainRoutes } from 'modules/app/routes';
import { logger, AuthConfig, ApiEndpoints } from 'modules/core';
import { Loading } from 'modules/core/components';
import { initPrecisionService } from 'modules/core/services';
import * as serviceWorker from './serviceWorker';
import { configureStore } from 'modules/app/store';
import { Provider } from 'react-redux';

const config = Amplify.configure({ Auth: AuthConfig, API: { endpoints: ApiEndpoints } });
logger.debug('AWS Config', config);
initPrecisionService();

const store = configureStore();

ReactDOM.render(
    <Suspense fallback={<Loading />}>
        <Provider store={store}>
            <MainRoutes />
        </Provider>
    </Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
