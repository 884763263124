import React from 'react';

import { ReactComponent as LogoSvg } from '../../../../assets/logo.svg';

export const Loading = () => (
    <div className="logo-loading">
        <div className="logo">
            <LogoSvg />
            <div className="awsui-util-font-size-2">Loading...</div>
        </div>
    </div>
);
