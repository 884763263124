import React, { Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    CheckboxProps,
    Container,
    DatePicker,
    Form,
    FormField,
    Header,
    SpaceBetween
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';

export type OnboardNewPurchaseOrdersContainerProps = {
    fromDatePickerValue: string;
    setFromDatePickerValue: Dispatch<SetStateAction<string>>;
    toDatePickerValue: string;
    setToDatePickerValue: Dispatch<SetStateAction<string>>;
    openCheckboxChecked: boolean;
    setOpenCheckbox: Dispatch<SetStateAction<boolean>>;
    softClosedCheckboxChecked: boolean;
    setSoftClosedCheckbox: Dispatch<SetStateAction<boolean>>;
};

export const OnboardNewPurchaseOrdersForm = (props: OnboardNewPurchaseOrdersContainerProps) => {
    const {
        fromDatePickerValue,
        setFromDatePickerValue,
        toDatePickerValue,
        setToDatePickerValue,
        openCheckboxChecked,
        setOpenCheckbox,
        softClosedCheckboxChecked,
        setSoftClosedCheckbox
    } = props;

    const { t } = useTranslation('onboarding');

    const onFromDatePickerChanged = (event: { detail: { value: React.SetStateAction<string> } }) => {
        setFromDatePickerValue(event.detail.value);
    };

    const onToDatePickerChanged = (event: { detail: { value: React.SetStateAction<string> } }) => {
        setToDatePickerValue(event.detail.value);
    };

    return (
        <Form>
            <Container header={<Header>{t('OnboardNewPurchaseOrdersForm.title')}</Header>}>
                <Header id="poCreationDateTitle" variant="h3">
                    {t('OnboardNewPurchaseOrdersForm.POCreationDateTitle')}
                </Header>
                <SpaceBetween id="datePickers" direction="horizontal" size={'s'} key={'Space'}>
                    <FormField id="fromDate">
                        <DatePicker
                            id="fromDate"
                            value={fromDatePickerValue}
                            todayAriaLabel={t('OnboardNewPurchaseOrdersForm.todayAriaLabel')}
                            nextMonthAriaLabel={t('OnboardNewPurchaseOrdersForm.nextMonthAriaLabel')}
                            previousMonthAriaLabel={t('OnboardNewPurchaseOrdersForm.previousMonthAriaLabel')}
                            onChange={onFromDatePickerChanged}
                        />
                    </FormField>
                    <div>{t('OnboardNewPurchaseOrdersForm.to')}</div>
                    <FormField>
                        <DatePicker
                            value={toDatePickerValue}
                            todayAriaLabel={t('OnboardNewPurchaseOrdersForm.todayAriaLabel')}
                            nextMonthAriaLabel={t('OnboardNewPurchaseOrdersForm.nextMonthAriaLabel')}
                            previousMonthAriaLabel={t('OnboardNewPurchaseOrdersForm.previousMonthAriaLabel')}
                            onChange={onToDatePickerChanged}
                        />
                    </FormField>
                </SpaceBetween>
                <Header variant="h3">{t('OnboardNewPurchaseOrdersForm.POStatusTitle')}</Header>
                <FormField>
                    <Checkbox
                        onChange={(event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) =>
                            setOpenCheckbox(event.detail.checked)
                        }
                        checked={openCheckboxChecked}
                    >
                        {t('OnboardNewPurchaseOrdersForm.POStatusOpen')}
                    </Checkbox>
                </FormField>
                <FormField>
                    <Checkbox
                        onChange={(event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) =>
                            setSoftClosedCheckbox(event.detail.checked)
                        }
                        checked={softClosedCheckboxChecked}
                    >
                        {t('OnboardNewPurchaseOrdersForm.POStatusSoftClosed')}
                    </Checkbox>
                </FormField>
            </Container>
        </Form>
    );
};
