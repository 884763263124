/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'modules/core/model/TableRow';
import { BudgetTableTemplate } from '../BudgetTableTemplate';
import { BudgetTableHeaderTemplate } from '../BudgetTableHeaderTemplate';
import { OwnerStatusRow } from 'modules/budgetManagement/model/OwnerStatusRow';
import { BudgetTablePagination } from '../BudgetTablePagination';

import styles from './CoaCombinationTable.module.scss';

interface CoaCombinationTableProps {
    coaItems: any[];
    loading: boolean;
    inStepOne: boolean;
    assignedOwner?: string;
    selectedItems?: any[];
    updateItems?: React.Dispatch<React.SetStateAction<TableRow<OwnerStatusRow>[] | undefined>>;
}

export const CoaCombinationTable = (props: CoaCombinationTableProps) => {
    const { coaItems, loading, inStepOne, assignedOwner, selectedItems, updateItems } = props;

    const { t } = useTranslation('budgetManagement');

    const displayOwner = (existingOwner?: string) => {
        if (assignedOwner) {
            return assignedOwner;
        }

        return existingOwner ?? t('table.coaCombination.notAvailable');
    };

    const columns = [
        {
            id: 'company',
            header: t('table.company'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa1}</div>
        },
        {
            id: 'location',
            header: t('table.location'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa2}</div>
        },
        {
            id: 'costCenter',
            header: t('table.costCenter'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa3}</div>
        },
        {
            id: 'glAccount',
            header: t('table.glAccount'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa4}</div>
        },
        {
            id: 'productLine',
            header: t('table.productLine'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa5}</div>
        },
        {
            id: 'channel',
            header: t('table.channel'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa6}</div>
        },
        {
            id: 'project',
            header: t('table.project'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa7}</div>
        },
        {
            id: 'eighthSegment',
            header: t('table.eighthSegment'),
            cell: (row: TableRow<OwnerStatusRow>) => <div>{row.payload.coa8}</div>
        },
        {
            id: 'owner',
            header: t('table.coaCombination.owner'),
            cell: (row: TableRow<OwnerStatusRow>) => (
                <div className={assignedOwner || row.payload.owner ? '' : styles['notAvailable']}>
                    {displayOwner(row.payload.owner)}
                </div>
            )
        }
    ];

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const pagination = <BudgetTablePagination openEnded={false} getItems={async () => {}} />;

    const allowModification = () => {
        return !loading && inStepOne && coaItems?.length > 0;
    };

    const { items, filteredItemsCount, filterProps } = useCollection(coaItems, {
        filtering: {
            empty: <></>,
            noMatch: <></>
        }
    });

    const getFilterCounterText = (count: number) => {
        return `${count} ${count === 1 ? 'match' : 'matches'}`;
    };

    const textFiltering = (
        <TextFilter
            {...filterProps}
            filteringAriaLabel="Filter COA Combinations"
            filteringPlaceholder="Find COA Combinations"
            countText={getFilterCounterText(filteredItemsCount ?? 0)}
        />
    );

    return (
        <BudgetTableTemplate
            loading={loading}
            items={items}
            columns={columns}
            header={
                <BudgetTableHeaderTemplate
                    title={t('table.coaCombination.title')}
                    emptyTitle={t('table.coaCombination.emptyTitle')}
                    loading={loading}
                    tableItemCount={items.length}
                    buttons={[]}
                />
            }
            pagination={pagination}
            textFilter={textFiltering}
            selection={allowModification()}
            selectedItems={selectedItems}
            updateSelectedItems={updateItems}
        />
    );
};
