import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Container, Header, Modal, Box } from '@amzn/awsui-components-react';
import { FileUpload } from 'modules/core/components/FileUpload';
import { ReportResult } from 'modules/reports/model';
import { ReportName, ReportParams } from 'modules/reports/constants';
import { useBulkUploadAccountingStrings } from 'modules/accountingStrings/hooks';

interface BulkUploadAccountingStringsProps {
    uploadInProgress: boolean;
    uploadReportAsync: (
        fileUrl: string,
        reportName: ReportName,
        sheetTab?: string | undefined,
        reportParams?: ReportParams | undefined
    ) => Promise<void>;
    report: ReportResult | undefined;
}

export const BulkUploadAccountingStrings = (props: BulkUploadAccountingStringsProps) => {
    const { t } = useTranslation('bulkEdit');
    const EDIT_TAB = 'Edit';

    const { uploadInProgress, uploadReportAsync, report } = props;
    const { validateFile, errors } = useBulkUploadAccountingStrings();

    const [file, setFile] = useState<File>();
    const [fileUrl, setFileUrl] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [uploadErrorModal, setUploadErrorModal] = useState<boolean>(false);
    const [uploadSuccessModal, setUploadSuccessModal] = useState<boolean>(false);

    useEffect(() => {
        setErrorMessage(errors.join(' '));
    }, [errors, setErrorMessage]);

    useEffect(() => {
        if (report?.succeeded && report?.report?.reportName === ReportName.BulkEditAccountingStringUpload) {
            setUploadSuccessModal(true);
            return;
        }

        if (report?.error && report?.report?.reportName === ReportName.BulkEditAccountingStringUpload) {
            setUploadErrorModal(true);
            setErrorMessage(report?.error || '');
        }
    }, [report]);

    const onFileUpload = async (uploadFile: File, fileUrl: string) => {
        if (!uploadFile || !fileUrl) return;

        setFile(uploadFile);
        setFileUrl(fileUrl);
        await validateFile(fileUrl);
    };

    const startUpload = async () => {
        if (!fileUrl) return;
        if (errors.length > 0) {
            setUploadErrorModal(true);
            return;
        }

        await uploadReportAsync(fileUrl, ReportName.BulkEditAccountingStringUpload, EDIT_TAB);
    };

    const uploadErrorModalClose = () => {
        setUploadErrorModal(false);
        resetFileState();
    };

    const uploadSuccessModalClose = () => {
        setUploadSuccessModal(false);
        resetFileState();
    };

    const resetFileState = () => {
        setFile(undefined);
        setFileUrl(undefined);
    };

    const UploadErrorsModal = () => (
        <Modal
            visible={uploadErrorModal}
            header={t('modal.errors')}
            onDismiss={uploadErrorModalClose}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={uploadErrorModalClose}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {t('modal.errorsBody', { errors: errorMessage })}
        </Modal>
    );

    const UploadSuccessModal = () => (
        <Modal
            visible={uploadSuccessModal}
            header={t('modal.UploadSubmitted')}
            onDismiss={uploadSuccessModalClose}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={uploadSuccessModalClose}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {t('modal.UploadSubmittedCoaBodyLine1')}
        </Modal>
    );

    return (
        <>
            <UploadSuccessModal />
            <UploadErrorsModal />

            <Container header={<Header variant="h2">{t('fileUpload.title')}</Header>}>
                <FileUpload onFileUpload={onFileUpload} file={file} />
                <br />

                <Button variant="primary" onClick={startUpload} disabled={file === undefined || uploadInProgress}>
                    {t('upload')}
                </Button>
            </Container>
        </>
    );
};
