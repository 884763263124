import { ChannelMeasurementMapping } from 'modules/mappings/models';

export interface ChannelMeasurement {
    companyCode: string;
    mappings: ChannelMeasurementMapping[];
}

export function aggregateChannelMeasurements(channelMeasurements?: ChannelMeasurementMapping[]): ChannelMeasurement[] {
    const channels = channelMeasurements || [];
    const measurements: ChannelMeasurement[] = [];

    let currentMapping = channels.shift();

    while (currentMapping) {
        const { event, nextMapping } = buildAggregatedEvent(currentMapping, channels);
        measurements.push(event);
        currentMapping = nextMapping;
    }

    measurements.forEach(measurement =>
        measurement.mappings.sort((m1, m2) =>
            m1.channelType === m2.channelType ? 0 : m1.channelType > m2.channelType ? 1 : -1
        )
    );

    return measurements;
}

function buildAggregatedEvent(currentMapping: ChannelMeasurementMapping, mappings: ChannelMeasurementMapping[]) {
    const companyCode = currentMapping.companyCode;
    const event = {
        companyCode,
        mappings: [currentMapping]
    } as ChannelMeasurement;
    let nextMapping = mappings.shift();

    while (companyCode === nextMapping?.companyCode) {
        event.mappings.push(nextMapping);
        nextMapping = mappings.shift();
    }

    return { event, nextMapping };
}
