import { v4 } from 'uuid';
import { COAConnector } from '../constants';
import { CoaMapping } from './CoaMapping';

export class BigOChannelMapping {
    id: string;
    coaString: string;
    channelType: string;
    bigObjective: string;

    constructor(coaMapping?: CoaMapping, coaString?: string, bigObjective?: string, channelType?: string) {
        if (!coaMapping) {
            this.id = v4();
            this.coaString = coaString || '';
            this.channelType = channelType || '';
            this.bigObjective = bigObjective || '';
            return;
        }

        const {
            company,
            productLine,
            channel,
            glAccount,
            costCenter,
            location,
            project,
            customEighthSegment
        } = coaMapping;
        coaString = [company, location, costCenter, glAccount, productLine, channel, project, customEighthSegment].join(
            COAConnector
        );

        this.id = coaMapping.id || v4();
        this.coaString = coaString;
        this.channelType = coaMapping.channelType;
        this.bigObjective = coaMapping.bigObjective;
    }
}
