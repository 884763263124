import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, HeaderProps } from '@amzn/awsui-components-react';

export type CustomerTeamListTableHeaderProps = Pick<HeaderProps, 'actions'> & {
    numberOfTotalTableRowTeams: number;
};

export const CustomerTeamListTableHeader = (props: CustomerTeamListTableHeaderProps) => {
    const { numberOfTotalTableRowTeams } = props;
    const { t } = useTranslation('onboarding');

    const title: string = t('CustomerTeamListTableHeader.title');
    const counter = `(${numberOfTotalTableRowTeams})`;

    return (
        <Header {...props} counter={counter} variant="h2">
            {title}
        </Header>
    );
};
