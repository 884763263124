export const ObjectiveOptions = {
    Alexa: 'alexa',
    Prime: 'prime',
    PvBlockBuster: 'pvBlockbuster',
    EmbraceAmazon: 'embraceAmazon',
    AmazonBrandReputation: 'amazonBrandReputation',
    Groceries: 'groceries',
    TheClimatePledge: 'theClimatePledge',
    Others: 'others',
    NotApplicable: 'notApplicable'
};

export const XcmOnlyObjectiveOptions = {
    Alexa: 'alexa',
    Prime: 'prime',
    PvBlockBuster: 'pvBlockbuster',
    EmbraceAmazon: 'embraceAmazon',
    MiscMediaFees: 'miscMediaFees',
    Groceries: 'groceries',
    BrandTruths: 'brandTruths',
    Others: 'others',
    NotApplicable: 'notApplicable'
};
