import { logger } from 'modules/core';
import { ApiClient } from 'modules/core/class/api/ApiClient';
import { GetRequestProps } from 'modules/core/class/api/RequestProps';
import { Team } from 'modules/onboarding/model';
import { ApiError } from 'modules/core/types';

export type GetTeamsRequest = {
    teamName?: string;
};

export type GetTeamsResponse = {
    status: number;
    teams?: Team[];
};

export type GetTeamsRawResponse = Team[];

export class OnboardingService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'onboarding';

    public async getTeamsAsync(getTeamsRequest: GetTeamsRequest): Promise<GetTeamsResponse> {
        const cleanGetTeamsRequest: GetTeamsRequest = Object.fromEntries(
            Object.entries(getTeamsRequest).filter(([_, v]) => v != null && v != undefined)
        );
        const getRequestProps: GetRequestProps = {
            queryStringParameters: cleanGetTeamsRequest
        };

        logger.log(getRequestProps);

        try {
            const response: GetTeamsRawResponse = await this.get('', getRequestProps);

            logger.info(`getTeams raw response: ${response}`);

            const getTeamsResponse: GetTeamsResponse = {
                status: 200,
                teams: response
            };

            return getTeamsResponse;
        } catch (error) {
            const apiError: ApiError = error as ApiError;
            logger.error(apiError);
            return { status: apiError?.response?.status };
        }
    }
}

export const onboardingService = new OnboardingService();
