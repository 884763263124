import React from 'react';
import { Modal, Button } from '@amzn/awsui-components-react-v2';
import { useTranslation } from 'react-i18next';

interface CancelModalProps {
    title: string;
    content: string;
    cancelFunction: () => void;
    backToEditFunction: () => void;
}

export const CancelModal = (props: CancelModalProps) => {
    const { title, content, cancelFunction, backToEditFunction } = props;

    const { t } = useTranslation('purchase');

    return (
        <Modal
            visible={true}
            onDismiss={() => backToEditFunction()}
            header={title}
            footer={
                <span className="awsui-util-f-r">
                    <Button onClick={() => backToEditFunction()}>{t('wrapper.back')}</Button>
                    <Button variant="primary" onClick={() => cancelFunction()}>
                        {t('dashboard.okBtnText')}
                    </Button>
                </span>
            }
        >
            {content}
        </Modal>
    );
};
