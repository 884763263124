import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from 'modules/core/types';

interface StartCycleDropdownProps {
    cycleOptions: string[];
    ongoingOptions: string[];
    onChange: (cycle: string) => void;
}

export const StartCycleDropdown = ({ onChange, cycleOptions, ongoingOptions }: StartCycleDropdownProps) => {
    const { t } = useTranslation('budgetManagement');
    const [selectedOption, setSelectedOption] = useState<SelectProps.Option>();

    const optionsList = cycleOptions.map(option => ({
        value: option,
        label: option,
        disabled: ongoingOptions.includes(option)
    }));

    const onObjectiveChange = (selectedOption: OptionDefinition) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption.value ?? '');
    };

    return (
        <Select
            selectedOption={selectedOption ?? null}
            options={optionsList}
            data-cy="StartCycleDropdown"
            placeholder={t('startCycleModal.dropdownPlaceholder')}
            onChange={({ detail }) => onObjectiveChange(detail.selectedOption)}
        />
    );
};
