import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse } from 'modules/core/model/response';
import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';

interface UserTeamResult {
    userTeams: string[] | undefined;
    requestId?: string;
    status?: number;
}

export class UserTeamService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'users';

    public async getUserTeamsAsync(): Promise<UserTeamResult> {
        try {
            const json: ResourceResponse = await this.get('teams');

            if (json.result) {
                const { result, requestId } = json;
                return { requestId, userTeams: result as string[], status: 200 };
            }

            return { userTeams: (json as unknown) as string[], status: 200 };
        } catch (error) {
            logger.error(error);
            return { userTeams: undefined, status: (error as ApiError)?.response?.status };
        }
    }
}

export const userTeamService = new UserTeamService();
