/* eslint-disable react/display-name */
import React from 'react';
import { HelpPanel } from 'modules/core/hooks/useHelpPanel.hook';
import { ToolContent } from 'modules/purchase/components';

export type PurchaseDetailPagePanels =
    | 'PO_HELP'
    | 'P_LINE_HELP'
    | 'SPEND_HELP'
    | 'CENTRAL_RECEIVER_HELP'
    | 'RECEIPT_HELP'
    | 'RECEIPT_STATUS_HELP'
    | 'SPEND_CONFIRMATION_HELP';

const ASP_SIM_TICKET_LINK = 'https://t.corp.amazon.com/create/templates/3065f386-d3e6-4a90-9e24-674a68c19313';

export const detailPagePanels: HelpPanel<PurchaseDetailPagePanels>[] = [
    {
        key: 'CENTRAL_RECEIVER_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent header="helpPanel.OwnerHeader" paragraphs={['helpPanel.permissionsInfo']} link={link} />
        )
    },
    {
        key: 'PO_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent
                header="helpPanel.POHeader"
                paragraphs={['helpPanel.POPara1', 'helpPanel.POPara2']}
                link={link}
            />
        )
    },
    {
        key: 'P_LINE_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent header="helpPanel.POLineHeader" paragraphs={['helpPanel.POLinePara1']} link={link} />
        )
    },
    {
        key: 'SPEND_HELP',
        content: (link: { url: string; value: string }) => (
            <ToolContent
                header="helpPanel.SDHeader"
                paragraphs={['helpPanel.SDPara1', 'helpPanel.SDPara2']}
                link={link}
            />
        )
    },
    {
        key: 'RECEIPT_HELP',
        content: () => <ToolContent header="helpPanel.ReceiptHeader" paragraphs={['helpPanel.ReceiptPara1']} />
    },
    {
        key: 'RECEIPT_STATUS_HELP',
        content: () => (
            <ToolContent
                header="helpPanel.ReceiptStatusHeader"
                paragraphs={[
                    { content: 'helpPanel.ReceiptStatusPosted', header: 'receipts.posted' },
                    { content: 'helpPanel.ReceiptStatusPendingPost', header: 'receipts.pendingPost' },
                    { content: 'helpPanel.ReceiptStatusPendingVoid', header: 'receipts.pendingVoid' },
                    { content: 'helpPanel.ReceiptStatusFutureReceipts', header: 'receipts.futureReceipts' },
                    { content: 'helpPanel.ReceiptStatusUnconfirmed', header: 'receipts.unconfirmed' },
                    {
                        content: 'helpPanel.ReceiptStatusPostFailure',
                        header: 'receipts.postFailure',
                        link: { url: ASP_SIM_TICKET_LINK, value: 'helpPanel.cutATicket' }
                    },
                    {
                        content: 'helpPanel.ReceiptStatusVoidFailure',
                        header: 'receipts.voidFailure',
                        link: { url: ASP_SIM_TICKET_LINK, value: 'helpPanel.cutATicket' }
                    }
                ]}
            />
        )
    },
    {
        key: 'SPEND_CONFIRMATION_HELP',
        content: () => (
            <ToolContent
                header="helpPanel.SpendConfirmationHeader"
                paragraphs={[
                    'helpPanel.SpendConfirmationPart1',
                    'helpPanel.SpendConfirmationPart2',
                    'helpPanel.SpendConfirmationPart3'
                ]}
            />
        )
    }
];

export type DashboardPagePanels = 'PR_LIST_HELP' | 'PO_LIST_HELP';

export const dashboardPanels: HelpPanel<DashboardPagePanels>[] = [
    {
        key: 'PO_LIST_HELP',
        content: () => (
            <ToolContent
                header="helpPanel.POListHeader"
                paragraphs={['helpPanel.POListPara1', 'helpPanel.POListPara2']}
            />
        )
    },
    {
        key: 'PR_LIST_HELP',
        content: () => (
            <ToolContent
                header="helpPanel.PRListHeader"
                paragraphs={['helpPanel.PRListPara1', 'helpPanel.PRListPara2']}
            />
        )
    }
];
