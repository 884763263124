import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Box } from '@amzn/awsui-components-react';

interface UploadErrorModalProps {
    onDismiss: () => void;
    errorMsg: string;
}

export const UploadErrorModal = (props: UploadErrorModalProps) => {
    const { t } = useTranslation('budgetManagement');

    const { onDismiss, errorMsg } = props;

    return (
        <Modal
            visible={true}
            header={t('requestChange.errorModal.title')}
            onDismiss={onDismiss}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={onDismiss}>
                        {t('requestChange.errorModal.ok')}
                    </Button>
                </Box>
            }
        >
            {t('requestChange.errorModal.errorMsg', { errorMsg: errorMsg })}
        </Modal>
    );
};
