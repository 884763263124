import React from 'react';
import { Popover, Icon, ColumnLayout } from '@amzn/awsui-components-react';

import style from './NameTagPopOver.module.scss';

interface NameTagProps {
    login: string;
    firstName: string;
    lastName: string;
}

export const NameTagPopOver = ({ login, firstName, lastName }: NameTagProps) => {
    const contentJSX = (
        <ColumnLayout>
            <div data-awsui-column-layout-root="true">
                <div className="has-display-flex  awsui-util-mv-xl">
                    <img src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${login}`} alt="avatar" />
                    <div className={`awsui-util-ph-s ${style['margin-top']}`}>
                        <div className="awsui-util-font-size-3">
                            {firstName} {lastName}
                        </div>
                        <div className={style['margin-bottom']}>
                            <a
                                href={`https://phonetool.amazon.com/users/${login}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                PhoneTool
                                <Icon name="external" />
                            </a>
                            <br />
                            <span className="awsui-util-status-info">{login}@amazon.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </ColumnLayout>
    );

    return (
        <Popover size="large" position="bottom" content={contentJSX}>
            {firstName} {lastName}
        </Popover>
    );
};
