import { logger } from 'modules/core';
import { ApiClient } from 'modules/core/class/api/ApiClient';

export class CoupaPermissionService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'users';
    private HALF_HOUR_EPOCH_MS = 1800000;
    private COUPA_PERMISSION_SYNC_TIME = 'coupaPermissionSyncLastEpoch';

    public async syncCoupaPermissions(alias?: string): Promise<void> {
        try {
            const currentUtcEpochMillis: number = new Date().getTime();
            if (!alias || alias.trim().length == 0) throw new Error('Invalid alias! Alias is null or empty');
            const url = `coupaPermissions?login=${alias}`;
            await this.handleSyncRequest(url, currentUtcEpochMillis);
        } catch (error) {
            logger.error(error);
        }
    }
    private async handleSyncRequest(url: string, currentEpoch: number): Promise<void> {
        const lastSyncedEpoch = parseFloat(localStorage.getItem(this.COUPA_PERMISSION_SYNC_TIME) ?? '0');
        if (currentEpoch - lastSyncedEpoch > this.HALF_HOUR_EPOCH_MS) {
            await this.post(url);
            localStorage.setItem(this.COUPA_PERMISSION_SYNC_TIME, currentEpoch.toString());
        }
    }
}

export const coupaPermissionService = new CoupaPermissionService();
