/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@amzn/awsui-components-react';
import { YearBudgetTable } from '../YearBudgetTable';

interface RequestChangeTableProps {
    items: any[];
    loading: boolean;
    onSendRequest: () => void;
}

export const RequestChangeTable = (props: RequestChangeTableProps) => {
    const { items, loading, onSendRequest } = props;

    const { t } = useTranslation('budgetManagement');

    const sendRequestButton = (
        <Button onClick={onSendRequest} variant="primary">
            {t('table.requestChange.sendRequest')}
        </Button>
    );

    return (
        <YearBudgetTable
            loading={loading}
            title={t('table.requestChange.title')}
            emptyTitle={t('table.requestChange.title')}
            items={items}
            headerButtons={[sendRequestButton]}
        />
    );
};
