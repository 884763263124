import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { PurchaseService } from 'modules/purchase/services';
import { RequestParams } from 'modules/core/class/api/RequestParams';

export class AuditEventDetailsService extends PurchaseService {
    public async getEventDetailsAsync(purchaseId: string, lineVersionLineNumber?: string) {
        try {
            const queryStringParameters = this.getRequestQueryStringParameters(purchaseId, lineVersionLineNumber);

            const jsonResponse = await this.get(
                `${purchaseId}/history/${lineVersionLineNumber}`,
                queryStringParameters
            );

            const { result, requestId } = jsonResponse;
            const { versionType, originalPurchaseLine, modifiedPurchaseLine } = result;
            return {
                requestId,
                versionType,
                originalPurchaseLine,
                modifiedPurchaseLine,
                lineVersionLineNumber,
                status: 200
            };
        } catch (error) {
            logger.error(error);
            return { status: (error as ApiError)?.response?.status };
        }
    }

    private getRequestQueryStringParameters(purchaseId: string, lineVersionLineNumber?: string): RequestParams {
        const target: RequestParams = {};

        if (lineVersionLineNumber) {
            target.lineVersionLineNumber = lineVersionLineNumber;
        }
        target.purchaseId = purchaseId;

        return target;
    }
}
export const auditEventDetailsService = new AuditEventDetailsService();
