import { TableRow } from 'modules/core/model';

export const mapToTableRow = <T extends Record<string, string>>(item: T, index: number): TableRow<T> => {
    return {
        number: index,
        selected: false,
        rowId: item.toString(),
        payload: item
    };
};
