import React, { useEffect, useRef, useState } from 'react';
import { Hub } from '@aws-amplify/core';
import { useTranslation } from 'react-i18next';

import { Receipt } from 'modules/purchase/models';
import { between } from 'modules/core';
import { I18nFormatter } from 'modules/core/class';

import styles from '../PurchaseLineWrapper/Wrapper.module.scss';

interface InvoiceEditedLabelProps {
    estimatedAmount: number;
    invoiceTotal: number;
    invoiceEdited: string;
    hubChannel: string;
    receipt?: Receipt;
}

export const InvoiceEditedLabel = ({
    estimatedAmount,
    invoiceTotal,
    invoiceEdited,
    hubChannel,
    receipt
}: InvoiceEditedLabelProps) => {
    const { t } = useTranslation('purchase');

    const [estimatedInvoiceAmount, setInvoiceAmount] = useState(estimatedAmount);

    const componentMounted = useRef(false);

    useEffect(() => {
        componentMounted.current = true;

        setInvoiceAmount(estimatedAmount);
        const subscribe = () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Hub.listen(hubChannel, (capsule: any) => {
                const { channel, payload } = capsule;
                if (channel !== hubChannel) {
                    return;
                }
                if (payload.data.estimatedAmount && componentMounted.current) {
                    setInvoiceAmount(payload.data.estimatedAmount);
                }
            });
        };

        subscribe();

        return () => {
            componentMounted.current = false;
            Hub.remove(hubChannel, () => ({}));
        };
    }, [estimatedAmount, hubChannel]);

    const LabelIndicator = () => {
        let isValid = false;
        if (invoiceTotal > 0) isValid = estimatedInvoiceAmount >= 0 && invoiceTotal - estimatedInvoiceAmount >= 0;
        else {
            const lowerLimit = Math.min(invoiceTotal, receipt?.billedAmount || 0);
            isValid = between(estimatedInvoiceAmount, lowerLimit, 0) && invoiceTotal - estimatedInvoiceAmount <= 0;
        }
        const indicator = isValid ? 'awsui-util-status-positive' : 'is-error';
        return (
            <div className="awsui-row">
                <div className="col-5"></div>
                <div className={`col-3 ${styles['invoice-label-padding']}`}>
                    <p className={`${indicator} ${styles['has-wide-columns']}`}>
                        {t('invoices.distributionMessage', {
                            actual: I18nFormatter.amount(t, estimatedInvoiceAmount),
                            expected: I18nFormatter.amount(t, invoiceTotal)
                        })}
                    </p>
                </div>
            </div>
        );
    };

    return invoiceEdited !== '' ? <LabelIndicator /> : <></>;
};
