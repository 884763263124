import { logger } from 'modules/core/logger';
import { parse, ParseResult } from 'papaparse';
import { months } from '../constants';

const MANDATORY_FIELDS_FOR_FA = ['Assign Owner'];
const MANDATORY_FIELDS = [
    'Forecast Id (DO NOT EDIT)',
    'Company Code (DO NOT EDIT)',
    'Location Code (DO NOT EDIT)',
    'Cost Center (DO NOT EDIT)',
    'Account Code (DO NOT EDIT)',
    'Product Line (DO NOT EDIT)',
    'Channel (DO NOT EDIT)',
    'Project Code (DO NOT EDIT)',
    '8th Segment (DO NOT EDIT)',
    'Currency (DO NOT EDIT)',
    'Year (DO NOT EDIT)',
    'Big Objective (DO NOT EDIT)',
    'Channel Type (DO NOT EDIT)',
    'SMARTe Classification (DO NOT EDIT)',
    'Existing Owner (DO NOT EDIT)',
    'Comments (from FA)'
];

const HAS_NO_DUPLICATE_HEADERS = 'Duplicate headers found in the input file';
const MISSING_HEADER_ERROR = `Please ensure mandatory columns ${MANDATORY_FIELDS.map(field =>
    field.replace('(DO NOT EDIT)', '')
).join(', ')} are present in the file uploaded.`;

class UploadBudgetProcessor {
    private errors: string[];
    private csvData?: ParseResult<unknown>;

    constructor() {
        this.errors = [];
    }

    public validateFile = async (fileUrl: string, isOwner = false) => {
        await this.fetchUploadedFile(fileUrl);

        if (this.errors.length === 0 && this.csvData) {
            this.validateHeaders(isOwner);
        }
    };

    private fetchUploadedFile = async (fileUrl: string) => {
        await fetch(fileUrl)
            .then(response => response.text())
            .then(responseText => {
                this.csvData = parse(responseText, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    delimiter: ','
                });
            });

        if (this.csvData?.errors && this.csvData?.errors.length > 0) {
            const fetchErrror = `Error fetching CSV: ${JSON.stringify(this.csvData?.errors)}`;

            logger.error(fetchErrror);
            this.errors = [...this.errors, fetchErrror];
        }
    };

    private validateHeaders = (isOwner: boolean) => {
        if (!this.csvData) return;

        const firstRow = this.csvData.data[0] as JSON;
        const headers = Object.keys(firstRow);

        const validHeaders = MANDATORY_FIELDS.every(i => headers.includes(i)) && months.every(i => headers.includes(i));

        const mandatoryUserfeilds = !isOwner ? MANDATORY_FIELDS_FOR_FA : [];
        const validUserfeilds = mandatoryUserfeilds.every(i => headers.includes(i));

        if (!(validHeaders && validUserfeilds)) {
            this.errors = [...this.errors, MISSING_HEADER_ERROR];
        }

        this.hasNoDuplicateHeaders(headers);
    };

    private async hasNoDuplicateHeaders(headers: string[]) {
        if (new Set(headers).size === headers.length) return;

        this.errors = [...this.errors, HAS_NO_DUPLICATE_HEADERS];
    }

    public getErrors(): string {
        return this.errors.join(' ');
    }

    public getParsedFile = () => {
        return this.csvData ? (this.csvData.data as JSON[]) : [];
    };
}

export const uploadBudgetProcessor = new UploadBudgetProcessor();
