import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineVersion } from 'modules/audit/models';
import styles from './VersionTitle.module.scss';
import { Link } from '@amzn/awsui-components-react';

export interface VersionTitleProps {
    title: string;
    lineVersion: LineVersion;
    showDetails?: boolean;
}

export const VersionTitle = (props: VersionTitleProps) => {
    const { title, lineVersion, showDetails } = props;
    const { t } = useTranslation('purchase');
    const versionDetailsUrl = `/audit/${lineVersion.purchaseId}/${lineVersion.lineVersion}$${lineVersion.lineNumber}`;

    return (
        <h2>
            <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">{title} </div>
                {showDetails && (
                    <div className="awsui-util-action-stripe-title">
                        <Link className={'awsui-util-help-info-link'} href={versionDetailsUrl}>
                            {t('version.seeDetails')}
                        </Link>
                    </div>
                )}
            </div>
            <span className={styles['line-version-status-title']}>
                {lineVersion.isPendingApproval && t('version.pendingApprovalTitle')}
                {lineVersion.isRejected && t('version.rejectedTitle')}
            </span>
        </h2>
    );
};
