import { ChannelSpend } from 'modules/channel/models';
import { InvoiceAllocation } from 'modules/invoices/models';
import { ReceiptStatus } from '../../purchase/models/';

export class ReceiptVersion {
    public receiptNumber: string;
    public purchaseId: string;
    public receiptVersion: number;
    public originalReceiptAmount: number;
    public modifiedReceiptAmount: number;
    public originalReceiptTime: number;
    public modifiedReceiptTime: number;
    public receiptStatus?: ReceiptStatus;
    public lineNumber: number;
    public versionCategory: string;
    public versionDescription: string;
    public originalChannelAllocations?: ChannelSpend[];
    public modifiedChannelAllocations?: ChannelSpend[];
    public originalInvoiceAllocations?: InvoiceAllocation[];
    public modifiedInvoiceAllocations?: InvoiceAllocation[];

    constructor() {
        this.receiptNumber = '';
        this.purchaseId = '';
        this.receiptVersion = 0;
        this.lineNumber = 0;
        this.versionCategory = '';
        this.versionDescription = '';
        this.originalReceiptAmount = 0;
        this.modifiedReceiptAmount = 0;
        this.originalReceiptTime = 0;
        this.modifiedReceiptTime = 0;
    }
}
