import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { channelMeasurementService } from 'modules/mappings/services/channelMeasurement';
import { aggregateChannelMeasurements, ChannelMeasurement } from 'modules/mappings/helpers';
import { ChannelMeasurementMapping, UpdateStatus } from 'modules/mappings/models';
import { showErrorPage } from 'modules/app/store';

interface UpdateResult {
    status: UpdateStatus;
    reason: string;
}

export function useChannelMeasurement() {
    const [updateRequests, setUpdateRequest] = useState(new Map<string, string>());
    const [team, setTeam] = useState<string>('');
    const [channelMeasurements, setChannelMeasurements] = useState<ChannelMeasurement[]>();
    const [showBtns, setShowBtns] = useState<boolean>(false);
    const [updateSuccess, setUpdateSuccess] = useState<UpdateResult>({
        status: UpdateStatus.NOT_SUBMITTED,
        reason: ''
    });

    const dispatch = useDispatch();

    const getChannelMeasurements = useCallback(async () => {
        const {
            channelMeasurements,
            teamName,
            status
        } = await channelMeasurementService.getChannelMeasurementMappingsAsync();

        if (channelMeasurements === undefined) {
            dispatch(showErrorPage({ errorCode: status || 400, accessError: true }));
            return;
        } else if (channelMeasurements.length === 0) {
            dispatch(showErrorPage({ errorCode: 404, accessError: false }));
            return;
        }

        setChannelMeasurements(aggregateChannelMeasurements(channelMeasurements));
        setUpdateRequest(new Map());
        setTeam(teamName);
        setShowBtns(false);
    }, [dispatch]);

    function updateSmartERequest(requestString: string) {
        const { companyCode, channelType, smartE } = splitRequestString(requestString);

        updateRequests.set(`${companyCode}$${channelType}`, smartE);

        setUpdateRequest(updateRequests);
        setShowBtns(true);
    }

    async function updateSmartEMappings(): Promise<void> {
        const channelMeasurementUpdateRequests: ChannelMeasurementMapping[] = [];

        updateRequests.forEach(async (value, key) => {
            const companyCode = key.split('$')[0];
            const channelType = key.split('$')[1];
            const smartE = value;

            channelMeasurementUpdateRequests.push({ teamName: team, companyCode, channelType, smartE });
        });

        const updateBody = {
            teamName: team,
            channelMeasurementMappings: channelMeasurementUpdateRequests
        };
        const { result, message } = await channelMeasurementService.updateChannelMeasurementMappingsAsync(updateBody);

        const resultStatus: UpdateStatus = result ? UpdateStatus.SUBMIT_SUCCESS : UpdateStatus.SUBMIT_FAILURE;

        setUpdateSuccess({ status: resultStatus, reason: message });

        if (result) {
            await reloadPageAsync();
        }
    }

    async function reloadPageAsync() {
        setChannelMeasurements(undefined);
        getChannelMeasurements();
    }

    function splitRequestString(requestString: string) {
        const splits = requestString.split('$');

        return { companyCode: splits[0], channelType: splits[1], smartE: splits[2] };
    }

    useEffect(() => {
        if (!channelMeasurements) {
            getChannelMeasurements();
        }
    }, [getChannelMeasurements]);

    return { updateSmartERequest, updateSmartEMappings, channelMeasurements, reloadPageAsync, showBtns, updateSuccess };
}
