import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Header, Button, Modal, Box } from '@amzn/awsui-components-react';
import { ReportName, ReportParams } from 'modules/reports/constants';
import { ReportResult } from 'modules/reports/model';

interface DownloadAccountingStringsProps {
    createInProgress: boolean;
    createReportAsync: (eventId: string, reportParams?: ReportParams) => Promise<void>;
    report: ReportResult | undefined;
}

export const DownloadAccountingStrings = (props: DownloadAccountingStringsProps) => {
    const { t } = useTranslation('bulkEdit');

    const { createInProgress, createReportAsync, report } = props;

    const [isDownloadSuccessModal, setIsDownloadSuccessModal] = useState<boolean>(false);
    const [isDownloadFailureModal, setIsDownloadFailureModal] = useState<boolean>(false);
    const [disableDownloadButton, setDisableDownloadButton] = useState<boolean>(true);
    const [reportId, setReportID] = useState<string | undefined>();
    const [reportGenerationError, setReportGenerationError] = useState<string | undefined>();

    useEffect(() => {
        const generatedId = report?.report?.id;

        if (report?.succeeded && generatedId && report?.report?.reportName === ReportName.ExportAccountingString) {
            setReportID(generatedId);
            setIsDownloadSuccessModal(true);
            return;
        }
        if (report?.error && report?.report?.reportName === ReportName.ExportAccountingString) {
            setReportGenerationError(report?.error);
            setIsDownloadFailureModal(true);
        }
    }, [report]);

    useEffect(() => {
        setDisableDownloadButton(createInProgress);
    }, [createInProgress]);

    const onSubmit = async () => {
        setReportID(undefined);
        setReportGenerationError(undefined);

        await createReportAsync(ReportName.ExportAccountingString);
    };

    const DownloadSuccessModal = () => (
        <Modal
            visible={isDownloadSuccessModal}
            header={t('modal.reportSubmitted')}
            onDismiss={() => setIsDownloadSuccessModal(false)}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={() => setIsDownloadSuccessModal(false)}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {t('modal.reportSubmittedBody', { reportId })}
        </Modal>
    );

    const DownloadFailureModal = () => (
        <Modal
            visible={isDownloadFailureModal}
            header={t('modal.reportCreationFailed')}
            onDismiss={() => setIsDownloadFailureModal(false)}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={() => setIsDownloadFailureModal(false)}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {reportGenerationError}
        </Modal>
    );

    return (
        <>
            <DownloadSuccessModal />
            <DownloadFailureModal />
            <Container header={<Header variant="h2">{t('downloadBulkEditCoa.title')}</Header>}>
                <Header variant="h3">{t('downloadBulkEditCoa.header')}</Header>
                <p>{t('downloadBulkEditCoa.content')}</p>
                <br />

                <Button variant="primary" onClick={onSubmit} disabled={disableDownloadButton}>
                    {t('downloadBtn')}
                </Button>
            </Container>
        </>
    );
};
