import { CustomFieldType } from './CustomFieldType';

export class CustomField {
    public fieldKey: string;
    public fieldValues: string[];
    public fieldType: string;
    public optional: boolean;
    public multiSelect: boolean;

    constructor() {
        this.fieldKey = '';
        this.fieldValues = [''];
        this.fieldType = '';
        this.optional = true;
        this.multiSelect = false;
    }

    public get isTextField() {
        return this.fieldType === CustomFieldType.TEXT;
    }

    public get isSelectionField() {
        return this.fieldType === CustomFieldType.SELECTION;
    }

    public get isOptional() {
        return this.optional;
    }

    public get isMultiSelect() {
        return this.isSelectionField && this.multiSelect;
    }
}
