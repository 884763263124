const isProdBuild = process.env.REACT_APP_VERBOSITY === 'ERROR';

class MspDateGenerator {
    private readonly _date: Date;
    private readonly year: number;
    private readonly getMonthName: (date: Date) => string;

    constructor() {
        this._date = new Date();
        this.year = this.date.getUTCFullYear();
        this.getMonthName = new Intl.DateTimeFormat('en-US', { month: 'short', timeZone: 'UTC' }).format;
    }

    private get date(): Date {
        if (isProdBuild) return this._date;

        const debugDate = localStorage.getItem('DEBUG:browserDate');
        return debugDate ? new Date(debugDate) : this._date;
    }

    public getCurrentDate() {
        const month = this.getMonthName(this.date);
        return `${month} ${this.year}`;
    }

    public getMonthYear(receiptTime: number) {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', timeZone: 'UTC' };
        const monthYear = new Intl.DateTimeFormat('en', options).format(receiptTime);
        return monthYear;
    }

    public getDayOfTheMonth() {
        return this.date.getDate();
    }

    public getDates(value?: string) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const years = [this.year - 1, this.year, this.year + 1];

        const shortDates = Array<string>();

        years.forEach(val => {
            months.forEach(mon => {
                const date = value ? `${mon} ${val} (${value})` : `${mon} ${val}`;
                shortDates.push(date);
            });
        });

        return shortDates;
    }

    public getFullYear() {
        return this.year;
    }

    public isMonthEnd() {
        return this.getDayOfTheMonth() >= 23;
    }

    public parsePolarisDate(date: string) {
        const monthOffset = 1;
        const [year, month, day] = date.split('-').map(s => Number(s));

        const localDate = new Date(year, month - monthOffset, day, 0, 0, 0, 0);

        return localDate;
    }

    public parseCurrentTime(currentTime: number) {
        const localDate = new Date(
            Date.UTC(
                new Date(currentTime).getUTCFullYear(),
                new Date(currentTime).getUTCMonth(),
                new Date(currentTime).getUTCDate(),
                new Date(currentTime).getUTCHours(),
                new Date(currentTime).getUTCMinutes(),
                new Date(currentTime).getUTCSeconds(),
                new Date(currentTime).getUTCMilliseconds()
            )
        );

        return localDate;
    }

    public getCurrentMonthShort() {
        return new Date().toLocaleString(navigator.language, { month: 'short' });
    }

    public getEndOfTheLastDayOfTheCurrentMonth(): number {
        const now = new Date();
        const startOfNextMonth = Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 1);
        const endOfLastDayOfTheMonth = startOfNextMonth - 1;

        return endOfLastDayOfTheMonth;
    }

    public getFirstDayOfTheCurrentMonth(): number {
        const now = new Date();
        const firstDayOfTheCurrentMonth = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1);

        return firstDayOfTheCurrentMonth;
    }

    public getStartOfYear(year?: number): number {
        const currentYear = new Date().getUTCFullYear();

        const date = new Date(Date.UTC(year ?? currentYear, 0, 1, 0, 0, 1));
        return date.getTime();
    }

    public getEndOfYear(year?: number): number {
        const currentYear = new Date().getUTCFullYear();

        const date = new Date(Date.UTC(year ?? currentYear, 11, 31, 23, 59, 59));
        return date.getTime();
    }
}

export const DateGenerator = new MspDateGenerator();
