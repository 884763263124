import { MspTask, TaskAction, TaskComment, TaskStatus } from '@amzn/merp-core/models/budgetManagement';
import { YearForecast } from 'modules/budgetManagement/model/YearForecast';

class RequestChangeProcessor {
    private MSP_TASK_ENTITY = 'Budget';

    public createRequestChangeTask(forecast: YearForecast, budgetId: string, creatorAlias: string): MspTask {
        const task = new MspTask();

        task.associatedEntity = this.MSP_TASK_ENTITY;
        task.entityId = budgetId;
        task.action = TaskAction.BUDGET_CHANGE_REQUEST;
        task.status = TaskStatus.PENDING;
        task.forecastIds = [forecast.id];
        task.assignedTo = forecast.assignedOwner || forecast.owner || '';
        task.requestedBy = creatorAlias;

        if (forecast.comment) {
            const comment = new TaskComment();
            comment.description = forecast.comment;
            comment.forecastId = forecast.id;
            task.comments = [comment];
        }

        return task;
    }
}

export const requestChangeProcessor = new RequestChangeProcessor();
