import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnLayout } from '@amzn/awsui-components-react';

import { ReceiptVersionRequest, ReceiptVersion } from 'modules/audit/models';
import { ReceiptVersionList } from 'modules/audit/components/ReceiptVersionList/ReceiptVersionList';

import styles from './OriginalReceiptVersion.module.scss';

export interface OriginalReceiptVersionProps {
    currency: string;
    receiptVersions: ReceiptVersion[];
    uncommittedBalance: number;
}

export const OriginalReceiptVersion = (props: OriginalReceiptVersionProps) => {
    const { currency, receiptVersions, uncommittedBalance } = props;
    const { t } = useTranslation('purchase');

    function getModifiedRequests() {
        return receiptVersions.map(
            ({ modifiedReceiptAmount, modifiedReceiptTime }) =>
                new ReceiptVersionRequest(modifiedReceiptAmount, modifiedReceiptTime)
        );
    }

    return (
        <ColumnLayout columns={1} className={styles['receipt-version-container']}>
            <div data-awsui-column-layout-root="true">
                <div>
                    <strong>{t('version.original')}</strong>
                </div>
                <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root="true">
                        <ReceiptVersionList
                            requests={getModifiedRequests()}
                            currency={currency}
                            uncommittedBalance={uncommittedBalance}
                        />
                    </div>
                </ColumnLayout>
            </div>
        </ColumnLayout>
    );
};
