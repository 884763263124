export enum ReportName {
    ExportPurchaseList = 'ExportPurchaseList',
    ExportChannelSpend = 'ExportChannelSpend',
    ExportMonthEndClose = 'ExportMonthEndClose',
    ExportPastMonth = 'ExportPastMonth',
    ExportPostClose = 'ExportPostClose',
    ExportBulkEditSpend = 'ExportBulkEditSpend',
    ExportGuidanceCycle = 'ExportGuidanceCycle',
    BulkEditSpendUpload = 'BulkEditSpendUpload',
    BulkReceivingSpendUpload = 'BulkReceivingSpendUpload',
    ExportAccountingString = 'ExportAccountingString',
    BulkEditAccountingStringUpload = 'AccountingStringUpload',
    ExportCreditMemo = 'ExportCreditMemo',
    MecUnconfirmedPurchases = 'MecUnconfirmedPurchases'
}

export enum ReportStatus {
    Pending = 'Pending',
    Started = 'Started',
    Completed = 'Completed',
    Failed = 'Failed',
    Canceled = 'Canceled'
}

export interface ReportReqBody {
    report: ReportName;
    timezone: string;
    alias?: string;
    year?: number;
    file?: JSON[];
}

export interface ReportRequestDetail {
    reportName: ReportName;
    needAlias?: boolean;
    needYear?: boolean;
}

const exportPurchaseListDetails = {
    reportName: ReportName.ExportPurchaseList,
    needAlias: false,
    needYear: false
};

const exportChannelSpendDetails = {
    reportName: ReportName.ExportChannelSpend,
    needAlias: false,
    needYear: false
};

const exportGuidanceCycleDetails = {
    reportName: ReportName.ExportGuidanceCycle,
    needAlias: false,
    needYear: false
};

const exportMonthEndCloseDetails = {
    reportName: ReportName.ExportMonthEndClose,
    needAlias: true,
    needYear: false
};

const exportPastMonthDetails = {
    reportName: ReportName.ExportPastMonth,
    needAlias: true,
    needYear: false
};

const exportPostCloseDetails = {
    reportName: ReportName.ExportPostClose,
    needAlias: true,
    needYear: false
};

const exportCreditMemoDetails = {
    reportName: ReportName.ExportCreditMemo,
    needAlias: true,
    needYear: false
};

const exportPurchaseConfirmationReport: ReportRequestDetail = {
    reportName: ReportName.MecUnconfirmedPurchases
};

export const ReportIdWithDetails: Record<string, ReportRequestDetail> = {
    exportPoListReport: exportPurchaseListDetails,
    channelSpendReports: exportChannelSpendDetails,
    guidanceCycleReport: exportGuidanceCycleDetails,
    exportMECReports: exportMonthEndCloseDetails,
    exportPastMonthReports: exportPastMonthDetails,
    exportPostCloseReports: exportPostCloseDetails,
    exportCreditMemoReport: exportCreditMemoDetails,
    exportPurchaseConfirmationReport
};

export interface ReportParams {
    [key: string]: string | number | JSON[];
}
