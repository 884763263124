import { v4 as uuidV4 } from 'uuid';
import BigNumber from 'bignumber.js';

import { ReceiptDateValidator } from './ReceiptDateValidator';
import { ReceiptStatus } from './ReceiptStatus';
import { CoupaReceiptType } from './CoupaReceiptType';
import { InvoiceAllocation } from 'modules/invoices/models/InvoiceAllocation';
import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { DateGenerator } from 'modules/core/class';
import { ReceiptType } from '@amzn/merp-core/models/receipt';

export class Receipt {
    public purchaseId?: string;
    public orderNumber?: string;
    public receiptNumber: string;
    public receiptTime: number;
    public reportTime?: number;
    public receiptAmount: number;
    public billedAmount: number;
    public receiptId?: string;
    public receiptStatus?: ReceiptStatus;
    public voidRequest?: boolean;
    public lineNumber: number;
    public creator?: string;

    public statusDescription?: string;
    public orderId?: string;
    public version?: number;
    public versionCategory?: string;
    public versionDescription?: string;
    public createTime?: number;
    public lastModifiedTime?: number;
    public adCategory?: string;
    public allocations: InvoiceAllocation[];
    public channelAllocations: ChannelSpend[];
    public confirmStatus?: boolean;
    public quantity?: number;
    public receivingType?: CoupaReceiptType;
    public pricePerUnit?: number;

    private pendingOrFailedReceiptStatuses = [
        ReceiptStatus.PENDING_POST,
        ReceiptStatus.POST_FAILURE,
        ReceiptStatus.PENDING_VOID,
        ReceiptStatus.VOID_FAILURE
    ];

    constructor() {
        this.receiptTime = Date.now();
        this.receiptAmount = 0;
        this.billedAmount = 0;
        this.receiptNumber = uuidV4();
        this.versionCategory = '';
        this.versionDescription = '';
        this.allocations = [];
        this.channelAllocations = [];
        this.lineNumber = 1;
    }

    public get hasValidReceiptAmount() {
        return this.receiptAmount !== 0 && !isNaN(this.receiptAmount);
    }

    public get hasValidQuantity() {
        return this.quantity !== 0 && this.quantity && !isNaN(this.quantity);
    }

    public get hasValidReceiptDate() {
        let validDate = false;

        if (this.receiptTime) {
            const date = new Date(this.receiptTime);
            validDate = date.getUTCFullYear() > ReceiptDateValidator.invalidYear();
        }

        return validDate;
    }

    public get isFullyBilled() {
        return new BigNumber(this.receiptAmount).minus(this.billedAmount).toNumber() < 0.1;
    }

    public get unBilled() {
        const coalescedReceiptAmount = isNaN(this.receiptAmount) ? 0 : this.receiptAmount;
        const unBilledAmount = new BigNumber(coalescedReceiptAmount || 0).minus(this.billedAmount).toNumber();

        return unBilledAmount;
    }

    public get hasAllocations() {
        return this.allocations.length > 0;
    }

    public get hasValidAllocationTotal() {
        const allocationTotal = this.allocations.reduce((p, alloc) => p.plus(alloc.allocatedAmount), new BigNumber(0));

        return allocationTotal.toNumber() === this.billedAmount;
    }

    public get hasValidBilledAmount() {
        return this.billedAmount <= this.receiptAmount;
    }

    public get hasValidChannels() {
        if (!this.channelAllocations || this.channelAllocations.length === 0) return true;

        return this.channelAllocations.every(channel => channel.amount >= 0) && this.hasValidChannelTotal;
    }

    public get hasValidChannelTotal() {
        const total = this.channelAllocations.reduce((p, channel) => p.plus(channel.amount), new BigNumber(0));
        return total.toNumber() === Number(this.receiptAmount);
    }

    public get qualifiesForConfirmation() {
        const endOfLastDayOfTheMonth = DateGenerator.getEndOfTheLastDayOfTheCurrentMonth();

        return this.receiptTime && this.receiptTime <= endOfLastDayOfTheMonth && !this.confirmStatus;
    }

    public get requiresConfirmation() {
        const endOfLastDayOfTheMonth = DateGenerator.getEndOfTheLastDayOfTheCurrentMonth();

        return this.receiptTime <= endOfLastDayOfTheMonth;
    }

    public get receiptType(): ReceiptType {
        const currentTime = Date.now();
        const currentYear = new Date(currentTime).getUTCFullYear();
        const currentMonth = new Date(currentTime).getUTCMonth();

        const receiptYear = new Date(this.receiptTime).getUTCFullYear();
        const receiptMonth = new Date(this.receiptTime).getUTCMonth();

        if (receiptYear === currentYear && receiptMonth === currentMonth) return ReceiptType.CURRENT_MONTH_RECEIPT;

        if (receiptYear > currentYear || (receiptYear === currentYear && receiptMonth > currentMonth)) {
            return ReceiptType.FUTURE_MONTH_RECEIPT;
        }

        return ReceiptType.PAST_MONTH_RECEIPT;
    }

    public get canEditReceipt(): boolean {
        return (
            (this.receiptStatus === ReceiptStatus.PENDING_POST &&
                this.receiptType === ReceiptType.FUTURE_MONTH_RECEIPT) ||
            (this.receiptStatus === ReceiptStatus.PENDING_POST && !this.confirmStatus) ||
            this.receiptStatus === ReceiptStatus.CORRECTION
        );
    }

    public get hasPendingOrFailedReceipt() {
        if (this.receiptStatus !== undefined) return this.pendingOrFailedReceiptStatuses.includes(this.receiptStatus);
        return false;
    }

    public get isFutureOrPendingOrFailed() {
        if (this.receiptTime > DateGenerator.getEndOfTheLastDayOfTheCurrentMonth()) {
            return true;
        }

        return this.hasPendingOrFailedReceipt;
    }
}
