import React from 'react';

import { ColumnLayout } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { DownloadBulkEditSpend, UploadBulkEditSpend } from 'modules/bulkEdit/components';

export const BulkEditSpend = () => {
    const { t } = useTranslation('bulkEdit');

    return (
        <>
            <div>
                <h1>{t('bulkUpload')}</h1>
            </div>
            <br />
            <ColumnLayout columns={2}>
                <div>
                    <h3>{t('bulkEditDownload')}</h3>
                    <br />
                    <DownloadBulkEditSpend />
                </div>
                <div>
                    <h3>{t('bulkEditUpload')}</h3>
                    <br />
                    <UploadBulkEditSpend />
                </div>
            </ColumnLayout>
        </>
    );
};
