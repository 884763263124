import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ReportIdWithDetails,
    ReportRequestDetail,
    ReportReqBody,
    ReportName,
    ReportParams
} from 'modules/reports/constants';
import { logger } from 'modules/core';
import { ReportResult } from 'modules/reports/model';
import { ApplicationState } from 'modules/app/store';
import { reportsService } from 'modules/reports/services';
import { read as xlsxRead, utils as xlsxUtils, WorkBook } from 'xlsx';

export function useReports() {
    const [report, updateReport] = useState<ReportResult | undefined>(undefined);
    const alias = useSelector((state: ApplicationState) => state.user.alias);
    const [userUploadedEditTabs, updateUserUploadedEditTabs] = useState<string[]>([]);

    const unableToFetchWorkbook = 'Unable To fetch Workbook from uploaded file';

    const [createInProgress, updateCreateInProgress] = useState(false);
    const [uploadInProgress, updateUploadInProgress] = useState(false);

    async function createReportAsync(eventId: string, reportParams?: ReportParams) {
        const reportRequestDetails = ReportIdWithDetails[eventId];
        updateCreateInProgress(true);

        const reqBody = reportRequestDetails
            ? createRequestBody(reportRequestDetails, reportParams)
            : createRequestBody({ reportName: eventId as ReportName }, reportParams);

        const result = await reportsService.createReportAsync(reqBody);

        updateReport(result);
        updateCreateInProgress(false);
    }

    function createRequestBody(requestDetail: ReportRequestDetail, reportParams?: ReportParams) {
        const report = requestDetail.reportName;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const requestBody = ({ report, timezone } as unknown) as ReportReqBody;

        if (requestDetail.needAlias) {
            requestBody.alias = alias;
        }

        if (requestDetail.needYear) {
            const year = new Date().getFullYear();
            requestBody.year = year;
        }

        return { ...requestBody, ...reportParams };
    }

    async function uploadReportAsync(
        fileUrl: string,
        reportName: ReportName,
        sheetTab?: string,
        reportParams?: ReportParams
    ) {
        if (!sheetTab) return;

        await uploadReportsAsync(fileUrl, reportName, [sheetTab], reportParams);
    }

    async function uploadReportsAsync(
        fileUrl: string,
        reportName: ReportName,
        editedSheetNames?: string[],
        reportParams?: ReportParams
    ) {
        if (!editedSheetNames) return;

        const workBook = await getWorkBook(fileUrl, editedSheetNames);
        updateUploadInProgress(true);
        if (!workBook) {
            updateReport({ succeeded: false, error: unableToFetchWorkbook });
            updateUploadInProgress(false);
            return;
        }

        updateUserUploadedEditTabs(editedSheetNames);
        const convertedWorkBook = convertWorkBookToJSONArray(workBook, editedSheetNames);

        const reqBody = createRequestBody({ reportName }, { file: convertedWorkBook, ...reportParams });
        const result = await reportsService.createReportAsync(reqBody, getUploadPath(reportName));

        updateReport(result);
        updateUploadInProgress(false);
    }

    function convertWorkBookToJSONArray(workBook: WorkBook, editedSheetNames: string[]): JSON[] {
        let jsonWorkBook: JSON[] = [];
        editedSheetNames.forEach((sheetName: string) => {
            const workSheet = workBook.Sheets[sheetName];
            if (!workSheet) return;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const jsonResult: any[] = xlsxUtils.sheet_to_json(workSheet);
            const labeledRows = jsonResult.map(row => {
                row['Tab/Sheet Name'] = sheetName;
                return row;
            });

            jsonWorkBook = jsonWorkBook.concat(labeledRows as JSON[]);
        });
        return jsonWorkBook;
    }

    async function getWorkBook(fileUrl: string, sheets: string[]): Promise<WorkBook | undefined> {
        try {
            const resp = await fetch(fileUrl);
            return xlsxRead(await resp.arrayBuffer(), { type: 'array', sheets: sheets });
        } catch (error) {
            logger.error(error);
        }
    }

    function getUploadPath(reportName: ReportName) {
        if (reportName === ReportName.BulkEditSpendUpload) return 'bulkEditSpendsUpload';
        if (reportName === ReportName.BulkEditAccountingStringUpload) return 'bulkEditAccountingString';
        return undefined;
    }

    return {
        report,
        createInProgress,
        uploadInProgress,
        userUploadedEditTabs,
        createReportAsync,
        uploadReportAsync,
        uploadReportsAsync
    };
}
