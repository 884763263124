/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { RollUpsView } from '../views';

export function RollUpRoutes(_props: RouteComponentProps) {
    const Routes = { RollUps: '/*' };

    return (
        <Router>
            <RollUpsView path={Routes.RollUps} />
        </Router>
    );
}
