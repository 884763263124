import React, { useEffect } from 'react';
import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { RouteComponentProps } from '@reach/router';
import { ApplicationState, isFinancialAnalyst, showErrorPage } from 'modules/app/store';
import { ErrorRouter } from 'modules/error/components';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BulkEditAccountingStrings } from '../components';

export const BulkEditCoa = (_props: RouteComponentProps) => {
    const { t } = useTranslation('bulkEdit');
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const dispatch = useDispatch();
    const isFA = useSelector(isFinancialAnalyst);

    const breadcrumbItems = [
        { text: t('coaStingsBreadCrumbs.dashboard'), href: '/budget' },
        {
            text: t('coaStingsBreadCrumbs.bulkEditCoa'),
            href: '#'
        }
    ];

    useEffect(() => {
        if (isFA) return;

        dispatch(showErrorPage({ errorCode: 401 }));
    }, []);

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                ) : (
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <BulkEditAccountingStrings />
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </div>
                )
            }
            breadcrumbs={<BreadcrumbGroup ariaLabel="Breadcrumbs" items={breadcrumbItems} />}
            toolsHide={true}
            navigationHide={true}
        />
    );
};
