import { CoupaPurchaseLineType } from '@amzn/merp-core/models';

export class BulkReceivingRequest {
    public purchaseId?: string;
    public orderNumber?: string;
    public lineNumber?: number;
    public lineType?: CoupaPurchaseLineType;
    public amount?: number;
    public quantity?: number;
    public receiveInFull?: boolean;
}
