export class CoaDropdownOptions {
    company: string[];
    costCenter: string[];
    productLine: string[];
    channel: string[];
    glAccount: string[];
    location: string[];
    project: string[];
    customEighthSegment: string[];

    constructor() {
        this.company = [];
        this.costCenter = [];
        this.productLine = [];
        this.channel = [];
        this.glAccount = [];
        this.location = [];
        this.project = [];
        this.customEighthSegment = [];
    }
}
