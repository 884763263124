import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Flashbar } from '@amzn/awsui-components-react';

import { useAppNotifications } from 'modules/app/hooks';

import styles from './AppNotifications.module.scss';

export const AppNotifications = () => {
    const { t } = useTranslation('app');
    const { notifications, updateNotification, dismissNotification } = useAppNotifications();

    for (const notification of notifications) {
        notification.content = <div>{notification.textContent}</div>;

        if (notification.canBeDiscarded) {
            notification.action = (
                <Checkbox
                    className={styles['notification-discard-checkbox']}
                    checked={notification.discarded}
                    onChange={event => updateNotification(notification, event.detail.checked)}
                >
                    {t('doNotShowAgain')}
                </Checkbox>
            );
        }

        notification.onDismiss = () => dismissNotification(notification);
    }

    return <Flashbar items={notifications} />;
};
