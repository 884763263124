import BigNumber from 'bignumber.js';

import { Receipt } from '@amzn/merp-core/models/receipt/';
import { DateGenerator } from 'modules/core/class';

export class MonthDistributionsV2 {
    private static generateMonths() {
        const dates = DateGenerator.getDates();
        const dateAmountMap = new Map<string, number>([]);

        dates.forEach(date => dateAmountMap.set(date, 0));

        return dateAmountMap;
    }

    public static mapReceiptAmountDate(receipts?: Receipt[]) {
        const dateAmountMap = this.generateMonths();
        receipts?.forEach(receipt => {
            const receiptDate = DateGenerator.getMonthYear(receipt.receiptTime);
            if (dateAmountMap.has(receiptDate)) {
                const receiptAmount = receipt.receiptAmount;

                const totalAmount = new BigNumber(dateAmountMap.get(receiptDate) || 0).plus(receiptAmount).toNumber();
                dateAmountMap.set(receiptDate, totalAmount);
            }
        });

        return dateAmountMap;
    }

    public static mapInvoiceAmountDate(receipts?: Receipt[], isBilled?: boolean) {
        const dateAmountMap = this.generateMonths();
        receipts?.forEach(receipt => {
            const receiptDate = DateGenerator.getMonthYear(receipt.receiptTime);
            if (dateAmountMap.has(receiptDate)) {
                const amount = isBilled
                    ? this.getReceiptBilledAmount(receipt)
                    : this.unBilledReceipt(receipt.receiptAmount, receipt.billedAmount);
                const totalAmount = new BigNumber(dateAmountMap.get(receiptDate) || 0).plus(amount).toNumber();
                dateAmountMap.set(receiptDate, totalAmount);
            }
        });

        return dateAmountMap;
    }

    public static unBilledReceipt(receiptAmount: number, billedAmount: number) {
        const coalescedReceiptAmount = isNaN(receiptAmount) ? 0 : receiptAmount;
        const coalescedBilledAmount = billedAmount ?? 0;

        const unBilledAmount = new BigNumber(coalescedReceiptAmount || 0).minus(coalescedBilledAmount).toNumber();

        return unBilledAmount;
    }

    private static getReceiptBilledAmount(receipt: Receipt) {
        return receipt.billedAmount ?? 0;
    }
}
