import * as yup from 'yup';

export const InvoiceSchema = yup.object().shape({
    allocation: yup
        .array()
        .min(1, 'Pick at least 1 item')
        .of(
            yup
                .number()
                .typeError('Enter Valid Amount')
                .required('Amount is required')
                .test('is-valid-decimal', 'Invalid decimals in amount', value => {
                    return (value + '').match(/^\d*\.{1}\d{3,}$/g) === null;
                })
        )
});
