import React from 'react';

import style from '../BigOChannelMappings/BigOChannelMappings.module.scss';
import { useTranslation } from 'react-i18next';
import { CoaMapping } from 'modules/mappings/models';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';

interface DeleteBigOChannelMappingModalProps {
    mapping: CoaMapping;
    onCancel: () => void;
    onRemove: (mapping: CoaMapping) => void;
}

export const DeleteBigOChannelMappingModal = (props: DeleteBigOChannelMappingModalProps) => {
    const { mapping, onCancel, onRemove } = props;
    const { t } = useTranslation('mappings');
    const title = t('bigOChannelMappings.deleteCoaCombination');

    const onModalSave = () => {
        onRemove(mapping);
    };

    const displayAttribute = (isCOA: boolean, coaName: string, coaValue: string) => (
        <div className={isCOA ? style['div-coa'] : style['div-attributes']}>
            <div>
                <p>{t(`bigOChannelMappings.${coaName}`)}</p>
            </div>
            <div>{coaValue}</div>
        </div>
    );

    const bigObjectiveAndChannel = (
        <SpaceBetween direction="horizontal" size="s">
            {displayAttribute(false, 'bigObjective', t(`dropdown.bigOList.${mapping.bigObjective}`))}
            {displayAttribute(false, 'channelType', t(`dropdown.channelsList.${mapping.channelType}`))}
        </SpaceBetween>
    );

    const coaSegments = (
        <SpaceBetween direction="horizontal" size="xxl">
            {displayAttribute(true, 'company', mapping.company)}
            {displayAttribute(true, 'location', mapping.location)}
            {displayAttribute(true, 'costCenter', mapping.costCenter)}
            {displayAttribute(true, 'glAccount', mapping.glAccount)}
            {displayAttribute(true, 'productLine', mapping.productLine)}
            {displayAttribute(true, 'channel', mapping.channel)}
            {displayAttribute(true, 'project', mapping.project)}
            {displayAttribute(true, 'customEighthSegment', mapping.customEighthSegment)}
        </SpaceBetween>
    );

    return (
        <Modal
            visible={true}
            header={title}
            onDismiss={onCancel}
            size="large"
            footer={
                <Box float="right">
                    <Button variant="link" onClick={onCancel}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={onModalSave}>
                        {t('bigOChannelMappings.yesRemoveIt')}
                    </Button>
                </Box>
            }
        >
            <div className={style['modal-div']}>
                <h2>{t('bigOChannelMappings.coaValue')}</h2>
                {coaSegments}
                <br />
                {bigObjectiveAndChannel}
            </div>
        </Modal>
    );
};
