import * as yup from 'yup';

export const OwnerSchema = yup.object().shape({
    userAlias: yup
        .string()
        .min(1)
        .max(8)
        .ensure()
        .required('User Alias is required!')
});
