import { logger } from 'modules/core';
import { Thread, Message } from '../models';
import { CreateThreadQuery, CreateMessageQuery } from 'modules/core/model';
import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ApiError } from 'modules/core/types';

interface GetThreadListResult {
    threadList: Thread[];
    status: number;
}

interface GetMessageListResult {
    messageList: Message[];
    status: number;
}

interface CreateResult {
    creationSuccess: boolean;
    errorDescription: string;
}

export class MessageThreadService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'messageThreads';

    public async getThreadsByPurchaseIdAsync(id: string): Promise<GetThreadListResult> {
        try {
            const jsonArray: Thread[] = await this.get(`${id}/threads`);
            return { threadList: jsonArray, status: 200 };
        } catch (error) {
            logger.error(error);
            const result: Thread[] = [];
            return { threadList: result, status: (error as ApiError)?.response?.status };
        }
    }

    public async createThread(body: CreateThreadQuery): Promise<CreateResult> {
        try {
            const result: CreateResult = await this.put('createThread', {
                body
            });
            return result;
        } catch (error) {
            logger.error(error);
            return { creationSuccess: false, errorDescription: String(error) };
        }
    }

    public async getMessagesByThreadIdAsync(id: string): Promise<GetMessageListResult> {
        try {
            const jsonArray: Message[] = await this.get(`${id}/messages`);
            return { messageList: jsonArray, status: 200 };
        } catch (error) {
            logger.error(error);
            const result: Message[] = [];
            return { messageList: result, status: (error as ApiError)?.response?.status };
        }
    }

    public async createMessage(body: CreateMessageQuery): Promise<CreateResult> {
        try {
            const result: { replySuccess: boolean; errorDescription: string } = await this.put('createMessage', {
                body
            });
            return { creationSuccess: result.replySuccess, errorDescription: result.errorDescription };
        } catch (error) {
            logger.error(error);
            return { creationSuccess: false, errorDescription: String(error) };
        }
    }
}

export const messageThreadService = new MessageThreadService();
