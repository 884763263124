import { useState, useEffect, useCallback } from 'react';
import { CoaDropdownOptions, CoaMapping } from '../models';
import { useDispatch } from 'react-redux';

import { BigOChannelMapping } from '../models/BigOChannelMapping';
import { bigOChannelMappingsService } from '../services/bigOChannelMappings';
import { coaSegmentsService } from '../services/coaSegments';

import { showErrorPage } from 'modules/app/store';

export interface SubmitResult {
    status: 'CREATE_SUCCESS' | 'DELETE_SUCCESS' | 'UPDATE_SUCCESS' | 'SUBMIT_FAILURE' | 'NOT_SUBMITTED';
    reason?: string;
}

export function useBigOChannelMappings() {
    const dispatch = useDispatch();

    const [fetchedMappings, setFetchedMappings] = useState<BigOChannelMapping[]>();
    const [coaDropdownOptions, setCoaDropdownOptions] = useState<CoaDropdownOptions>();
    const [bigOChannelMappings, setBigOChannelMappings] = useState<CoaMapping[]>([]);
    const [repeatedMapping, setRepeatedMapping] = useState<boolean>(false);
    const [submitSuccess, setSubmitSuccess] = useState<SubmitResult>({
        status: 'NOT_SUBMITTED',
        reason: ''
    });

    const getMappingsAsync = useCallback(async () => {
        const result = await bigOChannelMappingsService.getMappings();

        if (result.response === undefined && result.status !== undefined) {
            dispatch(showErrorPage({ errorCode: result.status }));
            return;
        }

        setFetchedMappings(result.response);
        setRepeatedMapping(false);
    }, [setFetchedMappings]);

    async function getCoaSegmentsAsync(): Promise<void> {
        const result = await coaSegmentsService.getCoaSegments();

        if (result.response === undefined && result.status !== undefined) {
            dispatch(showErrorPage({ errorCode: result.status }));
            return;
        }

        setCoaDropdownOptions(result.response);
    }

    async function saveUpdatedMappings(mapping: CoaMapping) {
        const formattedBigOMapping = new BigOChannelMapping(mapping);
        const response = await bigOChannelMappingsService.updateMappings([formattedBigOMapping]);
        const { result, message } = response;

        const resultStatus = result ? 'UPDATE_SUCCESS' : 'SUBMIT_FAILURE';
        setSubmitSuccess({ status: resultStatus, reason: message });

        if (result) {
            reloadPageAsync();
        }
    }

    function reloadPageAsync() {
        getMappingsAsync();
        getCoaSegmentsAsync();
    }

    async function addNewMappingsAsync(mapping: CoaMapping) {
        const bigOMapping = new BigOChannelMapping(mapping);

        const { result, message } = await bigOChannelMappingsService.createMapping(bigOMapping);

        const resultStatus = result ? 'CREATE_SUCCESS' : 'SUBMIT_FAILURE';
        setSubmitSuccess({ status: resultStatus, reason: message });

        if (result) {
            setRepeatedMapping(false);
            reloadPageAsync();
        } else setRepeatedMapping(true);
    }

    async function deleteRemovedMappingsAsync(mappingId: string) {
        const { result, message } = await bigOChannelMappingsService.deleteMapping(mappingId);

        const resultStatus = result ? 'DELETE_SUCCESS' : 'SUBMIT_FAILURE';
        setSubmitSuccess({ status: resultStatus, reason: message });

        if (result) {
            reloadPageAsync();
        }
    }

    useEffect(() => {
        if (!fetchedMappings) {
            getMappingsAsync();
        }
        if (!coaDropdownOptions) {
            getCoaSegmentsAsync();
        }
    }, [getMappingsAsync]);

    useEffect(() => {
        const coaMappings: CoaMapping[] = [];
        fetchedMappings?.forEach(mapping =>
            coaMappings.push(new CoaMapping(mapping.coaString, mapping.bigObjective, mapping.channelType, mapping.id))
        );
        setBigOChannelMappings(coaMappings);
    }, [fetchedMappings, setBigOChannelMappings]);

    return {
        deleteRemovedMappingsAsync,
        saveUpdatedMappings,
        addNewMappingsAsync,
        setRepeatedMapping,
        bigOChannelMappings,
        coaDropdownOptions,
        repeatedMapping,
        submitSuccess
    };
}
