/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

export type COACombinationTablePropertyFilterProps = {
    resetList: () => void;
    setTeamName: (teamName: string) => void;
};

export const COACombinationTablePropertyFilter = (props: COACombinationTablePropertyFilterProps) => {
    const { resetList, setTeamName } = props;

    const { t } = useTranslation('onboarding');

    const [query, setQuery] = useState({
        tokens: [],
        operation: 'and' as PropertyFilterProps.JoinOperation
    });

    const filteringProperties: PropertyFilterProps.FilteringProperty[] = [
        {
            propertyLabel: t('COACombinationTablePropertyFilter.properties.teamNameLabel'),
            key: t('COACombinationTablePropertyFilter.properties.teamNameKey'),
            groupValuesLabel: t('COACombinationTablePropertyFilter.properties.teamNameLabel') + ' name values',
            operators: ['=']
        }
    ];

    const i18nStrings: PropertyFilterProps.I18nStrings = {
        filteringAriaLabel: t('COACombinationTablePropertyFilter.filteringAriaLabel'),
        dismissAriaLabel: t('COACombinationTablePropertyFilter.dismissAriaLabel'),

        filteringPlaceholder: t('COACombinationTablePropertyFilter.placeholder'),
        groupValuesText: t('COACombinationTablePropertyFilter.groupValuesText'),
        groupPropertiesText: t('COACombinationTablePropertyFilter.groupPropertiesText'),
        operatorsText: t('COACombinationTablePropertyFilter.operatorsText'),

        operationAndText: t('COACombinationTablePropertyFilter.operationAndText'),
        operationOrText: t('COACombinationTablePropertyFilter.operationOrText'),

        operatorLessText: t('COACombinationTablePropertyFilter.operatorLessText'),
        operatorLessOrEqualText: t('COACombinationTablePropertyFilter.operatorLessOrEqualText'),
        operatorGreaterText: t('COACombinationTablePropertyFilter.operatorGreaterText'),
        operatorGreaterOrEqualText: t('COACombinationTablePropertyFilter.operatorGreaterOrEqualText'),
        operatorContainsText: t('COACombinationTablePropertyFilter.operatorContainsText'),
        operatorDoesNotContainText: t('COACombinationTablePropertyFilter.operatorDoesNotContainText'),
        operatorEqualsText: t('COACombinationTablePropertyFilter.operatorEqualsText'),
        operatorDoesNotEqualText: t('COACombinationTablePropertyFilter.operatorDoesNotEqualText'),

        editTokenHeader: t('COACombinationTablePropertyFilter.editTokenHeader'),
        propertyText: t('COACombinationTablePropertyFilter.propertyText'),
        operatorText: t('COACombinationTablePropertyFilter.operatorText'),
        valueText: t('COACombinationTablePropertyFilter.valueText'),
        cancelActionText: t('COACombinationTablePropertyFilter.cancelActionText'),
        applyActionText: t('COACombinationTablePropertyFilter.applyActionText'),
        allPropertiesLabel: t('COACombinationTablePropertyFilter.allPropertiesLabel'),

        tokenLimitShowMore: t('COACombinationTablePropertyFilter.tokenLimitShowMore'),
        tokenLimitShowFewer: t('COACombinationTablePropertyFilter.tokenLimitShowFewer'),
        clearFiltersText: t('COACombinationTablePropertyFilter.clearFiltersText'),
        removeTokenButtonAriaLabel: () => 'Remove token',
        enteredTextLabel: text => `Use: "${text}"`
    };

    const onSearchRecords = async (detail: any) => {
        resetList();
        setQuery(detail);
        if (detail.tokens.length == 1) {
            setTeamName(detail.tokens[0].value);
        } else {
            setTeamName('');
        }
    };

    return (
        <PropertyFilter
            query={query}
            filteringProperties={filteringProperties}
            filteringOptions={[]}
            onChange={({ detail }: { detail: any }) => {
                onSearchRecords(detail);
            }}
            i18nStrings={i18nStrings}
        />
    );
};
