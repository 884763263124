import { CustomField } from 'modules/customCategory/models';

export function mapToCustomField(json: unknown): CustomField {
    if (!json) {
        throw new Error('JSON Custom Field is not defined');
    }

    const customField = Object.assign(new CustomField(), (json || {}) as CustomField);

    return customField;
}

export function mapToCustomFields(json: unknown[]): CustomField[] {
    return json?.map(mapToCustomField) ?? [];
}
