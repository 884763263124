import { useState, useEffect } from 'react';
import { userTeamService } from '../services';

export function useBrassTeams() {
    const [brassTeams, updateBrassTeams] = useState<string[]>();

    useEffect(() => {
        async function fetchBrassTeams() {
            const userTeamResp = await userTeamService.getUserTeamsAsync();
            const userTeams = userTeamResp?.userTeams ?? [];

            updateBrassTeams(userTeams);
        }

        if (!brassTeams) {
            fetchBrassTeams();
        }
    }, []);

    return { brassTeams };
}
