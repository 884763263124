import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from '@amzn/awsui-components-react';
import { logger } from 'modules/core';
import { useReports } from 'modules/reports/hooks';
import { ReportName } from 'modules/reports/constants';
import { useBulkEditUpload } from 'modules/bulkEdit/hooks';
import { BulkReceivingExcelActionsModal } from 'modules/bulkEdit/components/BulkReceivingExcelActions/BulkReceivingExcelActionsModal';
import { ABPO_BULK_EDIT_TAB, QBPO_BULK_EDIT_TAB } from 'modules/bulkEdit/constants';

export const BulkReceivingExcelActionsUpload = (props: {
    disabled?: boolean;
    showAlert: (alert: JSX.Element) => void;
    dismissAlert?: () => void;
}) => {
    const { t } = useTranslation('bulkReceiving');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { disabled, showAlert, dismissAlert } = props;

    const { uploadReportsAsync, report, uploadInProgress } = useReports();
    const { validateFile } = useBulkEditUpload();

    useEffect(() => {
        if (report?.succeeded) {
            showAlert(uploadSucceededAlert);
            return;
        }

        const error = report?.error || '';
        if (error && error.length > 0) {
            logger.error('uploadReportAsync failed: ', error);
            showAlert(uploadFailedAlert(error));
        }

        return () => {
            onModalClose();
        };
    }, [report]);

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const onModalConfirm = () => {
        showFilePicker();
        onModalClose();
    };

    const showFilePicker = () => {
        const filePicker = document.createElement('input');
        filePicker.dataset.testid = 'filePicker';
        filePicker.type = 'file';
        filePicker.hidden = true;
        let fileName = '';

        filePicker.addEventListener('change', function() {
            if (filePicker.files && filePicker.files.length > 0) {
                const selectedFile = filePicker.files[0];
                fileName = selectedFile.name;
            }
        });
        const currentTimeMs: number = Date.now();

        const uploadFile = async (fileUrl: string) => {
            logger.debug(`uploadReportAsync start. report: ${JSON.stringify(report)}`);
            await uploadReportsAsync(
                fileUrl,
                ReportName.BulkEditSpendUpload,
                [ABPO_BULK_EDIT_TAB, QBPO_BULK_EDIT_TAB],
                {
                    createReceiptsOnly: 'true',
                    fileName: fileName,
                    timeOfUpload: currentTimeMs
                }
            );
        };

        filePicker.addEventListener('cancel', onModalClose);
        filePicker.addEventListener('change', async (e: Event) => {
            const files = (e.target as HTMLInputElement)?.files;
            if (!files || files.length < 1) {
                return;
            }

            const fileUrl = URL.createObjectURL(files[0]);

            try {
                const errors = await validateFile(fileUrl, ReportName.BulkReceivingSpendUpload);
                if (errors && errors.length) {
                    showAlert(uploadFailedAlert(errors.join(', ')));
                    return;
                }
                await uploadFile(fileUrl);
            } catch (error) {
                logger.error('file upload failed: ', error);
                showAlert(uploadFailedAlert(error));
            } finally {
                URL.revokeObjectURL(fileUrl);
                document.body.removeChild(filePicker);
                onModalClose();
            }
        });

        document.body.appendChild(filePicker);
        filePicker.click();
    };

    const uploadSucceededAlert = (
        <Alert type="success" header={t('uploadSucceededAlert.header')} dismissible onDismiss={dismissAlert}>
            {t('uploadSucceededAlert.message')}
        </Alert>
    );

    interface ErrorMessage {
        message: string;
    }

    const isString = (value: unknown): value is string => typeof value === 'string';

    const hasMessageProperty = (value: unknown): value is ErrorMessage =>
        typeof value === 'object' && value !== null && 'message' in value;

    const uploadFailedAlert = (error: unknown) => {
        const getErrorMessage = (error: unknown) => {
            if (isString(error)) return error;

            if (hasMessageProperty(error)) return error.message;

            return t('uploadFailedAlert.message');
        };

        const header = t('uploadFailedAlert.header');
        const message = getErrorMessage(error);

        return (
            <Alert type="error" header={header} dismissible onDismiss={dismissAlert}>
                {message}
            </Alert>
        );
    };

    return (
        <>
            <BulkReceivingExcelActionsModal
                modalVisible={isModalVisible}
                onConfirm={onModalConfirm}
                onCancel={onModalClose}
                header={t('uploadModal.header')}
            >
                <p>{t('uploadModal.rowCountWarning')}</p>
                <br />
                <p>{t('uploadModal.accessInfo')}</p>
                <br />
            </BulkReceivingExcelActionsModal>
            <Button
                data-testid="bulkReceiving.uploadBtn"
                disabled={disabled || isModalVisible || uploadInProgress}
                loading={isModalVisible || uploadInProgress}
                onClick={() => setIsModalVisible(true)}
            >
                {t('actions.upload')}
            </Button>
        </>
    );
};
