import { BigNumber } from 'bignumber.js';

import { Receipt } from 'modules/purchase/models';
import { InvoiceAllocation, InvoiceLine } from '../models';
import { logger } from 'modules/core';
import { DeepCopy } from 'modules/purchase/class';

BigNumber.set({ DECIMAL_PLACES: 2 });

export class InvoiceAllocationHandler {
    public allocateInvoiceLine(receipt: Receipt, invoiceLine: InvoiceLine) {
        const availableReceiptAmount = new BigNumber(receipt.receiptAmount).minus(receipt.billedAmount).toNumber();
        const availableInvoiceAmount = new BigNumber(invoiceLine.amount).minus(invoiceLine.allocatedAmount).toNumber();

        if (availableInvoiceAmount < 0.01 && !invoiceLine.isCredit) {
            return undefined;
        }

        const amount = Math.min(availableInvoiceAmount, availableReceiptAmount);
        receipt.billedAmount = new BigNumber(receipt.billedAmount).plus(amount).toNumber();
        invoiceLine.allocatedAmount = new BigNumber(invoiceLine.allocatedAmount).plus(amount).toNumber();

        const allocation = Object.assign(new InvoiceAllocation(), {
            purchaseLineId: invoiceLine.purchaseLineId,
            invoiceTotal: invoiceLine.invoiceTotal,
            receiptId: receipt.receiptNumber,
            invoiceOfaId: invoiceLine.ofaId,
            invoiceId: invoiceLine.invoiceId,
            invoiceLineId: invoiceLine.id,
            invoiceNumber: invoiceLine.invoiceNumber,
            allocatedAmount: amount
        });

        receipt.allocations.push(allocation);

        return { receipt, invoiceLine };
    }

    public deallocateInvoice(receipt: Receipt, invoiceLine: InvoiceLine) {
        const { allocations } = receipt;

        const index = receipt.allocations.findIndex(alloc => alloc.invoiceLineId === invoiceLine.id);
        const allocation = allocations[index];

        if (index < 0) {
            logger.error(
                `Receipt ${receipt.receiptNumber} does not contain allocation for invoice line with id ${invoiceLine.id}`
            );
            return;
        }

        receipt.billedAmount = new BigNumber(receipt.billedAmount).minus(allocation.allocatedAmount).toNumber();

        invoiceLine.allocatedAmount = new BigNumber(invoiceLine.allocatedAmount)
            .minus(allocation.allocatedAmount)
            .toNumber();

        receipt.allocations.splice(index, 1);

        return { receipt, invoiceLine };
    }

    public reallocateUnbilledAmount(from: Receipt, to?: Receipt) {
        const source = DeepCopy.receipt(from);
        const destination = to ? DeepCopy.receipt(to) : new Receipt();

        destination.receiptAmount = source.unBilled;
        source.receiptAmount = new BigNumber(source.receiptAmount).minus(source.unBilled).toNumber();

        return { source, destination };
    }
}

export const invoiceAllocationHandler = new InvoiceAllocationHandler();
