import thunk from 'redux-thunk';
import { createAction } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, applyMiddleware, AnyAction } from 'redux';

import { userReducer } from './user/user.slice';
import { UserClaims } from 'modules/auth/model';
import { ErrorStatus } from 'modules/error/model';
import { UserPreferences } from 'modules/preferences/model';
import { userPreferencesReducer } from './preferences/preferences.slice';
import { PurchaseProps, purchaseReducer } from './purchase/purchase.slice';
import { appHealthReducer } from './applicationHealth/applicationHealth.slice';
import { OrgCostCentersMapProps, orgCostCentersReducer } from './orgCostCentersMap/orgCostCentersMap.slice';

const appReducer = combineReducers({
    user: userReducer,
    purchaseDetails: purchaseReducer,
    preferences: userPreferencesReducer,
    applicationHealth: appHealthReducer,
    orgCostCenters: orgCostCentersReducer
});

export interface ApplicationState {
    user: UserClaims;
    preferences: UserPreferences;
    applicationHealth: ErrorStatus;
    purchaseDetails: PurchaseProps;
    orgCostCenters: OrgCostCentersMapProps;
}

export const resetPurchase = createAction('purchases/resetPurchase');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === resetPurchase.type) {
        const { user, preferences, applicationHealth } = state as ApplicationState;
        state = { user, preferences, applicationHealth };
    }

    return appReducer(state, action);
};

export function configureStore() {
    return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
}

export * from './user/user.slice';
export * from './purchase/purchase.slice';
export * from './preferences/preferences.slice';
export * from './applicationHealth/applicationHealth.slice';
export * from './orgCostCentersMap/orgCostCentersMap.slice';
