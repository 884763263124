import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { CustomerCOACombinationsPage, CustomerTeamListPage } from 'modules/onboarding/views';

export const OnboardingRoutes = (_props: RouteComponentProps): JSX.Element => {
    const Routes = {
        MyCOAs: '/mycoas',
        MyTeams: '/myteams'
    };

    return (
        <Router>
            <CustomerCOACombinationsPage path={Routes.MyCOAs} />
            <CustomerTeamListPage path={Routes.MyTeams} />
        </Router>
    );
};
