import { Hub } from '@aws-amplify/core';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { logger } from 'modules/core/logger';
import { retry } from 'modules/core/helpers';
import { HubCapsule, UserClaims } from '../model';
import { coupaPermissionService } from 'modules/user/services';

const AMPLIFY_REDIRECT_KEY = 'amplify-redirected-from-hosted-ui';

export class AmplifyAuthService {
    private user?: CognitoUser;
    private session?: CognitoUserSession;
    private readonly AuthChannel = 'auth';

    constructor() {
        Hub.listen(this.AuthChannel, (capsule: HubCapsule) => Promise.resolve(this.onHubCapsuleAsync(capsule)));
    }

    public async isAuthorizedAsync(): Promise<boolean> {
        const redirectedFromSignIn = 'true' === localStorage.getItem(AMPLIFY_REDIRECT_KEY);

        if (redirectedFromSignIn) {
            return await this.isAuthorizedWithRetryAsync(10, 1000);
        } else {
            return await this.isAuthorizedWithoutRetryAsync();
        }
    }

    public isAuthorizedWithRetryAsync(retries: number, timeoutInMilliseconds: number): Promise<boolean> {
        return new Promise(async resolve => {
            const authorized = await this.isAuthorizedWithoutRetryAsync();
            if (authorized) {
                return resolve(authorized);
            }
            const checkUserSession = {
                taskName: 'Check Authenticated user',
                maxCount: retries,
                stopCondition: () => this.user !== undefined,
                done: () => resolve(this.user !== undefined),
                interval: timeoutInMilliseconds
            };
            // We need to wait for event to trigger (if it does) before resolving promise,
            // if the event is not triggered, `isAuthorized` will return false.
            retry(checkUserSession).run();
        });
    }

    public async isAuthorizedWithoutRetryAsync(): Promise<boolean> {
        try {
            await this.loadCognitoUserAsync();
            return this.user !== undefined;
        } catch (error) {
            logger.error(error);
            return false;
        }
    }

    public async getUserClaimsAsync(): Promise<UserClaims | undefined> {
        await this.loadCurrentSessionAsync();
        if (!this.session) {
            return undefined;
        }

        const claims = this.session.getIdToken().decodePayload();
        logger.debug(claims);

        return {
            firstName: claims?.given_name,
            lastName: claims?.family_name,
            email: claims?.email,
            alias: claims?.email?.split('@')[0],
            posix: JSON.parse(claims['custom:posix'] || '[]'),
            ldap: JSON.parse(claims['custom:ldap'] || '[]')
        };
    }

    public async signInAsync(): Promise<void> {
        try {
            localStorage.removeItem(AMPLIFY_REDIRECT_KEY);
            await Auth.federatedSignIn();
        } catch (error) {
            logger.error('[AUTH][FEDERATION]: ', error);
            throw error;
        }
    }

    private async loadCognitoUserAsync(): Promise<void> {
        if (this.user !== undefined) {
            return;
        }

        this.user = await Auth.currentAuthenticatedUser();
        logger.debug('[AUTH][USER]', this.user);
    }

    private async loadCurrentSessionAsync(): Promise<void> {
        try {
            this.session = await Auth.currentSession();
            logger.debug('[AUTH][SESSION]', this.session);
        } catch (error) {
            logger.debug('[AUTH][SESSION]', error);
        }
    }

    private async onHubCapsuleAsync(capsule: HubCapsule): Promise<void> {
        const { channel, payload } = capsule;
        if (channel === this.AuthChannel) {
            await this.loadCognitoUserAsync();
            if (payload.event === 'configured') {
                const alias = this.user ? this.user.getUsername().split('_')[1] : undefined;
                await coupaPermissionService.syncCoupaPermissions(alias);
            }
        }
    }
}

export const authService = new AmplifyAuthService();
