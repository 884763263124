import React from 'react';
import { Alert } from '@amzn/awsui-components-react';
import { AppConfig } from 'modules/core';

export type DataRetrievalErrorAlertProps = {
    dataRetrievalErrorMessage: string | undefined;
};

export const DataRetrievalErrorAlert = ({ dataRetrievalErrorMessage }: DataRetrievalErrorAlertProps) => {
    if (dataRetrievalErrorMessage) {
        return (
            <Alert statusIconAriaLabel="Error" type="error" header="Unable to Display Purchase Order Lines">
                Please refresh the page. If the error persists, then please
                <a href={AppConfig.troubleTicketLink!}> open a ticket</a> and add a brief description of the problem
                along with the following information:
                <br />
                <br />
                <code>{dataRetrievalErrorMessage}</code>
            </Alert>
        );
    } else {
        return null;
    }
};
