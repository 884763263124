import { PurchaseStatus, ReceiptStatus, PurchaseLineStatus } from 'modules/purchase/models';
import { CurrencyCode, PaginatedQuery, PaginatedResult } from 'modules/core/model';
import { v4 as uuidV4 } from 'uuid';
import { LineVersion, ReceiptVersion } from '../models';

export const buildVersionLists = function(length: number) {
    const lineStatus = [
        PurchaseLineStatus.ABANDON,
        PurchaseLineStatus.ADDED,
        PurchaseLineStatus.DELETED,
        PurchaseLineStatus.PENDING_RECEIPTS_BY_USER,
        PurchaseLineStatus.UPDATED
    ];

    const orderStatus = [
        PurchaseStatus.ISSUED,
        PurchaseStatus.CANCELLED,
        PurchaseStatus.CHANGE_PURCHASE_PENDING_MERP_APPROVAL
    ];

    const mockedReceiptVersion: ReceiptVersion = {
        receiptNumber: '12e42d23',
        purchaseId: '3512109',
        receiptVersion: 111,
        originalReceiptAmount: 10000.0,
        modifiedReceiptAmount: 20000.0,
        originalReceiptTime: 21,
        modifiedReceiptTime: 2222222222222,
        receiptStatus: ReceiptStatus.PENDING_POST,
        lineNumber: 1,
        versionCategory: 'Actual spend below planned',
        versionDescription: 'We ordered the plan for 30 days but only used 24 days'
    };

    const template = {
        lineVersion: 1497956059383,
        lineNumber: 1,
        lineDescription: 'AQI.FIRE.MONITOR - Smart Sense AQI monitor for 6 months',
        isConfirmProcess: false,
        isLineAmountChanged: true,
        changedLineAmount: 3000,
        updaterLogin: 'weiyunh',
        updaterFirstName: 'Weiyun',
        updaterLastName: 'Hua',
        currencyCode: CurrencyCode.USD,
        receiptVersions: [mockedReceiptVersion, mockedReceiptVersion, mockedReceiptVersion]
    };

    const lineVersions = Array.from({ length }, _ => ({
        purchaseId: uuidV4(),
        purchaseLineStatus: lineStatus[Math.floor(Math.random() * 4)],
        orderStatus: orderStatus[Math.floor(Math.random() * 2)],
        ...template
    }));

    return lineVersions.map(lineVersion => Object.assign(new LineVersion(), lineVersion));
};

const purchase1Repository = buildVersionLists(10);
const purchase2Repository = buildVersionLists(10);
const purchase3Repository = buildVersionLists(5);

class MockedVersionListService {
    public async getVersionsAsync(
        _purchaseId: string,
        queries?: PaginatedQuery
    ): Promise<PaginatedResult<LineVersion>> {
        const records = [
            { items: purchase1Repository, lastEvaluatedKey: new Map<string, unknown>([['1', 1]]) },
            { items: purchase2Repository, lastEvaluatedKey: new Map<string, unknown>([['2', 2]]) },
            { items: purchase3Repository }
        ];

        if (!records) {
            throw Error('Unable to fetch records');
        }

        let index = 0;
        if (queries?.lastEvaluatedKey) {
            const values = (queries.lastEvaluatedKey as Map<string, unknown>).values();
            index = values.next().value as number;
        }

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(records[index] as PaginatedResult<LineVersion>);
            }, 500);
        });
    }
}

export const mockedVersionListService = new MockedVersionListService();
