/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MspTask } from '@amzn/merp-core/models/budgetManagement';

import { logger } from 'modules/core/logger';
import { TableRow } from 'modules/core/model';
import { showErrorPage } from 'modules/app/store';
import { taskService } from '../services/task.service';

export function useTask() {
    const [tasks, updateTasks] = useState<TableRow<MspTask>[]>();
    const [loadingTasks, setLoadingTasks] = useState<boolean>(false);

    const dispatch = useDispatch();

    const fetchTasks = useCallback(async () => {
        setLoadingTasks(true);
        const response = await taskService.getTasksAsync();

        if (response.status !== 200) {
            dispatch(showErrorPage({ errorCode: response?.status || 404 }));
        } else {
            updateTasks(getTaskTableRows(response.tasks || []));
        }

        setLoadingTasks(false);
    }, [dispatch]);

    async function postTasks(tasksList: MspTask[]) {
        try {
            const response = await taskService.postTasksAsync(tasksList);
            if (response.result) {
                fetchTasks();
            }
        } catch (error) {
            logger.error(error);
        }
    }

    useEffect(() => {
        if (!tasks) {
            fetchTasks();
        }
    }, [tasks]);

    return { loadingTasks, tasks, postTasks };
}

function getTaskTableRows(tasks: MspTask[]) {
    const tableRows: any[] = [];

    for (const task of tasks) {
        tableRows.push({
            number: task.id,
            rowId: task.id,
            selected: false,
            payload: {
                entityId: task.entityId,
                action: task.action,
                status: task.status,
                assignedTo: task.assignedTo,
                createdAt: task.createdAt
            }
        });
    }

    return tableRows;
}
