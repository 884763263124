import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Box, SpaceBetween, Alert } from '@amzn/awsui-components-react';

import { FileUpload } from 'modules/core/components/FileUpload';
import { uploadBudgetProcessor } from 'modules/budgetManagement/helper';
import { yearlyAccountingForecastMapper } from 'modules/budgetManagement/mappers';
import { YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';

interface UploadBudgetModalProps {
    onCancel: () => void;
    onUpload: (forecasts: YearlyAccountingForecast[]) => void;
    showWarning?: boolean;
}

export const UploadBudgetModal = (props: UploadBudgetModalProps) => {
    const { t } = useTranslation('budgetManagement');

    const { showWarning, onUpload, onCancel } = props;

    const title = showWarning ? t('budgetUploadModal.warningTitle') : t('budgetUploadModal.title');

    const [file, setFile] = useState<File>();
    const [fileUrl, setFileUrl] = useState<string>();
    const [disableUpload, setDisableUpload] = useState<boolean>(true);
    const [showAlert, setUploadAlert] = useState<boolean>(false);
    const [alertErrors, setAlertErrors] = useState<string>('');

    const onUploadClicked = async () => {
        if (!fileUrl) return;

        await uploadBudgetProcessor.validateFile(fileUrl, true);
        const errors = uploadBudgetProcessor.getErrors();

        if (errors.length > 0) {
            setAlertErrors(errors);
            setUploadAlert(true);
            return;
        }

        const data = uploadBudgetProcessor.getParsedFile();
        const yearlyAccountingForecast = data.map(row =>
            yearlyAccountingForecastMapper.mapToYearlyAccountingForecast(row)
        );
        onUpload(yearlyAccountingForecast);
    };

    const onFileUpload = async (uploadfile: File, fileUrl: string) => {
        if (!uploadfile || !fileUrl) return;

        setFile(uploadfile);
        setFileUrl(fileUrl);
        setDisableUpload(false);
        resetAlert();
    };

    const resetAlert = () => {
        setUploadAlert(false);
        setAlertErrors('');
    };

    const Footer = () => (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={onCancel}>
                    {t('budgetUploadModal.cancel')}
                </Button>
                <Button variant="primary" onClick={onUploadClicked} disabled={disableUpload}>
                    {t('budgetUploadModal.upload')}
                </Button>
            </SpaceBetween>
        </Box>
    );

    return (
        <Modal size={'medium'} visible={true} header={title} footer={<Footer />} onDismiss={onCancel}>
            <Alert onDismiss={() => setUploadAlert(false)} visible={showAlert} type="error" header={alertErrors} />
            <FileUpload onFileUpload={onFileUpload} file={file} fileFormats={'.csv'} />
        </Modal>
    );
};
