import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { FilteringCriteria } from 'modules/bulkEdit/types';
import { BulkReceivingExcelActionsDownload } from 'modules/bulkEdit/components/BulkReceivingExcelActions/BulkReceivingExcelActionsDownload';
import { BulkReceivingExcelActionsUpload } from 'modules/bulkEdit/components/BulkReceivingExcelActions/BulkReceivingExcelActionsUpload';

interface BulkReceivingExcelActionsProps {
    filteringCriteria?: FilteringCriteria;
    disabled?: boolean;
    updateAlerts?: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

export const BulkReceivingExcelActions = (props: BulkReceivingExcelActionsProps) => {
    const { disabled, filteringCriteria, updateAlerts } = props;

    const dismissAlert = () => updateAlerts?.(<></>);
    const showAlert = (alert: JSX.Element) => {
        updateAlerts?.(alert);
    };

    return (
        <SpaceBetween direction="horizontal" size="s">
            <BulkReceivingExcelActionsUpload {...{ disabled, showAlert, dismissAlert }} />
            <BulkReceivingExcelActionsDownload {...{ disabled, filteringCriteria, showAlert, dismissAlert }} />
        </SpaceBetween>
    );
};
