/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { Dashboard } from 'modules/dashboard/views';
import { PurchaseDetailPage } from 'modules/purchase/views/purchaseDetail';
import { usePurchase } from 'modules/purchase/hooks';

interface WrapperProps extends RouteComponentProps {
    purchaseId?: string;
}

export function PoRoutes(props: RouteComponentProps): JSX.Element {
    const Routes = {
        PurchaseId: ':purchaseId',
        BasePO: '/purchaseOrder/*',
        PurchaseOrder: 'purchaseOrder'
    };

    const PurchaseOrderWrapper = ({ purchaseId }: WrapperProps) => {
        usePurchase(purchaseId!);

        return <PurchaseDetailPage {...props} />;
    };

    return (
        <Router>
            <Dashboard path={Routes.BasePO} default />
            <PurchaseOrderWrapper path={Routes.PurchaseId} />
        </Router>
    );
}
