import { SelectProps } from '@amzn/awsui-components-react';

export * from './HelpPanels';

export const COAConnector = '-';

export const SmartEOptions = {
    SMARTE: 'SMARTE',
    MIE: 'MIE',
    DMUY: 'DMUY',
    FEELS_RIGHT: 'FEELS_RIGHT',
    ACCESS: 'ACCESS'
};

export const toSelectOptions = (optionLabels: string[], optionValues?: string[]) => {
    const selectOptions: SelectProps.Option[] = [];
    optionLabels.forEach((optionLabel, index) => {
        const optionValue = optionValues ? optionValues[index] : optionLabel;
        selectOptions.push({ label: optionLabel, value: optionValue });
    });
    return selectOptions;
};
