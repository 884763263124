/* eslint-disable react/display-name */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useMemo } from 'react';
import {
    NonCancelableCustomEvent,
    Table,
    TableProps,
    Pagination,
    PaginationProps,
    PropertyFilterProps
} from '@amzn/awsui-components-react';

import {
    PurchaseStatusCell,
    PurchaseTotalCell,
    NameTagPopOver,
    SpendDropDown,
    SpendDropDownHeader,
    PurchaseListHeader,
    PurchaseTableEdit,
    PurchaseToggleCell,
    EmptyListContent,
    PropertyFiltering,
    ConfirmStatusCell,
    ConfirmPurchaseFailedModal
} from 'modules/dashboard/components';
import { PurchaseStatus } from 'modules/purchase/models';
import { PurchaseType } from 'modules/dashboard/models';
import { usePaginatedPurchasesV2 } from 'modules/dashboard/hooks';

import style from './PurchaseList.module.scss';
import { TableRow } from 'modules/core/model';
import { DateGenerator, I18nFormatter } from 'modules/core/class';
import { ReportResult } from 'modules/reports/model';
import { ExpandableCell } from 'modules/core/components/Table';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';

interface PurchaseListProps {
    purchaseType: PurchaseType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPanelChange: (key: any, ...args: unknown[]) => void;
    onPendingReceipts: (count: number) => void;
    onConfirmPurchase: (succeeded: boolean, count: number) => void;
    onReportEvent?: (event: ReportResult) => void;
}

const getStyle = (status: PurchaseStatus) =>
    [PurchaseStatus.PENDING_SOFT_CLOSED, PurchaseStatus.SOFT_CLOSED, PurchaseStatus.CLOSED].includes(status)
        ? style['closed']
        : '';

export const PurchaseList = (props: PurchaseListProps) => {
    const { purchaseType, onPanelChange, onConfirmPurchase, onPendingReceipts, onReportEvent } = props;

    const { t } = useTranslation('purchase');

    const isPR = purchaseType === 'purchaseRequest';

    const {
        items,
        loading,
        hasNextPage,
        confirmFailedItems,
        selectedItems,
        setTableItems,
        resetPurchaseList,
        confirmReceiptsAsync,
        updateConfirmFailedItems,
        paginateFromServerAsyncV2
    } = usePaginatedPurchasesV2(purchaseType);

    const PAGE_SIZE = 15;

    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [dateSelected, setDateSelected] = useState(DateGenerator.getCurrentDate());
    const [dashboardQueryParams, setDashboardQueryParams] = useState<undefined | PropertyFilterProps.Query>(undefined);

    const onFailedConfirmModalDismiss = () => {
        updateConfirmFailedItems([]);
    };

    useEffect(() => {
        if (!items) return;

        setPageCount(Math.ceil(items.length / PAGE_SIZE));
    }, [items?.length]);

    useEffect(() => {
        const pending = items?.filter(rec => rec.payload.needsUpdate).length;

        if (pending) {
            onPendingReceipts(pending);
        }
    }, [items, onPendingReceipts]);

    const currentPageItems = useMemo(() => {
        if (loading || !items?.length) {
            return;
        }
        const offset = currentPage === 1 ? 0 : 1;

        if (items?.length <= PAGE_SIZE) {
            setCurrentPage(1);
            return items;
        } else {
            return currentPage === 1
                ? items?.slice(0, PAGE_SIZE)
                : items?.slice((currentPage - 1) * PAGE_SIZE + offset, (currentPage - 1) * PAGE_SIZE + PAGE_SIZE + 1);
        }
    }, [items, currentPage, loading]);

    const onRowExpanded = (rowNumber: number) => {
        const tableItems = items || [];
        const newRows = [...tableItems];
        const { expanded } = newRows[rowNumber];
        newRows[rowNumber].expanded = !expanded;

        setTableItems(newRows);
    };

    const onRowsSelected = (selectedItems: TableRow<PurchaseIndex>[]) => {
        const onUpdateItems = (current: TableRow<PurchaseIndex>[]) => {
            if (!current) return current;

            const selected = new Set(selectedItems.map(s => s.rowId));
            const newRows = [...current];

            for (const item of newRows) {
                item.selected = selected.has(item.payload.purchaseId);
            }

            return newRows;
        };

        setTableItems(current => onUpdateItems(current));
    };

    const onConfirmPurchases = async () => {
        const itemsSelected = selectedItems().length;
        if (items === undefined) {
            onConfirmPurchase(false, itemsSelected);
            return;
        }

        await confirmReceiptsAsync();

        onConfirmPurchase(confirmFailedItems.length === 0, itemsSelected);
    };

    const getFormattedCreateDate = (creationTime?: number) => {
        if (!creationTime) return 'Unknown';

        return creationTime ? I18nFormatter.date(t, creationTime) : 'Unknown';
    };

    const onSelectionChange = (
        event: NonCancelableCustomEvent<TableProps.SelectionChangeDetail<TableRow<PurchaseIndex>>>
    ) => {
        onRowsSelected(event.detail.selectedItems);
    };

    const onNextPageClick = (e: NonCancelableCustomEvent<PaginationProps.PageClickDetail>) => {
        if (!e.detail.requestedPageAvailable && hasNextPage) {
            void paginateFromServerAsyncV2(dashboardQueryParams);
            setCurrentPage(e.detail.requestedPageIndex);
        }
        if (!hasNextPage && e.detail.requestedPageIndex > pageCount) {
            setCurrentPage(currentPage);
        }
    };

    const onPaginationChange = (e: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => {
        setCurrentPage(e.detail.currentPageIndex);
    };

    const isItemDisabled = (item: TableRow<PurchaseIndex>) => item.disableSelection || false;

    const columns: TableProps.ColumnDefinition<TableRow<PurchaseIndex>>[] = [
        {
            id: 'poId',
            header: isPR ? t('table.prId') : t('table.poId'),
            cell: (row: TableRow<PurchaseIndex>) => <PurchaseToggleCell row={row} onChange={onRowExpanded} />
        },
        {
            id: 'status',
            header: t('table.status'),
            cell: (row: TableRow<PurchaseIndex>) => <PurchaseStatusCell row={row} />
        },
        {
            id: 'confirmation',
            header: t('table.confirmation'),
            cell: (row: TableRow<PurchaseIndex>) => <ConfirmStatusCell row={row} />
        },
        {
            id: 'created',
            header: t('table.created'),
            cell: (row: TableRow<PurchaseIndex>) => {
                const purchase = row.payload;
                return (
                    <ExpandableCell
                        row={row}
                        cell={
                            <span className={getStyle(purchase.status)}>
                                {isPR
                                    ? getFormattedCreateDate(purchase.requestCreationTime)
                                    : getFormattedCreateDate(purchase.orderCreationTime)}
                            </span>
                        }
                        content={row.payload.purchaseLines?.map(l => (
                            <div key={l.lineNumber}></div>
                        ))}
                    />
                );
            }
        },
        {
            id: 'vendor',
            header: t('table.vendor'),
            cell: (row: TableRow<PurchaseIndex>) => {
                const purchase = row.payload;
                return (
                    <ExpandableCell
                        row={row}
                        cell={<span className={getStyle(purchase.status)}>{purchase.supplierDisplayName}</span>}
                        content={row.payload.purchaseLines?.map(l => (
                            <div key={l.lineNumber} />
                        ))}
                    />
                );
            }
        },
        {
            id: 'owner',
            header: isPR ? t('table.prOwner') : t('table.poOwner'),
            cell: (row: TableRow<PurchaseIndex>) => {
                const purchase = row.payload;
                const {
                    requesterLogin,
                    requesterFirstName,
                    requesterLastName,
                    creatorFirstName,
                    creatorLastName,
                    creatorLogin
                } = purchase;

                return (
                    <ExpandableCell
                        cell={
                            <span className={getStyle(purchase.status)}>
                                {requesterLogin && requesterFirstName && requesterLastName ? (
                                    <NameTagPopOver
                                        login={requesterLogin}
                                        firstName={requesterFirstName}
                                        lastName={requesterLastName}
                                    />
                                ) : (
                                    creatorFirstName &&
                                    creatorLastName &&
                                    creatorLogin && (
                                        <NameTagPopOver
                                            login={creatorLogin}
                                            firstName={creatorFirstName}
                                            lastName={creatorLastName}
                                        />
                                    )
                                )}
                            </span>
                        }
                        content={row.payload.purchaseLines?.map(l => (
                            <div key={l.lineNumber} />
                        ))}
                        row={row}
                    />
                );
            }
        },
        {
            id: 'total',
            header: t('table.total'),
            cell: (row: TableRow<PurchaseIndex>) => <PurchaseTotalCell row={row} style={getStyle(row.payload.status)} />
        },
        {
            id: 'spend',
            header: <SpendDropDownHeader dateSelected={dateSelected} setDateSelected={setDateSelected} />,
            minWidth: 275,
            cell: (row: TableRow<PurchaseIndex>) => (
                <SpendDropDown row={row} dateSelection={dateSelected} style={getStyle(row.payload.status)} />
            )
        },
        {
            id: 'action',
            header: t('table.action'),
            cell: (row: TableRow<PurchaseIndex>) => <PurchaseTableEdit row={row} />
        }
    ];

    if (isPR) {
        const statusColumn = columns.findIndex(column => column.id === 'status');
        columns.splice(statusColumn, 1);

        const confirmColumn = columns.findIndex(column => column.id === 'confirmation');
        columns.splice(confirmColumn, 1);
    }

    return (
        <>
            {confirmFailedItems.length > 0 && (
                <ConfirmPurchaseFailedModal
                    failedPurchaseIds={confirmFailedItems}
                    onDismiss={onFailedConfirmModalDismiss}
                />
            )}
            <div data-testid="dashboardTitle" className="awsui-util-font-size-4">
                {t('dashboard.title', { name: `${isPR ? 'PR' : 'PO'}` })}
                <button onClick={() => onPanelChange('PO_LIST_HELP')} className="awsui-util-help-info-link">
                    {t('info')}
                </button>
                <PropertyFiltering
                    paginateFromServerAsyncV2={paginateFromServerAsyncV2}
                    resetPurchaseList={resetPurchaseList}
                    setDashboardQueryParams={setDashboardQueryParams}
                    dashboardQueryParams={dashboardQueryParams}
                />
            </div>
            <br />
            <div className="msp-expandable-table">
                <Table<TableRow<PurchaseIndex>>
                    data-testid="purchase-list-table"
                    resizableColumns={true}
                    loading={loading}
                    items={currentPageItems || []}
                    columnDefinitions={columns}
                    selectionType={isPR ? undefined : 'multi'}
                    selectedItems={isPR ? undefined : selectedItems()}
                    onSelectionChange={isPR ? undefined : onSelectionChange}
                    isItemDisabled={isPR ? undefined : isItemDisabled}
                    loadingText={isPR ? t('dashboard.loadingPR') : t('dashboard.loadingPO')}
                    pagination={
                        <Pagination
                            data-testid="purchase-list-pagination"
                            openEnd={true}
                            pagesCount={pageCount}
                            currentPageIndex={currentPage}
                            onNextPageClick={onNextPageClick}
                            onChange={onPaginationChange}
                        />
                    }
                    header={
                        <PurchaseListHeader
                            loading={loading}
                            isPR={isPR}
                            tableItemCount={items?.length}
                            selectedItems={selectedItems()}
                            onConfirmPurchases={onConfirmPurchases}
                            onReportEvent={onReportEvent}
                        />
                    }
                    empty={
                        <EmptyListContent
                            header={t('dashboard.noOrders')}
                            message={t('dashboard.noDisplay', { name: `${isPR ? 'PR' : 'PO'}` })}
                        />
                    }
                />
            </div>
        </>
    );
};
