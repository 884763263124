/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { Receipt } from '@amzn/merp-core/models/receipt/Receipt';

import { ExpandableCell } from 'modules/core/components/Table';
import { DateGenerator, I18nFormatter } from 'modules/core/class';
import { TableRow } from 'modules/core/model';

interface SpendDropDownProps {
    row: TableRow<PurchaseIndex>;
    dateSelection: string;
    style?: string;
}

export const SpendDropDown = ({ row, style, dateSelection }: SpendDropDownProps) => {
    const { t } = useTranslation('purchase');

    const isSelectedReceipts = (receipt: Receipt) => {
        const receiptDate = DateGenerator.getMonthYear(receipt.receiptTime);
        return receiptDate.includes(dateSelection);
    };

    const purchaseIndex = row.payload;

    const totalSpends: number[] = [];

    if (purchaseIndex.purchaseLines) {
        purchaseIndex.purchaseLines.forEach(line => {
            const receiptsMap = purchaseIndex.receipts as Map<number, Receipt[]>;
            if (receiptsMap?.has(line.lineNumber)) {
                const receipts = receiptsMap.get(line.lineNumber);
                const filteredReceipts = receipts?.filter(r => isSelectedReceipts(r)) || [];
                totalSpends.push(filteredReceipts?.reduce((t, { receiptAmount }) => t + receiptAmount, 0));
            }
        });
    }

    const totalAmount = totalSpends.reduce((t, val) => t + val, 0);

    const MapToLineAmounts = () => (
        <div>
            {totalSpends.map((value, index) => {
                return (
                    <Fragment key={index}>
                        <span className={style}>
                            {value && value !== 0 ? (
                                <>
                                    {I18nFormatter.amount(t, value)} {row.payload.currencyCode}
                                </>
                            ) : (
                                '-'
                            )}
                        </span>
                        <br />
                    </Fragment>
                );
            })}
        </div>
    );

    return (
        <ExpandableCell
            cell={
                <span className={style}>
                    {totalAmount ? `${I18nFormatter.amount(t, totalAmount)} ${row.payload.currencyCode}` : '-'}
                </span>
            }
            row={row}
            content={<MapToLineAmounts />}
        />
    );
};
