export class TablePreferencesService {
    private prefix = 'ASP::TablePreferences::';

    public getTablePreferences(key: string) {
        return this.loadFromLocalStorage(key);
    }

    public save(key: string, preferences: unknown) {
        localStorage.setItem(`${this.prefix}${key}`, JSON.stringify(preferences));
    }

    private loadFromLocalStorage(key: string) {
        const prefs = localStorage.getItem(`${this.prefix}${key}`);

        if (!prefs) {
            return undefined;
        }

        return JSON.parse(prefs);
    }
}

export const tablePreferencesService = new TablePreferencesService();
