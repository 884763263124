import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { AppLayout } from '@amzn/awsui-components-react';

import { UserCard, GroupCard, DebugCard } from 'modules/preferences/components';

export function UserPreferences(_props: RouteComponentProps) {
    const Content = () => {
        return (
            <div>
                <UserCard />
                <GroupCard />
                {process.env.NODE_ENV !== 'production' && <DebugCard />}
            </div>
        );
    };
    return <AppLayout content={<Content />} toolsHide={true} navigationHide={true} headerSelector="#merp-nav" />;
}
