import { Option } from '@amzn/polaris-data-grid';

export const mapToOption = (value: string): Option => {
    return {
        value: value,
        label: value
    };
};

export const mapToLabelAndOption = (label: string, value: string): Option => {
    return {
        label,
        value
    };
};

export * from './bulkReceivingRequest.mapper';
