import { ApiClient } from 'modules/core/class/api/ApiClient';
import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { Budget, BudgetStatus, YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';
import { ResourceResponse } from 'modules/core/model';

interface BudgetResult {
    budgetResult?: Budget;
    forecastsResult?: YearlyAccountingForecast[];
    status?: number;
}

interface GetBudgetsResult {
    budget?: Budget[];
    status?: number;
}

export class BudgetService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'budgets';

    public async getBudgetsBasedOnEntityId(entityId: string): Promise<BudgetResult> {
        try {
            const json = await this.get(`${entityId}?includeRows=true`);

            return {
                budgetResult: (json.budget as unknown) as Budget,
                forecastsResult: (json.entries as unknown) as YearlyAccountingForecast[],
                status: 200
            };
        } catch (error) {
            logger.error(error);
            return {
                budgetResult: undefined,
                forecastsResult: undefined,
                status: (error as ApiError)?.response?.status
            };
        }
    }

    public async getBudgets(teamName: string, status: BudgetStatus): Promise<GetBudgetsResult> {
        try {
            const result: Budget[] = await this.get(`?teamName=${teamName}&status=${status}`);

            return {
                budget: result.map(budget => Object.assign(new Budget(), budget)),
                status: 200
            };
        } catch (error) {
            logger.error(error);
            return {
                budget: undefined
            };
        }
    }

    public async approveBudgetBasedOnBudgetId(budgetId: string) {
        try {
            const json = await this.post(`${budgetId}/approval`);

            return { json, success: true, status: 200 };
        } catch (error) {
            logger.error(error);
            return {
                success: false,
                status: (error as ApiError)?.response?.status
            };
        }
    }

    public async startGuidanceCycle(budget: Budget) {
        try {
            const params = { body: budget };
            const json: ResourceResponse = await this.post('', params);

            return { json, success: true, status: 200 };
        } catch (error) {
            logger.error(error);
            return {
                success: false,
                status: (error as ApiError)?.response?.status
            };
        }
    }
}

export const budgetService = new BudgetService();
