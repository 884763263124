import React from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyCode } from 'modules/core/model';
import { I18nFormatter } from 'modules/core/class';

export interface ChangedLineAmountProps {
    amount: number;
    currency: CurrencyCode;
}

export const ChangedLineAmount = (props: ChangedLineAmountProps) => {
    const { amount, currency } = props;
    const { t } = useTranslation('purchase');

    return (
        <strong>
            {amount >= 0 && <span>{'+ '}</span>}
            {I18nFormatter.amount(t, amount)} {currency}
        </strong>
    );
};
