import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { logger } from 'modules/core';
import { PaginatedQuery } from 'modules/core/model';
import { purchaseListService } from '../services';
import { showErrorPage } from 'modules/app/store';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase/PurchaseIndex';

export function usePurchasesForExportV2() {
    const [purchaseResult, updatePurchaseOrdersV2] = useState<PurchaseIndex[]>([]);
    const from = useRef<number>(0);
    const dispatch = useDispatch();

    const getPaginatedBulkPurchaseV2 = async (isReport: boolean, includeCustomField: boolean) => {
        try {
            do {
                const queries = {
                    from: from.current,
                    pageSize: 500,
                    sortedBy: 'Created',
                    report: isReport,
                    includeCustomField
                } as PaginatedQuery;

                const response = await purchaseListService.getPurchaseOrdersAsyncV2(queries);

                const purchaseResult = response.items as PurchaseIndex[];
                if (response.items === undefined) {
                    dispatch(showErrorPage({ errorCode: response.status || 400, accessError: true }));
                } else {
                    const newFrom = response.items.length > 0 ? from.current + response.items.length : 0;
                    from.current = newFrom;
                }

                updatePurchaseOrdersV2(prev => {
                    purchaseResult?.forEach(rec => prev.push(rec));
                    return prev;
                });
            } while (from.current > 0 && from.current !== null && from.current !== undefined);
        } catch (e) {
            logger.error(e);
        }
    };

    return {
        purchaseResult,
        updatePurchaseOrdersV2,
        getPaginatedBulkPurchaseV2
    };
}
