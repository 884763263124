import { VersionComponentMap } from '../constants/VersionComponentMap';
import { LineVersion, VersionType } from '../models';

export interface VersionComponent<T> {
    key: T;
    content: (lineVersions: LineVersion[]) => JSX.Element;
}

function versionFactory<T>(versionComponents: VersionComponent<T>[]) {
    const componentMap = new Map<T, (lineVersions: LineVersion[]) => JSX.Element>();

    function register({ key, content }: VersionComponent<T>) {
        componentMap.set(key, content);
    }

    versionComponents.forEach(register);

    return componentMap;
}

export function getVersionComponent(response: { type: VersionType; lineVersions: LineVersion[] }) {
    const factory = versionFactory(VersionComponentMap);
    const renderComponent = factory.get(response.type);
    if (renderComponent) {
        return renderComponent(response.lineVersions);
    }
}
