import { PurchaseLineStatus, PurchaseStatus } from 'modules/purchase/models';
import { ReceiptVersion, VersionType } from '.';
import { CurrencyCode } from 'modules/core/model';

export class LineVersion {
    public purchaseId: string;
    public orderId: string;
    public lineVersion: number;
    public lineNumber: number;
    public lineId?: number;
    public lineDescription: string;
    public lineVersionLineNumber: string;
    public isConfirmProcess: boolean;
    public isLineAmountChanged: boolean;
    public changedLineAmount: number;
    public orderStatus: PurchaseStatus;
    public purchaseLineStatus: PurchaseLineStatus;
    public updaterLogin: string;
    public updaterFirstName?: string;
    public updaterLastName?: string;
    public currencyCode: CurrencyCode;
    public isFirst: boolean;
    public receiptVersions: ReceiptVersion[];
    public versionType: VersionType;
    public originalUncommittedBalance: number;
    public modifiedUncommittedBalance: number;
    public originalBigObjective: string;
    public modifiedBigObjective: string;

    constructor() {
        this.purchaseId = '';
        this.orderId = '';
        this.lineVersion = 0;
        this.lineNumber = 0;
        this.lineDescription = '';
        this.lineVersionLineNumber = '';
        this.isConfirmProcess = false;
        this.isLineAmountChanged = false;
        this.changedLineAmount = 0;
        this.updaterLogin = '';
        this.currencyCode = CurrencyCode.USD;
        this.orderStatus = PurchaseStatus.ISSUED;
        this.purchaseLineStatus = PurchaseLineStatus.UPDATED;
        this.isFirst = false;
        this.receiptVersions = [];
        this.originalUncommittedBalance = 0;
        this.modifiedUncommittedBalance = 0;
        this.versionType = VersionType.SPEND_EDIT;
        this.originalBigObjective = '';
        this.modifiedBigObjective = '';
    }

    private get isLineAdded() {
        return PurchaseLineStatus.ADDED === this.purchaseLineStatus;
    }

    private get isLineDeleted() {
        return PurchaseLineStatus.DELETED === this.purchaseLineStatus;
    }

    public get isPendingApproval() {
        return (
            PurchaseStatus.PENDING_APPROVAL === this.orderStatus ||
            PurchaseStatus.CHANGE_PURCHASE_PENDING_MERP_APPROVAL === this.orderStatus ||
            PurchaseStatus.PURCHASE_REQUEST_PENDING_MERP_APPROVAL === this.orderStatus
        );
    }

    public get isRejected() {
        return PurchaseStatus.PURCHASE_CHANGE_CANCELLED === this.orderStatus;
    }
}
