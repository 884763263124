import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpaceBetween, Icon, IconProps } from '@amzn/awsui-components-react';

interface InvoicedStatusCellProps {
    status: 'NO_INVOICES' | 'PARTIALLY_INVOICED' | 'PENDING_INVOICE' | 'FULLY_INVOICED';
}

export const InvoicedStatusCell = ({ status }: InvoicedStatusCellProps) => {
    const { t } = useTranslation('bulkReceiving');

    const variant: { [key: string]: IconProps.Variant } = {
        NO_INVOICES: 'error',
        PARTIALLY_INVOICED: 'subtle',
        PENDING_INVOICE: 'subtle',
        FULLY_INVOICED: 'success'
    };

    const name: { [key: string]: IconProps.Name } = {
        NO_INVOICES: 'status-negative',
        PARTIALLY_INVOICED: 'status-in-progress',
        PENDING_INVOICE: 'status-pending',
        FULLY_INVOICED: 'status-positive'
    };

    const textColor = {
        NO_INVOICES: 'is-error',
        PARTIALLY_INVOICED: 'is-subtle',
        PENDING_INVOICE: 'is-subtle',
        FULLY_INVOICED: 'is-success'
    };

    return (
        <SpaceBetween size="xxs" direction="horizontal">
            <Icon size="medium" variant={variant[status]} name={name[status]} />
            <div className={textColor[status]}>{t(`invoicedStatus.${status}`)}</div>
        </SpaceBetween>
    );
};
