export class ChannelMeasurementMapping {
    public teamName: string;
    public companyCode: string;
    public channelType: string;
    public smartE?: string;

    constructor() {
        this.teamName = '';
        this.channelType = '';
        this.companyCode = '';
    }
}
