import React from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { CancelableEventHandler, ClickDetail } from '@amzn/awsui-components-react/polaris/internal/events';

export class AppNotification implements FlashbarProps.MessageDefinition {
    public id = '';
    public textContent = '';
    public content?: React.ReactNode;
    public action?: React.ReactNode;
    public dismissable = true;
    public externalLink?: URL;
    public canBeDiscarded?: boolean;
    public discarded = false;
    public onDismiss?: CancelableEventHandler<ClickDetail> | undefined;

    public static from(obj: unknown) {
        return Object.assign(new AppNotification(), obj);
    }
}
