import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from '@amzn/awsui-components-react';
import { bulkReceivingService } from '../../services';
import { FilteringCriteria } from 'modules/bulkEdit/types';
import { BulkReceivingExcelActionsModal } from 'modules/bulkEdit/components/BulkReceivingExcelActions/BulkReceivingExcelActionsModal';

export const BulkReceivingExcelActionsDownload = (props: {
    disabled?: boolean;
    filteringCriteria?: FilteringCriteria;
    showAlert: (alert: JSX.Element) => void;
    dismissAlert?: () => void | undefined;
}) => {
    const { t } = useTranslation('bulkReceiving');

    const { filteringCriteria, disabled, showAlert, dismissAlert } = props;

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const onModalConfirm = async () => {
        setDownloadInProgress(true);
        triggerDownload().then(() => setDownloadInProgress(false));
        onModalClose();
    };

    const triggerDownload = async () => {
        try {
            await bulkReceivingService.downloadRowsAsync(filteringCriteria);
        } catch (error) {
            showAlert(downloadFailedAlert(error));
        } finally {
            onModalClose();
        }
    };

    const downloadFailedAlert = (error: unknown) => {
        const hasMessage = typeof error === 'object' && error != null && 'message' in error;
        const msg = hasMessage ? (error as { message: string }).message! : t('downloadErrorAlert.message');
        return (
            <Alert type="error" header={t('downloadErrorAlert.header')} dismissible onDismiss={dismissAlert}>
                {msg}
            </Alert>
        );
    };

    return (
        <>
            <BulkReceivingExcelActionsModal
                modalVisible={isModalVisible}
                onConfirm={onModalConfirm}
                onCancel={onModalClose}
                header={t('downloadModal.header')}
            >
                <p>{t('downloadModal.rowCountWarning')}</p>
                <br />
                <p>{t('downloadModal.accessInfo')}</p>
                <br />
            </BulkReceivingExcelActionsModal>
            <Button
                data-testid="bulkReceiving.downloadBtn"
                disabled={disabled || isModalVisible || downloadInProgress}
                loading={isModalVisible || downloadInProgress}
                onClick={() => setIsModalVisible(true)}
            >
                {t('actions.download')}
            </Button>
        </>
    );
};
