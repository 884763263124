import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ColumnLayout } from '@amzn/awsui-components-react';

import { CountryMapping } from 'modules/mappings/models';
import style from '../OrgMappings/OrgMappings.module.scss';
import { AppConfig } from 'modules/core';

interface CountryMappingsProps {
    countryMappings: CountryMapping[];
}

export const CountryMappings = ({ countryMappings }: CountryMappingsProps) => {
    const { t } = useTranslation('mappings');

    const openTroubledTicket = () => {
        window.open(AppConfig.troubleTicketLink, '_blank');
    };

    return (
        <Box>
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">
                    <h2>{t('countryMappings.countries')}</h2>
                </div>
                <ColumnLayout columns={4}>
                    {countryMappings.map(countryMapping => (
                        <div key={countryMapping.companyCode}>
                            <div className={style['grey-text']}>{countryMapping.companyCode}</div>
                            {countryMapping.countryCode}
                        </div>
                    ))}
                </ColumnLayout>
            </div>

            <Box float="right">
                <Button variant="primary" onClick={() => openTroubledTicket()}>
                    {t('countryMappings.changeMapping')}
                </Button>
            </Box>
        </Box>
    );
};
