import * as yup from 'yup';

export const BigONewCoaSchema = yup.object().shape({
    company: yup
        .string()
        .ensure()
        .required('Company is required'),
    productLine: yup
        .string()
        .ensure()
        .required('Product is required'),
    channel: yup
        .string()
        .ensure()
        .required('Channel is required'),
    glAccount: yup
        .string()
        .ensure()
        .required('Account is required'),
    costCenter: yup
        .string()
        .ensure()
        .required('CostCenter is required'),
    location: yup
        .string()
        .ensure()
        .required('Location is required'),
    project: yup
        .string()
        .ensure()
        .required('Project is required'),
    customEighthSegment: yup
        .string()
        .ensure()
        .required('Segment8 is required'),
    bigObjective: yup
        .string()
        .ensure()
        .required('BigObjective is required'),
    channelType: yup
        .string()
        .ensure()
        .required('Channel is required')
});
