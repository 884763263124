export enum VersionType {
    FIRST_EDIT = 'FIRST_EDIT',
    SPEND_EDIT = 'SPEND_EDIT',
    ADDED = 'ADDED',
    DELETED = 'DELETED',
    LINE_AMOUNT_CHANGE = 'LINE_AMOUNT_CHANGE',
    CONFIRM_PROCESS = 'CONFIRM_PROCESS',
    INVOICE_ADDED = 'INVOICE_ADDED',
    INVOICE_MATCHED = 'INVOICE_MATCHED',
    BIG_OBJECTIVE_UPDATE = 'BIG_OBJECTIVE_UPDATE',
    PO_SOFT_CLOSED = 'PO_SOFT_CLOSED',
    BULK_EDIT_SPEND = 'BULK_EDIT_SPEND',
    CREDIT_MEMO_REDUCED = 'CREDIT_MEMO_REDUCED',
    CREDIT_MEMO_CANCELLED = 'CREDIT_MEMO_CANCELLED',
    ONBOARD_PURCHASE_SPEND = 'ONBOARD_PURCHASE_SPEND',
    ONBOARD_PURCHASE = 'ONBOARD_PURCHASE',
    COUPA_SYNC_RECEIPTS = 'COUPA_SYNC_RECEIPTS'
}
