import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PurchaseLine, Receipt } from 'modules/purchase/models';
import { isQuantityBasedLine, isRequisition } from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';
import { ReceiptStatusColumn } from '../';
import { AllocationCell } from '../AllocationCell';

import styles from './PurchaseDetailTableRow.module.scss';

interface PurchaseDetailTableRowProps {
    line: PurchaseLine;
    receipt: Receipt;
    index: number;
}

export const PurchaseDetailTableRow = (props: PurchaseDetailTableRowProps) => {
    const { t } = useTranslation('purchase');
    const isPurchaseRequest = useSelector(isRequisition);

    const { line, receipt, index } = props;

    const isQuantityBased = isQuantityBasedLine(line);
    const InvoiceColumns = () => (
        <td className={styles['expandable-col']}>
            <AllocationCell editMode={false} receipt={receipt} isChannelAllocation={false} />
        </td>
    );

    const TableRow = () => (
        <tr key={index} className={styles['bottom-border']}>
            <td>{I18nFormatter.date(t, receipt?.receiptTime)}</td>
            {isQuantityBased && (
                <td className={styles['expandable-col']}>{I18nFormatter.quantity(t, receipt?.quantity)}</td>
            )}
            <td>{I18nFormatter.amount(t, receipt?.receiptAmount)}</td>
            {!isPurchaseRequest && (
                <td>
                    <ReceiptStatusColumn receipt={receipt} />
                </td>
            )}
            {!isQuantityBased && (
                <td className={styles['expandable-col']}>
                    <AllocationCell editMode={false} receipt={receipt} isChannelAllocation={true} />
                </td>
            )}
            {!isPurchaseRequest && !isQuantityBased && <InvoiceColumns />}
        </tr>
    );

    return <TableRow />;
};
