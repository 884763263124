import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequisitionDetailPagePanels } from 'modules/requisition/constants';
import { useSelector } from 'react-redux';
import { isRequisition, hasBeenApprovedByMsp } from 'modules/app/store';
import { Link } from '@amzn/awsui-components-react';

import style from './SpendDistribution.module.scss';

interface SpendDistributionHeaderProps {
    withLegend?: boolean;
    isQuantityBased?: boolean;
    onHelp?: (key: RequisitionDetailPagePanels) => void;
}

export const SpendDistributionHeader = (props: SpendDistributionHeaderProps) => {
    const { t } = useTranslation('purchase');
    const showLegend = props.withLegend || false;
    const isQuantityBased = props.isQuantityBased;

    const isPurchaseRequest = useSelector(isRequisition);
    const isCreationPage = isPurchaseRequest && useSelector(hasBeenApprovedByMsp) === false;

    const onInfoClicked = () => {
        if (props.onHelp) {
            props.onHelp('SPEND_HELP');
        }
    };

    const amountLineHeaders = () => {
        return (
            <div className="awsui-row">
                <div className="col-xxs-12 col-m-3">
                    <strong>
                        {t('line.estimatedReceiptDate')}
                        <Link className="ml-1" onFollow={onInfoClicked}>
                            {t('info')}
                        </Link>
                    </strong>
                    {showLegend && <div className="awsui-util-font-size-0 is-label">{t('receipts.headerLegend')}</div>}
                </div>
                <div className="col-xxs-12 col-m-2">
                    <strong>{t('line.receipt')}</strong>
                </div>
                <div className={'col-xxs-12 col-m-2'}>
                    {!isPurchaseRequest && <strong>{t('line.receiptStatus')}</strong>}
                </div>
                {!isCreationPage && (
                    <div className={`col-xxs-12 col-m-2 ${style['with-padding']}`}>
                        <strong>{t('channels.title')}</strong>
                    </div>
                )}
                {!isPurchaseRequest && !isCreationPage && (
                    <div className={`col-xxs-12 col-m-2 ${style['with-padding']}`}>
                        <strong>{t('invoices.matched')}</strong>
                    </div>
                )}
            </div>
        );
    };

    const quantityLineHeaders = () => {
        return (
            <div className="awsui-row">
                <div className="col-xxs-12 col-m-3">
                    <strong>
                        {t('line.estimatedReceiptDate')}
                        <Link className="ml-1" onFollow={onInfoClicked}>
                            {t('info')}
                        </Link>
                    </strong>
                </div>
                <div className="col-xxs-12 col-m-2">
                    <strong>{t('line.quantity')}</strong>
                </div>
                <div className={'col-xxs-12 col-m-2'}></div>
                {!isCreationPage && (
                    <div className={'col-xxs-12 col-m-2'}>
                        <strong>{t('line.totalAmount')}</strong>
                    </div>
                )}
                <div className={'col-xxs-12 col-m-2'}>
                    {!isPurchaseRequest && <strong>{t('line.receiptStatus')}</strong>}
                </div>
            </div>
        );
    };
    return isQuantityBased ? quantityLineHeaders() : amountLineHeaders();
};
