import { useState } from 'react';
import { Receipt } from 'modules/purchase/models';

export const useReallocateBudgetModal = () => {
    const [reallocateBudget, updateState] = useState<Receipt | undefined>();

    const showReallocateBudgetModal = (receipt: Receipt) => {
        updateState(receipt);
    };

    const onReallocateBudgetAction = (
        shouldRemove: boolean,
        selection = '',
        callback: (receipt: Receipt, selection: string) => void
    ) => {
        if (shouldRemove && reallocateBudget && selection) {
            callback(reallocateBudget, selection);
        }

        updateState(undefined);
    };

    return { reallocateBudget, showReallocateBudgetModal, onReallocateBudgetAction };
};
