import { OwnerAction } from './OwnerAction';
import { MspOwner } from '.';

export class Owner {
    firstName: string;
    lastName: string;
    login: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.login = '';
    }

    public static getOwnerRequest(ownerMap: Map<string, OwnerAction>, requester: string) {
        const ownerRequests: MspOwner[] = [];

        ownerMap.forEach((action: OwnerAction, alias: string) => {
            const request = new MspOwner(alias, requester, action);
            ownerRequests.push(request);
        });

        return ownerRequests;
    }
}
