import React from 'react';
import { BigNumber } from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PurchaseLine, ReceiptStatus } from 'modules/purchase/models';
import { ApplicationState, getInvoiceLines, isQuantityBasedLine } from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';

import styles from '../PurchaseLineWrapper/Wrapper.module.scss';

interface PurchaseLineHeaderProps {
    line: PurchaseLine;
}

export const PurchaseLineHeader = (props: PurchaseLineHeaderProps) => {
    const { t } = useTranslation('purchase');

    const { line } = props;

    const invoiceLines = useSelector((state: ApplicationState) => getInvoiceLines(state, line.lineNumber));

    const isQuantityBased = isQuantityBasedLine(line);

    const QuantityLineAttributes = () => {
        return (
            <>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.price')}</div>
                    <div>{I18nFormatter.quantity(t, line?.pricePerUnit)}</div>
                </div>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.totalAmount')}</div>
                    <div>{I18nFormatter.amount(t, line?.amount)}</div>
                </div>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.totalQuantity')}</div>
                    <div>{I18nFormatter.quantity(t, line?.quantity)}</div>
                </div>
            </>
        );
    };

    const receivedAmount = () =>
        line?.receipts
            .filter(r => r.receiptStatus === ReceiptStatus.POSTED || r.receiptStatus === ReceiptStatus.PENDING_VOID)
            .map(r => new BigNumber(r.receiptAmount))
            .reduce((prev, curr) => prev.plus(curr), new BigNumber(0))
            .toNumber();

    const invoiceAmount = () =>
        invoiceLines
            .map(i => new BigNumber(i.amount))
            .reduce((prev, curr) => prev.plus(curr), new BigNumber(0))
            .toNumber();

    return (
        <div className="awsui-util-action-stripe-group awsui-util-spacing-v-s">
            <div className={styles['grid-container']}>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.item')} </div>
                    <div>{line.description}</div>
                </div>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.accountingString')}</div>
                    <div>{line.account?.accountingString}</div>
                </div>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.currencyCode')}</div>
                    <div>{line?.currencyCode}</div>
                </div>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.invoicedAmount')}</div>
                    <div>{I18nFormatter.amount(t, invoiceAmount())}</div>
                </div>
                <div className={styles['grid-item']}>
                    <div className="awsui-util-label">{t('line.receivedAmount')}</div>
                    <div>{I18nFormatter.amount(t, receivedAmount())}</div>
                </div>
                {isQuantityBased && <QuantityLineAttributes />}
            </div>
        </div>
    );
};
