import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Container, Header, Modal, RadioGroup } from '@amzn/awsui-components-react';
import { FileUpload } from 'modules/core/components/FileUpload';
import { useBulkEditUpload } from 'modules/bulkEdit/hooks/useBulkEditUpload.hook';
import { useReports } from 'modules/reports/hooks/useReports.hook';
import { BULK_EDIT_TAB } from 'modules/bulkEdit/constants';
import { ReportName } from 'modules/reports/constants';
import { ConfirmPurchaseType } from '@amzn/merp-core/models';

export const UploadBulkEditSpend = () => {
    const { t } = useTranslation('bulkEdit');

    const { uploadInProgress, uploadReportAsync, report } = useReports();
    const { validateFile, setReportType, errors, reportName } = useBulkEditUpload();

    const [file, setFile] = useState<File>();
    const [fileUrl, setFileUrl] = useState<string>();
    const [errorHeader, setErrorHeader] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [selectedConfirmType, setSelectedConfirmType] = useState<ConfirmPurchaseType>(
        ConfirmPurchaseType.CONFIRM_UPDATED
    );
    const [confirmTypeModal, setConfirmTypeModal] = useState<boolean>(false);
    const [uploadErrorModal, setUploadErrorModal] = useState<boolean>(false);
    const [uploadSuccessModal, setUploadSuccessModal] = useState<boolean>(false);

    useEffect(() => {
        setErrorMessage(errors.join(' '));
    }, [errors, setErrorMessage]);

    useEffect(() => {
        if (report?.succeeded) {
            setUploadSuccessModal(true);
            return;
        }

        const error = report?.error || '';
        if (error && error.length > 0) {
            setUploadErrorModal(true);
            const existingUploadInProgress = error.toLowerCase().includes('in progress');
            const errorHeader = existingUploadInProgress ? t('modal.uploadInProgress') : t('modal.errors');
            const errorMessage = existingUploadInProgress ? error : t('modal.errorsBody', { errors: error });
            setErrorMessage(errorMessage);
            setErrorHeader(errorHeader);
        }
    }, [report]);

    const onFileUpload = async (uploadFile: File, fileUrl: string) => {
        if (!uploadFile || !fileUrl) return;
        setFile(uploadFile);
        setFileUrl(fileUrl);
        await validateFile(fileUrl, ReportName.BulkEditSpendUpload);
        await setReportType(fileUrl);
    };

    const startUpload = async () => {
        if (!fileUrl) return;
        if (errors.length > 0) {
            setUploadErrorModal(true);
            return;
        }
        if (reportName === ReportName.BulkEditSpendUpload) {
            setConfirmTypeModal(true);
            return;
        }
    };

    const submitBulkEditConfirmationType = async () => {
        if (!fileUrl || reportName !== ReportName.BulkEditSpendUpload) return;
        setConfirmTypeModal(false);
        const currentTimeMs: number = Date.now();
        const reportParam = {
            confirmPurchaseType: selectedConfirmType,
            fileName: file && file.name ? file.name : '',
            timeOfUpload: currentTimeMs
        };
        await uploadReportAsync(fileUrl, ReportName.BulkEditSpendUpload, BULK_EDIT_TAB, reportParam);
    };

    const confirmationTypeModalClose = () => {
        setConfirmTypeModal(false);
        resetFileState();
    };

    const uploadErrorModalClose = () => {
        setUploadErrorModal(false);
        resetFileState();
    };

    const uploadSuccessModalClose = () => {
        setUploadSuccessModal(false);
        resetFileState();
    };

    const resetFileState = () => {
        setFile(undefined);
        setFileUrl(undefined);
    };

    const ConfirmationTypeModal = () => (
        <Modal
            visible={confirmTypeModal}
            header={t('modal.selectConfirmationTypeHeader')}
            onDismiss={confirmationTypeModalClose}
            footer={
                <Box float="right">
                    <Button variant="link" onClick={confirmationTypeModalClose}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={submitBulkEditConfirmationType}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            <RadioGroup
                onChange={({ detail }) => setSelectedConfirmType(detail.value as ConfirmPurchaseType)}
                value={selectedConfirmType}
                items={[
                    { value: ConfirmPurchaseType.CONFIRM_UPDATED, label: t('modal.confirmUpdatedPurchases') },
                    { value: ConfirmPurchaseType.CONFIRM_ALL, label: t('modal.confirmAllPurchases') }
                ]}
            />
        </Modal>
    );

    const UploadErrorsModal = () => (
        <Modal
            visible={uploadErrorModal}
            header={errorHeader}
            onDismiss={uploadErrorModalClose}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={uploadErrorModalClose}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {errorMessage}
        </Modal>
    );

    const UploadSuccessModal = () => (
        <Modal
            visible={uploadSuccessModal}
            header={t('modal.UploadSubmitted')}
            onDismiss={uploadSuccessModalClose}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={uploadSuccessModalClose}>
                        {t('ok')}
                    </Button>
                </Box>
            }
        >
            {t('modal.UploadSubmittedBodyLine1')}
        </Modal>
    );

    return (
        <>
            <UploadErrorsModal />
            <UploadSuccessModal />
            <ConfirmationTypeModal />

            <Container header={<Header variant="h2">{t('fileUpload.title')}</Header>}>
                <FileUpload onFileUpload={onFileUpload} file={file} isFileHandlingDisabled={uploadInProgress} />
                <br />

                <Button
                    variant="primary"
                    onClick={startUpload}
                    disabled={file === undefined || uploadInProgress}
                    loading={uploadInProgress}
                >
                    {t('upload')}
                </Button>
            </Container>
        </>
    );
};
