import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AppLayout, Tabs } from '@amzn/awsui-components-react';
import { Router, RouteComponentProps, Redirect, navigate } from '@reach/router';

import { ChannelView } from '../Channel';
import { TabsEvent } from 'modules/core/types';
import { BigObjectiveView } from '../BigObjective';
import { SmartEView } from '../SmartE';

const Routes = {
    BigObjective: 'bigObjective',
    Channel: 'channel',
    SMARTe: 'smartE'
};

export const RollUpsView = (props: RouteComponentProps) => {
    const [activeTab, updateActiveTab] = useState(Routes.BigObjective);
    const { t } = useTranslation('rollups');

    const tabs = Object.values(Routes).map(id => ({ id, label: t(id) }));

    const onTabChange = (event: TabsEvent) => {
        navigate(event.detail.activeTabId);
        updateActiveTab(event.detail.activeTabId);
    };

    useEffect(() => {
        const path = props.location?.pathname.split('/').pop() || Routes.BigObjective;
        updateActiveTab(path);
    }, [props.location]);

    return (
        <AppLayout
            content={
                <section>
                    <h1>{t('dashboardHeader')}</h1>
                    <Tabs tabs={tabs} activeTabId={activeTab} onChange={onTabChange} />
                    <Router>
                        <BigObjectiveView path={Routes.BigObjective} />
                        <ChannelView path={Routes.Channel} />
                        <SmartEView path={Routes.SMARTe} />
                        <Redirect from="/" to={Routes.BigObjective} noThrow />
                    </Router>
                </section>
            }
            toolsHide={true}
            navigationHide={true}
        />
    );
};
