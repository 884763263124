import { OrgMapping } from 'modules/mappings/models';

interface OrgMappingsResult {
    status?: number;
    requestId?: string;
    response?: OrgMappingResponse;
}

interface OrgMappingResponse {
    mappings: OrgMapping[];
    unassignedCostCenters: string[];
}

class MockedOrgMappingService {
    public async getOrgMappingsAsync(): Promise<OrgMappingsResult> {
        const result = {
            mappings: [
                {
                    orgName: 'XCM',
                    costCenters: ['111', '222', '333']
                },
                {
                    orgName: 'Prime Video',
                    costCenters: ['111', '222']
                },
                {
                    orgName: 'Devices',
                    costCenters: ['111', '222']
                },
                {
                    orgName: 'Marketing',
                    costCenters: ['111', '222']
                },
                {
                    orgName: 'Fresh',
                    costCenters: ['111', '222']
                },
                {
                    orgName: 'Alexa',
                    costCenters: ['111', '222']
                },
                {
                    orgName: 'Books',
                    costCenters: ['111', '222']
                }
            ],
            unassignedCostCenters: ['555', '666', '777']
        };

        const resultBody: OrgMappingsResult = {
            requestId: 'requestId',
            response: result,
            status: 200
        };

        return resultBody;
    }

    public async updateOrgMappingsAsync(_body?: unknown) {
        return {
            success: true,
            message: 'Request saved successfully.'
        };
    }
}

export const mockedOrgMappingService = new MockedOrgMappingService();
