import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import { Header, Link } from '@amzn/awsui-components-react';
import { AppLayout, Button, Flashbar } from '@amzn/awsui-components-react-v2';
import { useTranslation } from 'react-i18next';

import { ErrorRouter } from 'modules/error/components';
import { ApplicationState } from 'modules/app/store';
import { useHelpPanel } from 'modules/core/hooks/useHelpPanel.hook';
import { teamNameSmartEMappingPanels } from 'modules/mappings/constants';
import { CancelModal } from 'modules/core/components/CancelModal';
import { CompanyCodeSmartE } from 'modules/mappings/components/CompanyCodeSmartE';
import { FormSchema, UpdateStatus } from 'modules/mappings/models';
import { useChannelMeasurement } from 'modules/mappings/hooks';

type ChannelMeasurementMappingsPageProps = RouteComponentProps;
export const ChannelMeasurement = (_props: ChannelMeasurementMappingsPageProps) => {
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);
    const [flashbarItems, updateFlashbarItems] = useState<Flashbar.MessageDefinition[]>([]);

    const { t } = useTranslation('mappings');

    const [showLeavePageModal, setShowLeavePageModal] = useState(false);
    const { formState } = FormSchema.getSMARTeUseForm();
    const panelList = teamNameSmartEMappingPanels;
    const { panel, dismissPanel, onPanelChange } = useHelpPanel(panelList);

    const {
        updateSmartERequest,
        updateSmartEMappings,
        reloadPageAsync,
        channelMeasurements,
        showBtns,
        updateSuccess
    } = useChannelMeasurement();

    const channelMeasurementsList = channelMeasurements || [];

    useEffect(() => {
        if (updateSuccess.status === UpdateStatus.NOT_SUBMITTED) {
            return;
        }
        const successMsg = {
            type: 'success',
            header: t('smartEMappings.flashTitle'),
            dismissible: true,
            onDismiss: () => updateFlashbarItems([])
        };

        const failureMsg = {
            type: 'error',
            header: t('smartEMappings.flashErrorTitle'),
            content: updateSuccess.reason,
            dismissible: true,
            onDismiss: () => updateFlashbarItems([])
        };

        const flashbar = updateSuccess.status === UpdateStatus.SUBMIT_SUCCESS ? successMsg : failureMsg;

        updateFlashbarItems([flashbar]);
    }, [updateSuccess, t]);

    const onLeavePageConfirmed = () => {
        setShowLeavePageModal(false);
        reloadPageAsync();
    };

    const onSmartEChange = (id: string) => {
        updateSmartERequest(id);
    };

    const onSubmit = () => {
        if (formState.isValid) {
            updateSmartEMappings();
        }
    };

    const onInfoClicked = () => {
        onPanelChange('SMARTe_HELP');
    };

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                ) : (
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <Header variant="h1">
                                    {t('smartEMappings.teamNameSmartEHeader')}
                                    <Link className="ml-1" onFollow={onInfoClicked}>
                                        {t('info')}
                                    </Link>
                                </Header>
                                <br />
                                {channelMeasurementsList.map(measurement => (
                                    <div key={measurement.companyCode}>
                                        <CompanyCodeSmartE
                                            companyCode={measurement.companyCode}
                                            channelMeasurementList={measurement.mappings}
                                            onChange={onSmartEChange}
                                        ></CompanyCodeSmartE>
                                        <br />
                                    </div>
                                ))}
                                {showLeavePageModal && (
                                    <CancelModal
                                        title={t('leavePageTitle')}
                                        content={t('leavePageContent')}
                                        cancelFunction={onLeavePageConfirmed}
                                        backToEditFunction={() => setShowLeavePageModal(false)}
                                    />
                                )}
                                {showBtns && (
                                    <div className="awsui-util-action-stripe">
                                        <div className="awsui-util-action-stripe-group">
                                            <Button
                                                data-cy="saveBtn"
                                                variant="primary"
                                                onClick={onSubmit}
                                                disabled={!showBtns}
                                            >
                                                {t('save')}
                                            </Button>
                                            <Button
                                                data-cy="cancelBtn"
                                                disabled={!showBtns}
                                                onClick={() => setShowLeavePageModal(true)}
                                            >
                                                {t('cancel')}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </div>
                )
            }
            notifications={<Flashbar items={flashbarItems} />}
            navigationHide={true}
            toolsOpen={panel !== undefined}
            toolsHide={panel === undefined}
            tools={panel}
            onToolsChange={dismissPanel}
            headerSelector="#merp-nav"
        />
    );
};
