import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { purchaseService } from 'modules/purchase/services';
import { DeepCopy } from 'modules/purchase/class';
import { updateInvoiceLines } from 'modules/app/store';

export function useInvoiceLines(purchaseId: string, lineNumber: number) {
    const [loading, updateLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        async function getInvoiceLinesAsync() {
            const result = await purchaseService.getInvoiceLinesAsync(purchaseId, lineNumber);
            const lines = result.map(DeepCopy.invoiceLine).filter(l => !l.deleted);

            dispatch(updateInvoiceLines({ lineNumber, invoiceLines: lines }));

            updateLoading(false);
        }

        updateLoading(true);
        getInvoiceLinesAsync();
    }, [purchaseId, lineNumber]);

    return { loadingInvoiceLines: loading };
}
