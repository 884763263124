/* eslint-disable react/display-name */
import React from 'react';
import {
    AddedOrGeneratedVersion,
    ConfirmVersion,
    DeletedVersion,
    EditedVersion,
    InvoiceAddedVersion,
    InvoiceMatchedVersion,
    SoftClosedVersion,
    BigObjectiveVersion
} from 'modules/audit/components';
import { VersionComponent } from 'modules/audit/hooks/versionFactory.hook';
import { LineVersion, VersionType } from 'modules/audit/models';

export const VersionComponentMap: VersionComponent<VersionType>[] = [
    {
        key: VersionType.FIRST_EDIT,
        content: (lineVersions: LineVersion[]) => <AddedOrGeneratedVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.ADDED,
        content: (lineVersions: LineVersion[]) => <AddedOrGeneratedVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.DELETED,
        content: (lineVersions: LineVersion[]) => <DeletedVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.CONFIRM_PROCESS,
        content: (lineVersions: LineVersion[]) => <ConfirmVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.SPEND_EDIT,
        content: (lineVersions: LineVersion[]) => (
            <EditedVersion lineVersions={lineVersions} versionType={VersionType.SPEND_EDIT} />
        )
    },
    {
        key: VersionType.BULK_EDIT_SPEND,
        content: (lineVersions: LineVersion[]) => (
            <EditedVersion lineVersions={lineVersions} versionType={VersionType.BULK_EDIT_SPEND} />
        )
    },
    {
        key: VersionType.ONBOARD_PURCHASE,
        content: (lineVersions: LineVersion[]) => (
            <EditedVersion lineVersions={lineVersions} versionType={VersionType.ONBOARD_PURCHASE} />
        )
    },
    {
        key: VersionType.ONBOARD_PURCHASE_SPEND,
        content: (lineVersions: LineVersion[]) => (
            <EditedVersion lineVersions={lineVersions} versionType={VersionType.ONBOARD_PURCHASE_SPEND} />
        )
    },
    {
        key: VersionType.COUPA_SYNC_RECEIPTS,
        content: (lineVersions: LineVersion[]) => (
            <EditedVersion lineVersions={lineVersions} versionType={VersionType.COUPA_SYNC_RECEIPTS} />
        )
    },
    {
        key: VersionType.INVOICE_ADDED,
        content: (lineVersions: LineVersion[]) => <InvoiceAddedVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.INVOICE_MATCHED,
        content: (lineVersions: LineVersion[]) => <InvoiceMatchedVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.PO_SOFT_CLOSED,
        content: (lineVersions: LineVersion[]) => <SoftClosedVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.BIG_OBJECTIVE_UPDATE,
        content: (lineVersions: LineVersion[]) => <BigObjectiveVersion lineVersions={lineVersions} />
    },
    {
        key: VersionType.CREDIT_MEMO_REDUCED,
        content: (lineVersions: LineVersion[]) => (
            <EditedVersion lineVersions={lineVersions} versionType={VersionType.CREDIT_MEMO_REDUCED} />
        )
    },
    {
        key: VersionType.CREDIT_MEMO_CANCELLED,
        content: (lineVersions: LineVersion[]) => (
            <EditedVersion lineVersions={lineVersions} versionType={VersionType.CREDIT_MEMO_CANCELLED} />
        )
    }
];
