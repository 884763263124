import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Select } from '@amzn/awsui-components-react-v2';

interface InvoiceModalProps {
    title: string;
    styleName?: string;
    content: JSX.Element;
    options: Array<Select.Option>;
    defaultSelection?: string;
    saveOrDismissFunction?: (dissmissOrSave: boolean, selected?: string) => void;
}

interface InvoiceSelection {
    id: string;
    label: string;
    labelTag?: string;
}

export const InvoiceModal = (props: InvoiceModalProps) => {
    const { t } = useTranslation('purchase');

    const { styleName, title, content, options, defaultSelection, saveOrDismissFunction } = props;

    const [selectedOption, setSelectedOption] = useState<InvoiceSelection>();

    useEffect(() => {
        if (!options.length) {
            setSelectedOption(undefined);
            return;
        }

        const { id, label, labelTag } = !defaultSelection
            ? options[0]
            : (options.find(({ id }) => id === defaultSelection) as InvoiceSelection);

        setSelectedOption({ id, label, labelTag });
    }, [options, defaultSelection]);

    const handleOnChange = (event: CustomEvent<Select.ChangeDetail>) => {
        const { label, id, labelTag } = event.detail.selectedOption;
        setSelectedOption({ label, id, labelTag });
    };

    function onSaveOrCancel(canSave: boolean) {
        if (!saveOrDismissFunction) {
            return;
        }

        saveOrDismissFunction(canSave, selectedOption?.id);
    }

    const Footer = () => (
        <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => onSaveOrCancel(false)}>
                {t('owner.cancel')}
            </Button>
            <Button variant="primary" onClick={() => onSaveOrCancel(true)}>
                {t('owner.save')}
            </Button>
        </span>
    );

    return (
        <Modal
            className={styleName}
            visible={true}
            header={title}
            expandToFit={true}
            footer={<Footer />}
            onDismiss={() => onSaveOrCancel(false)}
        >
            {content}
            <Select
                options={options}
                selectedOption={selectedOption}
                triggerVariant="option"
                selectedLabel="Selected"
                onChange={event => handleOnChange(event)}
            />
        </Modal>
    );
};
