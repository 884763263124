/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestChangeHeaders, YearForecast } from '../model';
import { YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';
import { logger } from 'modules/core';
import { yearlySpendMapper } from '.';

export class YearForecastInputRowMapper {
    public mapToYearForecastForecast(row: any, isFirstYearRow = false): YearForecast {
        const yearlyAccountingForecast = yearlySpendMapper.setYearlySpend(row, new YearlyAccountingForecast());

        const yearForecast = (yearlyAccountingForecast as unknown) as YearForecast;

        try {
            yearForecast.id = String(row[RequestChangeHeaders.FORECAST_ID] || '');
            yearForecast.coa1 = String(row[RequestChangeHeaders.COMPANY_CODE]);
            yearForecast.coa2 = String(row[RequestChangeHeaders.LOCATION_CODE]);
            yearForecast.coa3 = String(row[RequestChangeHeaders.COST_CENTER]);
            yearForecast.coa4 = String(row[RequestChangeHeaders.ACCOUNT_CODE]);
            yearForecast.coa5 = String(row[RequestChangeHeaders.PRODUCT_LINE]);
            yearForecast.coa6 = String(row[RequestChangeHeaders.CHANNEL_CODE]);
            yearForecast.coa7 = String(row[RequestChangeHeaders.PROJECT_CODE]);
            yearForecast.coa8 = String(row[RequestChangeHeaders.CUSTOM_SEGMENT]);
            yearForecast.currency = String(row[RequestChangeHeaders.CURRENCY]);
            yearForecast.year = this.toNumber(row[RequestChangeHeaders.YEAR]) || new Date().getFullYear();
            yearForecast.bigObjective = String(row[RequestChangeHeaders.BIG_OBJECTIVE]);
            yearForecast.owner = String(row[RequestChangeHeaders.EXISTING_OWNER] || '');
            yearForecast.isFirstYearRow = isFirstYearRow;

            const rowAssighnedOwner = row[RequestChangeHeaders.ASSIGN_OWNER];
            yearForecast.assignedOwner = rowAssighnedOwner ? String(rowAssighnedOwner) : undefined;
            const rowComments = row[RequestChangeHeaders.COMMENTS];
            yearForecast.comment = rowComments ? String(rowComments) : undefined;

            return yearForecast;
        } catch (error) {
            logger.error(error);
        }

        return yearForecast;
    }

    private toNumber(value: string | undefined): number | undefined {
        if (value === undefined || value === null) return undefined;

        const convertedValue = Number(value.toString().replace(/[^0-9\.]+/g, ''));

        return parseFloat(convertedValue.toFixed(2));
    }
}

export const yearForecastInputRowMapper = new YearForecastInputRowMapper();
