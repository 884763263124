import { PurchaseStatus } from './PurchaseStatus';
import { PurchaseLine } from '.';
import { Coupa } from 'modules/core/helpers';
import { Supplier } from './Supplier';
import { ConfirmStatus } from './ConfirmStatus';
import { CreatedBy } from './CreatedBy';
import { RequestedBy } from './RequestedBy';
import { CurrencyCode } from 'modules/core/model';
import BigNumber from 'bignumber.js';
import { CustomField } from 'modules/customCategory/models';

export class Purchase {
    public purchaseId: string;
    public orderNumber: string;
    public orderId: string;
    public amount: number;
    public status: PurchaseStatus;
    public statusDescription?: string;
    public currencyCode: CurrencyCode;
    public creatorGroup?: string;
    public creationTime?: number;
    public lines: PurchaseLine[];
    public confirmStatus: ConfirmStatus;
    public orgName?: string;
    public countryCode?: string;
    public purchaseType: 'PURCHASE_REQUEST' | 'PURCHASE_ORDER';
    public requestCreationTime?: number;
    public orderCreationTime?: number;
    public fullyBilled: boolean;
    public bigRockObjective?: string;
    public isPrMspApproved?: boolean;
    public customFields?: CustomField[];
    public customSelections?: CustomField[];

    public supplier: Supplier;
    public createdBy?: CreatedBy;
    public requestedBy?: RequestedBy;
    public mspOwner?: string[];

    private closedStatuses = [
        PurchaseStatus.CLOSED,
        PurchaseStatus.SOFT_CLOSED,
        PurchaseStatus.CANCELLED,
        PurchaseStatus.PENDING_SOFT_CLOSED
    ];

    constructor() {
        this.purchaseId = '';
        this.orderNumber = '';
        this.orderId = '';
        this.amount = 0;
        this.status = PurchaseStatus.PURCHASE_REQUEST;
        this.statusDescription = '';
        this.lines = [];
        this.confirmStatus = ConfirmStatus.NOT_CONFIRMED;
        this.fullyBilled = false;
        this.supplier = new Supplier();
        this.purchaseType = 'PURCHASE_REQUEST';
        this.currencyCode = CurrencyCode.USD;
        this.mspOwner = [];
        this.fullyBilled = false;
    }

    public get isPurchaseRequest() {
        return this.purchaseType === 'PURCHASE_REQUEST';
    }

    public get hasNotBeenConfirmed() {
        return this.confirmStatus === ConfirmStatus.NOT_CONFIRMED;
    }

    public get isClosed() {
        return this.confirmStatus === ConfirmStatus.PURCHASE_CLOSED;
    }

    public get needsUpdate() {
        return this.status === PurchaseStatus.CHANGE_PURCHASE_PENDING_MERP_APPROVAL;
    }

    public get canPurchaseBeConfirmed() {
        return !this.isPurchaseRequest && this.hasNotBeenConfirmed && !this.closedStatuses.includes(this.status);
    }

    public get canBeSoftClosed() {
        return !this.isPurchaseRequest && !this.closedStatuses.includes(this.status) && !this.fullyBilled;
    }

    public get coupaUrl() {
        if (this.isPurchaseRequest) {
            return Coupa.requisitionUrl(this.purchaseId);
        }

        return Coupa.purchaseOrderUrl(this.orderId);
    }

    public get isFullyBilled() {
        return this.billedAmount >= this.amount * 0.85;
    }

    public get billedAmount() {
        const totalBilledAmount = this.lines.reduce(
            (billedAmount, { allocatedAmount }) => billedAmount.plus(allocatedAmount),
            new BigNumber(0)
        );

        return parseFloat(totalBilledAmount.toFixed(2));
    }

    public get is85percentOrMoreBilled(): boolean {
        return (
            this.amount > 0 &&
            new BigNumber(this.billedAmount)
                .multipliedBy(100)
                .dividedBy(this.amount)
                .isGreaterThanOrEqualTo(85)
        );
    }

    public get canDisableEdit(): boolean {
        return this.closedStatuses.includes(this.status) || this.fullyBilled;
    }

    public get confirmationReceiptStatus(): ConfirmStatus {
        if (this.closedStatuses.includes(this.status)) {
            return ConfirmStatus.PURCHASE_CLOSED;
        }

        if (this.lines.find(line => line.requiresConfirmation)) {
            return ConfirmStatus.NOT_CONFIRMED;
        }

        return this.lines.some(line => line.isConfirmed) ? ConfirmStatus.CONFIRMED : ConfirmStatus.NOT_APPLICABLE;
    }

    public get displayReopenButton(): boolean {
        return PurchaseStatus.SOFT_CLOSED === this.status;
    }
}
