import { AppConfig } from '..';

function requisitionUrl(requisitionId: string) {
    const url = AppConfig.coupaLink;

    return `${url}/requisition_headers/${requisitionId}`;
}

function purchaseOrderUrl(requisitionId: string) {
    const url = AppConfig.coupaLink;

    return `${url}/order_headers/${requisitionId}`;
}

export const Coupa = {
    requisitionUrl,
    purchaseOrderUrl
};
