/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';

import { logger } from 'modules/core';
import { TableRow } from 'modules/core/model';
import { getTeamNames, isFinancialAnalyst, showErrorPage } from 'modules/app/store';
import { OwnerStatusRow } from '../model/OwnerStatusRow';
import { YearForecast } from '../model/YearForecast';
import { forecastService } from '../services/forecast.service';
import { generateCoaCombination } from '../mappers';

export function useTableRowForecast() {
    const [items, updateTableItems] = useState<TableRow<OwnerStatusRow>[]>();
    const [yearBudgetitems, updateyearBudgetItems] = useState<TableRow<YearForecast>[]>();
    const [budgetUpdated, setBudgetUpdated] = useState<boolean>();
    const [teamName, updateTeamName] = useState<string>();
    const [loadingForecast, setLoadingForecast] = useState<boolean>(false);

    const isFA = useSelector(isFinancialAnalyst);
    const userTeams = useSelector(getTeamNames);

    const dispatch = useDispatch();

    async function fetchForecastsForOwner() {
        setLoadingForecast(true);

        const response = await forecastService.getForecastsAsync();
        const forecasts = response.forecasts ?? [];

        if (response.status !== 200) {
            dispatch(showErrorPage({ errorCode: response.status || 400 }));
        } else {
            updateTableItems(getTableRows(forecasts) || []);
            updateyearBudgetItems(getYearTableRows(forecasts) || []);
            updateTeamName(forecasts.length > 1 ? forecasts[0].team : '');
        }
        setLoadingForecast(false);
    }

    async function fetchForecastsForFA() {
        setLoadingForecast(true);
        let validForAllTeams = true;
        let statusCode = 200;
        let allForecasts: YearlyAccountingForecast[] = [];

        for (const userTeam of userTeams) {
            const response = await forecastService.getForecastsAsync(userTeam);

            if (response.status !== 200) {
                validForAllTeams = false;
                statusCode = response.status || 400;
            } else {
                allForecasts = allForecasts.concat(response.forecasts ?? []);
            }
        }

        if (!validForAllTeams) {
            dispatch(showErrorPage({ errorCode: statusCode }));
        } else {
            updateTableItems(getTableRows(allForecasts));
            updateyearBudgetItems(getYearTableRows(allForecasts));
        }

        setLoadingForecast(false);
    }

    const fetchForecasts = useCallback(async () => {
        if (!isFA) {
            fetchForecastsForOwner();
            return;
        }

        fetchForecastsForFA();
    }, []);

    useEffect(() => {
        if (!items) {
            fetchForecasts();
        }
    }, [items]);

    async function uploadBudget(forecasts: YearlyAccountingForecast[]) {
        try {
            const result = await forecastService.updateForecastsAsync(forecasts);
            setBudgetUpdated(result.success);
            if (result.success) {
                fetchForecasts();
            }
        } catch (error) {
            logger.error(error);
        }
    }

    return {
        loadingForecast,
        items,
        teamName,
        yearBudgetitems,
        budgetUpdated,
        updateTableItems,
        uploadBudget,
        fetchForecasts
    };
}

function getTableRows(forecasts: YearlyAccountingForecast[]) {
    const tableRows: any[] = [];

    const uniqueForecasts = dedupForecasts(forecasts);

    for (const forecast of uniqueForecasts) {
        tableRows.push({
            number: forecast.id,
            rowId: forecast.id,
            selected: false,
            coa1: forecast.coa1,
            coa2: forecast.coa2,
            coa3: forecast.coa3,
            coa4: forecast.coa4,
            coa5: forecast.coa5,
            coa6: forecast.coa6,
            coa7: forecast.coa7,
            coa8: forecast.coa8,
            owner: forecast.owner,
            payload: {
                coa1: forecast.coa1,
                coa2: forecast.coa2,
                coa3: forecast.coa3,
                coa4: forecast.coa4,
                coa5: forecast.coa5,
                coa6: forecast.coa6,
                coa7: forecast.coa7,
                coa8: forecast.coa8,
                owner: forecast.owner,
                id: forecast.id,
                taskStatus: forecast.taskStatus
            } as OwnerStatusRow
        });
    }

    return tableRows;
}

function dedupForecasts(forecasts: YearlyAccountingForecast[]) {
    const accountingStringSet = new Set();
    const uniqueForecasts = [];

    for (const forecast of forecasts) {
        const accountingString = forecast.accountingString;
        if (!accountingStringSet.has(accountingString)) {
            accountingStringSet.add(accountingString);
            uniqueForecasts.push(forecast);
        }
    }

    return uniqueForecasts;
}

function getYearTableRows(forecasts: YearlyAccountingForecast[]) {
    const tableRows: any[] = [];

    const uniqueForecasts = dedupForecasts(forecasts);

    let currentCOA = uniqueForecasts.length > 0 ? generateCoaCombination(uniqueForecasts[0]) : undefined;

    for (const [index, forecast] of uniqueForecasts.entries()) {
        const displayCoaInRow = index === 0 || currentCOA !== generateCoaCombination(forecast);

        tableRows.push({
            number: forecast.id,
            rowId: forecast.id,
            selected: false,
            payload: {
                isFirstYearRow: displayCoaInRow,
                ...forecast
            } as YearForecast
        });

        currentCOA = generateCoaCombination(forecast);
    }

    return tableRows;
}
