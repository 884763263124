import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse } from 'modules/core/model/response';
import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { MspTask } from '@amzn/merp-core/models/budgetManagement';

interface TaskResult {
    tasks: MspTask[] | undefined;
    requestId?: string;
    status?: number;
}

export class TaskService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'tasks';

    public async getTasksAsync(): Promise<TaskResult> {
        try {
            const json: ResourceResponse = await this.get();

            if (json.result) {
                const { result, requestId } = json;
                return { requestId, tasks: result as MspTask[], status: 200 };
            }

            return { tasks: (json as unknown) as MspTask[], status: 200 };
        } catch (error) {
            logger.error(error);
            return { tasks: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    public async postTasksAsync(tasks: MspTask[]) {
        try {
            const params = { body: tasks };
            const json: ResourceResponse = await this.post('', params);
            return json;
        } catch (error) {
            logger.error(error);

            return {
                result: false,
                message: 'Unable to post Tasks',
                requestId: ''
            } as ResourceResponse;
        }
    }
}

export const taskService = new TaskService();
