import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { BigObjective } from '../../components';
import { useBigObjectiveRollUps } from '../../hooks';

export const BigObjectiveView = (_props: RouteComponentProps) => {
    const { loading, rollUps } = useBigObjectiveRollUps();

    return <BigObjective items={rollUps} loading={loading} />;
};
