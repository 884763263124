import { ApiClient } from 'modules/core/class/api/ApiClient';

export class PapiService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'users';

    public async isValidAliasAsync(userAlias: string): Promise<boolean> {
        try {
            await this.get(`validate?login=${userAlias}`);

            return true;
        } catch (error) {
            return false;
        }
    }
}

export const papiService = new PapiService();
