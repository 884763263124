import i18n from 'modules/core/config/i18n';
import { CSVTemplate } from 'modules/dashboard/models';
import { UploadBudgetHeaders, YearForecast } from '.';

export class BudgetPlanCsvConverter {
    private csvTemplate: CSVTemplate;

    private months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    constructor() {
        this.csvTemplate = new CSVTemplate();
    }

    public build(forecastRows: YearForecast[], isFA = false): CSVTemplate {
        this.setHeaders(isFA);

        forecastRows.forEach(forecast => {
            const {
                id,
                coa1,
                coa2,
                coa3,
                coa4,
                coa5,
                coa6,
                coa7,
                coa8,
                currency,
                comment,
                owner,
                assignedOwner,
                year,
                bigObjective,
                channelTypes,
                smartE,
                team,
                jan,
                feb,
                mar,
                apr,
                may,
                jun,
                jul,
                aug,
                sep,
                oct,
                nov,
                dec
            } = forecast;

            const reportData = [
                id,
                coa1,
                coa2,
                coa3,
                coa4,
                coa5,
                coa6,
                coa7,
                coa8,
                currency || '',
                owner || '',
                assignedOwner || '',
                comment || '',
                `${year}`,
                i18n.t('bigRock:dropdown.objectives.' + (bigObjective || 'noObjective')),
                Object.keys(channelTypes || {})
                    .map(key => i18n.t('rollups:channelMap.' + key))
                    .join(', '),
                smartE || '',
                team || '',
                `${jan ?? ''}`,
                `${feb ?? ''}`,
                `${mar ?? ''}`,
                `${apr ?? ''}`,
                `${may ?? ''}`,
                `${jun ?? ''}`,
                `${jul ?? ''}`,
                `${aug ?? ''}`,
                `${sep ?? ''}`,
                `${oct ?? ''}`,
                `${nov ?? ''}`,
                `${dec ?? ''}`
            ];

            if (!isFA) {
                const assignedOwnerColumn = Object.values(UploadBudgetHeaders).findIndex(
                    column => UploadBudgetHeaders.ASSIGN_OWNER === column
                );
                reportData.splice(assignedOwnerColumn, 1);
            }

            this.csvTemplate.setCSVData(reportData);
        });

        return this.csvTemplate;
    }

    private setHeaders(isFA: boolean) {
        const uploadHeaders = Object.values(UploadBudgetHeaders)
            .map(val => val.toString())
            .filter(val => isFA || UploadBudgetHeaders.ASSIGN_OWNER !== val);

        this.csvTemplate.setHeaders([...uploadHeaders, ...this.months]);
    }
}
