import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableProps } from '@amzn/awsui-components-react';

import { TableRow } from 'modules/core/model';
import { Team } from 'modules/onboarding/model';

export type CustomerTeamListTableProps = Required<Pick<TableProps, 'items' | 'header' | 'pagination' | 'loading'>> &
    Pick<TableProps, 'selectedItems' | 'onSelectionChange' | 'selectionType' | 'filter'>;
export const CustomerTeamListTable = (props: CustomerTeamListTableProps) => {
    const { t } = useTranslation('onboarding');

    const columnDefinitions = [
        {
            id: 'name',
            header: t('CustomerTeamListTable.columnDefinitions.teamName'),
            cell: (row: TableRow<Team>) => row.payload.teamName
        },
        {
            id: 'customerType',
            header: t('CustomerTeamListTable.columnDefinitions.teamCategory'),
            cell: (row: TableRow<Team>) => row.payload.teamCategory
        },
        {
            id: 'COAStatus',
            header: t('CustomerTeamListTable.columnDefinitions.onboardingStatus'),
            cell: (row: TableRow<Team>) => row.payload.onboardingStatus
        },
        {
            id: 'POStatus',
            header: t('CustomerTeamListTable.columnDefinitions.poBackfillStatus'),
            cell: (row: TableRow<Team>) => row.payload.poBackfillStatus
        },
        {
            id: 'invoiceStatus',
            header: t('CustomerTeamListTable.columnDefinitions.invoiceBackfillStatus'),
            cell: (row: TableRow<Team>) => row.payload.invoiceBackfillStatus
        },
        {
            id: 'MERPRuleActivationStatus',
            header: t('CustomerTeamListTable.columnDefinitions.coupaRuleActivated'),
            cell: (row: TableRow<Team>) => row.payload.coupaRuleActivated
        },
        {
            id: 'onboardedDate',
            header: t('CustomerTeamListTable.columnDefinitions.latestPoOnboardTime'),
            cell: (row: TableRow<Team>) => row.payload.latestPoOnboardTime
        },
        {
            id: 'owner',
            header: t('CustomerTeamListTable.columnDefinitions.owner'),
            cell: (row: TableRow<Team>) => row.payload.owner
        }
    ];

    const loadingText: string = t('CustomerTeamListTable.loadingText');
    const headerMessage: string = t('CustomerTeamListTable.emptyBox.header');
    const message: string = t('CustomerTeamListTable.emptyBox.message');

    const emptyBox: JSX.Element = (
        <Box textAlign="center" color="inherit">
            <b>{headerMessage}</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                {message}
            </Box>
        </Box>
    );

    return <Table {...props} columnDefinitions={columnDefinitions} loadingText={loadingText} empty={emptyBox} />;
};
