import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';
import { Flashbar, BreadcrumbGroup, FlashbarProps, AppLayout } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { Loading } from 'modules/core/components';
import { ErrorRouter } from 'modules/error/components';
import { Requisition } from 'modules/requisition/views';
import { PurchaseOrder } from 'modules/purchaseOrder/views';
import { ApplicationState, isRequisition } from 'modules/app/store';
import { useHelpPanel } from 'modules/core/hooks/useHelpPanel.hook';
import { detailPagePanels as purchaseOrderPanels, PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { detailPagePanels as requisitionPanels, RequisitionDetailPagePanels } from 'modules/requisition/constants';

export interface DetailPageContentProps {
    onFlashbarMessageDismissed?: () => void;
    onUpdateFlashbar?: (message: FlashbarProps.MessageDefinition) => void;
    onHelp?: (key: RequisitionDetailPagePanels | PurchaseDetailPagePanels, ...args: unknown[]) => void;
}

type PurchaseDetailPageProps = RouteComponentProps;

export const PurchaseDetailPage = (props: PurchaseDetailPageProps) => {
    const { path } = props;

    const purchase = useSelector((state: ApplicationState) => state?.purchaseDetails?.purchase);

    const isPurchaseRequest = useSelector(isRequisition);

    const panelList = isPurchaseRequest ? requisitionPanels : purchaseOrderPanels;
    const { panel, onPanelChange, dismissPanel } = useHelpPanel(panelList);

    const [flashbarItems, updateFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const { t } = useTranslation('purchase');

    const href = isPurchaseRequest ? '/purchaseRequest' : '/purchaseOrder';

    const breadcrumbItems = [
        { text: isPurchaseRequest ? t('breadCrumbs.prTitle') : t('breadCrumbs.poTitle'), href },
        {
            text: isPurchaseRequest
                ? t('breadCrumbs.prId', { identifier: purchase?.purchaseId })
                : t('breadCrumbs.poId', { identifier: purchase?.orderId }),
            href: '#'
        }
    ];

    useEffect(() => {
        if (!purchase) {
            return;
        }

        const { purchaseId } = purchase;

        if (isPurchaseRequest && props.path?.includes('/purchaseOrder')) {
            navigate(`/purchaseRequest/${purchaseId}`);
            return;
        }

        if (!isPurchaseRequest && props.path?.includes('/purchaseRequest')) {
            navigate(`/purchaseOrder/${purchaseId}`);
            return;
        }
    }, [purchase, path]);

    const onUpdateFlashbar = (message: FlashbarProps.MessageDefinition) => {
        updateFlashbarItems([message]);
    };

    const onFlashbarDismissed = () => {
        updateFlashbarItems([]);
    };

    const onHelp = (key: RequisitionDetailPagePanels | PurchaseDetailPagePanels, ...args: unknown[]) => {
        onPanelChange(key, ...args);
    };

    const DetailPage = isPurchaseRequest ? Requisition : PurchaseOrder;

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                ) : (
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <BreadcrumbGroup ariaLabel="Breadcrumbs" items={breadcrumbItems} />
                                {!purchase && <Loading />}
                                {purchase && (
                                    <DetailPage
                                        onHelp={onHelp}
                                        onUpdateFlashbar={onUpdateFlashbar}
                                        onFlashbarMessageDismissed={onFlashbarDismissed}
                                    />
                                )}
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </div>
                )
            }
            notifications={<Flashbar items={flashbarItems} />}
            navigationHide={true}
            toolsOpen={panel !== undefined}
            toolsHide={panel === undefined}
            tools={panel}
            onToolsChange={dismissPanel}
            headerSelector="#merp-nav"
        />
    );
};
