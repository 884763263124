import { v4 } from 'uuid';
import { COAConnector } from '../constants';

export class CoaMapping {
    id: string;
    company: string;
    costCenter: string;
    productLine: string;
    channel: string;
    glAccount: string;
    location: string;
    project: string;
    customEighthSegment: string;
    channelType: string;
    bigObjective: string;

    constructor(coaString?: string, bigObjective?: string, channelType?: string, id?: string) {
        const coaComponents = (coaString || '').split(COAConnector);
        this.id = id || v4();

        this.company = coaComponents[0] || '';
        this.location = coaComponents[1] || '';
        this.costCenter = coaComponents[2] || '';
        this.glAccount = coaComponents[3] || '';
        this.productLine = coaComponents[4] || '';
        this.channel = coaComponents[5] || '';
        this.project = coaComponents[6] || '';
        this.customEighthSegment = coaComponents[7] || '';

        this.bigObjective = bigObjective || '';
        this.channelType = channelType || '';
    }

    get getCOAString() {
        return (
            this.company +
            COAConnector +
            this.location +
            COAConnector +
            this.costCenter +
            COAConnector +
            this.glAccount +
            COAConnector +
            this.productLine +
            COAConnector +
            this.channel +
            COAConnector +
            this.project +
            COAConnector +
            this.customEighthSegment
        );
    }
}
