import { useEffect, useState } from 'react';

import { AppNotification } from '../model';
import { appNotificationService } from '../services';

export const useAppNotifications = () => {
    const [notifications, updateNotifications] = useState<AppNotification[]>([]);

    useEffect(() => {
        async function getNotifications() {
            const notifications = await appNotificationService.getNotifications();
            updateNotifications(notifications);
        }

        getNotifications();
    }, [updateNotifications]);

    const dismissNotification = (notification: AppNotification) => {
        if (notification.discarded) {
            appNotificationService.discardNotification(notification);
        }

        updateNotifications(curr => {
            return curr.map(c => AppNotification.from(c)).filter(c => c.id !== notification.id);
        });
    };

    const updateNotification = (notification: AppNotification, discarded: boolean) => {
        updateNotifications((current: AppNotification[]) => {
            for (const curr of current) {
                if (curr.id === notification.id) {
                    curr.discarded = discarded;
                }
            }

            return current.map(c => AppNotification.from(c));
        });
    };

    return { notifications, updateNotification, dismissNotification };
};
