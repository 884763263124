import { v4 } from 'uuid';

export class InvoiceAllocation {
    id: string;
    createdAt?: number;
    updatedAt?: number;
    deleted?: boolean;
    invoiceId: string;
    invoiceOfaId: string;
    invoiceLineId: string;
    purchaseLineId: number;
    receiptId?: string;
    allocatedAmount: number;
    invoiceTotal: number;
    invoiceNumber: string;

    constructor() {
        this.id = v4();
        this.invoiceId = '';
        this.invoiceOfaId = '';
        this.invoiceLineId = '';
        this.purchaseLineId = 0;
        this.invoiceNumber = '';
        this.allocatedAmount = 0;
        this.invoiceTotal = 0;
        this.deleted = false;
    }

    public get hasValidAllocatedAmount(): boolean {
        return this.allocatedAmount !== 0 && !isNaN(this.allocatedAmount);
    }
}
