import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Button, Form, Popover, SpaceBetween, FlashbarProps, Header, Link } from '@amzn/awsui-components-react';

import { SoftCloseConfirmModal } from 'modules/purchase/components/SoftClose/SoftCloseConfirmModal';
import { ExpandButton, LoadingIndicator, PurchaseLineWrapper } from 'modules/purchase/components';
import { PurchaseOverview } from 'modules/purchase/components/PurchaseOverview/PurchaseOverview';
import { usePurchase, usePurchaseOwner, useSubmitPurchase } from 'modules/purchase/hooks';
import { ApplicationState, getUserEditStatus, isNonMarketing, isQuantityBasedLine } from 'modules/app/store';
import {
    OwnerUpdateStatus,
    PurchaseSubmitStatus,
    SoftCloseStatus,
    UpdateReceiptsRequest,
    UpdateMotive,
    UpdateReceiptsReason
} from 'modules/purchase/models';
import { DetailPageContentProps } from 'modules/purchase/views';
import { ConfirmModal } from 'modules/purchaseOrder/components';
import { DateGenerator } from 'modules/core/class';
import { useValidate } from 'modules/user/hooks';
import { SoftCloseErrorAlert } from 'modules/purchase/components/SoftClose/SoftCloseErrorAlert';
import { useSubmitPurchaseSoftClose } from 'modules/purchase/hooks/useSubmitPurchaseSoftClose.hook';
import _ from 'lodash';

interface PurchaseOrderViewProps extends DetailPageContentProps, RouteComponentProps {}

export const PurchaseOrder = (props: PurchaseOrderViewProps) => {
    const { onUpdateFlashbar, onFlashbarMessageDismissed, onHelp } = props;

    const { t } = useTranslation('purchase');

    const purchase = useSelector((state: ApplicationState) => state?.purchaseDetails?.purchase)!;
    const validUserEditStatus = useSelector((state: ApplicationState) => getUserEditStatus(state));

    if (!purchase) {
        return <></>;
    }

    const purchaseId = purchase!.purchaseId;

    const isMarketingPR = !useSelector(isNonMarketing);
    purchase.confirmStatus = purchase.confirmationReceiptStatus;

    const { hasNextPage, purchaseLines, hasSmallerLines, loadLinesRef, markFullyBilledAsync } = usePurchase(purchaseId);
    const {
        hasUserSoftCloseAccess,
        isUserSoftCloseAccessFetched,
        validateUserSoftCloseAccessAsync,
        validateUserEditAsync
    } = useValidate();

    const { ownerUpdated, updateMspOwnersAsync } = usePurchaseOwner(purchaseId);
    const {
        softCloseErrors,
        purchaseSoftCloseSubmitted,
        submitSoftCloseInProgress,
        reloadPurchaseAsyncAfterSoftClose
    } = useSubmitPurchaseSoftClose(purchaseId);
    const { submitInProgress, purchaseSubmitted, updateConfirmAndSubmitReceiptsAsync } = useSubmitPurchase(purchaseId);

    if (!isUserSoftCloseAccessFetched) {
        validateUserSoftCloseAccessAsync(purchaseId);
    }

    validateUserEditAsync(purchaseId);

    const [isSoftClosePOErrorAlertVisible, showSoftClosePOErrorAlert] = useState(false);

    const [isInEditMode, toggleEditMode] = useState(false);
    const [confirmModalIsVisible, showConfirmModal] = useState(false);
    const [softCloseConfirmModalIsVisible, showSoftCloseConfirmModal] = useState(false);

    const [isExpandable, showExpandedSection] = useState(hasSmallerLines);

    useEffect(() => {
        if (ownerUpdated.status === OwnerUpdateStatus.NOT_UPDATED) {
            return;
        }

        const updateSuccess: FlashbarProps.MessageDefinition = {
            type: 'success',
            header: t('owner.flashTitle'),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const updateFailed: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: t('owner.flashErrorTitle'),
            content: ownerUpdated.reason,
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const flashbar = ownerUpdated.status === OwnerUpdateStatus.UPDATE_SUCCESS ? updateSuccess : updateFailed;

        if (onUpdateFlashbar) {
            onUpdateFlashbar(flashbar);
        }
    }, [ownerUpdated, t]);

    useEffect(() => {
        if (isMarketingPR && _.isEmpty(purchase?.bigRockObjective)) {
            const flashbarItem: FlashbarProps.MessageDefinition = {
                type: 'error',
                content: t('bigRockObjective.flashbarErrorContent'),
                dismissible: true,
                onDismiss: onDismissFlashbar
            };
            if (onUpdateFlashbar) {
                onUpdateFlashbar(flashbarItem);
            }
        }
    }, [purchase?.bigRockObjective]);

    useEffect(() => {
        if (purchaseSubmitted.status === PurchaseSubmitStatus.NOT_SUBMITTED) {
            return;
        }

        const lineCount = purchase?.lines.length || 0;
        const confirmSuccess: FlashbarProps.MessageDefinition = {
            type: 'success',
            header: t('flashConfirmPOTitle'),
            content: t('flashConfirmPOContent', {
                count: lineCount,
                date: DateGenerator.parseCurrentTime(Date.now()).valueOf()
            }),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const confirmFailed: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: t('flashConfirmErrorPO'),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };
        const flashbar =
            purchaseSubmitted.status === PurchaseSubmitStatus.SUBMIT_SUCCESS ? confirmSuccess : confirmFailed;

        if (onUpdateFlashbar) {
            onUpdateFlashbar(flashbar);
        }
    }, [purchaseSubmitted, t]);

    useEffect(() => {
        if (purchaseSoftCloseSubmitted.status === SoftCloseStatus.NOT_SUBMITTED) {
            return;
        }

        const confirmSuccess: FlashbarProps.MessageDefinition = {
            type: 'success',
            header: t('softClosePurchase.flashConfirmPendingSoftClosePO'),
            content: t('softClosePurchase.flashConfirmPendingSoftClosePOContent'),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };

        const confirmFailed: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: t('softClosePurchase.flashConfirmError'),
            content: Array.from(softCloseErrors.entries()).map(([pId, errors]) => (
                <div key={pId}>
                    <p>{`Errors found in purchase with purchase id ${pId}:`}</p>
                    <ul>
                        {errors.map((error, index) => (
                            <li key={index}>{`${error}`}</li>
                        ))}
                    </ul>
                </div>
            )),
            dismissible: true,
            onDismiss: onDismissFlashbar
        };
        const flashbar =
            purchaseSoftCloseSubmitted.status === SoftCloseStatus.SUBMIT_SUCCESS ? confirmSuccess : confirmFailed;

        if (onUpdateFlashbar) {
            onUpdateFlashbar(flashbar);
        }
    }, [purchaseSoftCloseSubmitted, t]);

    const onConfirmPurchase = async () => {
        showConfirmModal(false);

        const lineCount = purchase?.lines.length || 0;

        if (lineCount < 1 || !purchase?.hasNotBeenConfirmed) {
            return;
        }

        const updateRequestList: UpdateReceiptsRequest[] = [];
        purchase?.lines.forEach(line => {
            const reason = {
                motive: 'changeInDeliverablesOrScope' as UpdateMotive,
                explanation:
                    'All the spends that are in the past and current months for the PO has been marked confirmed'
            } as UpdateReceiptsReason;
            const isQuantityBased = isQuantityBasedLine(line);

            updateRequestList.push(new UpdateReceiptsRequest(isQuantityBased, line, reason));
        });

        await updateConfirmAndSubmitReceiptsAsync(updateRequestList);
    };

    const checkPoHasPendingOrFutureOrFailedReceipts = () => {
        const hasPendingOrFutureOrFailureReceipts = purchase.lines.some(line =>
            line.receipts.some(receipt => receipt.isFutureOrPendingOrFailed)
        );

        return hasPendingOrFutureOrFailureReceipts;
    };

    const onSoftClosePOClick = () => {
        if (checkPoHasPendingOrFutureOrFailedReceipts()) {
            showSoftClosePOErrorAlert(true);
        } else {
            showSoftCloseConfirmModal(true);
        }
    };

    async function onConfirmSoftClose(canSoftClose: boolean) {
        if (!canSoftClose) return;
        if (purchase !== undefined) {
            const result = await markFullyBilledAsync(purchase.purchaseId);
            await reloadPurchaseAsyncAfterSoftClose(result);
        }

        showSoftCloseConfirmModal(false);
    }

    const onInfoClicked = () => {
        if (onHelp) {
            onHelp('PO_HELP', {
                url: purchase?.coupaUrl,
                value: `PO ID ${purchase?.orderNumber}`
            });
        }
    };

    const onDismissFlashbar = () => {
        if (onFlashbarMessageDismissed) {
            onFlashbarMessageDismissed();
        }
    };

    if (!purchase) return <></>;

    return (
        <Form
            header={
                <Header
                    variant="h1"
                    actions={
                        <SpaceBetween direction="horizontal" size="s">
                            {hasUserSoftCloseAccess && !purchase.displayReopenButton && (
                                <Button
                                    data-testid="closePOButton"
                                    variant="normal"
                                    formAction="submit"
                                    onClick={() => onSoftClosePOClick()}
                                    disabled={purchase.canDisableEdit || submitSoftCloseInProgress}
                                    loading={submitSoftCloseInProgress}
                                >
                                    {t('softClosePurchase.softClosePO')}
                                </Button>
                            )}
                            {purchase.displayReopenButton && hasUserSoftCloseAccess && (
                                <Popover
                                    size="medium"
                                    position={'top'}
                                    dismissButton={false}
                                    triggerType="custom"
                                    content={
                                        <span>
                                            {`${t('softClosePurchase.reopenPOContent')}`}
                                            <a href={purchase.coupaUrl} target="_blank" rel="noopener noreferrer">
                                                {purchase.orderNumber}
                                            </a>
                                        </span>
                                    }
                                >
                                    <Button
                                        data-testid="reopenPO"
                                        variant="normal"
                                        formAction="submit"
                                        disabled={true}
                                        loading={false}
                                    >
                                        {t('softClosePurchase.reopenPO')}
                                    </Button>
                                </Popover>
                            )}
                            {purchase?.canPurchaseBeConfirmed && validUserEditStatus && (
                                <>
                                    <Button
                                        data-cy="confirmButton"
                                        variant="primary"
                                        formAction="submit"
                                        onClick={() => showConfirmModal(true)}
                                        disabled={submitInProgress}
                                        loading={submitInProgress}
                                    >
                                        {`${t('confirm')} ${DateGenerator.getCurrentMonthShort()} ${t('spend')}`}
                                    </Button>
                                    <Popover
                                        size="small"
                                        position="bottom"
                                        triggerType="custom"
                                        dismissButton={false}
                                        content={t('confirmButtonInfo')}
                                    >
                                        <Button iconName="status-info" variant="icon" />
                                    </Popover>
                                </>
                            )}
                        </SpaceBetween>
                    }
                >
                    PO: {purchase?.orderNumber}
                    <Link className="ml-1" onFollow={onInfoClicked}>
                        {t('info')}
                    </Link>
                </Header>
            }
        >
            <PurchaseOverview
                isCreationPage={false}
                onHelp={onHelp}
                updateOwners={updateMspOwnersAsync}
                onUpdateBigRockObjective={onDismissFlashbar}
            />
            <br />
            <SoftCloseConfirmModal
                isVisible={softCloseConfirmModalIsVisible}
                onConfirm={onConfirmSoftClose}
                onCancel={() => showSoftCloseConfirmModal(false)}
                onDismiss={() => showSoftCloseConfirmModal(false)}
            />
            {isSoftClosePOErrorAlertVisible && (
                <SoftCloseErrorAlert
                    purchaseLines={purchase?.lines}
                    onCancel={() => showSoftClosePOErrorAlert(false)}
                />
            )}
            <ExpandButton
                isExpandable={isExpandable}
                hideButton={hasSmallerLines}
                showExpandedSection={showExpandedSection}
            />
            <SpaceBetween size="l">
                {purchaseLines.map(line => (
                    <PurchaseLineWrapper
                        toggleExpand={isExpandable}
                        lineNumber={line.lineNumber}
                        key={line.lineNumber}
                        purchaseStatus={purchase.status}
                        onHelp={onHelp}
                        onUpdateFlashbar={onUpdateFlashbar}
                        onFlashbarMessageDismissed={onFlashbarMessageDismissed}
                        onEditModeToggled={editMode => toggleEditMode(editMode)}
                        line={line}
                    />
                ))}
            </SpaceBetween>
            <LoadingIndicator hasNextPage={hasNextPage} loadLinesRef={loadLinesRef} />
            <ConfirmModal
                isVisible={confirmModalIsVisible}
                poCanBeConfirmed={!isInEditMode}
                onComfirm={onConfirmPurchase}
                onDismiss={() => showConfirmModal(false)}
            />
        </Form>
    );
};
