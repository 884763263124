import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { OwnerSchema, InvoiceSchema, PurchaseSchema } from '../forms';
import { BigObjectiveSchema } from 'modules/bigRockObjective/forms';
import { RequisitionSchema } from 'modules/requisition/forms/RequisitionSchema';
import { ChannelAllocationInputSchema } from 'modules/channel/forms';
import { InvoiceAllocationInputSchema } from 'modules/invoices/forms';
import { PurchaseLine } from 'modules/purchase/models';

type ReceiptInputs = yup.InferType<typeof PurchaseSchema>;
type OwnerInputs = yup.InferType<typeof OwnerSchema>;
type InvoiceInputs = yup.InferType<typeof InvoiceSchema>;
type BigRockInputs = yup.InferType<typeof BigObjectiveSchema>;
type RequisitionInputs = yup.InferType<typeof RequisitionSchema>;
type ChannelAllocationInputSchemaInputs = yup.InferType<typeof ChannelAllocationInputSchema>;
type InvoiceAllocationInputSchemaInputs = yup.InferType<typeof InvoiceAllocationInputSchema>;

export class FormSchema {
    public static getUseForm(line?: PurchaseLine) {
        const methods = useForm<ReceiptInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: PurchaseSchema,
            validationContext: { line }
        });

        return methods;
    }

    public static getOwnerUseForm() {
        const methods = useForm<OwnerInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: OwnerSchema
        });

        return methods;
    }

    public static getInvoiceUseForm() {
        const methods = useForm<InvoiceInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: InvoiceSchema
        });

        return methods;
    }

    public static getBigRockUseForm() {
        const methods = useForm<BigRockInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: BigObjectiveSchema
        });

        return methods;
    }

    public static getRequisitionUseForm() {
        const methods = useForm<RequisitionInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: RequisitionSchema
        });

        return methods;
    }

    public static getChannelAllocationUseForm() {
        const methods = useForm<ChannelAllocationInputSchemaInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: ChannelAllocationInputSchema
        });

        return methods;
    }

    public static getInvoiceAllocationUseForm() {
        const methods = useForm<InvoiceAllocationInputSchemaInputs>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            validationSchema: InvoiceAllocationInputSchema
        });

        return methods;
    }
}
