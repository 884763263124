import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal } from '@amzn/awsui-components-react';

interface InfoModalProps {
    visible: boolean;
    title: string;
    content: string;
    onOk?: () => void;
    onDismiss: () => void;
}

export const InfoModal = (props: InfoModalProps) => {
    const { title, content, visible, onOk, onDismiss } = props;

    const { t } = useTranslation('purchase');

    return (
        <Modal
            data-cy={`infoModal.${title}`}
            visible={visible}
            header={title}
            onDismiss={onDismiss}
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={onOk} data-cy="infoModal.btnOk">
                        {t('dashboard.okBtnText')}
                    </Button>
                </Box>
            }
        >
            {content}
        </Modal>
    );
};
