import BigNumber from 'bignumber.js';
import { between } from 'modules/core';

export class InvoiceLine {
    public id: string;
    public createdAt: number;
    public updatedAt: number;
    public deleted: boolean;
    public invoiceId: string;
    public ofaId?: string;
    public lineNumber?: number;
    public purchaseId?: string;
    public purchaseLineNumber?: number;
    public purchaseLineId?: number;
    public amount: number;
    public unitPrice?: number;
    public quantityInvoiced?: number;
    public poLineQuantity?: number;
    public allocatedAmount: number;
    public status?: string;
    public invoiceStatus?: string;
    public invoiceTotal: number;
    public servicePeriodStartDate?: number;
    public servicePeriodEndDate?: number;
    public createdAtFns?: number;
    public invoiceNumber?: string;
    public dueDate?: string;
    public isCredit: boolean;

    constructor() {
        this.id = '';
        this.createdAt = Date.now();
        this.updatedAt = Date.now();
        this.deleted = false;
        this.amount = 0;
        this.allocatedAmount = 0;
        this.invoiceId = '';
        this.invoiceTotal = 0;
        this.isCredit = false;
    }

    public get unmatchedAmount() {
        return new BigNumber(this.amount).minus(this.allocatedAmount).toNumber();
    }

    public isCreditAmountValid(billedAmount: number) {
        const lowerLimit = Math.max(this.amount, -Math.abs(billedAmount));
        return this.isCredit && between(this.allocatedAmount, lowerLimit, 0) && this.amount - this.allocatedAmount <= 0;
    }
}
