import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnLayout } from '@amzn/awsui-components-react';

import styles from './ConfirmVersion.module.scss';
import { LineVersion } from 'modules/audit/models';
import { NameCard } from 'modules/audit/components/NameCard';
import { ConfirmVersionLine } from 'modules/audit/components/ConfirmVersionLine';

export interface ConfirmVersionProps {
    lineVersions: LineVersion[];
}

export const ConfirmVersion = (props: ConfirmVersionProps) => {
    const { lineVersions } = props;
    const { t } = useTranslation('purchase');

    return (
        <div className="awsui-util-container awsui-util-no-gutters">
            <div className={`awsui-util-container-header ${styles['confirm-version-title']}`}>
                <h2>{t('version.confirmedTitle')}</h2>
            </div>
            <div className={`awsui-grid ${styles['confirm-version-container']}`}>
                <div className="awsui-row">
                    <div className="col-1">
                        <NameCard
                            login={lineVersions[0].updaterLogin}
                            firstName={lineVersions[0].updaterFirstName}
                            lastName={lineVersions[0].updaterLastName}
                        />
                    </div>
                    <div className="col-9">
                        <ColumnLayout columns={1}>
                            <div data-awsui-column-layout-root="true">
                                {lineVersions.map(lineVersion => (
                                    <ConfirmVersionLine key={lineVersion.lineNumber} lineVersion={lineVersion} />
                                ))}
                            </div>
                        </ColumnLayout>
                    </div>
                </div>
            </div>
        </div>
    );
};
