import { useState, useEffect } from 'react';
import { ChannelRollUp } from '@amzn/merp-core/models';
import { rollUpService } from '../services';

export function useChannelRollUps() {
    const [loading, updateLoading] = useState(false);
    const [rollUps, updateRollUps] = useState<ChannelRollUp[]>([]);

    useEffect(() => {
        async function fetchRollUps() {
            updateLoading(true);

            const response = await rollUpService.getChannel();

            updateRollUps(response.succeeded ? response.result! : []);
            updateLoading(false);
        }

        fetchRollUps();
    }, [updateRollUps, updateLoading]);

    return { rollUps, loading };
}
