import { useState } from 'react';
import { Receipt } from 'modules/purchase/models';

export const useCorrectSpendModal = () => {
    const [correctedSpend, updateCorrectedSpend] = useState<Receipt | undefined>();

    const showCorrectSpendModal = (receipt: Receipt) => {
        updateCorrectedSpend(receipt);
    };

    const onCorrectSpendAction = (shouldCorrect: boolean, callback: (receipt: Receipt) => void) => {
        if (shouldCorrect && correctedSpend) {
            callback(correctedSpend);
        }

        updateCorrectedSpend(undefined);
    };

    return { correctedSpend, showCorrectSpendModal, onCorrectSpendAction };
};
