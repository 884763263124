import { AppNotification } from '../model';

export class AppNotificationService {
    private DoNotShowAgain = 'MSP::Notifications::DontShowAgain';
    private readonly hardcoded = [
        AppNotification.from({
            id: 'f6b956d0-18cf-4017-a88d-f4fdd89e11e2',
            textContent:
                'We released new Excel upload/download feature in bulk receiving dashboard and soft close PO feature in PO detail page.',
            dismissible: true,
            canBeDiscarded: true
        })
    ];

    public async getNotifications(): Promise<AppNotification[]> {
        const fromServer: AppNotification[] = [];

        const notifications = this.updateFromLocalStorage([...this.hardcoded, ...fromServer]);

        return notifications.filter(n => !n.discarded);
    }

    public discardNotification(notification: AppNotification) {
        if (!notification.discarded) return;

        const entry: string[] = JSON.parse(localStorage.getItem(this.DoNotShowAgain) ?? '[]');
        entry.push(notification.id);

        localStorage.setItem(this.DoNotShowAgain, JSON.stringify(Array.from(new Set(entry))));
    }

    private updateFromLocalStorage(notifications: AppNotification[]) {
        const dismissedNotifications: string[] = JSON.parse(localStorage.getItem(this.DoNotShowAgain) ?? '[]');

        for (const notification of notifications) {
            if (dismissedNotifications.includes(notification.id)) {
                notification.discarded = true;
            }
        }

        return notifications;
    }
}

export const appNotificationService = new AppNotificationService();
