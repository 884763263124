import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnLayout } from '@amzn/awsui-components-react';

import { LineVersion, ReceiptVersionRequest } from 'modules/audit/models';
import { ReceiptVersionList } from 'modules/audit/components/ReceiptVersionList/ReceiptVersionList';

export interface ConfirmVersionLineProps {
    lineVersion: LineVersion;
}

export const ConfirmVersionLine = (props: ConfirmVersionLineProps) => {
    const { lineVersion } = props;
    const { t } = useTranslation('purchase');

    function getModifiedRequests() {
        return lineVersion.receiptVersions.map(
            ({ modifiedReceiptAmount, modifiedReceiptTime }) =>
                new ReceiptVersionRequest(modifiedReceiptAmount, modifiedReceiptTime)
        );
    }

    return (
        <>
            <div>
                <strong>
                    {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
                </strong>
            </div>
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root="true">
                    <ReceiptVersionList requests={getModifiedRequests()} currency={lineVersion.currencyCode} />
                </div>
            </ColumnLayout>
        </>
    );
};
