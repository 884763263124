import { useDispatch } from 'react-redux';

import { validateUserEditService } from 'modules/user/services';
import { logger } from 'modules/core';
import { updateUserEditStatus } from 'modules/app/store';
import { useState } from 'react';

export function useValidate() {
    const dispatch = useDispatch();
    const [hasUserSoftCloseAccess, setHasUserSoftCloseAccess] = useState<boolean>(false);
    const [isUserSoftCloseAccessFetched, setIsSoftCloseAccessFetched] = useState(false);

    async function validateUserEditAsync(purchaseId: string): Promise<void> {
        try {
            const result = await validateUserEditService.validateUserEditAsync(purchaseId);
            dispatch(updateUserEditStatus(result.validUserEdit ?? false));
        } catch (error) {
            logger.error(error);
        }
    }

    async function validateUserSoftCloseAccessAsync(purchaseId: string): Promise<void> {
        try {
            const result = await validateUserEditService.validateUserSoftCloseAccessAsync(purchaseId);
            setHasUserSoftCloseAccess(result.validUserSoftCloseAccess ?? false);
            dispatch(updateUserEditStatus(result.validUserSoftCloseAccess ?? false));
            setIsSoftCloseAccessFetched(true);
        } catch (error) {
            logger.error(error);
        }
    }

    return {
        hasUserSoftCloseAccess,
        isUserSoftCloseAccessFetched,
        validateUserSoftCloseAccessAsync,
        validateUserEditAsync
    };
}
