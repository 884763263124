import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DatePicker, Input, CustomDetailEvent } from '@amzn/awsui-components-react-v2';

import { Receipt, ReceiptDateValidator } from 'modules/purchase/models';
import { DateGenerator } from 'modules/core/class';
import { DeepCopy } from 'modules/purchase/class';
import { useSelector } from 'react-redux';
import { isRequisition } from 'modules/app/store';

export interface EditReceiptEstimationProps {
    isQuantityBased: boolean;
    receipt: Receipt;
    index: number;
    onChanged: (receipt: Receipt) => void;
}

export const EditReceiptEstimation = (props: EditReceiptEstimationProps) => {
    const { isQuantityBased, receipt, onChanged, index } = props;

    const { register, setValue, triggerValidation, errors } = useFormContext();
    const amountName = `amount[${index}]`;
    const quantityName = `quantity[${index}]`;
    const dateName = `receiptDate[${index}]`;
    const receiptTime = receipt.receiptTime ? new Date(receipt.receiptTime).toISOString() : '';
    const isPurchaseRequest = useSelector(isRequisition);

    const setInputValue = () => {
        setValue(amountName, receipt.receiptAmount, true);
        setValue(dateName, receiptTime, true);
        setValue(quantityName, receipt.quantity, true);

        triggerValidation(amountName);
        triggerValidation(dateName);
        triggerValidation(quantityName);
    };

    useEffect(() => {
        register({ name: dateName, type: 'custom' });
        register({ name: isQuantityBased ? quantityName : amountName, type: 'custom' });
    }, [register, isQuantityBased ? quantityName : amountName, dateName]);

    const useEffectOnRegister = (setInput: () => void) =>
        useEffect(setInput, [register, amountName, dateName, quantityName]);
    useEffectOnRegister(setInputValue);

    const onAmountUpdated = ($event: CustomDetailEvent<Input.ChangeDetail>) => {
        const { value } = $event.detail;

        updateAmount(value);
    };

    const onQuantityUpdated = ($event: CustomDetailEvent<Input.ChangeDetail>) => {
        const { value } = $event.detail;

        updateQuantity(value);
    };

    const updateAmount = (value: string) => {
        setValue(amountName, value);
        triggerValidation(amountName);
        const newReceipt = DeepCopy.receipt(receipt);
        newReceipt.receiptAmount = (value as unknown) as number;
        onChanged(newReceipt);
    };

    const updateQuantity = (value: string) => {
        setValue(quantityName, value);
        triggerValidation(quantityName);

        const newReceipt = DeepCopy.receipt(receipt);
        newReceipt.quantity = (value as unknown) as number;
        onChanged(newReceipt);
    };

    const onDateUpdated = ($event: CustomDetailEvent<DatePicker.ChangeDetail>) => {
        const dateValue = $event.detail.value;

        const receiptTime = DateGenerator.parsePolarisDate(dateValue).valueOf();

        setValue(dateName, $event.detail.value);

        triggerValidation(dateName);

        const newReceipt = DeepCopy.receipt(receipt);
        newReceipt.receiptTime = receiptTime;

        onChanged(newReceipt);
    };

    const validateDate = (date: Date) => {
        return date.getUTCFullYear() !== ReceiptDateValidator.invalidYear();
    };

    const editDisabled = !receipt.canEditReceipt && receipt.receiptStatus != null && !isPurchaseRequest;

    return (
        <>
            <div className="col-xxs-12 col-m-3">
                <DatePicker
                    data-cy="spendAmountDatePicker"
                    placeholder="MM/DD/YYYY"
                    name={dateName}
                    value={receiptTime}
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    isDateEnabled={validateDate}
                    invalid={!receipt.hasValidReceiptDate}
                    disabled={editDisabled}
                    onChange={onDateUpdated}
                ></DatePicker>
                <div className="awsui-util-status-negative awsui-util-font-size-0">
                    {errors?.receiptDate && errors?.receiptDate[index]?.message}
                </div>
            </div>
            <div className="col-xxs-12 col-m-2">
                {isQuantityBased && (
                    <>
                        <Input
                            data-cy="spendQuantity"
                            name={quantityName}
                            value={receipt.quantity?.toString()}
                            onInput={onQuantityUpdated}
                            invalid={!receipt.hasValidQuantity}
                            disabled={editDisabled}
                        />
                        <div className="awsui-util-status-negative awsui-util-font-size-0">
                            {errors?.quantity && errors?.quantity[index]?.message}
                        </div>
                    </>
                )}
                {!isQuantityBased && (
                    <>
                        <Input
                            data-cy="spendAmount"
                            name={amountName}
                            value={receipt.receiptAmount.toString()}
                            onInput={onAmountUpdated}
                            invalid={!receipt.hasValidReceiptAmount}
                            disabled={editDisabled}
                        />
                        <div className="awsui-util-status-negative awsui-util-font-size-0">
                            {errors?.amount && errors?.amount[index]?.message}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
