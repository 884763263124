import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, HeaderProps } from '@amzn/awsui-components-react';

export type COACombinationTableHeaderProps = Pick<HeaderProps, 'actions'> & {
    numberOfTotalTableRowCOACombinations: number;
};

export const COACombinationTableHeader = (props: COACombinationTableHeaderProps) => {
    const { numberOfTotalTableRowCOACombinations } = props;
    const { t } = useTranslation('onboarding');

    const title: string = t('COACombinationTable.title');
    const counter = `(${numberOfTotalTableRowCOACombinations})`;

    return (
        <Header {...props} counter={counter} variant="h2">
            {title}
        </Header>
    );
};
