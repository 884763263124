import { useState, useEffect } from 'react';
import { logger } from '../logger';

export interface HelpPanel<T> {
    key: T;
    props?: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: (...args: any[]) => JSX.Element;
}

function panelFactory<T>(panels: HelpPanel<T>[]) {
    const repo = new Map<T, (...args: unknown[]) => JSX.Element>();

    function register({ key, content }: HelpPanel<T>) {
        repo.set(key, content);
        logger.debug(`Registered panel with key ${key}`);
    }

    panels.forEach(register);

    return repo;
}

export function useHelpPanel<T>(panels: HelpPanel<T>[]) {
    const [panel, updatePanel] = useState<JSX.Element | undefined>();
    const [factory, updateFactory] = useState<Map<T, (...args: unknown[]) => JSX.Element> | undefined>();

    useEffect(() => {
        updateFactory(panelFactory(panels));
    }, [panels]);

    function onPanelChange(key: T, ...args: unknown[]) {
        if (factory && !factory.has(key)) {
            logger.warn(`Unable to resolve help panel for key ${key}`);
            return;
        }

        const renderPanel = factory?.get(key);
        if (renderPanel) {
            updatePanel(renderPanel(...args));
        }
    }

    function dismissPanel() {
        updatePanel(undefined);
    }

    return { panel, onPanelChange, dismissPanel };
}
