import { useState } from 'react';

import { v4 as uuidV4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Receipt } from 'modules/purchase/models';
import { DeepCopy } from 'modules/purchase/class';
import { DateGenerator } from 'modules/core/class';
import { InvoiceAllocation, InvoiceLine } from 'modules/invoices/models';
import { ApplicationState, getInvoiceLines } from 'modules/app/store';

export function UseInvoiceAllocationModal(receipts: Receipt[], receiptIndex: number) {
    const [invoiceAllocations, setInvoiceAllocations] = useState<InvoiceAllocation[]>(
        receipts[receiptIndex].allocations.map(DeepCopy.invoiceAllocation)
    );

    const invoiceLines = useSelector((state: ApplicationState) =>
        getInvoiceLines(state, receipts[receiptIndex].lineNumber)
    );
    const [invoiceLinesInEdit, setInvoiceLinesInEdit] = useState<InvoiceLine[]>(invoiceLines);

    const emptyInvoiceAllocation = Object.assign(new InvoiceAllocation(), {
        invoiceId: uuidV4(),
        allocatedAmount: 0,
        invoiceOfaId: '',
        invoiceLineId: '',
        invoiceNumber: '',
        purchaseLineId: 0,
        createdAt: DateGenerator.parseCurrentTime(Date.now()).valueOf()
    });

    function updateInvoiceLinesInEdit(invoiceLine: InvoiceLine) {
        const copy = invoiceLinesInEdit.map(DeepCopy.invoiceLine);
        const index = copy.findIndex(line => line.id === invoiceLine.id);
        copy[index] = DeepCopy.invoiceLine(invoiceLine);

        setInvoiceLinesInEdit(copy);
    }

    function validateInvoiceLines() {
        const hasInvoiceLines = invoiceLines ? invoiceLines.length > 0 : false;
        const invoiceLinesCopy = hasInvoiceLines ? invoiceLines.map(DeepCopy.invoiceLine) : [];
        let isMatchedAmountValid = true;

        invoiceLinesCopy.forEach(invLine => {
            invLine.allocatedAmount = 0;
        });

        receipts.forEach((receipt, index) => {
            const allocations = index == receiptIndex ? invoiceAllocations : receipt.allocations;

            allocations.forEach(allocation => {
                const invoiceLineId = allocation.invoiceLineId;
                const invLine = invoiceLinesCopy.find(line => line.id === invoiceLineId);

                if (invLine !== undefined) {
                    invLine.allocatedAmount += allocation.allocatedAmount;

                    if (invLine.unmatchedAmount < 0 && !invLine.isCredit) {
                        isMatchedAmountValid = false;
                    }
                    if (invLine.isCredit) {
                        isMatchedAmountValid = invLine.isCreditAmountValid(receipt.billedAmount);
                    }
                }
            });
        });

        return isMatchedAmountValid;
    }

    function validateAvailableAmount(allocation: InvoiceAllocation, allocationIndex: number) {
        const { invoiceOfaId, invoiceLineId, allocatedAmount } = allocation;
        const updatedAllocations = invoiceAllocations[allocationIndex];

        if (updatedAllocations) {
            updatedAllocations.invoiceOfaId = invoiceOfaId;
            updatedAllocations.invoiceLineId = invoiceLineId;
            updatedAllocations.allocatedAmount = allocatedAmount;

            return validateInvoiceLines();
        }

        return false;
    }

    function calculateOverflowAmount(allocation: InvoiceAllocation, allocationIndex: number) {
        const { invoiceOfaId, invoiceLineId, allocatedAmount } = allocation;
        const updatedAllocation = invoiceAllocations[allocationIndex];

        if (updatedAllocation) {
            updatedAllocation.invoiceOfaId = invoiceOfaId;
            updatedAllocation.invoiceLineId = invoiceLineId;
            updatedAllocation.allocatedAmount = allocatedAmount;
        }

        const hasInvoiceLines = invoiceLines ? invoiceLines.length > 0 : false;
        const invoiceLinesCopy = hasInvoiceLines ? invoiceLines.map(DeepCopy.invoiceLine) : [];
        const invLine = invoiceLinesCopy.find(line => line.id === invoiceLineId);

        if (invLine === undefined) {
            return 0;
        }

        let invLineAllocatedAmount = 0;

        receipts.forEach((receipt, index) => {
            const allocations = index == receiptIndex ? invoiceAllocations : receipt.allocations;

            allocations.forEach(allocation => {
                if (allocation.invoiceLineId === invoiceLineId) {
                    invLineAllocatedAmount += allocation.allocatedAmount;
                }
            });
        });

        return invLineAllocatedAmount > invLine.amount ? invLineAllocatedAmount - invLine.amount : 0;
    }

    const updateAllocationLineId = (lineId: string, allocationIndex: number) => {
        const prevLine = invoiceLines.find(
            invoiceLine => invoiceLine.id === invoiceAllocations[allocationIndex].invoiceLineId
        );

        if (prevLine) {
            prevLine.allocatedAmount -= invoiceAllocations[allocationIndex].allocatedAmount;
            updateInvoiceLinesInEdit(prevLine);
        }

        const currentLine = invoiceLines.find(invoiceLine => invoiceLine.id === lineId);
        invoiceAllocations[allocationIndex].invoiceLineId = lineId;
        invoiceAllocations[allocationIndex].invoiceOfaId = currentLine?.ofaId || '';
        invoiceAllocations[allocationIndex].invoiceId = currentLine?.invoiceId || uuidV4();
        invoiceAllocations[allocationIndex].invoiceNumber = currentLine?.invoiceNumber || '';
        invoiceAllocations[allocationIndex].purchaseLineId = currentLine?.purchaseLineId || 0;

        setInvoiceAllocations([...invoiceAllocations]);
        if (currentLine) {
            currentLine.allocatedAmount += invoiceAllocations[allocationIndex].allocatedAmount;
            updateInvoiceLinesInEdit(currentLine);
        }
    };

    const updateInputAllocation = (amount: number, index: number) => {
        const invoiceLine = invoiceLines.find(line => line.id === invoiceAllocations[index].invoiceLineId);
        if (invoiceLine) {
            invoiceLine.allocatedAmount += amount - invoiceAllocations[index].allocatedAmount;
            updateInvoiceLinesInEdit(invoiceLine);
        }

        invoiceAllocations[index].allocatedAmount = amount;
        setInvoiceAllocations(invoiceAllocations);
    };

    const addEmptyInvoiceAllocation = () => {
        invoiceAllocations.push(emptyInvoiceAllocation);
        setInvoiceAllocations([...invoiceAllocations]);
    };

    const removeInvoiceAllocation = (allocation: InvoiceAllocation) => {
        const invoiceAllocationIndex = invoiceAllocations.findIndex(
            invAllocation => invAllocation.invoiceLineId === allocation.invoiceLineId
        );

        if (invoiceAllocationIndex != -1) {
            const invoiceLine = invoiceLines.find(
                line => line.id === invoiceAllocations[invoiceAllocationIndex].invoiceLineId
            );

            if (invoiceLine) {
                invoiceLine.allocatedAmount -= invoiceAllocations[invoiceAllocationIndex].allocatedAmount;
                updateInvoiceLinesInEdit(invoiceLine);
            }
        }

        invoiceAllocations.splice(invoiceAllocationIndex, 1);
        setInvoiceAllocations([...invoiceAllocations]);
    };

    return {
        invoiceAllocations,
        invoiceLinesInEdit,
        setInvoiceAllocations,
        updateInputAllocation,
        addEmptyInvoiceAllocation,
        removeInvoiceAllocation,
        validateAvailableAmount,
        updateAllocationLineId,
        calculateOverflowAmount
    };
}
