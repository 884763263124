/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, ColumnLayout } from '@amzn/awsui-components-react-v2';
import { FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { Receipt, UpdateReceiptsReason, PurchaseLine, FormSchema, ReceiptStatus } from 'modules/purchase/models';
import {
    UpdateReceiptReason,
    EditReceipts,
    SpendDistributionHeader,
    PurchaseLabelIndicator
} from 'modules/purchase/components';
import { isQuantityBasedLine, isRequisition, updateLine } from 'modules/app/store';
import { CancelModal } from 'modules/core/components/CancelModal';
import { InvoiceLineTable } from 'modules/invoices/components/InvoiceLineTable';

import styles from '../PurchaseLineWrapper/Wrapper.module.scss';
import { RequisitionDetailPagePanels } from 'modules/requisition/constants';
import { CoupaReceiptType } from '@amzn/merp-core/models/receipt/CoupaReceiptType';
import { PurchaseLineHeader } from '../PurchaseLineHeader';
import { DeepCopy } from 'modules/purchase/class';

interface EditPurchaseLineProps {
    line: PurchaseLine;
    loadingInvoiceLines?: boolean;
    isSubmitInProgress?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
    onReceiptsChange?: (receipts: Receipt[]) => void;
    onUncommittedBalanceChange?: (amount: string) => void;
    onUpdateReasonChange?: (reasonForUpdate: UpdateReceiptsReason) => void;
    onAutoAdjustChannelAllocations?: (updatedReceipts: Receipt[], isCreateMode?: boolean | undefined) => void;
    onHelp?: (key: RequisitionDetailPagePanels | PurchaseDetailPagePanels, ...args: unknown[]) => void;
}

export const EditPurchaseLine = (props: EditPurchaseLineProps) => {
    const { t } = useTranslation('purchase');

    const {
        line,
        isSubmitInProgress,
        loadingInvoiceLines,
        onHelp,
        onCancel,
        onSave,
        onReceiptsChange,
        onUpdateReasonChange,
        onAutoAdjustChannelAllocations
    } = props;

    const dispatch = useDispatch();

    const isPurchaseRequest = useSelector(isRequisition);

    const title = isPurchaseRequest ? 'PR' : 'PO';

    const { lineNumber } = line;

    const formContext = FormSchema.getUseForm(line);

    const [lineBeforeEdits] = useState(DeepCopy.purchaseLine(line));
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [validDistributions, setValidDistributionFlag] = useState(true);
    const [validAllocations, setValidAllocationFlag] = useState(true);
    const [validChannelAllocations, setValidChannelAllocationFlag] = useState(true);
    const [validDistributionValues, setValidDistributionValuesFlag] = useState(true);

    const isQuantityBased = isQuantityBasedLine(line);

    useEffect(() => {
        const voidCount = (line: PurchaseLine) => line.receipts.filter(r => r.voidRequest).length;
        const hasOnlyVoidRequests = (line: PurchaseLine, lineBefore: PurchaseLine) =>
            line.receipts.length === lineBefore.receipts.length && voidCount(line) > voidCount(lineBefore);
        if (line.hasValidDistribution || hasOnlyVoidRequests(line, lineBeforeEdits)) {
            setValidDistributionFlag(true);
        } else {
            setValidDistributionFlag(false);
        }
        line.receiptsHasValidAllocations ? setValidAllocationFlag(true) : setValidAllocationFlag(false);
        line.hasInvalidReceipts ? setValidDistributionValuesFlag(false) : setValidDistributionValuesFlag(true);
        line.receiptsHasValidChannelAllocations
            ? setValidChannelAllocationFlag(true)
            : setValidChannelAllocationFlag(false);
    }, [line]);

    const onFormSaved = () => {
        line.receipts.forEach(r => {
            if (!r.receiptStatus) {
                r.receiptStatus = ReceiptStatus.PENDING_POST;
            }
        });
        dispatch(updateLine(line));

        if (onSave) {
            onSave();
        }
    };
    const onFormDismissed = () => {
        dismissAmountForm();
        if (onCancel) onCancel();
    };

    const dismissAmountForm = () => {
        formContext.reset();
        formContext.clearError();
    };

    const triggerReceiptsChange = (receipts: Receipt[]) => {
        receipts.forEach(receipt => setReceivingType(receipt));
        if (onReceiptsChange) {
            onReceiptsChange(receipts);
        }
    };
    const setReceivingType = (receipt: Receipt) => {
        receipt.receivingType = isQuantityBased ? CoupaReceiptType.QUANTITY : CoupaReceiptType.AMOUNT;
    };

    const triggerUpdateReasonChange = (reason: UpdateReceiptsReason) => {
        if (onUpdateReasonChange) {
            onUpdateReasonChange(reason);
        }
    };

    const getAlertMessage = () => {
        if (!validDistributions) {
            return t('line.modal.invalidSpendDistribution', { purchaseType: `${title}` });
        } else if (!validDistributionValues) {
            return t('line.modal.invalidSpendDistributionValues', { purchaseType: `${title}` });
        } else if (!validAllocations) {
            return t('invoices.invalidAllocationWarning', { purchaseType: `${title}` });
        } else if (!validChannelAllocations) {
            return t('channels.invalidChannelAllocationWarning', { purchaseType: `${title}` });
        } else return '';
    };

    const AlertWarning = () => {
        const lineAlertMessage = getAlertMessage();
        if (lineAlertMessage.length)
            return <Alert data-cy="invalidAllocationDistribution" header={lineAlertMessage} type="warning"></Alert>;

        return <></>;
    };

    const disableBtn = () => {
        const { errors, formState } = formContext;
        const { isValid } = formState;
        if (isQuantityBased) {
            return isSubmitInProgress || !validDistributions || !(isValid || Object.keys(errors).length === 0);
        }

        return (
            isSubmitInProgress ||
            !validChannelAllocations ||
            !validDistributions ||
            !validDistributionValues ||
            !(isValid || Object.keys(errors).length === 0)
        );
    };

    const LineHeader = () => {
        return (
            <div className="awsui-util-action-stripe awsui-util-spacing-v-s">
                <PurchaseLineHeader line={line} />
                <div className="awsui-util-action-stripe-group awsui-util-spacing-v-s">
                    <div>
                        <Button data-cy="lineEditCancel" formAction="none" onClick={() => setShowCancelModal(true)}>
                            {t('wrapper.cancel')}
                        </Button>
                        <Button
                            data-cy="lineEditCancel"
                            formAction="none"
                            disabled={disableBtn()}
                            variant="primary"
                            onClick={onFormSaved}
                            loading={isSubmitInProgress || false}
                        >
                            {t('wrapper.save')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <AlertWarning />

            {showCancelModal && (
                <CancelModal
                    title={t('wrapper.cancelEdit')}
                    content={t('wrapper.cancelContent')}
                    cancelFunction={onFormDismissed}
                    backToEditFunction={() => setShowCancelModal(false)}
                />
            )}
            <div className="awsui-util-spacing-v-s">
                <LineHeader />
                <div>
                    <div className="awsui-util-label">
                        {t('line.receiptInformation')} ({line.receipts.length})
                    </div>
                    <FormContext {...formContext}>
                        <div className="awsui-grid">
                            <SpendDistributionHeader
                                withLegend={true}
                                isQuantityBased={isQuantityBased}
                                onHelp={onHelp}
                            />
                            <EditReceipts
                                line={line}
                                uncommittedBalance={line.uncommittedBalance}
                                onReceiptsUpdated={triggerReceiptsChange}
                                onAutoAdjustChannelAllocations={onAutoAdjustChannelAllocations}
                            />
                            <div />
                            <PurchaseLabelIndicator line={line} />
                        </div>

                        <ColumnLayout columns={3}>
                            <div data-awsui-column-layout-root="true" className={styles['has-narrow-columns']}>
                                <UpdateReceiptReason
                                    updatedReason={line.reason}
                                    onReasonsChange={triggerUpdateReasonChange}
                                />
                            </div>
                        </ColumnLayout>
                    </FormContext>
                </div>
            </div>
            {!isPurchaseRequest && (
                <div className={styles['invoice-lines-container']}>
                    <InvoiceLineTable
                        loading={loadingInvoiceLines}
                        lineNumber={lineNumber}
                        isQuantityBased={isQuantityBased}
                    />
                </div>
            )}
        </>
    );
};
