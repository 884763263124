import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Header, ColumnLayout } from '@amzn/awsui-components-react';

import { ChannelTypeSmartE } from 'modules/mappings/components/ChannelTypeSmartE';
import { ChannelMeasurementMapping } from 'modules/mappings/models';

interface CompanyCodeSmartEProps {
    companyCode: string;
    channelMeasurementList: ChannelMeasurementMapping[];
    onChange: (id: string) => void;
}

export const CompanyCodeSmartE = (props: CompanyCodeSmartEProps) => {
    const { companyCode, channelMeasurementList, onChange } = props;

    const { t } = useTranslation('mappings');

    const onDropDownChange = (id: string) => {
        const changeId = `${companyCode}$${id}`;
        onChange(changeId);
    };

    return (
        <Container
            header={
                <Header variant="h2">
                    {t('smartEMappings.companyCode')}: {companyCode}
                </Header>
            }
        >
            <ColumnLayout columns={4}>
                {channelMeasurementList.map((value, index) => (
                    <ChannelTypeSmartE
                        key={index}
                        defaultSelection={value.smartE}
                        channelType={value.channelType}
                        onChange={onDropDownChange}
                    />
                ))}
            </ColumnLayout>
        </Container>
    );
};
