import React from 'react';
import { useTranslation } from 'react-i18next';
import { fixedMarketingChannelOptions, automatedMarketingChannelOptions } from 'modules/channel/constants';

export const ChannelsDescriptionToolContent = () => {
    const [t] = useTranslation('channelSpend');

    const ChannelDefinitionList = ({ channelList }: { channelList: string[] }) => {
        return (
            <ul className="awsui-list-unstyled">
                {channelList.map(channel => {
                    return (
                        <div key={channel}>
                            <b>
                                <li>{t(`channelsList.${channel}`)}</li>
                            </b>
                            <li>{t(`channelsListDefinitions.${channel}`)}</li>
                        </div>
                    );
                })}
            </ul>
        );
    };

    const ChannelDescription = () => {
        return (
            <>
                <p>{t('channelsDescription1')}</p>
                <p>{t('channelsDescription2')}</p>
            </>
        );
    };

    return (
        <div className="awsui-util-help-panel">
            <div className="awsui-util-help-panel-header">
                <h2>{t('channelHeader')}</h2>
            </div>
            <ChannelDescription />
            <h3>{t('fixedMarketing')}</h3>
            <p>{t('definitionOfFixedMarketing')}</p>
            <ChannelDefinitionList channelList={fixedMarketingChannelOptions} />
            <hr />
            <h4>{t('automatedMarketing')}</h4>
            <p>{t('definitionOfAutomatedMarketing')}</p>
            <ChannelDefinitionList channelList={automatedMarketingChannelOptions} />
        </div>
    );
};
