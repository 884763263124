import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CountryMapping } from 'modules/mappings/models';
import { countryMappingService } from 'modules/mappings/services/countryMappings';

import { showErrorPage } from 'modules/app/store';

export function useCountryMappings() {
    const dispatch = useDispatch();

    const [countryMappings, setCountryMappings] = useState<CountryMapping[]>();

    async function getMappingsAsync(): Promise<void> {
        const result = await countryMappingService.getCountryMappingsAsync();

        if (result.countryMappings) {
            setCountryMappings(result.countryMappings);
        }

        if (result.countryMappings === undefined && result.status !== undefined) {
            dispatch(showErrorPage({ errorCode: result.status }));
        }
    }

    useEffect(() => {
        if (!countryMappings) {
            getMappingsAsync();
        }
    }, [getMappingsAsync]);

    return {
        countryMappings
    };
}
