import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpaceBetween, Icon, IconProps } from '@amzn/awsui-components-react';

import { Receipt } from 'modules/purchase/models';

import styles from './ConfirmationColumn.module.scss';
import { DateGenerator } from 'modules/core/class';

export const ConfirmationColumn = ({ receipt }: { receipt: Receipt }) => {
    const { confirmStatus } = receipt;

    const iconVariant = confirmStatus ? 'success' : 'subtle';
    let name: IconProps.Name = confirmStatus ? 'status-positive' : 'status-pending';

    const { t } = useTranslation('purchase');

    let content = t(confirmStatus ? 'confirmation.isConfirmed' : 'confirmation.unconfirmed');

    const firstDayOfTheMonth = DateGenerator.getFirstDayOfTheCurrentMonth();
    const lastDayOfTheMonth = DateGenerator.getEndOfTheLastDayOfTheCurrentMonth();

    const inCurrentMonth =
        receipt.receiptTime && receipt.receiptTime >= firstDayOfTheMonth && receipt.receiptTime <= lastDayOfTheMonth;

    if (!(inCurrentMonth || receipt.qualifiesForConfirmation)) {
        name = 'status-stopped';
        content = t('confirmation.receiptDoesNotQualify');
    }

    return (
        <SpaceBetween direction="horizontal" size="xs" className={styles['text-grey']}>
            <Icon name={name} variant={iconVariant} />
            <span>{content}</span>
        </SpaceBetween>
    );
};
