import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nFormatter } from 'modules/core/class';
import { Modal, Button } from '@amzn/awsui-components-react-v2';
import { PurchaseLine } from 'modules/purchase/models';

import style from './SoftCloseModal.module.scss';

interface SoftCloseErrorAlertProps {
    purchaseLines?: PurchaseLine[];
    onCancel?: () => void;
}

export const SoftCloseErrorAlert = (props: SoftCloseErrorAlertProps) => {
    const { purchaseLines, onCancel } = props;
    const { t } = useTranslation('purchase');

    function onCancelClick() {
        onCancel?.();
    }

    const Footer = () => (
        <span className="awsui-util-f-r">
            <Button variant="primary" onClick={() => onCancelClick()}>
                {t('softClosePurchase.ok')}
            </Button>
        </span>
    );

    const displayContent = t('softClosePurchase.receiptErrorModalContent');

    const title = t('softClosePurchase.receiptErrorModalHeader');
    return (
        <Modal
            data-testid="errorModal"
            onDismiss={onCancelClick}
            visible={true}
            header={title}
            expandToFit={true}
            footer={<Footer />}
        >
            <>
                <div className={style['content-padding']}>{displayContent}</div>
                <span>
                    {purchaseLines?.map((line, lineNumber) => {
                        const elements: JSX.Element[] = [];
                        line.receipts.forEach(receipt => {
                            if (receipt.isFutureOrPendingOrFailed) {
                                elements.push(
                                    <div
                                        key={`${lineNumber}-${receipt.receiptNumber}`}
                                        className={style['line-content-padding']}
                                    >
                                        <p>
                                            {t('version.poLine')} {line.lineNumber} {line.description}
                                        </p>
                                        <p>
                                            <span>
                                                {`${t('softClosePurchase.receiptAmount')} ${receipt.receiptAmount} ${
                                                    line.currencyCode
                                                }`}
                                                &nbsp;
                                            </span>
                                            <span>{`${t('softClosePurchase.receiptDate')} ${I18nFormatter.date(
                                                t,
                                                receipt.receiptTime
                                            )}`}</span>
                                        </p>
                                    </div>
                                );
                            }
                        });
                        return elements;
                    })}
                </span>
            </>
        </Modal>
    );
};
