/* eslint-disable @typescript-eslint/no-explicit-any */
import { PurchaseIndex, PurchaseLineIndex } from '@amzn/merp-core/models/purchase/';
import { CSVTemplate, CSVExportBuilderV2, MonthDistributionsV2 } from '.';
import { DateGenerator } from 'modules/core/class';
import { InvoiceLine, PurchaseStatus, Receipt as TSCoreReceipt } from '@amzn/merp-core/models';
import BigNumber from 'bignumber.js';
import { ConfirmStatus } from 'modules/purchase/models/ConfirmStatus';
import { pruneCsv } from './CSVHelper';
import { Receipt } from 'modules/purchase/models';

interface PurchaseForExportV2 {
    poOwner: string;
    vendor: string;
    confirmStatus: string;
    status: string;
    orderCreationTime: string;
    orderNumber: string;
    countryCode: string;
    orgName: string;
    mspOwner: string;
    freeTextFields?: string[];
    selectionFields?: string[];
}

export class PurchaseExportBuilderV2 implements CSVExportBuilderV2 {
    private csvTemplate: CSVTemplate;
    private purchaseOrders: PurchaseIndex[];
    private totalAmount: BigNumber = new BigNumber(0);
    private billedAmount: BigNumber = new BigNumber(0);
    private unBilledAmount: BigNumber = new BigNumber(0);
    private invoiceLinesPerLine: InvoiceLine[] = [];
    private includeCustomField = false;

    private readonly url = process.env.REACT_APP_REDIRECT_SIGNIN_URI;
    private readonly getDate = new Intl.DateTimeFormat('en', { timeZone: 'UTC' }).format;

    private readonly purchaseStatusMap = new Map([
        [PurchaseStatus.CHANGE_PURCHASE_PENDING_MERP_APPROVAL, 'Update Needed'],
        [PurchaseStatus.CANCELLED, 'Cancelled'],
        [PurchaseStatus.SOFT_CLOSED, 'Soft Closed'],
        [PurchaseStatus.CLOSED, 'Closed'],
        [PurchaseStatus.ISSUED, 'Open']
    ]);
    private readonly confirmStatusMap = new Map([
        [ConfirmStatus.CONFIRMED, 'Confirmed'],
        [ConfirmStatus.NOT_CONFIRMED, 'Unconfirmed'],
        [ConfirmStatus.NOT_APPLICABLE, 'No Spend'],
        [ConfirmStatus.PURCHASE_CLOSED, 'Closed']
    ]);
    private readonly closedStatuses = [PurchaseStatus.CANCELLED, PurchaseStatus.SOFT_CLOSED, PurchaseStatus.CLOSED];

    private header = [
        'PO Number',
        'Company Code',
        'Location Code',
        'Cost Center',
        'Account Code',
        'Product Line',
        'Channel',
        'Project Code',
        '8th custom segment',
        'PO Owner Name',
        'ASP Owners',
        'Vendor Name',
        'PO Create Date',
        'Country Code',
        'Org Name',
        'PO Line Number',
        'PO Line Description',
        'PO Currency',
        'Total PO Line Amount',
        'Current MEC Confirmation Status',
        'PO Status',
        'Link to PO Detail page',
        'Uncommitted Balance'
    ];

    private invoiceHeader = ['Invoice Count', 'Total Invoiced', 'Total Unmatched', 'Total Unbilled'];

    private freeTextFieldHeader: string[] = [];
    private selectionFieldHeader: string[] = [];

    constructor() {
        this.csvTemplate = new CSVTemplate();
        this.purchaseOrders = [];
    }

    private purchaseExport(purchase: PurchaseIndex): PurchaseForExportV2 {
        const { creatorFirstName, creatorLastName } = purchase;

        const poOwner = creatorFirstName && creatorLastName ? `${creatorFirstName} ${creatorLastName}` : '';
        const mspOwner = `${purchase.mspOwner?.toString()}`;
        const vendor = purchase.supplierDisplayName;
        const confirmStatus = this.confirmStatusMap.get(this.getPurchaseReceiptConfirmationStatus(purchase));
        const orderCreationTime = `${this.getDate(purchase.orderCreationTime)}`;
        const orderNumber = purchase.orderNumber || 'N/A';
        const countryCode = purchase.countryCode || 'N/A';
        const orgName = purchase.orgName || 'N/A';
        const status = this.purchaseStatusMap.get(purchase.status) || 'Pending Approval';

        const result = {
            poOwner,
            vendor,
            confirmStatus,
            orderCreationTime,
            orderNumber,
            countryCode,
            orgName,
            status,
            mspOwner
        } as PurchaseForExportV2;

        if (this.includeCustomField) {
            result.freeTextFields = this.displayCustomFieldValues(
                purchase.customFields,
                this.freeTextFieldHeader,
                'No Values Entered'
            );
            result.selectionFields = this.displayCustomFieldValues(
                purchase.customSelections,
                this.selectionFieldHeader,
                'No Values Selected'
            );
        }

        return result;
    }

    private displayCustomFieldValues(
        fields: string[] | undefined,
        fieldHeader: string[],
        defaultValue: string
    ): string[] {
        const result = Array(fieldHeader.length).fill(defaultValue);

        if (!fields || fields.length === 0) {
            return result;
        }

        fields.forEach(field => {
            const fieldKey = field.split('$')[0];
            const fieldValues = field
                .split('$')[1]
                .split('&')
                .join(', ');

            const index = fieldHeader.indexOf(fieldKey);
            result[index] = fieldValues;
        });

        return result;
    }

    private getInvoiceData(purchaseIndex: PurchaseIndex, lineIndex: PurchaseLineIndex, lineData: any[]) {
        this.invoiceLinesPerLine = purchaseIndex.invoiceLines?.get(lineIndex.lineNumber) || ([] as InvoiceLine[]);
        lineData.push(this.getUniqueAllocationsCount(this.invoiceLinesPerLine));
        this.billedAmount = this.invoiceLinesPerLine?.reduce(
            (p, { allocatedAmount }) => p.plus(allocatedAmount),
            new BigNumber(0)
        );

        this.totalAmount = this.invoiceLinesPerLine?.reduce((p, { amount }) => p.plus(amount), new BigNumber(0));

        const unmatchedAmount = this.totalAmount.minus(this.billedAmount).toNumber();

        lineData.push(`${this.billedAmount}`);
        lineData.push(`${unmatchedAmount}`);

        const purchaseIndexReceipts = purchaseIndex.receipts?.get(lineIndex.lineNumber) || ([] as TSCoreReceipt[]);
        MonthDistributionsV2.mapInvoiceAmountDate(purchaseIndexReceipts, true).forEach(value => {
            lineData.push(`${value}`);
        });

        this.unBilledAmount = purchaseIndexReceipts.reduce(
            (p, { billedAmount, receiptAmount }) =>
                p.plus(MonthDistributionsV2.unBilledReceipt(receiptAmount, billedAmount)),
            new BigNumber(0)
        );

        lineData.push(`${this.unBilledAmount}`);
        MonthDistributionsV2.mapInvoiceAmountDate(purchaseIndexReceipts, false).forEach(value => {
            lineData.push(`${value}`);
        });

        return lineData;
    }

    private getLineData(
        purchaseIndex: PurchaseIndex,
        lineIndex: PurchaseLineIndex,
        purchaseExport: PurchaseForExportV2
    ) {
        let lineData = [
            purchaseExport.orderNumber,
            lineIndex.company,
            lineIndex.location,
            lineIndex.costCenter,
            lineIndex.glAccount,
            lineIndex.productLine,
            lineIndex.channel,
            lineIndex.project,
            lineIndex.customEighthSegment,
            purchaseExport.poOwner,
            purchaseExport.mspOwner,
            purchaseExport.vendor,
            purchaseExport.orderCreationTime,
            purchaseIndex.countryCode,
            purchaseIndex.orgName,
            `${lineIndex.lineNumber}`,
            pruneCsv(lineIndex.lineDescription),
            lineIndex.currencyCode,
            `${lineIndex.lineAmount}`,
            purchaseExport.confirmStatus,
            purchaseExport.status,
            `${this.url}/purchaseOrder/${lineIndex.purchaseId}`,
            `${lineIndex.uncommittedBalance}`
        ];

        const freeTextFields = purchaseExport.freeTextFields ?? [];
        const selectionFields = purchaseExport.selectionFields ?? [];

        if (this.includeCustomField) {
            lineData = lineData.concat(freeTextFields);
            lineData = lineData.concat(selectionFields);
        }

        if (purchaseIndex.receipts !== undefined) {
            MonthDistributionsV2.mapReceiptAmountDate(purchaseIndex.receipts.get(lineIndex.lineNumber)).forEach(
                (value: any) => {
                    lineData.push(`${value}`);
                }
            );
        }
        const lineDataWithInvoice = this.getInvoiceData(purchaseIndex, lineIndex, lineData);

        return lineDataWithInvoice;
    }

    private prepareCustomFieldsHeader(purchaseOrders: PurchaseIndex[]) {
        const freeTextHeaderSet = new Set<string>();
        const selectionHeaderSet = new Set<string>();

        purchaseOrders.forEach(purchase => {
            const freeTextFields = purchase.customFields ?? [];
            const selectionFields = purchase.customSelections ?? [];

            if (freeTextFields.length !== 0) {
                freeTextFields.forEach(f => {
                    freeTextHeaderSet.add(f.split('$')[0]);
                });
            }

            if (selectionFields.length !== 0) {
                selectionFields.forEach(f => {
                    selectionHeaderSet.add(f.split('$')[0]);
                });
            }
        });

        this.freeTextFieldHeader = Array.from(freeTextHeaderSet.values());
        this.selectionFieldHeader = Array.from(selectionHeaderSet.values());
    }

    private setReportHeaders() {
        if (this.includeCustomField) {
            this.freeTextFieldHeader.forEach(column => this.header.push(column));
            this.selectionFieldHeader.forEach(column => this.header.push(column));
        }

        DateGenerator.getDates('Spend').forEach(date => this.header.push(date));

        DateGenerator.getDates('Invoiced').forEach((date, index) => this.invoiceHeader.splice(3 + index, 0, date));
        DateGenerator.getDates('Balance').forEach(date => this.invoiceHeader.push(date));

        this.invoiceHeader.forEach(value => this.header.push(value));

        this.csvTemplate.setHeaders(this.header);
    }

    private parseRecords() {
        this.purchaseOrders?.forEach(purchase => {
            const lines = purchase.purchaseLines;
            const purchaseForExport = this.purchaseExport(Object.assign(new PurchaseIndex(), purchase));
            if (lines === undefined) {
                return;
            }
            lines.forEach(line => {
                const lineData = this.getLineData(
                    Object.assign(new PurchaseIndex(), purchase),
                    Object.assign(new PurchaseLineIndex(), line),
                    purchaseForExport
                );
                this.csvTemplate.setCSVData(lineData);
            });
        });
    }

    public withCustomField(includeCustomField: boolean): CSVExportBuilderV2 {
        this.includeCustomField = includeCustomField;
        return this;
    }

    public useRecords(purchaseOrders?: PurchaseIndex[]): CSVExportBuilderV2 {
        if (purchaseOrders) this.purchaseOrders = purchaseOrders;

        if (this.includeCustomField) {
            this.prepareCustomFieldsHeader(purchaseOrders ?? []);
        }

        return this;
    }

    public build(): CSVTemplate {
        this.setReportHeaders();
        this.parseRecords();

        return this.csvTemplate;
    }

    private getUniqueAllocations(invoiceLine: InvoiceLine[]) {
        return invoiceLine.filter(value => new Set(value.invoiceId));
    }

    public getUniqueAllocationsCount(invoiceLine: InvoiceLine[]) {
        return String(this.getUniqueAllocations(invoiceLine).length);
    }

    private getPurchaseReceiptConfirmationStatus(purchase: PurchaseIndex): ConfirmStatus {
        if (this.closedStatuses.includes(purchase.status)) {
            return ConfirmStatus.PURCHASE_CLOSED;
        }

        if (!purchase?.receipts?.size) {
            return ConfirmStatus.NOT_APPLICABLE;
        }

        if (this.hasUnconfirmedReceipts(purchase)) {
            return ConfirmStatus.NOT_CONFIRMED;
        }

        return this.hasAtleastOneConfirmedReceipt(purchase) ? ConfirmStatus.CONFIRMED : ConfirmStatus.NOT_APPLICABLE;
    }

    private hasUnconfirmedReceipts(purchase: PurchaseIndex): boolean {
        const qualifiesForConfirmation = (r: TSCoreReceipt) => {
            const receiptCopy: Receipt = Object.assign(new Receipt(), r);
            return receiptCopy.qualifiesForConfirmation;
        };

        return Array.from(purchase.receipts?.values() ?? []).find(r => r.some(qualifiesForConfirmation)) !== undefined;
    }

    private hasAtleastOneConfirmedReceipt(purchase: PurchaseIndex): boolean {
        const doesNotQualifyForConfirmation = (r: TSCoreReceipt) => {
            const receiptCopy: Receipt = Object.assign(new Receipt(), r);
            return receiptCopy.requiresConfirmation && !receiptCopy.qualifiesForConfirmation;
        };

        return (
            Array.from(purchase.receipts?.values() ?? []).find(r => r.some(doesNotQualifyForConfirmation)) !== undefined
        );
    }
}
