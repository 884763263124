import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableProps, Header } from '@amzn/awsui-components-react';

import { COACombination } from 'modules/onboarding/model';
import { TableRow } from 'modules/core/model';

export type COACombinationTableProps = Required<Pick<TableProps, 'items' | 'loading'>>;

export const CoaScopeTable = (props: COACombinationTableProps) => {
    const { t } = useTranslation('customCategory');

    const columnDefinitions = [
        {
            id: 'company',
            header: t('coaTable.companyCode'),
            cell: (row: TableRow<COACombination>) => row.payload.company
        },
        {
            id: 'location',
            header: t('coaTable.location'),
            cell: (row: TableRow<COACombination>) => row.payload.location
        },
        {
            id: 'costCenter',
            header: t('coaTable.costCenter'),
            cell: (row: TableRow<COACombination>) => row.payload.costCenter
        },
        {
            id: 'account',
            header: t('coaTable.glAccount'),
            cell: (row: TableRow<COACombination>) => row.payload.glAccount
        },
        {
            id: 'product',
            header: t('coaTable.productLine'),
            cell: (row: TableRow<COACombination>) => row.payload.productLine
        },
        {
            id: 'channel',
            header: t('coaTable.channel'),
            cell: (row: TableRow<COACombination>) => row.payload.channel
        },
        {
            id: 'project',
            header: t('coaTable.projectCode'),
            cell: (row: TableRow<COACombination>) => row.payload.project
        },
        {
            id: 'eighthSegment',
            header: t('coaTable.customEighthSegment'),
            cell: (row: TableRow<COACombination>) => row.payload.customEighthSegment
        }
    ];

    const header = <Header variant="h2">{t('coaTable.title')}</Header>;

    const loadingText: string = t('coaTable.loading');
    const headerMessage: string = t('coaTable.empty');

    const emptyBox: JSX.Element = (
        <Box textAlign="center" color="inherit">
            <b>{headerMessage}</b>
        </Box>
    );

    return (
        <Table
            {...props}
            header={header}
            columnDefinitions={columnDefinitions}
            loadingText={loadingText}
            empty={emptyBox}
        />
    );
};
