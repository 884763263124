import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { useSmartERollUps } from 'modules/rollups/hooks';
import { SmartE } from 'modules/rollups/components/SmartE';

export const SmartEView = (_props: RouteComponentProps) => {
    const { loading, rollUps } = useSmartERollUps();

    return <SmartE items={rollUps} loading={loading} />;
};
