/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@amzn/awsui-components-react';
import { SMARTeRollUp } from '@amzn/merp-core/models';

import { TableRow } from 'modules/core/model';
import { ExpandableCell } from 'modules/core/components/Table';
import { RollUpsHeader } from '../RollUpsHeader';
import { CSVTemplate } from 'modules/dashboard/models';
import { CsvConverter } from 'modules/rollups/csv';
import { CSVLink } from 'react-csv';

type SmartEProps = {
    items: SMARTeRollUp[];
    loading?: boolean;
};

export const SmartE = (props: SmartEProps) => {
    const { items, loading } = props;

    const { t } = useTranslation(['rollups', 'mappings']);

    const [rows, updateRows] = useState<TableRow<SMARTeRollUp>[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csvlink = useRef<any>();
    const [exportLoading, updateExportLoading] = useState(false);
    const [csvExport, setCsvExport] = useState<CSVTemplate | undefined>(undefined);

    useEffect(() => {
        updateRows(prev => {
            if (prev.length === 0) {
                return items.map((payload, number) => ({
                    number,
                    payload,
                    expanded: false,
                    selected: false,
                    rowId: payload.countryChannelSmartE
                }));
            } else {
                return items.map((payload, i) =>
                    Object.assign(prev[i], { payload, rowId: payload.countryChannelSmartE })
                );
            }
        });
    }, [items]);

    const onExport = () => {
        updateExportLoading(true);

        const result = new CsvConverter().smartE(rows.map(r => r.payload));
        setCsvExport(result);
    };

    useEffect(() => {
        if (!csvExport) return;

        // The csv library needs some time to load the csv data and make the blob.
        // this effect runs before the ref is rendered, so we ned to wait for the
        // ref to be rendered before triggering the click
        setTimeout(() => {
            csvlink.current?.link.click();
            updateExportLoading(false);
            setCsvExport(undefined);
        }, 500);
    }, [csvExport]);

    const currentYear = new Date().getUTCFullYear();

    const columns = [
        {
            id: 'country',
            header: <div>{t('rollups:country')}</div>,
            cell: (e: TableRow<SMARTeRollUp>) => (
                <ExpandableCell row={e} cell={<span className="mt-auto mb-auto">{e.payload.country}</span>} />
            )
        },
        {
            id: 'channel',
            header: <div>{t('rollups:channel')}</div>,
            cell: (e: TableRow<SMARTeRollUp>) => (
                <ExpandableCell
                    row={e}
                    cell={<span className="mt-auto mb-auto">{t('rollups:channelMap.' + e.payload.channel)}</span>}
                />
            )
        },
        {
            id: 'smartE',
            header: <div>{t('rollups:smartE')}</div>,
            cell: (e: TableRow<SMARTeRollUp>) => (
                <ExpandableCell
                    row={e}
                    cell={<>{t('mappings:smartEMappings.dropdown.smartEList.' + e.payload.smartE)}</>}
                />
            )
        },
        {
            id: 'prevYear',
            header: <div>{currentYear - 1}</div>,
            cell: (e: TableRow<SMARTeRollUp>) => <ExpandableCell row={e} cell={<>{e.payload.previousYear}</>} />
        },
        {
            id: 'currYear',
            header: <div>{currentYear}</div>,
            cell: (e: TableRow<SMARTeRollUp>) => <ExpandableCell row={e} cell={<>{e.payload.currentYear}</>} />
        },
        {
            id: 'nextYear',
            header: <div>{currentYear + 1}</div>,
            cell: (e: TableRow<SMARTeRollUp>) => <ExpandableCell row={e} cell={<>{e.payload.nextYear}</>} />
        }
    ];

    let header = t('rollups:smartE');

    if (rows.length > 0) {
        header += ` (${rows.length})`;
    }

    return (
        <div className="msp-expandable-table">
            {csvExport && (
                <CSVLink
                    ref={csvlink}
                    data={csvExport?.getCSVData() || []}
                    headers={csvExport?.getHeaders() || []}
                    filename={'msp_smart_e_rollups.csv'}
                    className="hidden"
                    target="_blank"
                />
            )}
            <Table
                columnDefinitions={columns}
                items={rows}
                loading={loading}
                loadingText={t('rollups:loading')}
                header={
                    <RollUpsHeader
                        header={header}
                        disabled={rows.length <= 0}
                        exportLoading={exportLoading}
                        onExport={onExport}
                    />
                }
            />
        </div>
    );
};
