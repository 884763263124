import React, { useEffect } from 'react';
import { Select, SelectProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useBrassTeams } from 'modules/budgetManagement/hooks';
import { isNonMarketing } from 'modules/app/store';
import { brassTeamsIncludesNonMarketingTeams } from '../../../../modules/core/helpers/nonMarketingValidator';

interface EditChannelDropDownProps {
    selectedOption?: string;
    channelOptions: string[];
    onChange: (channel: string) => void;
}

export const EditChannelDropDown = (props: EditChannelDropDownProps) => {
    const { selectedOption, channelOptions, onChange } = props;

    const isMarketingPR = !useSelector(isNonMarketing);
    const { t } = useTranslation('channelSpend');
    const channelList = selectedOption ? [selectedOption].concat(channelOptions) : channelOptions;
    const { brassTeams } = useBrassTeams();

    const optionsList: SelectProps.Option[] = channelList.map(id => ({ value: id, label: t(`channelsList.${id}`) }));
    useEffect(() => {
        const notApplicableIndex = optionsList.findIndex(option => option.label === 'Not Applicable');
        if (!brassTeams) {
            return;
        }
        if (!brassTeamsIncludesNonMarketingTeams(brassTeams) && notApplicableIndex > -1 && isMarketingPR) {
            optionsList.splice(notApplicableIndex, 1);
        }
    }, [brassTeams, optionsList]);

    return (
        <div>
            <Select
                data-cy="EditChannelDropDown"
                options={optionsList}
                placeholder={t('selectChannel')}
                onChange={event => onChange(event.detail.selectedOption.value || '')}
                selectedOption={optionsList.find(option => option.value === selectedOption)!}
            />
        </div>
    );
};
