import React from 'react';

import { DateGenerator } from 'modules/core/class';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import styles from './SpendDropDownHeader.module.scss';

interface SpendDropDownHeaderProps {
    setDateSelected: (selection: string) => void;
    dateSelected: string;
}
export const SpendDropDownHeader = ({ dateSelected, setDateSelected }: SpendDropDownHeaderProps) => {
    const buttonItems = DateGenerator.getDates();
    const dropdownItems = buttonItems.map(text => ({ text: text, id: text, disabled: false }));

    const { t } = useTranslation('purchase');

    return (
        <div className={styles.dropdown}>
            <span>{t('table.receiptMonth')}</span>
            <ButtonDropdown
                items={dropdownItems}
                expandToViewport={true}
                onItemClick={event => setDateSelected(event.detail.id)}
            >
                {dateSelected}
            </ButtonDropdown>
        </div>
    );
};
