import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, SpaceBetween } from '@amzn/awsui-components-react';
import { ColumnLayout } from '@amzn/awsui-components-react-v2';

import { AppConfig } from 'modules/core';
import { ConfirmStatus, MspOwner, PurchaseStatus } from '../../models';
import { EditMspOwners } from '../EditMspOwner/EditMspOwner';
import { PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { ApplicationState, getUserEditStatus } from 'modules/app/store';
import { EditBigRockObjective } from 'modules/bigRockObjective/components';
import { EditFreeTextModal } from 'modules/customCategory/components/EditFreeTextModal';
import { EditSelectionModal } from 'modules/customCategory/components/EditSelectionModal';
import { useCustomField } from 'modules/customCategory/hooks';
import { CustomField, CustomFieldType } from 'modules/customCategory/models';
import { RequisitionDetailPagePanels } from 'modules/requisition/constants';
import { I18nFormatter } from 'modules/core/class';

import styles from './PurchaseOverview.module.scss';

interface PurchaseOverviewProps {
    isCreationPage: boolean;
    updateOwners?: (updateOwners: MspOwner[]) => void;
    onHelp?: (key: RequisitionDetailPagePanels | PurchaseDetailPagePanels, ...args: unknown[]) => void;
    onUpdateBigRockObjective?: () => void;
}

export const PurchaseOverview = (props: PurchaseOverviewProps) => {
    const { isCreationPage, updateOwners, onHelp, onUpdateBigRockObjective } = props;

    const purchase = useSelector((state: ApplicationState) => state?.purchaseDetails?.purchase);

    const validUserEditStatus = useSelector(getUserEditStatus);

    const isPurchaseOrder = !purchase?.isPurchaseRequest;
    const purchaseId = purchase?.purchaseId ?? '';

    const title = isPurchaseOrder ? 'PO' : 'PR';
    const date = isPurchaseOrder ? purchase?.orderCreationTime : purchase?.requestCreationTime;

    const [isEditOwnerModalVisible, showEditOwnerModal] = useState(false);
    const [isEditBigRockObjectiveVisible, showEditBigRockObjective] = useState(false);
    const [isFreeTextModalVisible, updateFreeTextModalVisible] = useState(false);
    const [isSelectionModalVisible, updateSelectionModalVisible] = useState(false);

    const { defaultCustomFields } = useCustomField(purchase?.purchaseId ?? '');
    const defaultFreeTextFields = defaultCustomFields?.filter(f => {
        return CustomFieldType.TEXT === f.fieldType;
    });
    const defaultSelectionFields = defaultCustomFields?.filter(f => {
        return CustomFieldType.SELECTION === f.fieldType;
    });

    const existingFreeTextFields = purchase?.customFields ?? [];
    const existingSelectionFields = purchase?.customSelections ?? [];

    const { t } = useTranslation(['purchase', 'bigRock', 'customCategory']);

    const confirmStatusMappings = new Map([
        [ConfirmStatus.CONFIRMED, t('confirmation.isConfirmed')],
        [ConfirmStatus.NOT_CONFIRMED, t('confirmation.unconfirmed')],
        [ConfirmStatus.NOT_APPLICABLE, t('line.noSpendActivity')],
        [ConfirmStatus.PURCHASE_CLOSED, t('confirmation.isClosed')]
    ]);

    const purchaseStatusMap = new Map([
        [PurchaseStatus.CHANGE_PURCHASE_PENDING_MERP_APPROVAL, 'Update Needed'],
        [PurchaseStatus.CANCELLED, 'Cancelled'],
        [PurchaseStatus.SOFT_CLOSED, 'Soft Closed'],
        [PurchaseStatus.PENDING_SOFT_CLOSED, 'Pending Soft Close'],
        [PurchaseStatus.CLOSED, 'Closed'],
        [PurchaseStatus.ISSUED, 'Open']
    ]);

    const viewEditModal = () => {
        showEditOwnerModal(true);
    };

    const closeEditModal = () => {
        showEditOwnerModal(false);
    };

    const onInfoClicked = () => {
        if (onHelp) {
            onHelp('CENTRAL_RECEIVER_HELP', {
                url: AppConfig.coupaAccessFormUrl || '',
                value: t('purchase:helpPanel.accessRequestGuide')
            });
        }
    };

    const confirmStatusString = purchase ? confirmStatusMappings.get(purchase.confirmationReceiptStatus) : '';
    const purchaseStatus = purchase?.status !== undefined ? purchaseStatusMap.get(purchase.status) : '';
    const className = purchaseStatus?.toLowerCase().replace(/\s/g, '') || '';

    const ASPOwner = () => {
        const onAliasClicked = (owner: string) => {
            window.open(`https://phonetool.amazon.com/users/${owner}`, '_blank', 'noopener,noreferrer');
        };

        const AliasButton = ({ alias }: { alias: string }) => (
            <button onClick={() => onAliasClicked(alias)} className="awsui-util-help-info-link">
                {alias}@
            </button>
        );

        const { createdBy, requestedBy, mspOwner } = purchase || {};
        const uniqueAlias = new Set([createdBy?.login, requestedBy?.login, ...(mspOwner || [])]);

        const owners = Array.from(uniqueAlias)
            .filter(s => s)
            .map(String);

        const onOwnersUpdated = (owners: MspOwner[]) => {
            if (updateOwners) {
                updateOwners(owners);
            }
        };

        return (
            <>
                <EditMspOwners
                    visible={isEditOwnerModalVisible}
                    updateOwners={onOwnersUpdated}
                    onCancel={closeEditModal}
                />
                <div className="awsui-util-label">
                    {t('purchase:overview.mspOwner')}
                    <Link className="ml-1" onFollow={onInfoClicked}>
                        {t('info')}
                    </Link>
                    <button className="awsui-util-help-info-link" onClick={viewEditModal}>
                        {t('purchase:edit')}
                    </button>
                </div>
                <div className={styles['owner-container']}>
                    {owners.map(owner => (
                        <AliasButton key={owner} alias={owner} />
                    ))}
                </div>
            </>
        );
    };

    const stringHasContent = (s: string | undefined) => s != null && s !== '';

    const BigRock = () => {
        return (
            <>
                <div className="awsui-util-label">
                    <SpaceBetween direction="horizontal" size="xs">
                        <span>{t('purchase:overview.bigRock')}</span>
                        {validUserEditStatus && (
                            <Link onFollow={() => showEditBigRockObjective(true)}>{t('purchase:edit')}</Link>
                        )}
                    </SpaceBetween>
                </div>
                <div>
                    {
                        <span>
                            {stringHasContent(purchase?.bigRockObjective)
                                ? t(`bigRock:dropdown.objectives.${purchase?.bigRockObjective}`)
                                : t('bigRock:dropdown.objectives.noObjective')}
                        </span>
                    }
                </div>
            </>
        );
    };

    const displayCustomField = (field: CustomField) => {
        return <span>{field.fieldValues.join(', ')}</span>;
    };

    const CustomFreeText = () => {
        return (
            <div>
                {defaultFreeTextFields && defaultFreeTextFields.length > 0 && (
                    <div className="awsui-util-label">
                        <SpaceBetween direction="horizontal" size="xs">
                            <span>{defaultFreeTextFields[0].fieldKey}</span>
                            <Link onFollow={() => updateFreeTextModalVisible(true)}>{t('purchase:edit')}</Link>
                        </SpaceBetween>
                    </div>
                )}
                {existingFreeTextFields.length > 0 ? (
                    <div>{displayCustomField(existingFreeTextFields[0])}</div>
                ) : (
                    <div>{t('customCategory:editModal.freeTextEmpty')}</div>
                )}
            </div>
        );
    };

    const CustomSelection = () => {
        return (
            <div>
                {defaultSelectionFields && defaultSelectionFields.length > 0 && (
                    <div className="awsui-util-label">
                        <SpaceBetween direction="horizontal" size="xs">
                            <span>{defaultSelectionFields[0].fieldKey}</span>
                            <Link onFollow={() => updateSelectionModalVisible(true)}>{t('purchase:edit')}</Link>
                        </SpaceBetween>
                    </div>
                )}
                {existingSelectionFields.length > 0 ? (
                    <div>{displayCustomField(existingSelectionFields[0])}</div>
                ) : (
                    <div>{t('customCategory:editModal.selectionEmpty')}</div>
                )}
            </div>
        );
    };

    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>
                    {title} {t('purchase:overview.overview')}
                </h2>
            </div>
            <ColumnLayout columns={3} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                    <div>
                        <div className="awsui-util-spacing-v-s">
                            <div>
                                <div className="awsui-util-label">{t('purchase:overview.vendor')}</div>
                                <div>{purchase?.supplier?.displayName}</div>
                            </div>
                            <div>
                                <ASPOwner />
                            </div>
                            {isPurchaseOrder && defaultFreeTextFields && defaultFreeTextFields.length > 0 && (
                                <CustomFreeText />
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="awsui-util-spacing-v-s">
                            <div>
                                <div className="awsui-util-label">{t('purchase:overview.created')}</div>
                                <div>
                                    {I18nFormatter.date(t, date)}
                                    {purchase && isPurchaseOrder && (
                                        <a href={`/audit/${purchaseId}`} target="_blank" rel="noopener noreferrer">
                                            &nbsp;{t('purchase:overview.viewEdit')}&nbsp;
                                        </a>
                                    )}
                                </div>
                            </div>
                            {isPurchaseOrder && (
                                <div>
                                    <div className="awsui-util-label">{t('purchase:overview.confirmed')}</div>
                                    <div>{confirmStatusString}</div>
                                </div>
                            )}
                            {isPurchaseOrder && defaultSelectionFields && defaultSelectionFields.length > 0 && (
                                <CustomSelection />
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="awsui-util-spacing-v-s">
                            <div>
                                {isCreationPage ? (
                                    <div>
                                        <div className="awsui-util-label">{t('purchase:overview.account')}</div>
                                        <div>{purchase?.lines[0]?.company}</div>
                                    </div>
                                ) : (
                                    <BigRock />
                                )}
                            </div>
                            {isPurchaseOrder && (
                                <div>
                                    <div>
                                        <div className="awsui-util-label">{t('purchase:overview.status')}</div>
                                        <div className={styles[className]}>{purchaseStatus}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ColumnLayout>
            <EditBigRockObjective
                visible={isEditBigRockObjectiveVisible}
                onDismissed={() => {
                    if (
                        onUpdateBigRockObjective &&
                        purchase?.bigRockObjective !== undefined &&
                        purchase?.bigRockObjective !== ''
                    ) {
                        onUpdateBigRockObjective();
                    }
                    showEditBigRockObjective(false);
                }}
            />
            <EditFreeTextModal
                visible={isFreeTextModalVisible}
                defaultFreeTextFields={defaultFreeTextFields ?? []}
                onDismissed={() => {
                    updateFreeTextModalVisible(false);
                }}
            />
            <EditSelectionModal
                visible={isSelectionModalVisible}
                defaultSelectionFields={defaultSelectionFields ?? []}
                onDismissed={() => {
                    updateSelectionModalVisible(false);
                }}
            />
        </div>
    );
};
