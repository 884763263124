import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Receipt, ReceiptStatus } from 'modules/purchase/models';
import { SpaceBetween, Icon, IconProps, Popover, Link } from '@amzn/awsui-components-react';
import { ReceiptType } from '@amzn/merp-core/models';

interface OnHoverTextDetails {
    content: string;
    link?: { url: string; value: string };
}
interface ReceiptStatusIconDetails {
    name: IconProps.Name;
    variant: IconProps.Variant;
    text: string;
    onHoverText?: string | OnHoverTextDetails;
}

const ASP_SIM_TICKET_LINK = 'https://t.corp.amazon.com/create/templates/3065f386-d3e6-4a90-9e24-674a68c19313';

export const ReceiptStatusColumn = ({ receipt }: { receipt: Receipt }) => {
    const { t } = useTranslation('purchase');

    if (receipt.receiptStatus == null) {
        return <></>;
    }

    const iconTextMap: Record<ReceiptStatus, ReceiptStatusIconDetails> = {
        [ReceiptStatus.POSTED]: {
            name: 'status-positive',
            variant: 'success',
            text: t('receipts.posted'),
            onHoverText: t('helpPanel.ReceiptStatusPosted')
        },
        [ReceiptStatus.PENDING_POST]: {
            name: 'status-pending',
            variant: 'subtle',
            text: t('receipts.pendingPost'),
            onHoverText: t('helpPanel.ReceiptStatusPendingPost')
        },
        [ReceiptStatus.PENDING_VOID]: {
            name: 'status-pending',
            variant: 'subtle',
            text: t('receipts.pendingVoid'),
            onHoverText: t('helpPanel.ReceiptStatusPendingVoid')
        },
        [ReceiptStatus.CORRECTION]: {
            name: 'status-pending',
            variant: 'subtle',
            text: t('receipts.correction'),
            onHoverText: t('helpPanel.ReceiptStatusUnderCorrection')
        },
        [ReceiptStatus.POST_FAILURE]: {
            name: 'status-negative',
            variant: 'error',
            text: t('receipts.postFailure'),
            onHoverText: {
                content: 'helpPanel.ReceiptStatusPostFailure',
                link: { url: ASP_SIM_TICKET_LINK, value: 'helpPanel.cutATicket' }
            }
        },
        [ReceiptStatus.VOID_FAILURE]: {
            name: 'status-negative',
            variant: 'error',
            text: t('receipts.voidFailure'),
            onHoverText: {
                content: 'helpPanel.ReceiptStatusVoidFailure',
                link: { url: ASP_SIM_TICKET_LINK, value: 'helpPanel.cutATicket' }
            }
        },
        [ReceiptStatus.VOIDED]: { name: 'close', variant: 'subtle', text: t('receipts.voided') },
        [ReceiptStatus.ABANDONED]: { name: 'status-negative', variant: 'subtle', text: t('receipts.abandoned') },
        [ReceiptStatus.PENDING_APPROVAL]: {
            name: 'status-pending',
            variant: 'subtle',
            text: t('receipts.pendingApproval')
        }
    };

    const getIcon = (receipt: Receipt): ReceiptStatusIconDetails | undefined => {
        if (!receipt.receiptStatus) return;

        const receiptType = receipt.receiptType;
        if (receipt.receiptStatus === ReceiptStatus.PENDING_POST && receiptType === ReceiptType.FUTURE_MONTH_RECEIPT) {
            return {
                name: 'status-stopped',
                variant: 'subtle',
                text: t('receipts.futureReceipts'),
                onHoverText: t('helpPanel.ReceiptStatusFutureReceipts')
            };
        }
        if (
            receipt.receiptStatus === ReceiptStatus.PENDING_VOID &&
            (!receipt.confirmStatus || receipt.receiptType === ReceiptType.FUTURE_MONTH_RECEIPT)
        ) {
            return {
                name: 'status-stopped',
                variant: 'subtle',
                text: t('receipts.futureReceiptsPendingRemoval'),
                onHoverText: t('helpPanel.ReceiptStatusPendingRemoval')
            };
        }
        if (receipt.receiptStatus === ReceiptStatus.PENDING_POST && !receipt.confirmStatus) {
            return {
                name: 'status-warning',
                variant: 'warning',
                text: t('receipts.unconfirmed'),
                onHoverText: t('helpPanel.ReceiptStatusUnconfirmed')
            };
        }

        return iconTextMap[receipt.receiptStatus];
    };

    const iconDetails = getIcon(receipt) ?? { name: 'status-pending', variant: 'subtle', text: '' };

    const isString = (value: string | OnHoverTextDetails): value is string => typeof value === 'string';

    const onHoverContent = (details?: string | OnHoverTextDetails) => {
        if (!details) return <></>;
        if (isString(details)) return t(details);
        return (
            <>
                {!details.link && t(details.content)}
                {details.link && (
                    <Trans
                        i18nKey={t(details.content)}
                        values={{ link: t(details.link.value) }}
                        components={{
                            anchor: <Link href={`${details.link.url}`} target="_blank" />
                        }}
                    />
                )}
            </>
        );
    };

    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Popover
                size="small"
                position={'top'}
                triggerType="custom"
                header={iconDetails.text}
                content={<span>{onHoverContent(iconDetails.onHoverText)}</span>}
            >
                <Icon name={iconDetails.name} variant={iconDetails.variant} />
            </Popover>
            <span>{iconDetails.text}</span>
        </SpaceBetween>
    );
};
