import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineVersion } from 'modules/audit/models';
import styles from '../EditedVersion/EditedVersion.module.scss';
import { ChangedLineAmount } from '../ChangedLineAmount';
import { VersionTitle } from '../VersionTitle/VersionTitle';

export interface DeletedVersionProps {
    lineVersions: LineVersion[];
}

export const DeletedVersion = (props: DeletedVersionProps) => {
    const { lineVersions } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    let containerClass = 'awsui-util-container awsui-util-no-gutters';
    if (lineVersion.isRejected) {
        containerClass += ` ${styles['transparent-box']}`;
    }

    return (
        <div className={containerClass}>
            <div className="awsui-util-container-header">
                <VersionTitle title={t('version.lineDeletedTitle')} lineVersion={lineVersion} />
            </div>
            <div className={`awsui-grid ${styles['line-version-content']}`}>
                <div className="awsui-row">
                    <div className="col-9">
                        <strong>
                            {t('version.poLine')} {lineVersion.lineNumber}: {lineVersion.lineDescription}
                        </strong>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-2">
                        <ChangedLineAmount amount={lineVersion.changedLineAmount} currency={lineVersion.currencyCode} />
                    </div>
                </div>
            </div>
        </div>
    );
};
