import React from 'react';
import { useTranslation } from 'react-i18next';

import { PurchaseLine } from 'modules/purchase/models';

import { isQuantityBasedLine } from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';

import styles from '../PurchaseLineWrapper/Wrapper.module.scss';

interface PurchaseLabelIndicatorProps {
    line: PurchaseLine;
}

export const PurchaseLabelIndicator = ({ line }: PurchaseLabelIndicatorProps) => {
    const { t } = useTranslation('purchase');

    const indicator = line.hasValidDistribution ? 'awsui-util-status-positive' : 'is-error';

    const isQuantityBased = isQuantityBasedLine(line);
    const AmountDistributionMessage = () => {
        return (
            <p className={`${indicator} ${styles['has-wide-columns']}`}>
                {t('line.distributionMessage', {
                    actual: I18nFormatter.amount(t, line.estimatedAmount),
                    expected: I18nFormatter.amount(t, line.amount)
                })}
                {line.remainingAmount < 0 &&
                    t('line.distributionOverflow', {
                        remaining: I18nFormatter.amount(t, -line.remainingAmount)
                    })}
            </p>
        );
    };
    const QuantityDistributionMessage = () => {
        return (
            <p className={`${indicator} ${styles['has-wide-columns']}`}>
                {t('line.quantityDistributionMessage', {
                    actual: I18nFormatter.amount(t, line.estimatedQuantity),
                    expected: I18nFormatter.amount(t, line.quantity ?? 0)
                })}
                {line.remainingQuantity < 0 &&
                    t('line.quantityDistributionOverflow', {
                        remaining: I18nFormatter.amount(t, -line.remainingQuantity)
                    })}
            </p>
        );
    };

    return (
        <div className="awsui-row">
            <div className="col-xxs-12 col-m-4">
                {isQuantityBased ? QuantityDistributionMessage() : AmountDistributionMessage()}
            </div>
        </div>
    );
};
