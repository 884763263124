export class ReceiptDateValidator {
    private static readonly year = new Date().getUTCFullYear();
    private static readonly month = new Date().getUTCMonth();

    public static isValidReceipt(receiptTime: number): boolean {
        const year = new Date(receiptTime || '').getUTCFullYear();
        const month = new Date(receiptTime || '').getUTCMonth();

        return this.year === year && this.month === month;
    }

    public static invalidYear(): number {
        const year = this.year - 2;
        return year;
    }

    public static validDate(): Date {
        const d = new Date(this.year - 1, 0, 1);

        return d;
    }
}
