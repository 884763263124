import i18n from 'i18next';
import HttpAPI from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getMaxDecimalDigits } from '../services';

let canDebug = false;
if (process.env.NODE_ENV !== 'production') {
    canDebug = true;
}

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(HttpAPI)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        ns: [
            'auth',
            'app',
            'purchase',
            'requisition',
            'bigRock',
            'channelSpend',
            'mappings',
            'bulkEdit',
            'budgetManagement',
            'rollups',
            'bulkReceiving'
        ],
        defaultNS: 'app',
        fallbackLng: 'en',
        debug: canDebug,
        supportedLngs: ['en', 'de', 'es'],
        interpolation: {
            escapeValue: false,
            format: function(value, format, locale) {
                if (format === 'intlDate') {
                    const options: Intl.DateTimeFormatOptions = {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        timeZone: 'UTC'
                    };
                    return new Intl.DateTimeFormat(locale, options).format(value);
                }
                if (format === 'intlDateTime') {
                    const options: Intl.DateTimeFormatOptions = {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        timeZone: 'UTC'
                    };
                    return new Intl.DateTimeFormat(locale, options).format(value);
                }
                if (format === 'intlAmount') {
                    return new Intl.NumberFormat(locale, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: getMaxDecimalDigits()
                    }).format(value);
                }
                if (format === 'intlQuantity') {
                    return new Intl.NumberFormat(locale, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: getMaxDecimalDigits()
                    }).format(value);
                }
                if (format === 'intlInputAmount') {
                    return new Intl.NumberFormat(locale).format(value);
                }
                if (format === 'intlCurrency') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: 'USD'
                    }).format(value);
                }
                return value;
            }
        },
        react: { useSuspense: true }
    });

export default i18n;
