import { useCallback, useEffect, useState } from 'react';
import { logger } from 'modules/core';

import { bulkReceivingService } from '../services';
import { Option } from '@amzn/polaris-data-grid';

export function useBulkReceivingColumns() {
    const [loading, setLoading] = useState(false);
    const [columnValues, updateColumnValues] = useState<Option[]>();

    const mapToOption = (columnValue: string): Option => {
        return {
            value: columnValue,
            label: columnValue
        };
    };

    const getColumnValuesAsync = useCallback(async (columnName?: string, searchInput?: string) => {
        const fetchColumnValues = async () => {
            if (!columnName || !searchInput) {
                return [];
            } else {
                try {
                    return await bulkReceivingService.getColumnValuesAsync({
                        columnName,
                        searchInput
                    });
                } catch (e) {
                    logger.error(e);
                }
            }
            return [];
        };

        setLoading(true);

        const columnValuesAsync: string[] = await fetchColumnValues();

        const mappedColumnValues: Option[] = columnValuesAsync.map(mapToOption);

        updateColumnValues(mappedColumnValues);

        setLoading(false);
    }, []);

    useEffect(() => {
        getColumnValuesAsync();
    }, [getColumnValuesAsync]);

    return {
        loading,
        columnValues,
        updateColumnValues,
        getColumnValuesAsync
    };
}
