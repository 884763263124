import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, FormField } from '@amzn/awsui-components-react';

interface AssignOwnerInputProps {
    owner: string;
    updateOwner: React.Dispatch<React.SetStateAction<string>>;
    errorText?: string;
}

export const AssignCoaOwnerInput = (props: AssignOwnerInputProps) => {
    const { owner, updateOwner, errorText } = props;
    const { t } = useTranslation('budgetManagement');

    const setInputText = (inputValue: string) => {
        updateOwner(inputValue);
    };

    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>{t('coaInput.title')}</h2>
            </div>

            <FormField label={t('coaInput.label')} errorText={errorText}>
                <Input
                    type="text"
                    value={owner}
                    placeholder="User alias"
                    onChange={({ detail }) => setInputText(detail.value)}
                />
            </FormField>
        </div>
    );
};
