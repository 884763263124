/* eslint-disable react/display-name */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@amzn/awsui-components-react';

type RollUpsHeaderProps = {
    header: string;
    disabled?: boolean;
    exportLoading?: boolean;
    onExport?: () => void;
};

export const RollUpsHeader = (props: RollUpsHeaderProps) => {
    const { header, disabled, exportLoading, onExport } = props;

    const { t } = useTranslation('rollups');

    return (
        <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
                <h2>{header}</h2>
            </div>
            <div className="awsui-util-action-stripe-title">
                <Button variant="primary" disabled={disabled} onClick={onExport} loading={exportLoading}>
                    {t('export')}
                </Button>
            </div>
        </div>
    );
};
