import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logger } from 'modules/core';
import { getBigObjective, updateBigObjective } from 'modules/app/store';
import { bigRockObjectiveService } from 'modules/bigRockObjective/services';

export function useBigObjective() {
    const dispatch = useDispatch();
    const defaultSelection = useSelector(getBigObjective);

    const [loading, updateLoading] = useState(false);
    const [success, updateSuccess] = useState(false);
    const [bigObjective, updateBigObj] = useState(defaultSelection);

    async function updatePurchaseBigObjectiveAsync(purchaseId: string, bigO: string): Promise<void> {
        try {
            updateLoading(true);
            updateSuccess(false);

            await bigRockObjectiveService.updateBigObjectiveAsync(purchaseId, bigO);
            dispatch(updateBigObjective(bigO));

            updateBigObj(bigO);
            updateSuccess(true);
        } catch (error) {
            logger.error(error);
            updateSuccess(false);
        } finally {
            updateLoading(false);
        }
    }

    return { loading, success, bigObjective, updatePurchaseBigObjectiveAsync };
}
