import * as yup from 'yup';

import { ReceiptDateValidator } from 'modules/purchase/models';

export const RequisitionSchema = yup.object().shape({
    amount: yup
        .array()
        .min(1, 'Pick at least 1 item')
        .of(
            yup
                .number()
                .moreThan(0, 'Amount should be more than zero')
                .typeError('Enter Valid Amount')
                .required('Amount is required')
                .test('is-valid-decimal', 'Invalid decimals in amount', value => {
                    return (value + '').match(/^\d*\.{1}\d{3,}$/g) === null;
                })
        ),
    receiptDate: yup
        .array()
        .min(1, 'Pick at least 1 item')
        .of(
            yup
                .date()
                .min(
                    ReceiptDateValidator.validDate(),
                    `Date field must be later than ${ReceiptDateValidator.validDate().toDateString()}`
                )
                .required('Date is required')
                .typeError('Date is invalid')
        )
});
