import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineVersion } from 'modules/audit/models';
import { VersionTitle } from '../VersionTitle/VersionTitle';

export interface InvoiceAddedVersionProps {
    lineVersions: LineVersion[];
}

export const InvoiceAddedVersion = (props: InvoiceAddedVersionProps) => {
    const { lineVersions } = props;
    const lineVersion = lineVersions[0];
    const { t } = useTranslation('purchase');

    const containerClass = 'awsui-util-container awsui-util-no-gutters';

    return (
        <div className={containerClass}>
            <div className="awsui-util-container-header">
                <VersionTitle title={t('version.newInvoiceAddedTitle')} lineVersion={lineVersion} showDetails={false} />
            </div>
        </div>
    );
};
