import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse } from 'modules/core/model/response';
import { logger } from 'modules/core';
import { ChannelMeasurementMapping } from 'modules/mappings/models';
import { ApiError } from 'modules/core/types';

interface ChannelMeasurementsResult {
    status?: number;
    requestId?: string;
    channelMeasurements?: ChannelMeasurementMapping[];
    teamName: string;
}

interface UpdateChannelMeasurementRequest {
    teamName: string;
    channelMeasurementMappings: ChannelMeasurementMapping[];
}

export class ChannelMeasurementService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'mappings';
    protected path = 'channelMeasurement';

    public async getChannelMeasurementMappingsAsync(): Promise<ChannelMeasurementsResult> {
        try {
            const json: ResourceResponse = await this.get(this.path);

            const { result, requestId } = json;
            const channelMeasurementMappings = result as ChannelMeasurementMapping[];
            const teamName = channelMeasurementMappings.length ? channelMeasurementMappings[0].teamName : '';
            return { requestId, channelMeasurements: channelMeasurementMappings, teamName, status: 200 };
        } catch (error) {
            logger.error(error);
            return { channelMeasurements: undefined, teamName: '', status: (error as ApiError)?.response?.status };
        }
    }

    public async updateChannelMeasurementMappingsAsync(body: UpdateChannelMeasurementRequest) {
        try {
            const response = await this.put(this.path, { body });

            return response;
        } catch (error) {
            logger.error(error);
            return {
                success: false,
                message: 'Unable to submit request'
            };
        }
    }
}

export const channelMeasurementService = new ChannelMeasurementService();
