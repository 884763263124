/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber } from 'bignumber.js';
import { YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';
import { logger } from 'modules/core/logger';

export class YearlySpendMapper {
    public setYearlySpend(row: any, forecast: YearlyAccountingForecast): YearlyAccountingForecast {
        try {
            forecast.jan = this.toNumber(row['Jan']) || 0;
            forecast.feb = this.toNumber(row['Feb']) || 0;
            forecast.mar = this.toNumber(row['Mar']) || 0;

            forecast.apr = this.toNumber(row['Apr']) || 0;
            forecast.may = this.toNumber(row['May']) || 0;
            forecast.jun = this.toNumber(row['Jun']) || 0;

            forecast.jul = this.toNumber(row['Jul']) || 0;
            forecast.aug = this.toNumber(row['Aug']) || 0;
            forecast.sep = this.toNumber(row['Sep']) || 0;

            forecast.oct = this.toNumber(row['Oct']) || 0;
            forecast.nov = this.toNumber(row['Nov']) || 0;
            forecast.dec = this.toNumber(row['Dec']) || 0;

            const { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec } = forecast;
            forecast.q1 = this.addMonths(jan, feb, mar);
            forecast.q2 = this.addMonths(apr, may, jun);
            forecast.q3 = this.addMonths(jul, aug, sep);
            forecast.q4 = this.addMonths(oct, nov, dec);

            const { q1, q2, q3, q4 } = forecast;
            forecast.total = this.addMonths(q1, q2, q3, q4);
        } catch (error) {
            logger.error(error);
        }

        return forecast;
    }

    private toNumber(value: string | undefined): number | undefined {
        if (value === undefined || value === null) return undefined;

        const convertedValue = Number(value.toString().replace(/[^0-9\.]+/g, ''));

        return parseFloat(convertedValue.toFixed(2));
    }

    private addMonths(m1: number, m2: number, m3?: number, m4?: number) {
        return new BigNumber(m1)
            .plus(m2)
            .plus(m3 || 0)
            .plus(m4 || 0)
            .toNumber();
    }
}

export const yearlySpendMapper = new YearlySpendMapper();
