import { PurchaseStatus, Receipt, ConfirmStatus, Purchase } from 'modules/purchase/models';
import { PaginatedQuery, PaginatedResult, PurchaseQueryV2, PurchaseResultV2 } from 'modules/core/model';
import { v4 as uuidV4 } from 'uuid';
import { mapToPurchase, mapToPurchaseIndex } from 'modules/core/mappers';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';

export const buildRepository = function(length: number) {
    const template = {
        amount: 106000,
        creatorGroup: 'Kira Sun (kirasun)',
        creationTime: 1580803200000,
        status: PurchaseStatus.PURCHASE_REQUEST,
        supplier: { displayName: 'AT&T' },
        createdBy: { login: 'jeff', firstName: 'Jeff', lastName: 'Bezos' },
        lines: [
            {
                amount: 20000,
                lineNumber: 1,
                purchaseId: '213442134223',
                description: 'AQI.FIRE.MONITOR - Smart Sense AQI monitor for 6 months',
                currencyCode: 'USD',
                creationTime: 1580803200000,
                account: {
                    code: '6a0e5178-cc8e-420f-944d-b0c2b20c04cf',
                    active: true,
                    id: '7a3eef31efbc',
                    name: 'Amazon.com, Inc.-SEA37 - Roxanne (Seattle, WA, US)-Procurement'
                },
                receipts: [
                    Object.assign(new Receipt(), {
                        receiptId: 'a63d0a06-221e-4bf6-afa3-cffcb37724dd',
                        receiptAmount: 10000,
                        receiptTime: 1580803200000
                    }),
                    Object.assign(new Receipt(), {
                        receiptId: '45412abc-5a1a-40e7-a5ff-76239cd48d45',
                        receiptAmount: 10000,
                        receiptTime: 1586905114708
                    })
                ]
            },
            {
                amount: 80000,
                lineNumber: 2,
                purchaseId: '213442134223',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat ac risus hendrerit tristique. Duis convallis erat quis facilisis semper. Nullam vehicula feugiat sem, a pellentesque libero placerat nec.',
                currencyCode: 'USD',
                creationTime: 1580803200000,
                account: {
                    code: 'af4b2fb7-4b01-4e89-8403-8b91e526167e',
                    active: true,
                    id: 'be28133b8fbe',
                    name: 'Amazon.com, Inc.-SEA68 - CORAL (Seattle, WA, US)-Procurement'
                },
                receipts: [
                    Object.assign(new Receipt(), {
                        receiptId: 'a63d0a06-221e-4bf6-afa3-cffcb37724dd',
                        receiptAmount: 40000,
                        receiptTime: 1580803200000
                    }),
                    Object.assign(new Receipt(), {
                        receiptId: '45412abc-5a1a-40e7-a5ff-76239cd48d45',
                        receiptAmount: 40000,
                        receiptTime: 1586905114708
                    })
                ]
            },
            {
                amount: 6000,
                lineNumber: 3,
                purchaseId: '213442134223',
                description: 'AQI.FIRE.MONITOR - Smart Sense AQI monitor for 6 months',
                currencyCode: 'USD',
                creationTime: 1580803200000,
                account: {
                    code: '34e971d86879',
                    active: true,
                    id: '390dead1-d670-4c64-825f-6bafe6cdf91e',
                    name: 'Amazon.com, Inc.-SEA43- Day 1 (Seattle, WA, US)-Procurement'
                },
                receipts: [
                    Object.assign(new Receipt(), {
                        receiptId: 'a63d0a06-221e-4bf6-afa3-cffcb37724dd',
                        receiptAmount: 3000,
                        receiptTime: 1580803200000
                    }),
                    Object.assign(new Receipt(), {
                        receiptId: '45412abc-5a1a-40e7-a5ff-76239cd48d45',
                        receiptAmount: 3000,
                        receiptTime: 1586905114708
                    })
                ]
            }
        ]
    };

    const purchases = Array.from({ length }, _ => ({
        purchaseId: uuidV4(),
        confirmStatus: ConfirmStatus[Math.floor(Math.random() * 4) + 1],
        ...template
    }));

    return purchases.map(mapToPurchase);
};

export const buildIndexRepository = function(length: number) {
    const template1 = {
        confirmStatus: 'No Spend',
        confirmStatusInt: 300,
        requestAmount: 100.23,
        currencyCode: 'USD',
        orderId: null,
        orderNumber: null,
        status: 'PENDING_APPROVAL',
        statusKeyword: 'Pending Approval',
        statusDescription: null,
        requestCreationTime: 1635453134000,
        orderCreationTime: null,
        requesterLogin: 'weiyunh',
        requesterFirstName: 'Weiyun',
        requesterLastName: 'Hua',
        addressLocationCode: '44326727',
        justification: null,
        creatorLogin: 'weiyunh',
        creatorFirstName: 'Weiyun',
        creatorLastName: 'Hua',
        creationTime: 1636360692212,
        lastModifiedTime: 1636360692594,
        location: '6086',
        costCenter: '4304',
        mspOwner: [],
        creatorGroupPurchaseType: 'Merp-SpendingPlatform-Team-XCM_PURCHASE_REQUEST',
        purchaseType: 'PURCHASE_REQUEST',
        supplierId: '839969',
        supplierDisplayName: 'DYNATA GLOBAL UK LTD:CORPORATE AP:SERVICES(YR7OP)',
        bigObjective: 'amazonBrandReputation',
        purchaseLines: [
            {
                purchaseId: '6378675',
                lineNumber: 1,
                coupaVersion: null,
                lineAmount: 100.23,
                uncommittedBalance: 0.0,
                allocatedAmount: 0.0,
                billingAccountCode: '2D-6086-4304-62350-0000-1000-D557-0034',
                billingAccountName:
                    'Amazon.com Services LLC-SEA86 - Corp Office - Triumph East (Seattle, WA, US)-XCM Fixed Marketing 2 - VAR-Customer Research-Unspecified-Amazon.com - US-XCM - MR - Device-MR - Device',
                commodity: 'Marketing Research Services',
                currencyCode: 'USD',
                lineDescription: 'Line 1',
                needByDate: 1634281200000,
                paymentTermCode: '60 NET',
                supplierId: '839969',
                lineId: null,
                company: '2D',
                location: '6086',
                costCenter: '4304',
                glAccount: '62350',
                productLine: '0000',
                channel: '1000',
                project: 'D557',
                customEighthSegment: '0034',
                purchaseLineStatus: 'PENDING_RECEIPTS_BY_USER',
                creationTime: null,
                lastModifiedTime: 1636360692652,
                version: null
            }
        ],
        invoiceLines: null,
        receipts: {},
        identifier: '6378675'
    };
    const template = {
        confirmStatus: 'No Spend',
        confirmStatusInt: 300,
        requestAmount: 1000.0,
        currencyCode: 'USD',
        orderId: null,
        orderNumber: null,
        status: 'PENDING_APPROVAL',
        statusKeyword: 'Pending Approval',
        statusDescription: null,
        requestCreationTime: 1636149557000,
        orderCreationTime: null,
        requesterLogin: 'mingzhd',
        requesterFirstName: 'Mingzhong',
        requesterLastName: 'Deng',
        addressLocationCode: '44326727',
        justification: null,
        creatorLogin: 'mingzhd',
        creatorFirstName: 'Mingzhong',
        creatorLastName: 'Deng',
        creationTime: 1636149919364,
        lastModifiedTime: 1636149919984,
        location: '6086',
        costCenter: '4304',
        mspOwner: [],
        creatorGroupPurchaseType: 'Merp-SpendingPlatform-Team-XCM_PURCHASE_REQUEST',
        purchaseType: 'PURCHASE_REQUEST',
        supplierId: '839969',
        supplierDisplayName: 'DYNATA GLOBAL UK LTD:CORPORATE AP:SERVICES(YR7OP)',
        bigObjective: 'amazonBrandReputation',
        purchaseLines: [
            {
                purchaseId: '6378730',
                lineNumber: 1,
                coupaVersion: null,
                lineAmount: 1000.0,
                uncommittedBalance: 0.0,
                allocatedAmount: 0.0,
                billingAccountCode: '2D-6086-4304-62350-0000-1000-D557-0034',
                billingAccountName:
                    'Amazon.com Services LLC-SEA86 - Corp Office - Triumph East (Seattle, WA, US)-XCM Fixed Marketing 2 - VAR-Customer Research-Unspecified-Amazon.com - US-XCM - MR - Device-MR - Device',
                commodity: 'Marketing Research Services',
                currencyCode: 'USD',
                lineDescription: 'check smartE channel allocation',
                needByDate: 1636963200000,
                paymentTermCode: '60 NET',
                supplierId: '839969',
                lineId: null,
                company: '2D',
                location: '6086',
                costCenter: '4304',
                glAccount: '62350',
                productLine: '0000',
                channel: '1000',
                project: 'D557',
                customEighthSegment: '0034',
                purchaseLineStatus: 'UPDATED',
                creationTime: null,
                lastModifiedTime: 1636150058301,
                version: 1636150058133
            }
        ],
        invoiceLines: null,
        receipts: {
            '1': [
                {
                    receiptAmount: 400.0,
                    versionCategory: 'initialInput',
                    versionDescription: 'Initial creation for receipt and channels',
                    statusDescription: null,
                    billedAmount: 0.0,
                    creditAmount: null,
                    creationTime: 1636150058119,
                    lastModifiedTime: 1636150058119,
                    coupaStatus: null,
                    purchaseId: '6378730',
                    teamName: 'Merp-SpendingPlatform-Team-XCM',
                    receiptNumber: '1$a0b6684464714252913030a11b03098b',
                    receiptTime: 1636963200000,
                    receiptId: null,
                    receiptStatus: 'PENDING_POST',
                    orderId: null,
                    lineNumber: 1,
                    lineId: null,
                    dueMonth: '202111',
                    agedAccrualNotificationDate: '2022-02-13',
                    version: 1636150058133,
                    confirmStatus: null,
                    postedAt: null,
                    allocations: null,
                    channelAllocations: null,
                    deprecatedChannelSpends: null
                },
                {
                    receiptAmount: 600.0,
                    versionCategory: 'initialInput',
                    versionDescription: 'Initial creation for receipt and channels',
                    statusDescription: null,
                    billedAmount: 0.0,
                    creditAmount: null,
                    creationTime: 1636150058119,
                    lastModifiedTime: 1636150058119,
                    coupaStatus: null,
                    purchaseId: '6378730',
                    teamName: 'Merp-SpendingPlatform-Team-XCM',
                    receiptNumber: '1$f25f1a2772694fc1877d40d53428009f',
                    receiptTime: 1639555200000,
                    receiptId: null,
                    receiptStatus: 'PENDING_POST',
                    orderId: null,
                    lineNumber: 1,
                    lineId: null,
                    dueMonth: '202112',
                    agedAccrualNotificationDate: '2022-03-15',
                    version: 1636150058133,
                    confirmStatus: null,
                    postedAt: null,
                    allocations: null,
                    channelAllocations: null,
                    deprecatedChannelSpends: null
                }
            ]
        },
        identifier: '6378730'
    };

    const purchases = Array.from({ length }, _ => ({
        purchaseId: uuidV4(),
        ...template1,
        ...template
    }));

    return purchases.map(mapToPurchaseIndex);
};

const updateRecord = {
    '213442134224': {
        purchaseId: '213442134224',
        amount: 20000,
        creatorGroup: 'Kira Sun (kirasun)',
        creationTime: 1580803200000,
        status: PurchaseStatus.PURCHASE_REQUEST,
        confirmStatus: 5,
        supplier: { displayName: 'AT&T' },
        createdBy: { login: 'jeff', firstName: 'Jeff', lastName: 'Bezos' },
        lines: [
            {
                amount: 20000,
                lineNumber: 1,
                purchaseId: '213442134224',
                description: 'AQI.FIRE.MONITOR - Smart Sense AQI monitor for 6 months',
                currencyCode: 'USD',
                creationTime: 1580803200000,
                account: {
                    code: '6a0e5178-cc8e-420f-944d-b0c2b20c04cf',
                    active: true,
                    id: '7a3eef31efbc',
                    name: 'Amazon.com, Inc.-SEA37 - Roxanne (Seattle, WA, US)-Procurement'
                }
            }
        ]
    }
};

class MockedPurchaseListService {
    purchase1Repository = buildRepository(45);
    purchase2Repository = buildRepository(15);

    public async getPurchaseOrdersAsync(queries?: PaginatedQuery): Promise<PaginatedResult<Purchase>> {
        const records = [
            { items: this.purchase1Repository, lastEvaluatedKey: new Map<string, unknown>([['1', 1]]) },
            { items: this.purchase2Repository }
        ];

        if (!records) {
            throw Error('Unable to fetch records');
        }

        if (queries?.pageSize) {
            return { items: buildRepository(queries.pageSize) };
        }

        let index = 0;
        if (queries?.lastEvaluatedKey) {
            const values = (queries.lastEvaluatedKey as Map<string, unknown>).values();
            index = values.next().value as number;
        }

        return records[index] as PaginatedResult<Purchase>;
    }

    public async getPurchaseRequestsAsync(queries?: PaginatedQuery): Promise<PaginatedResult<Purchase>> {
        const newPurchaseRepository = [...this.purchase1Repository];
        //Included a Update needed record in a PurchaseRequest records only for testing Alert Modal
        newPurchaseRepository.push(mapToPurchase(updateRecord));
        newPurchaseRepository.shift();

        const records = [
            { items: newPurchaseRepository, lastEvaluatedKey: new Map<string, unknown>([['1', 1]]) },
            { items: this.purchase2Repository }
        ];

        if (!records) {
            throw Error('Unable to fetch records');
        }

        let index = 0;
        if (queries?.lastEvaluatedKey) {
            const values = (queries.lastEvaluatedKey as Map<string, unknown>).values();
            index = values.next().value as number;
        }

        return records[index] as PaginatedResult<Purchase>;
    }

    public async getPurchaseOrdersAsyncV2(_queries?: PurchaseQueryV2): Promise<PurchaseResultV2<PurchaseIndex>> {
        const records = buildIndexRepository(20);

        if (!records) {
            throw Error('Unable to fetch records');
        }

        return { items: records as PurchaseIndex[], status: 200 };
    }

    public async getPurchaseRequestsAsyncV2(queries?: PurchaseQueryV2): Promise<PurchaseResultV2<PurchaseIndex>> {
        const records = buildIndexRepository(queries?.pageSize || 10);

        if (!records) {
            throw Error('Unable to fetch records');
        }

        return { items: records as PurchaseIndex[], status: 200 };
    }
}

export const mockedPurchaseListService = new MockedPurchaseListService();
