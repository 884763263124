import React from 'react';
import { Box } from '@amzn/awsui-components-react';

interface EmptyTableProps {
    title: string;
    subtitle: string;
    action?: JSX.Element;
}

export const EmptyTable = ({ title, subtitle, action }: EmptyTableProps): JSX.Element => {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" padding={{ top: 'xs', bottom: 'xs' }}>
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 'xs' }}>
                {subtitle}
            </Box>
            {action}
        </Box>
    );
};
