import { ApiClient } from 'modules/core/class/api/ApiClient';
import { ResourceResponse } from 'modules/core/model/response';
import { logger } from 'modules/core/logger';
import { CountryMapping } from 'modules/mappings/models';
import { ApiError } from 'modules/core/types';

interface CountryMappingsResult {
    status?: number;
    requestId?: string;
    countryMappings?: CountryMapping[];
}

export class CountryMappingService extends ApiClient {
    protected apiName = 'MERP_API';
    protected resource = 'mappings';
    protected path = 'country';

    public async getCountryMappingsAsync(): Promise<CountryMappingsResult> {
        try {
            const json: ResourceResponse = await this.get(this.path);

            const { result, requestId } = json;

            const countryMappings = result as CountryMapping[];

            return { requestId, countryMappings: countryMappings, status: 200 };
        } catch (error) {
            logger.error(error);
            return { countryMappings: undefined, status: (error as ApiError)?.response?.status };
        }
    }
}

export const countryMappingService = new CountryMappingService();
