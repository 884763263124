import { useState } from 'react';
import { Receipt } from 'modules/purchase/models';

export const useRemoveSpendModal = () => {
    const [removeSpend, updateRemoveSpend] = useState<Receipt | undefined>();

    const showRemoveSpendModal = (receipt: Receipt) => {
        updateRemoveSpend(receipt);
    };

    const onRemoveSpendAction = (shouldRemove: boolean, callback: (receipt: Receipt) => void) => {
        if (shouldRemove && removeSpend) {
            callback(removeSpend);
        }

        updateRemoveSpend(undefined);
    };

    return { removeSpend, showRemoveSpendModal, onRemoveSpendAction };
};
