/* eslint-disable @typescript-eslint/no-explicit-any */
import { YearlyAccountingForecast } from '@amzn/merp-core/models/budgetManagement';
import { TableRow } from 'modules/core/model';
import { YearForecast } from '../model/YearForecast';

export function mapToYearForecast(forecasts: YearlyAccountingForecast[] | undefined) {
    if (!forecasts) {
        return [];
    }

    const tableRows: TableRow<YearForecast>[] = [];

    let currentCOA = forecasts.length > 0 ? generateCoaCombination(forecasts[0]) : undefined;

    for (const [index, forecast] of forecasts.entries()) {
        const displayCoaInRow = index === 0 || currentCOA !== generateCoaCombination(forecast);

        tableRows.push({
            number: index,
            rowId: forecast.id,
            selected: false,
            payload: {
                isFirstYearRow: displayCoaInRow,
                ...forecast
            } as YearForecast
        });

        currentCOA = generateCoaCombination(forecast);
    }

    return tableRows;
}

export function generateCoaCombination(row: YearlyAccountingForecast) {
    const { coa1, coa2, coa3, coa4, coa5, coa6, coa7, coa8 } = row;
    return `${coa1}-${coa2}-${coa3}-${coa4}-${coa5}-${coa6}-${coa7}-${coa8}`;
}
