import { BigObjectiveRollUp, ChannelRollUp, SMARTeRollUp } from '@amzn/merp-core/models';

import { logger } from 'modules/core';
import { RollUpResult } from '../model';
import { RollUpTypes } from '../constants';
import { ApiError } from 'modules/core/types';
import { ResourceResponse } from 'modules/core/model';
import { ApiClient } from 'modules/core/class/api/ApiClient';

export class RollUpsService extends ApiClient {
    protected resource = 'rollups';
    protected apiName = 'MERP_REPORTS';

    public async getBigObjective(): Promise<RollUpResult<BigObjectiveRollUp>> {
        try {
            const payload: ResourceResponse = await this.get(RollUpTypes.BigObjective);

            return { succeeded: true, result: payload.result as BigObjectiveRollUp[] };
        } catch (error) {
            const { data } = (error as ApiError).response;
            logger.error(data.error);

            return { succeeded: false, error: data.error };
        }
    }

    public async getChannel(): Promise<RollUpResult<ChannelRollUp>> {
        try {
            const payload: ResourceResponse = await this.get(RollUpTypes.Channel);

            return { succeeded: true, result: payload.result as ChannelRollUp[] };
        } catch (error) {
            const { data } = (error as ApiError).response;
            logger.error(data.error);

            return { succeeded: false, error: data.error };
        }
    }

    public async getSMARTe(): Promise<RollUpResult<SMARTeRollUp>> {
        try {
            const payload: ResourceResponse = await this.get(RollUpTypes.SMARTe);

            return { succeeded: true, result: payload.result as SMARTeRollUp[] };
        } catch (error) {
            const { data } = (error as ApiError).response;
            logger.error(data.error);

            return { succeeded: false, error: data.error };
        }
    }
}

export const rollUpService = new RollUpsService();
