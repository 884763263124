import { useTranslation } from 'react-i18next';
import { PropertyFilterProps } from '@amzn/awsui-components-react';

export const PropertyFilteringStrings = () => {
    const { t } = useTranslation('purchase');

    const i18nStrings: PropertyFilterProps.I18nStrings = {
        filteringAriaLabel: t('dashboard.search.filteringAriaLabel'),
        dismissAriaLabel: t('dashboard.search.dismissAriaLabel'),

        filteringPlaceholder: t('dashboard.search.placeholder'),
        groupValuesText: t('dashboard.search.groupValuesText'),
        groupPropertiesText: t('dashboard.search.groupPropertiesText'),
        operatorsText: t('dashboard.search.operatorsText'),

        operationAndText: t('dashboard.search.operationAndText'),
        operationOrText: t('dashboard.search.operationOrText'),

        operatorLessText: t('dashboard.search.operatorLessText'),
        operatorLessOrEqualText: t('dashboard.search.operatorLessOrEqualText'),
        operatorGreaterText: t('dashboard.search.operatorGreaterText'),
        operatorGreaterOrEqualText: t('dashboard.search.operatorGreaterOrEqualText'),
        operatorContainsText: t('dashboard.search.operatorContainsText'),
        operatorDoesNotContainText: t('dashboard.search.operatorDoesNotContainText'),
        operatorEqualsText: t('dashboard.search.operatorEqualsText'),
        operatorDoesNotEqualText: t('dashboard.search.operatorDoesNotEqualText'),

        editTokenHeader: t('dashboard.search.editTokenHeader'),
        propertyText: t('dashboard.search.propertyText'),
        operatorText: t('dashboard.search.operatorText'),
        valueText: t('dashboard.search.valueText'),
        cancelActionText: t('dashboard.search.cancelActionText'),
        applyActionText: t('dashboard.search.applyActionText'),
        allPropertiesLabel: t('dashboard.search.allPropertiesLabel'),

        tokenLimitShowMore: t('dashboard.search.tokenLimitShowMore'),
        tokenLimitShowFewer: t('dashboard.search.tokenLimitShowFewer'),
        clearFiltersText: t('dashboard.search.clearFiltersText'),
        removeTokenButtonAriaLabel: () => 'Remove token',
        enteredTextLabel: text => `Use: "${text}"`
    };

    return i18nStrings;
};
