import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Box, SpaceBetween } from '@amzn/awsui-components-react';

interface YesNoModalProps {
    title: string;
    content: string;
    visible: boolean;
    onDismiss: () => void;
    yesFunction: () => void;
    noFunction?: () => void;
}

export const YesNoModal = (props: YesNoModalProps) => {
    const { title, content, onDismiss, yesFunction, noFunction, visible } = props;

    const { t } = useTranslation('purchase');

    return (
        <Modal
            data-cy="yesNoModal"
            visible={visible}
            onDismiss={onDismiss}
            header={title}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={noFunction} data-cy="yesNoModal.btnNo">
                            {t('dashboard.noBtnText')}
                        </Button>
                        <Button variant="primary" onClick={yesFunction} data-cy="yesNoModal.btnYes">
                            {t('dashboard.yesBtnText')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {content}
        </Modal>
    );
};
