import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, SpaceBetween, Box } from '@amzn/awsui-components-react';

interface SoftCloseConfirmModalProps {
    isVisible: boolean;
    onDismiss: () => void;
    onConfirm?: (canSoftClose: boolean) => void;
    onCancel?: () => void;
}

export const SoftCloseConfirmModal = (props: SoftCloseConfirmModalProps) => {
    const { isVisible, onCancel, onConfirm, onDismiss } = props;

    const { t } = useTranslation('purchase');

    const header = t('softClosePurchase.softCloseConfirmModalHeader');
    const content = t('softClosePurchase.softCloseConfirmModalMessage');

    function onCancelClick() {
        onCancel?.();
    }

    function onConfirmSoftClose(canRemove: boolean) {
        onConfirm?.(canRemove);
    }

    return (
        <Modal
            data-testid="confirmModal"
            onDismiss={onDismiss}
            visible={isVisible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onCancelClick()}>
                            {t('softClosePurchase.cancel')}
                        </Button>
                        <Button variant="primary" onClick={() => onConfirmSoftClose(true)}>
                            {t('softClosePurchase.ok')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={header}
        >
            {content}
        </Modal>
    );
};
