import React from 'react';
import { ErrorContent } from '../components/errorContent/ErrorContent';
import { RouteComponentProps } from '@reach/router';

interface ErrorProps extends RouteComponentProps {
    message: string;
    btnContent: string;
    btnFunction?: () => void;
}

export const Error = ({ message, btnContent, btnFunction }: ErrorProps) => {
    return <ErrorContent message={message} btnContent={btnContent} btnFunction={btnFunction} />;
};
