/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import styles from './BudgetTableHeaderTemplate.module.scss';

interface BudgetTableHeaderTemplateProps {
    title: string;
    emptyTitle: string;
    loading: boolean;
    tableItemCount?: number;
    buttons?: any[];
}

export const BudgetTableHeaderTemplate = (props: BudgetTableHeaderTemplateProps) => {
    const { title, emptyTitle, loading, tableItemCount, buttons } = props;

    const validNumber = (count?: number) => {
        return count != undefined && count > 0;
    };

    const getTitle = () => {
        let displayTitle = title;

        if (!loading && !validNumber(tableItemCount)) {
            displayTitle = emptyTitle;
        }

        return (
            <h2 className={'awsui-util-d-ib'}>
                {displayTitle}
                {!loading && validNumber(tableItemCount) && (
                    <span id="custom-action-stripe-counter" className="awsui-util-header-counter">
                        {` (${tableItemCount})`}
                    </span>
                )}
            </h2>
        );
    };

    return (
        <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">{getTitle()}</div>
            <div className="awsui-util-action-stripe-group">
                {buttons?.map((button, index) => (
                    <div className={styles['button-padding']} key={index}>
                        {button}
                    </div>
                ))}
            </div>
        </div>
    );
};
