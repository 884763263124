import { NON_MARKETING_TEAMS } from 'modules/core/constants';

export const brassTeamsIncludesNonMarketingTeams = (brassTeams: string[]) => {
    let includeNonMarketing = false;

    NON_MARKETING_TEAMS.forEach(team => {
        includeNonMarketing = brassTeams.includes(team, 0) || includeNonMarketing;
    });

    return includeNonMarketing;
};
