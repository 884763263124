/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableRow } from 'modules/core/model/TableRow';
import { I18nFormatter } from 'modules/core/class';
import { BudgetTableTemplate } from '../BudgetTableTemplate';
import { BudgetTableHeaderTemplate } from '../BudgetTableHeaderTemplate';
import { YearForecast } from 'modules/budgetManagement/model/YearForecast';
import { BudgetTablePagination } from '../BudgetTablePagination/BudgetTablePagination';

interface YearBudgetTableProps {
    loading: boolean;
    title: string;
    emptyTitle: string;
    items: any[];
    headerButtons: any[];
}

export const YearBudgetTable = (props: YearBudgetTableProps) => {
    const { items, headerButtons, title, emptyTitle, loading } = props;

    const { t } = useTranslation('budgetManagement');

    const columns = [
        {
            id: 'company',
            header: t('table.company'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa1 : ''}</div>
        },
        {
            id: 'location',
            header: t('table.location'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa2 : ''}</div>
        },
        {
            id: 'costCenter',
            header: t('table.costCenter'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa3 : ''}</div>
        },
        {
            id: 'glAccount',
            header: t('table.glAccount'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa4 : ''}</div>
        },
        {
            id: 'productLine',
            header: t('table.productLine'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa5 : ''}</div>
        },
        {
            id: 'channel',
            header: t('table.channel'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa6 : ''}</div>
        },
        {
            id: 'project',
            header: t('table.project'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa7 : ''}</div>
        },
        {
            id: 'eighthSegment',
            header: t('table.eighthSegment'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.isFirstYearRow ? row.payload.coa8 : ''}</div>
        },
        {
            id: 'year',
            header: t('table.budgetCyclePlan.year'),
            cell: (row: TableRow<YearForecast>) => <div>{row.payload.year ? row.payload.year.toString() : ''} </div>
        },
        {
            id: 'budget',
            header: t('table.budgetCyclePlan.budget'),
            cell: (row: TableRow<YearForecast>) => <div>{I18nFormatter.amount(t, row.payload.total)} </div>
        }
    ];

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const pagination = <BudgetTablePagination openEnded={false} getItems={async () => {}} />;

    const header = () => {
        return (
            <BudgetTableHeaderTemplate
                title={title}
                emptyTitle={emptyTitle}
                loading={loading}
                tableItemCount={items.length}
                buttons={headerButtons}
            />
        );
    };

    return (
        <BudgetTableTemplate
            loading={loading}
            items={items}
            columns={columns}
            header={header()}
            pagination={pagination}
        />
    );
};
