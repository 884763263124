import { CountryMapping } from 'modules/mappings/models';

interface CountryMappingsResult {
    status?: number;
    requestId?: string;
    countryMappings?: CountryMapping[];
}

class MockedCountryMappingService {
    public async getCountryMappingsAsync(): Promise<CountryMappingsResult> {
        const result = [
            {
                companyCode: '2D',
                countryCode: 'USA'
            },
            {
                companyCode: '4R',
                countryCode: 'CAN'
            },
            {
                companyCode: '9X',
                countryCode: 'IND'
            },
            {
                companyCode: '9R',
                countryCode: 'CAN'
            },
            {
                companyCode: '2R',
                countryCode: 'AUS'
            },
            {
                companyCode: '7B',
                countryCode: 'USA'
            },
            {
                companyCode: '6S',
                countryCode: 'IND'
            }
        ];

        const resultBody: CountryMappingsResult = {
            requestId: 'requestId',
            countryMappings: result,
            status: 200
        };

        return resultBody;
    }
}

export const mockedCountryMappingService = new MockedCountryMappingService();
