import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserClaims } from 'modules/auth/model';
import { ApplicationState } from '..';

const initialState: UserClaims = { alias: '' };

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser(state, action: PayloadAction<UserClaims>) {
            Object.assign(state, action.payload);
        }
    }
});

const isFinancialAnalyst = (state: ApplicationState) => {
    const regexPattern = /([A-Za-z])(-[A-Za-z])?-merp-financial-analyst/;
    return state.user.ldap?.some(team => team.match(regexPattern)) || false;
};

const getTeamNames = (state: ApplicationState) => {
    const ldapGroups = state.user.ldap?.filter(team => team.includes('merp-financial-analyst'));

    const extractedTeams = ldapGroups?.map(group => group.substring(0, group.indexOf('merp') - 1));

    return extractedTeams?.map(team => `Merp-SpendingPlatform-SuperUser-${team}`) || [];
};

const { actions, reducer } = userSlice;

const { updateUser } = actions;

export { isFinancialAnalyst, getTeamNames, updateUser, reducer as userReducer };
