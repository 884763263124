import React from 'react';
import styles from './NameCard.module.scss';

export interface NameCardProps {
    login: string;
    firstName?: string;
    lastName?: string;
}

export const NameCard = (props: NameCardProps) => {
    const { login } = props;
    return (
        <div>
            <img
                className={styles['name-card-image']}
                src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${login}`}
                alt="avatar"
            />
            <p className={styles['name-card-name']}>{login}</p>
        </div>
    );
};
