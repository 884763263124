import React from 'react';
import { AppLayout, Header, Pagination } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';

import { ErrorRouter } from 'modules/error/components';
import { ApplicationState } from 'modules/app/store';
import {
    CustomerTeamListTable,
    CustomerTeamListTableHeader,
    CustomerTeamListTablePropertyFilter
} from 'modules/onboarding/components';
import { useTeams } from 'modules/onboarding/hooks/useTeams.hook';
import { useCollection } from '@amzn/awsui-collection-hooks';

export const CustomerTeamListPage = (_props: RouteComponentProps) => {
    const { t } = useTranslation('onboarding');
    const applicationHealth = useSelector((state: ApplicationState) => state.applicationHealth);

    const title: string = t('CustomerTeamListPage.title');

    const { teams, loading, setTeamName } = useTeams();

    teams.forEach(team => {
        const poOnboardDate = new Date(team.payload.latestPoOnboardTime);
        team.payload.latestPoOnboardTime = `${poOnboardDate.getUTCFullYear()}/${poOnboardDate.getUTCMonth()}/${poOnboardDate.getUTCDate()}`;
    });

    const { items, collectionProps, paginationProps } = useCollection(teams, {
        pagination: {
            pageSize: 15
        }
    });

    const header: JSX.Element = (
        <CustomerTeamListTableHeader
            // TODO: uncomment when want to allow user to create new teams
            // actions={
            //     <SpaceBetween direction="horizontal" size="xs">
            //         <Link to={'/onboarding/createteam'}>
            //             <Button variant="primary">{t('CustomerTeamListTableHeader.createTeamButtonText')}</Button>
            //         </Link>
            //     </SpaceBetween>
            // }
            numberOfTotalTableRowTeams={teams.length}
        />
    );
    const pagination: JSX.Element = <Pagination {...paginationProps} />;

    const propertyFilter = <CustomerTeamListTablePropertyFilter setTeamName={setTeamName} />;

    return (
        <AppLayout
            content={
                applicationHealth.showError ? (
                    <div className="errorRouter">
                        <ErrorRouter errorStatusCode={applicationHealth.errorCode} />
                    </div>
                ) : (
                    <React.Fragment>
                        <Header variant="h1">{title}</Header>
                        <CustomerTeamListTable
                            {...collectionProps}
                            loading={loading}
                            header={header}
                            items={items}
                            pagination={pagination}
                            filter={propertyFilter}
                        />
                    </React.Fragment>
                )
            }
            toolsHide={true}
            navigationHide={true}
        />
    );
};
