import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as model from 'modules/core/model';
import { Receipt } from 'modules/purchase/models';
import { InvoiceAllocation } from 'modules/invoices/models';
import { ExpandableCell } from 'modules/core/components/Table';
import { ChannelSpend } from 'modules/channel/models/ChannelSpend';
import { ApplicationState, getInvoiceLines } from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';

import styles from './AllocationCell.module.scss';

interface AllocationCellProps {
    editMode: boolean;
    receipt: Receipt;
    isChannelAllocation: boolean;
    receiptIndex?: number;
    onOpenEditInvoiceModal?: (index: number) => void;
    onOpenEditChannelModal?: (index: number) => void;
}

export const AllocationCell = (props: AllocationCellProps) => {
    const {
        editMode,
        receipt,
        isChannelAllocation,
        receiptIndex,
        onOpenEditInvoiceModal,
        onOpenEditChannelModal
    } = props;

    const { t } = useTranslation(['purchase', 'channelSpend']);

    const [row, updateRow] = useState<model.TableRow<Receipt>>({
        number: 1,
        expanded: false,
        selected: false,
        payload: receipt,
        rowId: receipt.receiptNumber
    });

    const getChannelAllocations = () => {
        return receipt.channelAllocations ? [...receipt.channelAllocations] : [];
    };

    const getInvoiceAllocations = () => {
        return receipt.allocations ? [...receipt.allocations] : [];
    };

    const invoiceLines = useSelector((state: ApplicationState) => getInvoiceLines(state, receipt.lineNumber));
    const hasInvoices = invoiceLines?.length > 0 || false;
    const allocations = isChannelAllocation ? getChannelAllocations() : getInvoiceAllocations();

    const toggleRow = () => {
        const newRow = { ...row };
        newRow.expanded = !row.expanded;
        updateRow(newRow);
    };

    const showToggle = allocations.length > 1;

    const [first] = allocations.splice(0, 1);

    const Allocation = ({
        allocation,
        isChannelAllocation
    }: {
        allocation: InvoiceAllocation | ChannelSpend;
        isChannelAllocation: boolean;
    }) => {
        if (!allocation) {
            return (
                <span className={styles['with-top-padding']}>
                    {isChannelAllocation
                        ? t('purchase:channels.noMatchedChannels')
                        : t('purchase:invoices.noMatchedInvoices')}
                </span>
            );
        }

        let id;
        let allocationAmount;

        if (isChannelAllocation) {
            const { type, amount } = allocation as ChannelSpend;
            id = t(`channelSpend:channelsList.${type}`);
            allocationAmount = amount;
        } else {
            const { invoiceNumber, invoiceLineId, allocatedAmount } = allocation as InvoiceAllocation;
            const invoiceLineNumber = invoiceLines.find(line => line.id === invoiceLineId)?.lineNumber || '';
            id = `${invoiceNumber} - line ${invoiceLineNumber}`;
            allocationAmount = allocatedAmount;
        }

        return (
            <span className={styles['with-top-padding']}>
                {id} <small>( {I18nFormatter.amount(t, allocationAmount)})</small>
            </span>
        );
    };

    const openEditInvoiceModal = () => {
        if (onOpenEditInvoiceModal && receiptIndex !== undefined) {
            onOpenEditInvoiceModal(receiptIndex);
        }
    };

    const openEditChannelModal = () => {
        if (onOpenEditChannelModal && receiptIndex !== undefined) {
            onOpenEditChannelModal(receiptIndex);
        }
    };

    const EditButtons = () => (
        <>
            <Allocation allocation={first} isChannelAllocation={isChannelAllocation} />
            {editMode && hasInvoices && onOpenEditInvoiceModal && (
                <button className="awsui-util-help-info-link" onClick={openEditInvoiceModal}>
                    {t('edit')}
                </button>
            )}
            {editMode && onOpenEditChannelModal && isChannelAllocation && (
                <button className="awsui-util-help-info-link" onClick={openEditChannelModal}>
                    {t('edit')}
                </button>
            )}
        </>
    );
    const multipleCellContent = row.expanded ? <EditButtons /> : t('purchase:invoices.multiple');

    const cellClass = ['mb-auto', 'mt-auto'];

    const cell = <div className={cellClass.join(' ')}>{showToggle ? multipleCellContent : <EditButtons />}</div>;
    const contentStyle = `has-display-flex with-columns has-space-between is-full-height ${styles['clear-expanded-section']}`;

    const content = (
        <div className={contentStyle}>
            {allocations.map((a: ChannelSpend | InvoiceAllocation, index: number) => (
                <Allocation allocation={a} key={index} isChannelAllocation={isChannelAllocation} />
            ))}
        </div>
    );

    return <ExpandableCell row={row} cell={cell} content={content} control={showToggle} onChange={toggleRow} />;
};
