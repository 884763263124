import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineVersion } from 'modules/audit/models';
import { NameCard } from 'modules/audit/components/NameCard';
import { BigObjectiveLine } from './BigObjectiveLine';
import styles from './BigObjectiveVersion.module.scss';
import { ColumnLayout } from '@amzn/awsui-components-react';

export interface BigObjectiveVersionProps {
    lineVersions: LineVersion[];
}

export const BigObjectiveVersion = (props: BigObjectiveVersionProps) => {
    const { lineVersions } = props;
    const { t } = useTranslation(['purchase', 'bigRock']);

    return (
        <div className="awsui-util-container awsui-util-no-gutters">
            <div className={`awsui-util-container-header ${styles['big-objective-version-title']}`}>
                <h2>{t('version.bigObjectiveChangedTitle')}</h2>
            </div>
            <div className={`awsui-grid ${styles['big-objective-version-container']}`}>
                <div className="awsui-row">
                    <div className="col-1">
                        <NameCard
                            login={lineVersions[0].updaterLogin}
                            firstName={lineVersions[0].updaterFirstName}
                            lastName={lineVersions[0].updaterLastName}
                        />
                    </div>
                    <div className="col-9">
                        <ColumnLayout columns={1}>
                            <div data-awsui-column-layout-root="true">
                                {lineVersions.map(lineVersion => (
                                    <BigObjectiveLine key={lineVersion.lineNumber} lineVersion={lineVersion} />
                                ))}
                            </div>
                        </ColumnLayout>
                        <ColumnLayout borders="vertical" columns={2}>
                            <div data-awsui-column-layout-root="true">
                                <div className="awsui-util-spacing-v-s">
                                    <div>
                                        <strong>{t('version.original')}</strong>
                                    </div>
                                    <div className={styles['with-no-gutters']}>
                                        {t(`bigRock:dropdown.objectives.${lineVersions[0].originalBigObjective}`)}
                                    </div>
                                </div>
                                <div className={'awsui-util-spacing-v-s'}>
                                    <div>
                                        <strong>{t('version.modified')}</strong>
                                    </div>
                                    <div className={styles['with-no-gutters']}>
                                        {t(`bigRock:dropdown.objectives.${lineVersions[0].modifiedBigObjective}`)}
                                    </div>
                                </div>
                            </div>
                        </ColumnLayout>
                    </div>
                </div>
            </div>
        </div>
    );
};
