import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorProps {
    errorCode: number;
    accessError?: boolean;
}

const initialState = {
    showError: false,
    errorCode: 200,
    accessError: false
};

const applicationHealthSlice = createSlice({
    name: 'applicationHealth',
    initialState,
    reducers: {
        showErrorPage(state, action: PayloadAction<ErrorProps>) {
            const { errorCode, accessError } = action.payload;
            Object.assign(state, { showError: true, errorCode: errorCode || 400, accessError });
        },
        resetErrorPage(state) {
            Object.assign(state, { showError: false, errorCode: 200 });
        }
    }
});

const { actions, reducer } = applicationHealthSlice;

const { showErrorPage, resetErrorPage } = actions;

export { showErrorPage, resetErrorPage, reducer as appHealthReducer };
