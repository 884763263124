import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, SpaceBetween, Box } from '@amzn/awsui-components-react';

interface ConfirmModalProps {
    isVisible: boolean;
    poCanBeConfirmed: boolean;
    onDismiss: () => void;
    onComfirm: () => void;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
    const { isVisible, poCanBeConfirmed, onComfirm, onDismiss } = props;

    const { t } = useTranslation('purchase');

    const header = poCanBeConfirmed ? t('confirmModalHeader') : t('confirmModalHeaderEdit');
    const content = poCanBeConfirmed ? t('confirmModalMessage') : t('confirmModalMessageEdit');

    return (
        <Modal
            data-cy="confirmModal"
            onDismiss={onDismiss}
            visible={isVisible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    {poCanBeConfirmed && (
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={onDismiss} data-cy="confirmModalNoBtn">
                                {t('dashboard.noBtnText')}
                            </Button>
                            <Button variant="primary" data-cy="confirmModalYesBtn" onClick={onComfirm}>
                                {t('dashboard.yesBtnText')}
                            </Button>
                        </SpaceBetween>
                    )}
                    {!poCanBeConfirmed && (
                        <Button variant="primary" data-cy="confirmModalCancelBtn" onClick={onDismiss}>
                            {t('dashboard.okBtnText')}
                        </Button>
                    )}
                </Box>
            }
            header={header}
        >
            {content}
        </Modal>
    );
};
