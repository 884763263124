import React, { useEffect } from 'react';
import { CustomDetailEvent, Input } from '@amzn/awsui-components-react-v2';

import styles from './EditInvoices.module.scss';
import { InvoiceAllocation } from 'modules/invoices/models';
import { useFormContext } from 'react-hook-form';

export interface EditInvoiceInputProps {
    allocation: InvoiceAllocation;
    index: number;
    lastAllocation: string;
    onChange?: (allocationId: string, value: string) => void;
}

export const EditInvoiceInput = (props: EditInvoiceInputProps) => {
    const { allocation, index, onChange } = props;

    const { allocatedAmount } = allocation;

    const allocationName = `allocation[${index}]`;

    const { register, setValue, triggerValidation } = useFormContext();

    const setAllocationValue = () => {
        if (allocatedAmount) {
            setValue(allocationName, allocatedAmount, true);
        }
    };

    useEffect(() => {
        register({ name: allocationName, type: 'custom' });
    }, [register, allocationName]);

    const useEffectOnRegister = (setInput: () => void) => useEffect(setInput, [register, allocationName]);
    useEffectOnRegister(setAllocationValue);

    const onAllocationUpdated = ($event: CustomDetailEvent<Input.ChangeDetail>) => {
        const { value } = $event.detail;

        setValue(allocationName, value);
        triggerValidation(allocationName);

        if (onChange) {
            onChange(allocation.id, value);
        }
    };

    return (
        <Input
            data-cy="allocatedAmount"
            name={allocationName}
            value={allocatedAmount?.toString()}
            className={styles['has-narrow-input']}
            onInput={onAllocationUpdated}
            invalid={!allocation?.hasValidAllocatedAmount}
        />
    );
};
