import { useState } from 'react';

import { logger } from 'modules/core/logger';
import { utils as xlsxUtils, read as xlsxRead } from 'xlsx';

export function useBulkUploadAccountingStrings() {
    const [errors, setErrors] = useState<string[]>([]);

    const EDIT_TAB = 'Edit';
    const MANDATORY_FIELDS = [
        'Company',
        'Location',
        'Cost Center',
        'Account',
        'Product Line',
        'Channel',
        'Project',
        '8th Segment',
        'Planning Currency',
        'Big Objective',
        'Channel Type',
        'Channel % Breakdown',
        'SMARTe Classification',
        'Mark Delete (Y)'
    ];

    const HAS_DUPLICATE_HEADERS = 'Duplicate headers found in the input file';
    const MISSING_HEADERS = `Please ensure mandatory sheet ${EDIT_TAB} and mandatory columns ${MANDATORY_FIELDS.join(
        ', '
    )} are present in the file uploaded`;

    const validateFile = async (fileUrl: string) => {
        setErrors([]);

        await validateEditTabHeaders(fileUrl);
    };

    const validateEditTabHeaders = async (fileUrl: string) => {
        const headers = await extractHeaders(fileUrl, EDIT_TAB);

        hasMandatoryHeaders(headers);
        hasNoDuplicateHeaders(headers);
    };

    const extractHeaders = async (fileUrl: string, tab: string) => {
        try {
            const resp = await fetch(fileUrl);
            const workbook = xlsxRead(await resp.arrayBuffer());

            return (xlsxUtils.sheet_to_json(workbook.Sheets[tab], { header: 1 })[0] as string[]) || [];
        } catch (error) {
            logger.error(error);
            return [];
        }
    };

    const hasMandatoryHeaders = async (headers: string[]) => {
        const validHeaders = MANDATORY_FIELDS.every(i => headers.includes(i));

        if (validHeaders) return;

        setErrors([...errors, MISSING_HEADERS]);
    };

    const hasNoDuplicateHeaders = async (headers: string[]) => {
        if (new Set(headers).size === headers.length) return;

        setErrors([...errors, HAS_DUPLICATE_HEADERS]);
    };

    return { validateFile, errors };
}
