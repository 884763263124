import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from '@amzn/awsui-components-react';

import { OptionDefinition } from 'modules/core/types';
import { isNonMarketing, isXcm } from 'modules/app/store';
import { useBrassTeams } from 'modules/budgetManagement/hooks';
import { ObjectiveOptions, XcmOnlyObjectiveOptions } from '../../constants';
import { brassTeamsIncludesNonMarketingTeams } from 'modules/core/helpers/nonMarketingValidator';

interface BigRockDropdownProps {
    defaultSelection?: string;
    onChange: (objective: string) => void;
}

export const BigRockDropdown = ({ onChange, defaultSelection }: BigRockDropdownProps) => {
    const { t } = useTranslation('bigRock');
    const isTeamXcm = useSelector(isXcm);
    const isMarketingPR = !useSelector(isNonMarketing);
    const [selectedOption, setSelectedOption] = useState<SelectProps.Option>();
    const { brassTeams } = useBrassTeams();

    const optionsList = isTeamXcm
        ? Object.values(XcmOnlyObjectiveOptions).map(id => ({
              value: id,
              label: t(`dropdown.objectives.${id}`)
          }))
        : Object.values(ObjectiveOptions).map(id => ({
              value: id,
              label: t(`dropdown.objectives.${id}`)
          }));
    useEffect(() => {
        if (!brassTeams) {
            return;
        }

        const notApplicableIndex = optionsList.findIndex(option => option.value === 'notApplicable');

        if (!brassTeamsIncludesNonMarketingTeams(brassTeams) && notApplicableIndex > -1 && isMarketingPR) {
            optionsList.splice(notApplicableIndex, 1);
        }
    }, [brassTeams, optionsList]);

    const onObjectiveChange = (selectedOption: OptionDefinition) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption.value || '');
    };

    useEffect(() => {
        optionsList.forEach(option => {
            if (option.value === defaultSelection) setSelectedOption(option);
        });
    }, [defaultSelection, setSelectedOption]);

    return (
        <Select
            selectedOption={selectedOption || null}
            options={optionsList}
            data-cy="BigRockObjectiveDropDown"
            placeholder={t('dropdown.placeholderSelect')}
            onChange={({ detail }) => onObjectiveChange(detail.selectedOption)}
        />
    );
};
