import { logger } from 'modules/core';
import { ApiError } from 'modules/core/types';
import { LineVersion } from '../models/LineVersion';
import { PurchaseService } from 'modules/purchase/services';
import { RequestParams } from 'modules/core/class/api/RequestParams';
import { mapToLineVersion } from 'modules/core/mappers/lineVersion.mappers';
import { PaginatedResult, PaginatedQuery } from 'modules/core/model';

export class VersionListService extends PurchaseService {
    public async getVersionsAsync(purchaseId: string, query?: PaginatedQuery): Promise<PaginatedResult<LineVersion>> {
        try {
            const queryStringParameters = this.mapPaginatedQueryToStringParameters({ purchaseId }, query);

            const jsonResponse = await this.get(`${purchaseId}/history`, {
                queryStringParameters
            });

            if (jsonResponse.result) {
                const { result, requestId } = jsonResponse;
                const { lineVersions, lastEvaluatedKey } = result;
                const items = lineVersions.map(mapToLineVersion);

                return { requestId, items, lastEvaluatedKey, status: 200 };
            }

            const { lineVersions, lastEvaluatedKey } = jsonResponse;
            const items = lineVersions.map(mapToLineVersion);
            return { items, lastEvaluatedKey, status: 200 };
        } catch (error) {
            logger.error(error);
            return { items: undefined, status: (error as ApiError)?.response?.status };
        }
    }

    private mapPaginatedQueryToStringParameters(target: RequestParams, source?: PaginatedQuery): RequestParams {
        if (source?.lastEvaluatedKey) {
            target.lastEvaluatedKey = JSON.stringify(source.lastEvaluatedKey);
        }
        if (source?.filterBy) {
            target.filterBy = source.filterBy;
        }
        if (source?.pageSize) {
            target.pageSize = source.pageSize;
        }

        return target;
    }
}
export const versionListService = new VersionListService();
