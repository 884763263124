import * as yup from 'yup';

export const DateInputValidationSchema = yup.object().shape({
    startDate: yup.date(),
    endDate: yup.date().when('startDate', (startDate: Date) => {
        if (startDate) {
            return yup
                .date()
                .min(startDate, 'End Date is required to be equal to or greater than start date')
                .typeError('End Date is required');
        }
    })
});
