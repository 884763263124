import { COACombination } from 'modules/onboarding/model';
import { TableRow } from 'modules/core/model';

export const mapCOACombinationToTableRow = (
    coaCombination: COACombination,
    index: number
): TableRow<COACombination> => {
    return {
        number: index,
        selected: false,
        rowId: coaCombination.toString(),
        payload: coaCombination
    };
};
