import { budgetService } from '../services';
import { logger } from 'modules/core/logger';
import { Budget } from '@amzn/merp-core/models/budgetManagement';

export function useGuidanceCycle() {
    async function startGuidanceCycle(budget: Budget): Promise<boolean> {
        try {
            const result = await budgetService.startGuidanceCycle(budget);
            return result.success;
        } catch (error) {
            logger.error(error);
            return false;
        }
    }

    return { startGuidanceCycle };
}
